import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useOrderContext } from '../OrderProvider';
import RenderImage from './RenderImage';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: theme.spacing(1),
      '& p:first-child': {
        fontWeight: 600,
        fontSize: 12,
        color: '#02475B',
      },
      '& p:last-child': {
        fontWeight: 400,
        fontSize: 12,
        color: '#02475B',
        maxWidth: 250,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
    thumbnailList: {
      display: 'flex',
      flexFlow: 'wrap',
      justifyContent: 'flex-start',
      marginRight: theme.spacing(1),
      gap: theme.spacing(1),
    },
    docName: {
      paddingTop: '6px',
      '& label': {
        fontSize: '12px',
        fontWeight: 600,
      },
      '&>div': {
        position: 'relative',
        '&>span': {
          position: 'absolute',
          fontWeight: 600,
          fontSize: 14,
          bottom: 7,
          lineHeight: '18px',
        },
        '&>div': {
          '&>div': {
            '&:before': {
              borderBottom: '2px solid #00B38e !important',
            },
            '&:after': {
              borderBottom: '2px solid #00B38e !important',
            },
          },
        },

        '& input': {
          fontSize: 14,
          fontWeight: 600,
          paddingLeft: 25,
        },
      },
    },
  })
);
interface PrescriptionProp {
  setDoctorName: (e) => void;
}

export const Prescriptions: React.FC<PrescriptionProp> = ({
  setDoctorName,
}) => {
  const classes = useStyles();
  const { selectedNonCartFlowOrder } = useOrderContext();
  const prescriptionURLs =
    selectedNonCartFlowOrder?.prescriptionItemsObj?.prescriptions?.map(
      (prescription: any) => prescription.prescriptionUrl
    );
  const comments = selectedNonCartFlowOrder?.prescriptionItemsObj?.comments;
  return (
    <>
      <h3>
        <span>DOCTOR & PRESCRIPTIONS</span>
      </h3>
      <div className={classes.container}>
        <div className={classes.docName}>
          <label>Doctor Name (optional)</label>
          <div>
            <span>Dr.</span>
            <TextField
              onChange={(e) => setDoctorName(e.target.value as string)}
              placeholder="Enter doctor name"
            />
          </div>
        </div>
        <div className={classes.thumbnailList}>
          {prescriptionURLs?.map((prescriptionURL: any) => (
            <RenderImage url={prescriptionURL} height={102} width={72} />
          ))}
        </div>
        <p>Comment:</p>
        <p title={comments}> {comments}</p>
      </div>
    </>
  );
};
