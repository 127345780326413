import { default as React, useEffect, useState } from 'react';
import Alert from '@material-ui/lab/Alert';
import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => {
  return {
    alert: {
      position: 'absolute',
      left: 0,
      top: 60,
      minWidth: 'calc(100% - 50px)',
      backgroundColor: '#D23D3D',
      borderRadius: 4,
      margin: '0px 8px',
      fontSize: 12,
      zIndex: 2,
    },
  };
});

type AlertToastProps = {
  alertMessage: string;
  setAlertMessage: (message: string) => void;
  timeOut?: number;
};

export const AlertToast: React.FC<AlertToastProps> = ({
  alertMessage,
  setAlertMessage,
  timeOut = 4000,
}) => {
  const classes = useStyles({});
  const [isAlertOpen, setIsAlertOpen] = useState<boolean>(false);

  const closeHandler = () => {
    setAlertMessage && setAlertMessage('');
    setIsAlertOpen(false);
  };

  useEffect(() => {
    setIsAlertOpen(!!alertMessage?.length);
  }, [alertMessage]);

  useEffect(() => {
    if (isAlertOpen) {
      const timerId = setTimeout(() => {
        setAlertMessage && setAlertMessage('');
        setIsAlertOpen(false);
      }, timeOut);
      return () => {
        clearTimeout(timerId);
      };
    }
  }, [isAlertOpen, timeOut, setAlertMessage]);

  return (
    <>
      {isAlertOpen && (
        <Alert
          variant={'filled'}
          icon={false}
          className={classes.alert}
          onClose={closeHandler}
        >
          {alertMessage || 'Something went wrong, please try again!'}
        </Alert>
      )}
    </>
  );
};
