import React from 'react';
//import { makeStyles, createStyles, Theme } from '@material-ui/styles';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Dialog } from '@material-ui/core';
import { DialogProps } from '@material-ui/core/Dialog';

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      padding: 0,
    },
    container: {
      display: 'block',
    },
    paper: {
      position: 'relative',
      backgroundColor: '#fff',
      borderRadius: 10,
      boxShadow: '0 5px 20px 0 rgba(0, 0, 0, 0.3)',
      overflowY: 'visible',
      margin: '88px auto 0 auto',
    },
    paperWidthSm: {
      maxWidth: 328,
    },
    paperWidthMd: {
      maxWidth: 676,
    },
    paperWidthLg: {
      maxWidth: '70%',
      marginTop: '10px',
      maxBlockSize: 'fit-content',
    },
  };
});

const AphDialog: React.FC<DialogProps> = (props) => {
  const defaultClasses = useStyles({});
  const classes = props.classes || defaultClasses;

  return (
    <Dialog {...props} classes={classes}>
      {props.children}
    </Dialog>
  );
};

export default AphDialog;
