import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import makeStyles from '@material-ui/styles/makeStyles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Tooltip from '@material-ui/core/Tooltip';
import Slider from 'react-slick';
import {
  DiagnosticCartItem,
  getActiveInclusions,
  getObservationCount,
  getTestsCirclePrice,
  getWebTdpUrl,
  hasLowerCase,
  PatientObjWithLineItems,
  toTitleCase,
} from './helpers/commonHelpers';
import AphButton from './AphButton';
import { useOrderContext } from '../OrderProvider';
import {
  CDN_URL,
  DIAGNOSTICS_GROUPPLAN,
  GET_DIAG_ITEM_RECOMMENDATIONS,
  GET_REPORT_TAT,
} from '../appConfig';

const useStyles = makeStyles((theme: Theme) => {
  return {
    container: {
      border: '1px solid #D4D4D4',
      borderRadius: 8,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      width: '100%',
      boxSizing: 'border-box',
    },
    sliderContainer: {
      width: 350,
      [theme.breakpoints.down(768)]: {
        width: '80vw',
      },
    },
    banner: {
      height: 38,
      width: '100%',
      background: '#007C9D',
      borderRadius: '5px 5px 0px 0px',
      '& > h2': {
        fontSize: 14,
        fontWeight: 700,
        padding: 10,
        margin: 0,
        color: '#fff',
      },
    },
    root: {
      width: 350,
      color: '#01475B',
      [theme.breakpoints.down(768)]: {
        width: '90vw',
      },
      [theme.breakpoints.down(300)]: {
        width: '92vw',
      },
      '& >div >img': {
        width: 24,
        height: 24,
      },
      '& .slick-prev': {
        left: -20,
        [theme.breakpoints.down('xs')]: {
          left: -13,
          display: 'none !important',
        },
      },
      '& .slick-next': {
        right: -15,
        [theme.breakpoints.down('xs')]: {
          right: -11,
          display: 'none !important',
        },
      },
    },
    circlePrice: {
      fontWeight: 600,
      opacity: '1 !important',
      fontSize: '14px !important',
      marginTop: '5px',
    },

    couponDiscount: {
      color: '#34AA55 !important',
      lineHeight: '18px',
      '& img': {
        width: 26,
        marginRight: 3,
      },
    },
    card: {
      padding: 7,
      outline: 'none',
      width: '152px !important',
      [theme.breakpoints.down(300)]: {
        marginLeft: 50,
      },
    },
    cardWrap: {
      padding: 8,
      position: 'relative',
      backgroundColor: '#fff',
      borderRadius: 10,
      boxShadow: '0 2px 4px 1px rgba(128, 128, 128, 0.3)',
    },
    productIcon: {
      textAlign: 'left',
      minHeight: '27px',
      display: 'flex',
      cursor: 'pointer',
      margin: '5px 0px',
      '& img': {
        width: 32,
        height: 32,
      },
    },
    productTitle: {
      fontSize: 16,
      color: '#01475b',
      fontWeight: 600,
      textAlign: 'left',
      height: 49,
      lineHeight: '20px',
      overflow: 'hidden',
      cursor: 'pointer',
      display: '-webkit-box',
      lineClamp: 2,
      boxOrient: 'vertical',
      maxHeight: 40,
      marginBottom: 4,
      '& a': {
        textDecoration: 'none',
        color: 'inherit',
        outline: 0,
      },
    },
    priceRow: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      '& div': {
        width: 'unset',
      },
    },
    savings: {
      fontWeight: 500,
      fontSize: 10,
      color: '#02475B',
      opacity: 0.87,
      marginLeft: 4,
      position: 'relative',
      top: 2,
    },
    slashPrice: {
      fontSize: '12px',
      lineHeight: '16px',
      fontWeight: 600,
      opacity: 0.6,
      textDecoration: 'line-through',
    },
    mrpLabel: {
      fontSize: '12px',
      lineHeight: '16px',
      fontWeight: 600,
      opacity: 0.6,
    },
    bottomSection: {
      borderTop: '0.5px solid rgba(2, 71, 91, 0.2)',
      marginTop: 5,
      paddingTop: 6,
      minHeight: 117,
      maxHeight: 117,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
    },
    priceGroup: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'flex-end',
      height: 80,
      fontSize: 16,
      fontWeight: 'bold',
      color: '#02475B',
      [theme.breakpoints.down(980)]: {
        height: '57px',
      },
      '& div': {
        width: 'fit-content',
        display: 'flex',
        minHeight: 20,
        alignItems: 'center',
      },
    },
    regularPrice: {
      fontWeight: 600,
      opacity: 0.6,
      paddingRight: 5,
      paddingBottom: 4,
      fontSize: '12px',
      textDecoration: 'line-through',
    },
    addToCart: {
      margin: '5px 0px',
      '& button': {
        color: '#fc9916',
        boxShadow: 'none',
        backgroundColor: 'transparent !important',
        padding: 0,
        minWidth: 'auto',
        fontSize: '14px',
      },
      '&>div': {
        display: 'inline-block',
      },
    },
    parameters: {
      fontWeight: 'normal',
      fontSize: '11px',
      lineHeight: '16px',
      minHeight: '16px',
      maxHeight: '16px',
      marginBottom: 8,
    },
    circleMemberText: {},
    circleMemberTextIn: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      '&>div:first-child': {
        marginBottom: 4,
        '& img': {
          width: 26,
          marginRight: 5,
        },
      },
      '& span': {
        marginRight: '4px',
        display: 'flex',
        whiteSpace: 'nowrap',
        '&:last-of-type': {
          marginRight: 0,
        },
      },
    },
    genderSpecificItem: {
      '& button': {
        opacity: 0.5,
        color: '#fc9916',
      },
    },
    loaderContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: 200,
    },
    percentDisc: {
      background: '#00B38E',
      color: '#fff',
      borderRadius: 60,
      padding: '0px 7px',
      lineHeight: '18px',
      fontSize: 10,
      fontWeight: 600,
      whiteSpace: 'nowrap',
    },
    cashBackContainer: {
      display: 'flex',
      alignItems: 'center',
      background: '#F3F3F3',
      borderRadius: 4,
      padding: '4px 8px',
      fontSize: 10,
      fontWeight: 500,
      marginTop: 4,
      '& img': {
        height: 12,
        marginRight: 5,
      },
      '& div': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        minHeight: 'auto',
        '& span': {
          whiteSpace: 'nowrap',
          '&.cashback': {
            color: '#0087BA',
          },
          '&.showPlus:before': {
            color: '#0087BA',
            content: "'+'",
            marginRight: '3px',
          },
          '&.showExtra:before': {
            color: '#0087BA',
            content: "'Extra'",
            marginRight: '3px',
          },
        },
      },
    },
  };
});

export default function TestRecommendation() {
  const {
    setDiagnosticCartItems,
    diagnosticCartItems,
    selectedProfile,
    selectedAddress,
    serviceabilityData,
    isUserCare,
    circleMemberShipPlan,
    couponApplied,
    circleBenefitsApplied,
    patientLineItems,
    setPatientLineItems,
    updatePatientLineItemPricing,
    orderSource,
    deletedPatientLineItems,
    setDeletedPatientLineItems,
  } = useOrderContext();
  const classes = useStyles();
  const [recommendationItems, setRecommendationItems] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [updatePricing, setUpdatePricing] = useState<boolean>(false);

  const selectedPatientLineItems = patientLineItems?.find(
    (item) => item?.patientID === selectedProfile?.id
  );
  const selectedpatientLineItemIds = selectedPatientLineItems?.lineItems?.map(
    (lineItem) => lineItem?.itemId
  );

  useEffect(() => {
    if (selectedProfile?.id && serviceabilityData?.cityID) {
      const selectedPatientLineItems = patientLineItems?.find(
        (item) => item?.patientID === selectedProfile?.id
      );
      if (selectedPatientLineItems?.lineItems?.length > 0) {
        const itemIds = selectedPatientLineItems?.lineItems?.map(
          (item) => item?.itemId
        );
        axios
          .post(`${GET_DIAG_ITEM_RECOMMENDATIONS}`, {
            itemIds: Array.from(itemIds),
            cityId: serviceabilityData.cityID,
            pincode: Number(localStorage.getItem('currentZip')),
            numberOfRecordsToFetch: 10,
            genderFilters: [selectedProfile.gender],
          })
          .then((data) => {
            setRecommendationItems(data?.data?.itemsData);
          });
      } else {
        setRecommendationItems([]);
      }
    }
  }, [selectedpatientLineItemIds?.length, selectedProfile, serviceabilityData]);

  useEffect(() => {
    if (updatePricing) {
      setUpdatePricing(false);
      updatePatientLineItemPricing(isUserCare || !!circleMemberShipPlan);
    }
  }, [
    updatePricing,
    isUserCare,
    circleMemberShipPlan,
    updatePatientLineItemPricing,
  ]);

  const getSliderSettings = () => {
    const nextArrow = (
      <img src={require('../images/right-arrow.svg')} alt="Right Arrow" />
    );

    const prevArrow = (
      <img src={require('../images/left-arrow.svg')} alt="Left Arrow" />
    );
    return {
      infinite: false,
      speed: 500,
      slidesToShow: 2,
      slidesToScroll: 2,
      centerMode: false,
      arrows: recommendationItems?.length > 2 ? true : false,
      nextArrow,
      prevArrow,
      responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            infinite: !true,
            centerPadding: '50px',
            arrows: recommendationItems?.length > 2 ? true : false,
            nextArrow,
            prevArrow,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            infinite: false,
            initialSlide: 0,
            arrows: recommendationItems?.length > 3 ? true : false,
            nextArrow,
            prevArrow,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            centerPadding: '0px',
            infinite: false,
            initialSlide: 0,
            arrows: false,
          },
        },
        {
          breakpoint: 300,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerPadding: '0px',
            infinite: false,
            initialSlide: 0,
            arrows: false,
          },
        },
      ],
    };
  };

  const renderCard = (hotSeller: any): JSX.Element => {
    const diagnosticPricing = getTestsCirclePrice(
      hotSeller?.diagnosticPricing,
      isUserCare || !!circleMemberShipPlan
    );
    const { cashbackText, mrp, price, showDiscountPercent, groupPlan } =
      diagnosticPricing;

    const observationCount = hotSeller?.allObservations?.length
      ? getActiveInclusions(hotSeller.allObservations)
      : getObservationCount(hotSeller?.diagnosticInclusions);

    const setUpLineItemsData = (itemData: DiagnosticCartItem) => {
      const itemPrices = getTestsCirclePrice(
        itemData?.diagnosticPricing,
        isUserCare || !!circleMemberShipPlan
      );
      const { price, groupPlan, mrp, priceBeforeDiscount, cashbackAmount } =
        itemPrices;
      let patientItemsObj: PatientObjWithLineItems[] = [...patientLineItems];
      let currentPatientIdx = patientItemsObj.findIndex(
        (patientItem) => patientItem.patientID === selectedProfile?.id
      );
      const lineItem = {
        itemName: itemData.itemName,
        priceBeforeDiscount,
        mrp,
        price,
        groupPlan,
        itemId: itemData.itemId,
        couponDiscAmount: 0,
        isCouponOnMrp: false,
        groupPlanDiscAmount: 0,
        reportTAT: itemData.reportTAT,
        circleCashback: cashbackAmount,
        testParametersCount: itemData?.testParametersCount || 1,
        observations: hotSeller?.allObservations,
      };
      if (currentPatientIdx > -1) {
        patientItemsObj[currentPatientIdx].lineItems.push(lineItem);
        patientItemsObj[currentPatientIdx].totalPrice += price;
      } else {
        const newPatientObj: PatientObjWithLineItems = {
          patientID: selectedProfile?.id || '',
          lineItems: [lineItem],
          totalPrice: price,
        };
        patientItemsObj.push(newPatientObj);
      }
      setPatientLineItems(patientItemsObj);
      setUpdatePricing(true);
    };

    const itemIndexInCart = (item: DiagnosticCartItem) => {
      return diagnosticCartItems.findIndex(
        (cartItem) =>
          cartItem.itemId.toString() === `${item ? item.itemId : ''}`
      );
    };

    const productTitle = hasLowerCase(hotSeller.itemName)
      ? hotSeller.itemName
      : toTitleCase(hotSeller.itemName);

    const totalDiscount = mrp - price;
    const totalDiscountPercent = Math.round((totalDiscount / mrp) * 100);
    const circleApplied =
      (isUserCare || !!circleMemberShipPlan) &&
      groupPlan === DIAGNOSTICS_GROUPPLAN.circle;

    const showCashback =
      (isUserCare || !!circleMemberShipPlan) &&
      (!couponApplied || (couponApplied && circleBenefitsApplied)) &&
      cashbackText?.length > 0;

    return (
      <div className={classes.cardWrap}>
        <div className={classes.productIcon}>
          {hotSeller?.imageUrl ? (
            <img
              src={hotSeller.imageUrl}
              alt={`Lab Test - ${hotSeller.itemName}`}
            />
          ) : (
            <img
              src={`${CDN_URL}/images/shopby/ic_stomach.svg`}
              alt={`Lab Test - ${hotSeller.itemName}`}
            />
          )}
        </div>
        <Tooltip placement="top" title={productTitle}>
          <div className={classes.productTitle}>
            <a
              href={getWebTdpUrl(hotSeller?.canonicalTag)}
              target="_blank"
              rel="noopener noreferrer"
            >
              {productTitle}
            </a>
          </div>
        </Tooltip>
        <div className={classes.parameters}>
          {`${observationCount} test${
            observationCount > 1 ? 's' : ''
          } included`}
        </div>
        <div className={classes.bottomSection}>
          <div className={classes.priceGroup}>
            <div className={classes.circleMemberText}>
              <div className={classes.circleMemberTextIn}>
                <div>
                  <span className={classes.mrpLabel}>MRP</span>
                  <span className={classes.slashPrice}>
                    {mrp !== price && `₹${mrp}`}
                  </span>
                  {totalDiscountPercent > 0 && showDiscountPercent && (
                    <span
                      className={classes.percentDisc}
                    >{`${totalDiscountPercent}% off`}</span>
                  )}
                </div>
              </div>
            </div>
            <div className={classes.priceRow}>
              <div>₹{price}</div>
              {totalDiscount > 0 && !showDiscountPercent && (
                <div className={classes.savings}>save ₹{totalDiscount}</div>
              )}
            </div>
            {(circleApplied || showCashback) && (
              <div className={classes.cashBackContainer}>
                <img
                  src={`${CDN_URL}/images/circle_logo.png`}
                  alt="circle logo"
                />
                <div>
                  <span>{circleApplied ? 'Discount Applied' : ''}</span>
                  {showCashback && (
                    <span
                      className={`cashback ${
                        circleApplied ? 'showPlus' : 'showExtra'
                      }`}
                    >{`${cashbackText}`}</span>
                  )}
                </div>
              </div>
            )}
          </div>
          <div className={`${classes.addToCart}`}>
            {itemIndexInCart(hotSeller) === -1 ? (
              <AphButton
                onClick={(e) => {
                  e.stopPropagation();

                  let newItem: DiagnosticCartItem = {
                    diagnosticPricing: hotSeller?.diagnosticPricing,
                    inclusions: hotSeller?.observations,
                    itemId: hotSeller?.itemId,
                    itemName: hotSeller?.itemName,
                    itemType: '',
                    patientId: selectedProfile?.id,
                    reportTAT: '',
                    itemImageUrl: hotSeller?.itemImageUrl,
                    gender: hotSeller?.gender,
                    testParametersCount: observationCount || 1,
                    canonicalTag: hotSeller?.canonicalTag,
                    observations: hotSeller?.allObservations,
                  };
                  setLoading(true);
                  axios
                    .post(`${GET_REPORT_TAT}`, {
                      itemIds: [newItem?.itemId],
                      cityId: serviceabilityData.cityID,
                      pincode: Number(localStorage.getItem('currentZip')),
                      latitude: selectedAddress?.latitude || null,
                      longitude: selectedAddress?.longitude || null,
                      bookingSource: orderSource,
                    })
                    .then(({ data }) => {
                      if (data) {
                        newItem.reportTAT = data.preOrderReportTATMessage;
                        hotSeller.reportTAT = data?.preOrderReportTATMessage;
                        let updatedItems = [...diagnosticCartItems, newItem];
                        setDiagnosticCartItems(updatedItems);
                        setUpLineItemsData(hotSeller);
                        setRecommendationItems([]);
                        setLoading(false);
                        if (deletedPatientLineItems) {
                          setDeletedPatientLineItems(null);
                        }
                      }
                    });
                }}
              >
                Add To Cart
              </AphButton>
            ) : (
              <AphButton
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                remove
              </AphButton>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {patientLineItems?.length !== 0 && recommendationItems?.length > 0 && (
        <div className={classes.container}>
          <div className={classes.banner}>
            <h2>ADD MORE TESTS</h2>
          </div>
          <div className={classes.root}>
            {loading ? (
              <div className={classes.loaderContainer}>
                <CircularProgress />
              </div>
            ) : (
              <Slider {...getSliderSettings()}>
                {recommendationItems &&
                  recommendationItems?.map(
                    (item, index) =>
                      Array.isArray(item?.diagnosticPricing) &&
                      item?.diagnosticPricing?.length > 0 && (
                        <div className={classes.card} key={index}>
                          {renderCard(item)}
                        </div>
                      )
                  )}
              </Slider>
            )}
          </div>
        </div>
      )}
    </>
  );
}
