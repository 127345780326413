import React, { useEffect, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Header from './Header';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import axios from '../axios';
import formatDate from 'date-fns/format';
import parse from 'date-fns/parse';
import {
  FIND_USER_BY_MOBILE_URL,
  ORDER_SOURCES,
  BOOK_HOME_COLLECTION,
  GET_REPORT_TAT,
  GET_PRESCRIPTIONS,
  SAVE_AND_SEND_PAYMENT_LINK,
  CIRCLE_PLAN_ID,
  GET_CANCELLATION_REASONS,
  UPDATE_ORDER_STATUS,
  DIAG_NON_CART_FLOW_MESSAGES,
  GET_ACTIVE_ORDERS,
  SLOT_EXPIRE_DURATION_IN_HOURS,
  MODIFY_ORDER,
  NA_STRING,
  GET_ORDER_DETAILS,
  USER_ACCESS,
  GET_ORDER_CANCELLATION_REASONS,
  CANCEL_ORDER,
  SOURCE,
  RESCHEDULE_ERROR_ORDER_STATUS,
  ORDER_MODIFY_PROGRESS_TEXT,
  DIAGNOSTIC_USER_ACCESS,
  ERROR_MESSAGES,
  DISABLE_COD_OPTION,
  CDN_URL,
} from '../appConfig';
import {
  allowCopyPaste,
  CartDiscountObj,
  copyTextToClipboard,
  couponPricingAccumulator,
  defaultNewUserData,
  DiagnosticOrderInput,
  FinalCartItemsToDisplay,
  getAgentEmail,
  getCircleSubscription,
  isMobileNumberValid,
  isNumber,
  itemLevelReportTATObject,
  NewProfile,
  PatientObjWithLineItems,
  PatientProfile,
  PlanPurchaseType,
  sanitizeLineItems,
  SLOT_DATE_FORMAT_FOR_API,
  updateModifyOrderPrice,
} from './helpers/commonHelpers';
import { Slots } from './Slots';
import { CustomerProfiles } from './CustomerProfiles';
import { Addresses } from './Addresses';
import { PatientCartItems } from './PatientCartItems';
import { OrderSummary } from './OrderSummary';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  getCommonPatientAttributes,
  sendClevertTapEvent,
} from './helpers/eventTracking';
import { ViewPrescription } from './ViewPrescription';
import AphDialog from './AphDialog';
import AphDialogTitle from './AphDialogTitle';
import AphButton from './AphButton';
import { AddCircle } from './AddCircle';
import { useOrderContext } from '../OrderProvider';
import TestRecommendation from './TestRecommendation';
import PackageRecommendation from './PackageRecommendation';
import AphTextField from './AphTextField';
import { PrescriptionOrders } from './PrescriptionOrders';
import AphDialogClose from './AphDialogClose';
import { Prescriptions } from './Prescriptions';
import OrderCard from './OrderCard';
import { getDiagnosticOrdersListByMobile_getDiagnosticOrdersListByMobile_ordersList } from '../types/getDiagnosticOrdersListByMobile';
import addHours from 'date-fns/addHours';
import { DIAGNOSTIC_ORDER_STATUS } from '../types/globalTypes';
import Radiology from './Radiology';
import ModifyOrderFlow from './ModifyOrderFlow';
import { PreviousOrderSummary } from './PrevOrderSummary';
import format from 'date-fns/format';
import isEqual from 'date-fns/isEqual';

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      width: '100%',
    },
    container: {
      maxWidth: 1064,
      margin: 'auto',
    },
    orderPunchingTool: {
      padding: '48px 20px',
      position: 'relative',
      paddingTop: 10,
      minHeight: 580,
      [theme.breakpoints.down('xs')]: {
        backgroundColor: 'transparent',
        padding: '48px 10px',
        paddingTop: 0,
        paddingBottom: 20,
      },
    },
    detailSections: {
      marginTop: '24px',
      minHeight: 500,
    },
    loaderDiv: {
      textAlign: 'center',
      marginTop: '125px !important',
    },
    detailBoxes: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      [theme.breakpoints.down(768)]: {
        flexWrap: 'wrap',
      },
      '&>div': {
        width: '29%',
        [theme.breakpoints.down(768)]: {
          width: '100%',
          marginTop: '10px',
        },
        '&:nth-child(2)': {
          width: '39%',
          [theme.breakpoints.down(768)]: {
            width: '100%',
          },
        },
      },
    },
    formGroup: {
      position: 'relative',
      padding: '3px 0px',
      marginRight: 15,
      '& input': {
        fontSize: '13px',
        fontWeight: 500,
        padding: '7px 6px',
      },
      '& div': {
        fontSize: '13px',
        fontWeight: 500,
      },
      '& label': {
        fontSize: '14px',
        fontWeight: 'normal',
        display: 'block',
      },
    },
    ctaHeading: {
      display: 'flex',
      justifyContent: 'flex-end',
      gap: '8px',
      marginRight: 15,
      paddingBottom: 10,
      [theme.breakpoints.down('xs')]: {
        padding: '20px 0',
        marginRight: 0,
      },
    },
    prescriptionOrders: {
      alignSelf: 'normal',
      '& button': {
        [theme.breakpoints.down('xs')]: {
          padding: '5px 10px',
          fontSize: 10,
        },
      },
    },
    shadowedBox: {
      background: '#FFFFFF',
      boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.16)',
      borderRadius: '10px',
      marginBottom: 30,
      '&>h3': {
        fontWeight: 'bold',
        fontSize: '14px',
        borderRadius: '5px 5px 0px 0px',
        lineHeight: '22px',
        padding: '8px 12px',
        background: '#01475B',
        margin: 0,
        textTransform: 'uppercase',
        color: '#fff',
        display: 'flex',
        justifyContent: 'space-between',
        '& button': {
          padding: 0,
          fontSize: 13,
          color: '#FCA317',
          lineHeight: 'unset',
          fontWeight: 'bold',
        },
        '& em': {
          fontStyle: 'normal',
          fontSize: 12,
          fontWeight: 600,
        },
      },
      '& button': {
        '&:disabled': {
          background: '#FCB716',
          color: '#fff',
        },
      },
    },
    menuPopover: {
      boxShadow: '0 5px 20px 0 rgba(0, 0, 0, 0.3)',
      '& ul': {
        padding: '10px 20px',
        '& li': {
          fontSize: 14,
          fontWeight: 500,
          color: '#01475b',
          minHeight: 'auto',
          paddingLeft: 0,
          paddingRight: 0,
          borderBottom: '1px solid rgba(1,71,91,0.2)',
          backgroundColor: 'transparent !important',
          '&:last-child': {
            borderBottom: 'none',
          },
        },
      },
    },
    menuSelected: {
      backgroundColor: 'transparent !important',
      color: '#00b38e !important',
      '&:focus': {
        backgroundColor: 'transparent !important',
      },
    },
    textField: {
      width: '100%',
      marginTop: '5px',
      background: '#FAFBFC',
      border: '2px solid #DFE1E6',
      boxSizing: 'border-box',
      borderRadius: 8,
      '&>div:after': {
        display: 'none !important',
      },
      '&>div:before': {
        display: 'none !important',
      },
    },
    selectField: {
      width: '100%',
      marginTop: '7px',
      background: '#FAFBFC',
      border: '2px solid #DFE1E6',
      boxSizing: 'border-box',
      borderRadius: 8,
      '&:after': {
        display: 'none !important',
      },
      '&:before': {
        display: 'none !important',
      },
      '&>div': {
        paddingLeft: 5,
      },
      '& svg': {
        fill: '#01475b',
      },
    },
    inputs: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'start',
      alignItems: 'center',
      '&>div': {
        flexBasis: '23%',
        [theme.breakpoints.down('xs')]: {
          flexBasis: '100%',
        },
      },
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        flexWrap: 'wrap',
      },
    },
    circleImage: {
      width: 35,
      marginRight: 5,
    },
    selected: {
      color: '#00B38E',
    },
    selectedBox: {
      '&>div': {
        border: '2px solid #00b38e',
        borderTop: 'unset',
        borderRadius: '0 0 5px 5px',
      },
    },
    orderSummary: {
      '&>div': {
        '&>p': {
          margin: 0,
          padding: '16px 13px',
          fontSize: 14,
          background: '#F9F9F9',
          opacity: 0.8,
        },
      },
    },
    viewPrescription: {
      alignSelf: 'flex-end',
      '& button': {
        fontSize: 13,
        fontWeight: 700,
        color: '#FCA317',
        background: '#FFFFFF !important',
        border: '2px solid #FCA317',
        boxSizing: 'border-box',
        borderRadius: 8,
        [theme.breakpoints.down('xs')]: {
          width: '100%',
        },
      },
    },
    paymentOptionDialogTitle: {
      '& h2': {
        fontSize: 14,
        fontWeight: 600,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        '& button': {
          boxShadow: 'none',
          minWidth: 20,
          padding: 0,
          '&:hover': {
            backgroundColor: 'transparent',
          },
        },
      },
      boxShadow: 'none',
      padding: '8px 12px',
    },
    activeOrderTitle: {
      '& h2': {
        fontSize: 14,
        fontWeight: 600,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
    },
    activeOrders: {
      margin: theme.spacing(1.5),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      '& >span': {
        fontSize: 14,
        fontWeight: 400,
        color: '#02475B',
        paddingBottom: 20,
      },
      '& button': {
        width: 320,
        alignSelf: 'center',
        marginTop: '10px',
      },
    },
    activeOrdersList: {
      overflow: 'auto',
    },
    orderHeading: {
      fontWeight: 600,
      fontSize: '16px',
      marginTop: '3px',
    },
    listWithPrescription: {
      maxHeight: '30vh',
    },
    listWithoutPrescription: {
      maxHeight: '60vh',
    },
    prescriptionOrdersList: {
      overflow: 'auto',
    },
    listWithActiveOrders: {
      maxHeight: '20vh',
    },
    listWithoutActiveOrders: {
      maxHeight: '60vh',
    },
    paymentOptionDialogContainer: {
      display: 'flex',
      flexDirection: 'column',
      padding: '5px 8px',
      '&> div': {
        minHeight: 45,
        marginBottom: 12,
        paddingLeft: 8,
        fontSize: 14,
        fontWeight: 500,
        letterSpacing: '-0.55px',
        border: '1px solid #D4D4D4',
        borderRadius: 8,
        color: '#01475B',
        '& p': {
          display: 'flex',
          alignItems: 'center',
          fontSize: 11,
          margin: '3px 0 10px',
        },
        '& button': {
          boxShadow: 'none',
          color: '#FC9916',
          letterSpacing: '-0.55px',
          minWidth: 30,
          paddingRight: 8,
          '&:hover': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
    codSection: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    loader: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center !important',
      minHeight: 45,
      padding: 16,
    },
    codOrderDetail: {
      display: 'flex',
      flexDirection: 'column',
      padding: '5px 8px 15px 8px',
      '&> div': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        minHeight: 45,
        paddingLeft: 8,
        fontSize: 14,
        fontWeight: 500,
        letterSpacing: '-0.55px',
        border: '1px solid #D4D4D4',
        borderRadius: '8px 8px 0px 0px',
        color: '#01475B',
        '& button': {
          boxShadow: 'none',
          color: '#FC9916 !important',
          letterSpacing: '-0.55px',
          minWidth: 30,
          paddingRight: 8,
          '&:hover': {
            backgroundColor: 'transparent',
          },
        },
        '&:last-child': {
          flexDirection: 'column',
          alignItems: 'flex-start',
          padding: '8px 5px 0px 8px',
          fontSize: 13,
          borderTop: 'none',
          minHeight: 75,
          borderRadius: '0px 0px 8px 8px',
          justifyContent: 'space-evenly',
          '& p': {
            margin: 0,
            paddingBottom: 8,
            '&.headerLabel': {
              fontWeight: 700,
            },
            '&.startNewOrderBtn': {
              alignSelf: 'center',
              marginTop: 10,
              '& button': {
                background: '#FCB716',
                color: '#fff !important',
                '&:hover': {
                  background: '#FC9916',
                },
              },
            },
          },
        },
      },
    },
    paymentLinkOrderDetail: {
      display: 'flex',
      flexDirection: 'column',
      padding: '5px 8px 15px 8px',
      '&> div': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        minHeight: 45,
        paddingLeft: 8,
        fontSize: 14,
        fontWeight: 500,
        letterSpacing: '-0.55px',
        border: '1px solid #D4D4D4',
        borderRadius: '8px 8px 0px 0px',
        color: '#01475B',
        '&:last-child': {
          flexDirection: 'column',
          alignItems: 'flex-start',
          padding: '8px 5px 0px 8px',
          fontSize: 13,
          borderTop: 'none',
          minHeight: 110,
          borderRadius: '0px 0px 8px 8px',
          justifyContent: 'space-evenly',
          '& p': {
            margin: 0,
            paddingBottom: 8,
            '&.paymentLink': {
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              '& span': {
                color: '#FC9916',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              },
              '& img': {
                margin: '0px 5px',
                cursor: 'pointer',
                '&:active': {
                  opacity: 0.5,
                },
              },
            },
            '&.startNewOrderBtn': {
              alignSelf: 'center',
              marginTop: 10,
              '& button': {
                background: '#FCB716',
                color: '#fff !important',
                '&:hover': {
                  background: '#FC9916',
                },
              },
            },
          },
        },
      },
    },
    helperText: {
      position: 'absolute',
      top: '100%',
    },
    resendLink: {
      color: '#01475B',
      fontSize: 12,
      boxShadow: 'none',
      textTransform: 'none',
      textDecoration: 'underline',
      '&:hover': {
        background: 'transparent',
      },
    },
    storeIdInput: {
      paddingTop: 12,
    },
    orderId: {
      color: '#02475B',
      fontWeight: 500,
      fontSize: 15,
      marginRight: 14,
    },
    cancelTitle: {
      padding: '16px 12px',
      '& h2': {
        fontSize: 14,
        fontWeight: 500,
      },
    },
    cancellationReasons: {
      maxHeight: 300,
      overflow: 'auto',
      '&>ul': {
        margin: 0,
        padding: 0,
        '&>li': {
          cursor: 'pointer',
          listStyleType: 'none',
          padding: '12px 20px',
          borderBottom: '1px solid rgba(0,0,0,0.1)',
          fontSize: 13,
          fontWeight: 500,
          '&>div': {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            '&>div': {
              maxWidth: '70%',
            },
          },
          '&:last-child': {
            borderBottom: 'unset',
          },
        },
      },
    },
    footer: {
      padding: '30px 42px',
      '&>button': {
        width: '100%',
        borderRadius: 10,
      },
      '& .MuiButton-root.Mui-disabled': {
        opacity: 0.5,
      },
    },
    customReason: {
      margin: 8,
    },
    selectedReason: {
      background: '#00B38E',
      color: '#fff',
    },
    modifyOrderCod: {
      padding: '28px',
      textAlign: 'center',
      '&>.successMsg': {
        fontSize: 14,
        fontWeight: 700,
        paddingTop: 10,
        margin: 0,
      },
      '&>.paymentMode': {
        fontWeight: 500,
        fontSize: 14,
        margin: 0,
        paddingTop: 6,
        paddingBottom: 6,
      },
      '&>.additionalAmount': {
        fontWeight: 500,
        fontSize: 14,
        margin: 0,
        paddingTop: 6,
        paddingBottom: 20,
      },
    },
    modifyOrderPayment: {
      textAlign: 'center',
      '&>.successMsg': {
        fontSize: 14,
        fontWeight: 700,
        paddingTop: 10,
        margin: 0,
      },
      '&>.paymentMode': {
        fontWeight: 500,
        fontSize: 14,
        margin: 0,
        paddingTop: 6,
        paddingBottom: 6,
      },
      '&>.additionalAmount': {
        fontWeight: 500,
        fontSize: 14,
        margin: 0,
        paddingTop: 6,
        paddingBottom: 20,
      },
      '& .amount': {
        display: 'flex',
        justifyContent: 'center',
        fontSize: 12,
        fontWeight: 500,
        gap: 16,
        color: '#01475B',
        '& > p': {
          margin: '0px !important',
          padding: 4,
        },
        '& > b': {
          margin: '0px !important',
          padding: 4,
        },
      },
      '& .refund-amount': {
        display: 'flex',
        justifyContent: 'center',
        fontSize: 12,
        fontWeight: 600,
        gap: 16,
        color: '#01475B',
        '& > p': {
          margin: '0px !important',
          padding: 4,
        },
      },
    },
    modifyPaymentDialog: {
      '& .MuiDialog-paperWidthMd': {
        maxWidth: `500px !important`,
      },
    },
    dialogSmallScreen: {
      '& .MuiDialog-paperWidthSm': {
        maxWidth: `400px !important`,
      },
    },
    modifyInProgress: {
      '&>div': {
        border: '1px solid #D4D4D4',
        borderRadius: 14,
        margin: '24px 8px 24px 8px',
        padding: 8,
        '&>button': {
          width: '80%',
          borderRadius: 10,
          textAlign: 'center',
        },
        '&>p:nth-child(3)': {
          fontWeight: 500,
          fontSize: 14,
          textAlign: 'left',
          color: '#FC9916',
        },
        '&>p:first-child': {
          fontWeight: 600,
          fontSize: 16,
          margin: 0,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
        '&>p:p:nth-child(2)': {
          fontWeight: 600,
          fontSize: 16,
          margin: 0,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
        '& div': {
          textAlign: 'center',
          '&>p': {
            color: '#00B38E',
          },
        },
      },
      '&.paymentLink': {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        '& span': {
          color: '#FC9916',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
        '& img': {
          margin: '0px 5px',
          cursor: 'pointer',
          '&:active': {
            opacity: 0.5,
          },
        },
      },
    },
    modifyOrderOnline: {
      padding: '20px',
      textAlign: 'center',
      '&>span': {
        fontWeight: 400,
        fontSize: 12,
        margin: 0,
        paddingTop: 6,
      },
      '&>div:last-child': {
        border: '1px solid #D4D4D4',
        borderRadius: 14,
        marginTop: 24,
        marginBottom: 24,
        padding: 8,
        '&>button': {
          width: '80%',
          borderRadius: 10,
        },
        '&>p:nth-child(2)': {
          fontWeight: 500,
          fontSize: 14,
          textAlign: 'left',
        },
        '&>p:first-child': {
          fontWeight: 600,
          fontSize: 14,
          margin: 0,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          color: '#FC9916',
        },
      },
    },
    orderCancelled: {
      padding: '42px 28px',
      textAlign: 'center',
      '&>div': {
        fontSize: 16,
        fontWeight: 700,
        paddingTop: 10,
      },
      '&>p': {
        color: '#1084A9',
        fontWeight: 400,
        fontSize: 12,
        margin: 0,
        paddingTop: 6,
      },
      '&>button': {
        width: '100%',
        borderRadius: 10,
        marginTop: 50,
      },
    },
    resend: {
      display: 'flex',
      flexDirection: 'column',
      alignSelf: 'normal',
      marginTop: 8,
      marginBottom: 8,
      '& .MuiButton-root.Mui-disabled': {
        opacity: 0.5,
        color: `${theme.palette.primary.main}`,
      },
      '& button': {
        marginTop: 16,
        boxShadow: 'none',
      },
      '& input': {
        fontSize: 14,
        marginTop: 4,
      },
    },
    disabledCODBtn: {
      color: '#FC9916 !important',
      opacity: 0.5,
    },
    modifyOrderSlot: {
      fontWeight: 500,
      fontSize: 14,
      color: '#01475B',
      padding: 16,
    },
    hideOnModifyFlow: {
      display: 'none',
    },
    modifyOrderFlowContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      gap: 32,
      paddingBottom: 16,
      paddingTop: 8,
      borderBottom: '1px solid #d4d1d1',
      '&>div': {
        display: 'flex',
        gap: 14,
        '&>div': {
          '&>span:first-child': {
            fontWeight: 400,
            fontSize: 14,
          },
          '&>span:last-child': {
            fontWeight: 500,
            fontSize: 14,
          },
        },
        '& button': {
          background: 'transparent !important',
          color: '#FCB716 !important',
          boxShadow: 'none',
        },
      },
    },
    modifyConfirmation: {
      padding: '8px 10px',
      '& .actions': {
        display: 'flex',
        justifyContent: 'center',
        gap: 20,
        '& button:first-child': {
          boxShadow: 'none !important',
        },
      },
      '& p': {
        fontSize: 14,
        textAlign: 'center',
        color: '#02475B',
      },
    },
    modfiyOnlineConfirmation: {
      '& p:first-child': {
        fontWeight: 600,
        fontSize: 16,
        color: '#FC9916',
        margin: '4px !important',
        textAlign: 'left',
      },
      '& p:nth-child(2)': {
        fontWeight: 500,
        color: '#02475B',
        fontSize: 14,
        margin: 4,
      },
      '& div': {
        margin: '12px 0px',
        '& button': {
          boxShadow: 'none',
          color: '#FC9916 !important',
          letterSpacing: '-0.55px',
          minWidth: 30,
          paddingRight: 8,
          '&:hover': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
    refundContainer: {
      margin: 8,
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
      '& .heading': {
        fontWeight: 700,
        fontSize: 16,
        color: '#01475B',
        textAlign: 'center',
        padding: '18px 0px 8px 0px',
      },
      '& .amount': {
        display: 'flex',
        justifyContent: 'center',
        fontSize: 12,
        fontWeight: 500,
        gap: 16,
        color: '#01475B',
        '& > p': {
          margin: '0px !important',
          padding: 4,
        },
      },
      '& .refund-amount': {
        display: 'flex',
        justifyContent: 'center',
        fontSize: 12,
        fontWeight: 600,
        gap: 16,
        color: '#01475B',
        '& > p': {
          margin: '0px !important',
          padding: 4,
        },
      },
      '& .refund-message': {
        display: 'flex',
        justifyContent: 'center',
        fontSize: 12,
        fontWeight: 600,
        gap: 16,
        color: '#01475B',
        textAlign: 'center',
      },
      '&>.paymentMode': {
        fontWeight: 500,
        fontSize: 14,
        margin: 0,
        paddingTop: 0,
        paddingBottom: 16,
      },
    },
    docName: {
      padding: '16px 12px',
      '& label': {
        fontSize: '12px',
        fontWeight: 600,
      },
      '&>div': {
        position: 'relative',
        '&>span': {
          position: 'absolute',
          fontWeight: 600,
          fontSize: 14,
          bottom: 7,
          lineHeight: '18px',
        },
        '&>div': {
          '&>div': {
            '&:before': {
              borderBottom: '2px solid #00B38e !important',
            },
            '&:after': {
              borderBottom: '2px solid #00B38e !important',
            },
          },
        },

        '& input': {
          fontSize: 14,
          fontWeight: 600,
          paddingLeft: 25,
        },
      },
    },
  };
});

interface DashboardInterface {
  userName: string;
}
enum ORDER_CANCEL_TYPE {
  PRESCRIPTION_ORDER_CANCEL = 'PRESCRIPTION_ORDER_CANCEL',
  ORDER_CANCEL = 'ORDER_CANCEL',
}
const Dashboard: React.FC<DashboardInterface> = (props) => {
  const classes = useStyles();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  const {
    circleMemberShipPlan,
    circleSubscriptionObj,
    codEnabled,
    couponApplied,
    detailsFetchedFlag,
    diagnosticCartItems,
    homeCollectionCharges,
    isUserCare,
    orderResult,
    patientLineItems,
    paymentLink,
    selectedAddress,
    selectedProfile,
    selectedSlot,
    selectedSlotDate,
    showCodOrderDetail,
    showPaymentLinkOrderDetail,
    showPaymentOptionDialog,
    showPaymentOptionRetry,
    serviceabilityData,
    shouldResetDashboard,
    userPhoneNumber,
    isNonCartFlowActivated,
    selectedNonCartFlowOrder,
    radiologyTest,
    couponCode,
    circleCashback,
    showActiveOrdersDialog,
    closePopupPrescription,
    resetDashboard,
    resetPaymentSettings,
    setCircleMemberShipPlan,
    setCircleBenefitsApplied,
    setCircleSubscriptionObj,
    setCodEnabled,
    setShowCodOrderDetail,
    setCouponApplied,
    setCouponErrMsg,
    setCouponValidateErr,
    setDiagnosticCartItems,
    setDetailsFetchedFlag,
    setEnableSlotSelection,
    setHasDupes,
    setIsUserCare,
    setOrderResult,
    setPatientAddresses,
    setPatientLineItems,
    setPaymentLink,
    setRemoveHCCharges,
    setSearchText,
    setSelectedAddress,
    setSelectedProfile,
    setSelectedSlot,
    setShouldResetDashboard,
    setShowPaymentLinkOrderDetail,
    setShowPaymentOptionDialog,
    setShowPaymentOptionRetry,
    setUserFound,
    setUserPhoneNumber,
    setUserProfiles,
    setServiceabilityData,
    setUserSubscriptions,
    setSelectedNonCartFlowOrder,
    setIsNonCartFlowActivated,
    isHcSelected,
    setIsHcSelected,
    setRadiologyTest,
    orderSource,
    setOrderSource,
    setShowActiveOrdersDialog,
    setClosePopupPrescription,
    setShowModifyFlow,
    showModifyFlow,
    modifyFlowOrder,
    setModifyFlowOrder,
    modifyOrderPaidAmountBreakup,
    setUserAccess,
    setIsItemRemoved,
    isItemRemoved,
    hasAccessToCancelOrder,
    setModifyOrderSelectedProfile,
    setHasAccessToCancelOrder,
    setHasAccessToChangeProfile,
    setHasAccessToModifyItems,
    setHasAccessToReschedule,
    codMessage,
    setCodMessage,
    setHomeCollectionCharges,
    isModifyInProgress,
    modifyPaymentLink,
    setModifyPaymentLink,
    setIsModifyInProgress,
    phleboCharges,
    setPhleboCharges,
  } = useOrderContext();
  /************************************* State Variables start here *************************************/
  const [loading, setLoading] = useState<boolean>(false);
  const [dashboardErr, setDashboardErr] = useState<boolean>(false);
  const [userPhoneNumberValid, setUserPhoneNumberValid] =
    useState<boolean>(false);
  const [storeId, setStoreId] = useState<string>(
    localStorage.getItem('storeId') || ''
  );
  const [placeOrderLoading, setPlaceOrderLoading] = useState<boolean>(false);
  const [newUserProfile, setNewUserProfile] =
    useState<NewProfile>(defaultNewUserData);
  const [finalCartItems, setFinalCartItems] = useState<
    FinalCartItemsToDisplay[]
  >([]);

  const [couponSelected, setCouponSelected] = useState<string>('');
  const [cartDiscountObj, setCartDiscountObj] = useState<CartDiscountObj>({
    ...couponPricingAccumulator,
  });
  const [cartArrayAfterDiscount, setCartArrayAfterDiscount] = useState<
    PatientObjWithLineItems[]
  >([]);
  const [totalAmountToPay, setTotalAmountToPay] = useState<number>(0);

  const [collectionChargesLoader, setCollectionChargesLoader] =
    useState<boolean>(false);

  const [viewPrescription, setViewPrescription] = useState<boolean>(false);
  const [patientPrescriptions, setPatientPrescriptions] = useState<any[]>([]);
  const [prescriptionsLoader, setPrescriptionsLoader] =
    useState<boolean>(false);
  const [paymentOptionLoader, setPaymentOptionLoader] =
    useState<boolean>(false);
  const [mobileError, setMobileError] = useState<boolean>(false);
  const [cancellationReasons, setCancellationReasons] = useState<string[]>([]);
  const [isCancellationLoading, setIsCancellationLoading] =
    useState<boolean>(false);
  const [showCancellationReasonDialog, setShowCancellationReasonDialog] =
    useState<boolean>(false);
  const [showOrderCancelDialog, setShowOrderCancelDailog] =
    useState<boolean>(false);
  const [showOrderCancelFailedDailog, setShowOrderCancelFailedDailog] =
    useState<boolean>(false);
  const [selectedReasonIndex, setSelectedReasonIndex] = useState<number>(-1);
  const [cancellationReason, setCancellationReason] = useState<string>('');

  const [activeOrders, setActiveOrders] = useState<
    (getDiagnosticOrdersListByMobile_getDiagnosticOrdersListByMobile_ordersList | null)[]
  >([]);
  const [altMobNumber, setAltMobNumber] = useState<string>(null);
  const [isAlternateMobNumError, setIsAlternateMobNumError] =
    useState<boolean>(false);
  const [confirmationMsg, setConfirmationMsg] = useState<string>('');
  const [maxReportTAT, setMaxReportTAT] = useState<string>('');
  const [prescriptionOrders, setPrescriptionOrders] = useState<
    (getDiagnosticOrdersListByMobile_getDiagnosticOrdersListByMobile_ordersList | null)[]
  >([]);
  const [showModifyPaymentDialog, setShowModifyPaymentDialog] =
    useState<boolean>(false);
  const [showModifyCodDialog, setShowModifyCodDialog] =
    useState<boolean>(false);
  const [
    showModifyOrderConfirmationDialog,
    setShowModifyOrderConfirmationDialog,
  ] = useState<boolean>(false);
  const [showRefundDialog, setShowRefundDialog] = useState<boolean>(false);
  const [orderCancelType, setOrderCancelType] =
    useState<ORDER_CANCEL_TYPE>(null);
  const [cancelledOrder, setCancelledOrder] = useState<any>(null);
  const [isSlotError, setIsSlotError] = useState<boolean>(false);

  const [doctorName, setDoctorName] = useState<string>('');
  /************************************* State Variables end here *************************************/

  /************************************* Use Effect section starts here *************************************/

  useEffect(() => {
    const getOktaTokenStorage =
      localStorage.getItem('okta-token-storage') &&
      JSON.parse(localStorage.getItem('okta-token-storage'));
    localStorage.setItem(
      'aphToken',
      getOktaTokenStorage?.accessToken?.accessToken
    );
  }, []);

  useEffect(() => {
    if (modifyFlowOrder?.attributesObj?.doctorName) {
      setDoctorName(modifyFlowOrder?.attributesObj?.doctorName);
    }
  }, [modifyFlowOrder]);

  const { mobileNumber, orderId } = params;

  const fetchModifyOrderDetails = () => {
    setLoading(true);
    axios
      .post(GET_ORDER_DETAILS, {
        diagnosticOrderId: orderId,
        phoneNo: `+91${mobileNumber}`,
      })
      .then(({ data }) => {
        const modifyOrder = updateModifyOrderPrice(data?.ordersList);
        if (modifyOrder?.modifyInProgress) {
          setIsModifyInProgress(true);
          setModifyPaymentLink(modifyOrder?.paymentLinkURL);
        }
        setShowModifyFlow(false);
        setModifyFlowOrder(modifyOrder);
        setHomeCollectionCharges({
          charges: modifyOrder?.collectionCharges ?? 0,
          distanceCharges: 0,
        });
      })
      .catch((e) => console.error(e))
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    const { mobileNumber, orderId } = params;
    if (mobileNumber && orderId && !loading) {
      setUserPhoneNumber(mobileNumber);
      fetchModifyOrderDetails();
    }
  }, [mobileNumber, orderId]);

  useEffect(() => {
    axios
      .get(USER_ACCESS)
      .then(({ data }) => {
        const permissions = data?.access;
        if (permissions?.includes(DIAGNOSTIC_USER_ACCESS.CANCEL_ORDER)) {
          setHasAccessToCancelOrder(true);
        }
        if (permissions?.includes(DIAGNOSTIC_USER_ACCESS.RESCHEDULE_ORDER)) {
          setHasAccessToReschedule(true);
        }
        if (permissions?.includes(DIAGNOSTIC_USER_ACCESS.EDIT_PATIENT)) {
          setHasAccessToChangeProfile(true);
        }
        if (
          permissions?.includes(DIAGNOSTIC_USER_ACCESS.UPSELL) ||
          permissions?.includes(DIAGNOSTIC_USER_ACCESS.MODIFY_ORDER)
        ) {
          setHasAccessToModifyItems(true);
        }
        setUserAccess(permissions);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const diagnosticCartItemIdsString = diagnosticCartItems
    ?.map((item) => item?.itemId)
    ?.toString();
  useEffect(() => {
    setEnableSlotSelection(false);
    removeCoupon();
    // eslint-disable-next-line
  }, [diagnosticCartItemIdsString]);

  useEffect(() => {
    if (selectedAddress && patientLineItems.length && !modifyFlowOrder) {
      setEnableSlotSelection(true);
      setCouponErrMsg('');
      setCouponValidateErr('');
    }
    // eslint-disable-next-line
  }, [selectedAddress, patientLineItems, modifyFlowOrder]);

  useEffect(() => {
    if (shouldResetDashboard) {
      resetDashboard();
      setIsNonCartFlowActivated(false);
      setSelectedNonCartFlowOrder(null);
      setShowModifyFlow(false);
      setModifyFlowOrder(null);
    }
  }, [shouldResetDashboard, resetDashboard]);

  const selectedPatientLineItems = patientLineItems?.find(
    (item) => item?.patientID === selectedProfile?.id
  );
  useEffect(() => {
    if (selectedSlot) {
      const slotDateTime = parse(
        `${selectedSlot?.slotDetail.slotDisplayTime}, ${formatDate(
          selectedSlotDate,
          SLOT_DATE_FORMAT_FOR_API
        )}`,
        `hh:mm a, ${SLOT_DATE_FORMAT_FOR_API}`,
        new Date()
      ).toISOString();

      const itemIds: number[] = patientLineItems?.reduce((acc, item) => {
        acc = acc.concat(item?.lineItems?.map((lineItem) => lineItem?.itemId));
        return acc;
      }, [] as number[]);

      const itemNames: string[] = patientLineItems?.reduce((acc, item) => {
        acc = acc.concat(
          item?.lineItems?.map((lineItem) => lineItem?.itemName)
        );
        return acc;
      }, [] as string[]);

      const cartValue = patientLineItems?.reduce(
        (price, item) =>
          price +
          item?.lineItems?.reduce(
            (total, lineItem) => total + lineItem?.price,
            0
          ),
        0
      );

      const eventData = {
        ...getCommonPatientAttributes(selectedProfile),
        Latitude: selectedAddress?.latitude || '',
        Longitude: selectedAddress?.longitude || '',
        'Address Pincode': selectedAddress?.zipcode || '',
        City: selectedAddress?.city || '',
        'Slot date':
          selectedSlot && selectedSlotDate
            ? formatDate(selectedSlotDate, 'dd-MMM-yyyy')
            : '',
        'Slot time': selectedSlot?.slotDetail?.slotDisplayTime || '',
        Type: selectedSlot?.isPaidSlot ? 'Paid' : 'Free',
        'No. of slots': selectedSlot?.slotDetail?.internalSlots?.length || 0,
        'Circle user': isUserCare ? 'Yes' : 'No',
        itemIds: JSON.stringify(itemIds),
        itemNames: JSON.stringify(itemNames),
        'Cart Value': cartValue,
        Source: 'OPT',
      };
      sendClevertTapEvent(
        'Diagnostic slot time selected',
        userPhoneNumber,
        eventData
      );

      const uniqueCartItems = new Set(
        diagnosticCartItems.map((item) => item.itemId)
      );
      axios
        .post(`${GET_REPORT_TAT}`, {
          slotDateTimeInUTC: slotDateTime,
          itemIds: Array.from(uniqueCartItems),
          cityId: serviceabilityData.cityID,
          pincode: Number(localStorage.getItem('currentZip')),
          latitude: selectedAddress?.latitude || null,
          longitude: selectedAddress?.longitude || null,
          bookingSource: orderSource,
        })
        .then(({ data }) => {
          if (data) {
            data.itemLevelReportTATs.forEach(
              (tatItem: itemLevelReportTATObject) => {
                const updatedCartItems = diagnosticCartItems.map((cartItem) => {
                  if (cartItem.itemId === tatItem.itemId) {
                    cartItem.reportTAT = tatItem.reportTATMessage;
                  }
                  return cartItem;
                });
                setDiagnosticCartItems(updatedCartItems);
                const updatePatientItems = patientLineItems.map((pItem) => {
                  const patientItem = pItem.lineItems.find(
                    (lineItem) => lineItem.itemId === tatItem.itemId
                  );
                  if (patientItem) {
                    patientItem.reportTAT = tatItem.reportTATMessage;
                  }
                  return pItem;
                });
                setPatientLineItems(updatePatientItems);
              }
            );
            setMaxReportTAT(data.maxReportTAT);
          }
        });
    } else {
      if (diagnosticCartItems?.length) {
        const uniqueCartItems = new Set(
          diagnosticCartItems.map((item) => item.itemId)
        );
        axios
          .post(`${GET_REPORT_TAT}`, {
            itemIds: Array.from(uniqueCartItems),
            cityId: serviceabilityData.cityID,
            pincode: Number(localStorage.getItem('currentZip')),
            latitude: selectedAddress?.latitude || null,
            longitude: selectedAddress?.longitude || null,
            bookingSource: orderSource,
          })
          .then(({ data }) => {
            if (data) {
              data.itemLevelReportTATs.forEach(
                (tatItem: itemLevelReportTATObject) => {
                  const updatedCartItems = diagnosticCartItems.map(
                    (cartItem) => {
                      if (cartItem.itemId === tatItem.itemId) {
                        cartItem.reportTAT = tatItem.preOrderReportTATMessage;
                      }
                      return cartItem;
                    }
                  );
                  setDiagnosticCartItems(updatedCartItems);
                  const updatePatientItems = patientLineItems.map((pItem) => {
                    const patientItem = pItem.lineItems.find(
                      (lineItem) => lineItem.itemId === tatItem.itemId
                    );
                    if (patientItem) {
                      patientItem.reportTAT = tatItem.preOrderReportTATMessage;
                    }
                    return pItem;
                  });
                  setPatientLineItems(updatePatientItems);
                }
              );
            }
          });
      }
    }
    // eslint-disable-next-line
  }, [selectedSlot, selectedPatientLineItems?.lineItems?.length]);

  useEffect(() => {
    localStorage.setItem('storeId', storeId);
  }, [storeId]);

  // trigger user registration when nonCartFlow activates or modifyFlow activates
  useEffect(() => {
    if ((isNonCartFlowActivated || modifyFlowOrder) && userPhoneNumber) {
      handleUserRegistrationStatus();
    }
  }, [isNonCartFlowActivated, userPhoneNumber, modifyFlowOrder]);
  /************************************* Use Effect section ends here *************************************/

  const displayIds = orderResult?.saveBookHCOrder?.patientsObjWithOrderIDs?.map(
    (result: any) => result.displayID
  );

  const prebookingIds = orderResult?.processHCOrder?.result?.map(
    (result: any) => result.preBookingID
  ) ?? [orderResult?.processHCOrder?.preBookingID];

  const handleUserRegistrationStatus = async () => {
    setLoading(true);
    setDetailsFetchedFlag(false);
    setDashboardErr(false);
    setUserProfiles([]);
    setSelectedProfile(null);
    setSelectedAddress(null);
    setServiceabilityData({ cityID: 0, stateID: 0 });
    setSearchText('');
    setPatientLineItems([]);
    setDiagnosticCartItems([]);
    setCircleMemberShipPlan(null);
    resetPaymentSettings();
    setCircleSubscriptionObj(null);
    setUserSubscriptions(null);
    setIsUserCare(false);
    setCircleBenefitsApplied(false);
    setIsHcSelected(false);
    setPhleboCharges(null);
    getCircleSubscription(userPhoneNumber).then((res) => {
      const { data } = res;
      setUserSubscriptions(data?.response || null);
      const activeSubscriptions = data?.response?.APOLLO?.filter(
        (subscription: any) =>
          subscription?.status === 'active' ||
          subscription?.status === 'deferred_active'
      );
      if (activeSubscriptions?.length > 0 && activeSubscriptions[0]._id) {
        setCircleSubscriptionObj(activeSubscriptions[0]);
        setIsUserCare(true);
        setCircleBenefitsApplied(true);
      }
    });
    await axios
      .post(FIND_USER_BY_MOBILE_URL, { mobile: '+91' + userPhoneNumber })
      .then(({ data }) => {
        setDetailsFetchedFlag(true);
        const userDetails: PatientProfile[] = data?.user;
        if (userDetails?.length > 0) {
          !modifyFlowOrder && getActiveOrders();
          setUserProfiles(userDetails);
          setPatientAddresses(data.address);
          setUserFound(true);
          if (data?.user?.length === 1 && userDetails[0]?.uhid) {
            setSelectedProfile(userDetails[0]);
          }
          setNewUserProfile(defaultNewUserData);
        } else {
          setUserFound(false);
          let newProfile = { ...newUserProfile };
          newProfile.mobileNumber = '+91' + userPhoneNumber;
          setNewUserProfile(newProfile);
        }
      })
      .catch((err) => {
        console.error(err);
        setUserFound(false);
        setDashboardErr(true);
        setDetailsFetchedFlag(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getActiveOrders = () => {
    axios
      .post(GET_ACTIVE_ORDERS, { mobileNumber: '+91' + userPhoneNumber })
      .then(({ data }) => {
        if (data?.ordersList?.length > 0) {
          setActiveOrders(
            data?.ordersList.filter(
              (order) => order?.orderStatus !== DIAGNOSTIC_ORDER_STATUS.NEW_LEAD
            )
          );

          const prescription = data?.ordersList.filter(
            (order) => order?.orderStatus === DIAGNOSTIC_ORDER_STATUS.NEW_LEAD
          );
          setPrescriptionOrders(prescription);
          closePopupPrescription
            ? setShowActiveOrdersDialog(false)
            : setShowActiveOrdersDialog(true);
        }
      })
      .catch((err) => {
        console.error('error while fetching active Orders');
      });
  };

  const handleMobileNumberChange = (
    ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const numberToKeep = ev.target.value.slice(-10);

    setUserPhoneNumber(numberToKeep);

    if (isMobileNumberValid(numberToKeep)) {
      setUserPhoneNumberValid(true);
    } else {
      setUserPhoneNumberValid(false);
    }

    if (ev && ev.target.value.length && ev.target.value.length < 10) {
      setMobileError(true);
    } else {
      setMobileError(false);
    }
  };

  const handleAlternateMobileNumberChange = (
    ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const numberToKeep = ev.target.value.slice(-10);
    setAltMobNumber(numberToKeep);
    if (isAlternateMobNumError) {
      verifyAlternateMobileNumber(numberToKeep);
    }
  };

  const verifyAlternateMobileNumber = (mobNumber) => {
    if (isMobileNumberValid(mobNumber)) {
      setIsAlternateMobNumError(false);
    } else {
      setIsAlternateMobNumError(true);
    }
  };

  const handleSendConfirmationMsg = () => {
    const orderAmount = couponApplied
      ? cartDiscountObj.toPay +
        homeCollectionCharges.charges +
        homeCollectionCharges.distanceCharges
      : totalAmountToPay;

    const slotTime = selectedSlot?.slotDetail?.slotDisplayTime || '';

    const slotEndDate = addHours(
      parse(
        `${formatDate(selectedSlotDate, 'yyyy-MM-dd')} ${slotTime}`,
        'yyyy-MM-dd hh:mm a',
        new Date()
      ),
      SLOT_EXPIRE_DURATION_IN_HOURS
    );

    const webEngageData = {
      slotDate:
        selectedSlot && selectedSlotDate
          ? formatDate(selectedSlotDate, 'dd-MMM-yyyy')
          : '',
      slotTime: slotTime,
      patientName: getCommonPatientAttributes(selectedProfile)['Patient Name'],
      displayIds: JSON.stringify(displayIds),
      orderAmount: orderAmount,
      paymentMode: 'COD',
      source: 'OPT COD order',
      agentLoginID: getAgentEmail(),
      isMultiUhid: patientLineItems.length > 1,
      patientMobile: userPhoneNumber,
      prebookingIDs: JSON.stringify(prebookingIds),
      slotEndTime: formatDate(slotEndDate, 'hh:mm a'),
      alternatePatientMobile: altMobNumber,
    };
    sendClevertTapEvent(
      'Diagnostic alternate number comms',
      userPhoneNumber,
      webEngageData
    );
    setConfirmationMsg('Sent Successfully');
    setAltMobNumber('');
  };

  const handleResendModifyPaymentLink = () => {
    const orderAmount = couponApplied
      ? cartDiscountObj?.toPay +
        homeCollectionCharges?.charges +
        homeCollectionCharges?.distanceCharges
      : totalAmountToPay +
        homeCollectionCharges?.charges +
        homeCollectionCharges?.distanceCharges;

    const webEngageData = {
      slotDate: 'NA',
      slotTime: 'NA',
      patientName: getCommonPatientAttributes(selectedProfile)['Patient Name'],
      displayIds: modifyFlowOrder?.displayId,
      orderAmount: orderAmount,
      paymentMode: 'ONLINE_PAYMENT',
      source: 'OPT Modify Order Payment Link',
      paymentLink: modifyPaymentLink,
      paymentLinkExpiry: NA_STRING,
      paymentLinkExpiryDate: NA_STRING,
      paymentLinkExpiryTime: NA_STRING,
      agentLoginID: getAgentEmail(),
      isMultiUhid: patientLineItems.length > 1,
      patientMobile: userPhoneNumber,
      slotEndTime: 'NA',
      alternatePatientMobile: userPhoneNumber,
      codAllowed: codEnabled,
    };
    sendClevertTapEvent(
      'Diagnostic alternate number comms',
      userPhoneNumber,
      webEngageData
    );
    setConfirmationMsg(`Payment link sent successfully to ${userPhoneNumber}`);
  };

  const handleSendModifyPaymentLink = () => {
    const orderAmount = couponApplied
      ? cartDiscountObj.toPay +
        homeCollectionCharges.charges +
        homeCollectionCharges.distanceCharges
      : totalAmountToPay +
        homeCollectionCharges.charges +
        homeCollectionCharges.distanceCharges;

    const slotTime = selectedSlot?.slotDetail?.slotDisplayTime || '';

    const slotEndDate = addHours(
      parse(
        `${formatDate(selectedSlotDate, 'yyyy-MM-dd')} ${slotTime}`,
        'yyyy-MM-dd hh:mm a',
        new Date()
      ),
      SLOT_EXPIRE_DURATION_IN_HOURS
    );

    const webEngageData = {
      slotDate:
        selectedSlot && selectedSlotDate
          ? formatDate(selectedSlotDate, 'dd-MMM-yyyy')
          : '',
      slotTime: slotTime,
      patientName: getCommonPatientAttributes(selectedProfile)['Patient Name'],
      displayIds: orderResult?.displayId,
      orderAmount: orderAmount,
      paymentMode: 'ONLINE_PAYMENT',
      source: 'OPT Modify Order Payment Link',
      paymentLink: orderResult?.paymentLink,
      paymentLinkExpiry: NA_STRING,
      paymentLinkExpiryDate: NA_STRING,
      paymentLinkExpiryTime: NA_STRING,
      agentLoginID: getAgentEmail(),
      isMultiUhid: patientLineItems.length > 1,
      patientMobile: userPhoneNumber,
      slotEndTime: formatDate(slotEndDate, 'hh:mm a'),
      alternatePatientMobile: altMobNumber,
      codAllowed: codEnabled,
    };
    sendClevertTapEvent(
      'Diagnostic alternate number comms',
      userPhoneNumber,
      webEngageData
    );
    setConfirmationMsg('Sent Successfully');
    setAltMobNumber('');
  };

  const handleSendPaymentLink = () => {
    const orderAmount = couponApplied
      ? cartDiscountObj.toPay +
        homeCollectionCharges.charges +
        homeCollectionCharges.distanceCharges
      : totalAmountToPay;

    const slotTime = selectedSlot?.slotDetail?.slotDisplayTime || '';

    const slotEndDate = addHours(
      parse(
        `${formatDate(selectedSlotDate, 'yyyy-MM-dd')} ${slotTime}`,
        'yyyy-MM-dd hh:mm a',
        new Date()
      ),
      SLOT_EXPIRE_DURATION_IN_HOURS
    );

    let paymentLinkExpiry = NA_STRING;
    let paymentLinkExpiryDate = NA_STRING;
    let paymentLinkExpiryTime = NA_STRING;
    if (orderResult?.saveBookHCOrder?.paymentLinkExpiry) {
      paymentLinkExpiry = orderResult?.saveBookHCOrder?.paymentLinkExpiry;
      const [expiryDate, expiryTime] = paymentLinkExpiry?.split(', ');
      const [date, monthText] = expiryDate?.split(' ');
      const fulldateParseObj = parse(
        `${date}-${monthText}-${new Date().getFullYear()}`,
        'dd-MMM-yyyy',
        new Date()
      );
      paymentLinkExpiryDate = formatDate(fulldateParseObj, 'dd-MM-yyyy');
      paymentLinkExpiryTime = expiryTime;
    }

    const webEngageData = {
      slotDate:
        selectedSlot && selectedSlotDate
          ? formatDate(selectedSlotDate, 'dd-MMM-yyyy')
          : '',
      slotTime: slotTime,
      patientName: getCommonPatientAttributes(selectedProfile)['Patient Name'],
      displayIds: JSON.stringify(displayIds),
      orderAmount: orderAmount,
      paymentMode: 'ONLINE_PAYMENT',
      source: 'OPT payment link order',
      paymentLink: paymentLink?.paymentLink,
      paymentLinkExpiry: paymentLinkExpiry,
      paymentLinkExpiryDate: paymentLinkExpiryDate,
      paymentLinkExpiryTime: paymentLinkExpiryTime,
      agentLoginID: getAgentEmail(),
      isMultiUhid: patientLineItems.length > 1,
      patientMobile: userPhoneNumber,
      slotEndTime: formatDate(slotEndDate, 'hh:mm a'),
      alternatePatientMobile: altMobNumber,
      codAllowed: codEnabled,
    };
    sendClevertTapEvent(
      'Diagnostic alternate number comms',
      userPhoneNumber,
      webEngageData
    );
    setConfirmationMsg('Sent Successfully');
    setAltMobNumber('');
  };

  const setDuplicateItemsMessage = (
    itemsToKeep: any,
    itemsToBeRemoved: any
  ) => {
    setHasDupes(true);
    let errMsg = `Your cart has been revised! The "${itemsToBeRemoved}" has been removed from your cart as it is already included in another test "${itemsToKeep}" in your cart. Kindly proceed to pay the revised amount.`;
    setOrderResult({
      errorMessage: errMsg,
    });
  };

  const checkAndRemoveDuplicates = (patientsObjArray: any[]) => {
    let updatedPatientItems = [...patientLineItems];
    let updatedCartItems = [...diagnosticCartItems];
    const itemsToBeRemoved: string[] = [];
    const itemsToKeep: string[] = [];
    updatedPatientItems = updatedPatientItems.map((patientObj) => {
      const patientIdx = patientsObjArray.findIndex(
        (patient) => patient.patientID === patientObj.patientID
      );
      if (patientIdx > -1) {
        patientsObjArray[patientIdx].attributes.conflictedItems.forEach(
          (conflict: any) => {
            conflict.itemsWithConflicts.forEach((id: number) => {
              let itemIdx = patientObj.lineItems.findIndex(
                (item) => item.itemId === id
              );
              if (itemIdx > -1) {
                !itemsToBeRemoved.includes(
                  patientObj.lineItems[itemIdx].itemName!
                ) &&
                  itemsToBeRemoved.push(
                    patientObj.lineItems[itemIdx].itemName!
                  );
                patientObj.totalPrice -= patientObj.lineItems[itemIdx].price;
                patientObj.lineItems.splice(itemIdx, 1);
              }
              const indexToRemoveFromCartItemsArray =
                updatedCartItems.findIndex(
                  (item) =>
                    item.patientId === patientObj.patientID &&
                    item.itemId === id
                );

              indexToRemoveFromCartItemsArray > -1 &&
                updatedCartItems.splice(indexToRemoveFromCartItemsArray, 1);
            });
            conflict.itemToKeep.forEach((id: number) => {
              let itemIdx = patientObj.lineItems.findIndex(
                (item) => item.itemId === id
              );
              itemIdx > -1 &&
                !itemsToKeep.includes(
                  patientObj.lineItems[itemIdx].itemName!
                ) &&
                itemsToKeep.push(patientObj.lineItems[itemIdx].itemName!);
            });
          }
        );
      }
      return patientObj;
    });
    setDiagnosticCartItems(updatedCartItems);
    setPatientLineItems(updatedPatientItems);
    setDuplicateItemsMessage(
      itemsToKeep.join('", " '),
      itemsToBeRemoved.join('", " ')
    );
  };

  const handleMultiPatientOrderResult = (orderResult: any) => {
    const { processHCOrder, saveBookHCOrder } = orderResult;
    const someOrdersFailedInProcessing = processHCOrder?.result?.some(
      (oResult: { message: String; preBookingID: number; status: boolean }) =>
        !oResult.status
    );
    const allOrdersPassedInProcessing = processHCOrder?.result?.every(
      (oResult: { message: String; preBookingID: number; status: boolean }) =>
        oResult.status
    );
    const allOrdersFailedInProcessing = processHCOrder?.result?.every(
      (oResult: { message: String; preBookingID: number; status: boolean }) =>
        !oResult.status
    );

    if (someOrdersFailedInProcessing) {
      const ordersPlacedPreBookingIds = processHCOrder?.result
        ?.filter(
          (oResult: {
            message: String;
            preBookingID: number;
            status: boolean;
          }) => oResult.status
        )
        .map(
          (oResult: {
            message: String;
            preBookingID: number;
            status: boolean;
          }) => oResult.preBookingID
        )
        .join(', ');
      const ordersPlacedDisplayIds = saveBookHCOrder?.patientsObjWithOrderIDs
        .filter((oResult: any) => oResult.status && oResult.displayID)
        .map((oResult: any) => oResult.displayID)
        .join(', ');
      const errMsg = `Only Some Orders were placed: \n 
        Prebooking IDs: ${ordersPlacedPreBookingIds}\n
        Display IDs: ${ordersPlacedDisplayIds}`;
      setOrderResult({
        errorMessage: errMsg,
      });
      setSelectedSlot(null);
    } else if (allOrdersFailedInProcessing) {
      setOrderResult({
        errorMessage: 'Failed to process all orders.',
      });
    } else if (
      allOrdersPassedInProcessing &&
      saveBookHCOrder?.patientsObjWithOrderIDs?.length
    ) {
      setOrderResult(orderResult);
      const orderAmount = couponApplied
        ? cartDiscountObj.toPay +
          homeCollectionCharges.charges +
          homeCollectionCharges.distanceCharges
        : totalAmountToPay;
      const itemIds = finalCartItems?.map((cartItem) => cartItem?.itemId);
      const itemNames = finalCartItems?.map((cartItem) => cartItem?.itemName);
      const webEngageData = {
        ...getCommonPatientAttributes(selectedProfile),
        Source: 'OPT',
        'Order id':
          orderResult?.saveBookHCOrder?.patientsObjWithOrderIDs[0].displayId ||
          '',
        'Payment mode': 'COD',
        'Total items in order': finalCartItems?.length || '',
        'Order amount': orderAmount || 0,
        Pincode: localStorage.getItem('currentZip') || '',
        'Circle discount': '',
        'Circle member': isUserCare ? 'Yes' : 'No',
        'Appointment Date': formatDate(selectedSlotDate, 'yyyy-MM-dd') || '',
        'Appointment time': selectedSlot?.slotDetail?.slotDisplayTime,
        'No of patients':
          orderResult?.saveBookHCOrder?.patientsObjWithOrderIDs?.length || 0,
        itemIds: JSON.stringify(itemIds),
        itemNames: JSON.stringify(itemNames),
        'Cart Value': orderAmount || 0,
        'Coupon Code': couponCode,
        'Circle cashback': circleCashback,
      };
      sendClevertTapEvent(
        'Diagnostic order placed',
        userPhoneNumber,
        webEngageData
      );
    } else {
      setOrderResult({
        errorMessage: `Something went Wrong! Couldn't place order.`,
      });
    }
  };

  const getDiagCODCtaSettings = () => {
    setCodEnabled(false);
    setCodMessage('');
    setShowPaymentOptionRetry(false);
    setShowPaymentOptionDialog(true);
    setPaymentOptionLoader(true);
    const payLoad = {
      cityId: serviceabilityData?.cityID,
      itemIds: diagnosticCartItems?.map((item) => item?.itemId),
    };
    axios
      .post(DISABLE_COD_OPTION, payLoad, {
        headers: {
          Authorization: localStorage.getItem('aphToken'),
        },
      })
      .then(({ data }) => {
        setCodEnabled(data?.cod);
        setCodMessage(data?.message);
      })
      .catch((err) => {
        console.error('Error fetching diagnostic COD CTA settings', err);
        setShowPaymentOptionRetry(true);
      })
      .finally(() => setPaymentOptionLoader(false));
  };

  const isModifyOrderSlotChanged = (): boolean => {
    return (
      !isEqual(
        new Date(modifyFlowOrder?.slotDateTimeInUTC),
        selectedSlotDate
      ) ||
      selectedSlot?.slotDetail?.slotDisplayTime !==
        format(new Date(modifyFlowOrder?.slotDateTimeInUTC), 'hh:mm aaa')
    );
  };

  const processModifyOrder = () => {
    setPlaceOrderLoading(true);
    const orderAmount = couponApplied
      ? cartDiscountObj.toPay +
        homeCollectionCharges.charges +
        homeCollectionCharges.distanceCharges
      : totalAmountToPay +
        homeCollectionCharges.charges +
        homeCollectionCharges.distanceCharges;
    const paidAmount =
      modifyOrderPaidAmountBreakup.subTotal +
      modifyFlowOrder.collectionCharges -
      modifyOrderPaidAmountBreakup.circleSavings -
      modifyOrderPaidAmountBreakup.cartSavings -
      modifyOrderPaidAmountBreakup.couponSavings;

    const remainingAmountToBePaid = orderAmount - paidAmount;

    const currentCartItemIds = finalCartItems?.map((item) => item?.itemId);

    const filterPrevRemovedOrders =
      modifyFlowOrder?.diagnosticOrderLineItems?.filter(
        (lineItem) => !lineItem?.isRemoved
      );

    const removedOrders = filterPrevRemovedOrders?.filter(
      (lineItem) => !currentCartItemIds?.includes(lineItem.itemId)
    );

    const removeOrderLineItems = removedOrders?.map((removedOrder) => {
      return {
        price: removedOrder?.price,
        groupPlan: removedOrder?.groupPlan,
        itemId: removedOrder?.itemId,
        couponDiscAmount: removedOrder?.couponDiscAmount,
        removed: true,
      };
    });

    const useMrp =
      !!couponApplied &&
      (!!cartArrayAfterDiscount?.some((items) =>
        items?.lineItems?.some((lineItem) => !!lineItem?.isCouponOnMrp)
      ) ||
        !!removedOrders?.some(
          (removeOrder) => !!removeOrder?.itemObj?.isOnMrpCoupon
        ));

    const isModifyCardUpdated = () => {
      return (
        (Number(remainingAmountToBePaid) > 0 || removedOrders?.length) &&
        finalCartItems?.length
      );
    };

    const lineItems = !couponApplied
      ? sanitizeLineItems(patientLineItems, selectedNonCartFlowOrder)?.[0]
          ?.lineItems
      : sanitizeLineItems(
          cartArrayAfterDiscount!,
          selectedNonCartFlowOrder
        )?.[0]?.lineItems;

    const payloadLineItems = lineItems?.map((lineItem) => ({
      price: lineItem?.price,
      groupPlan: lineItem?.groupPlan,
      itemId: lineItem?.itemId,
      couponDiscAmount: lineItem?.couponDiscAmount,
    }));
    if (modifyFlowOrder?.paymentType !== 'COD') {
      if (remainingAmountToBePaid < 0) {
        setShowRefundDialog(true);
      } else if (remainingAmountToBePaid === 0) {
        setShowModifyCodDialog(true);
      } else {
        setShowModifyPaymentDialog(true);
      }
    } else {
      setShowModifyCodDialog(true);
    }
    const saveModifyInput = {
      orderId: modifyFlowOrder?.id,
      collectionChargesV2: phleboCharges,
      collectionCharges:
        phleboCharges?.additionalCharges + phleboCharges?.collectionCharges,
      bookingSource: orderSource,
      deviceType: 'DESKTOP',
      items: [...payloadLineItems, ...removeOrderLineItems],
      userSubscriptionId: circleSubscriptionObj
        ? circleSubscriptionObj._id
        : '',
      amountToPay: remainingAmountToBePaid,
      useMrp,
    };
    if (doctorName?.length) {
      saveModifyInput['doctorName'] = doctorName;
    }
    if (remainingAmountToBePaid < 0) {
      saveModifyInput['downSellAmountRefund'] = Math.abs(
        remainingAmountToBePaid
      );
      saveModifyInput['amountToPay'] = 0;
    }
    if (!isModifyCardUpdated()) {
      delete saveModifyInput['items'];
    }
    if (isModifyOrderSlotChanged()) {
      saveModifyInput['rescheduleSlot'] = {
        reason: '',
        parentOrderID: modifyFlowOrder?.parentOrderId,
        selectedDate: formatDate(selectedSlotDate, 'yyyy-MM-dd'),
        slotInfo: {
          paidSlot: selectedSlot?.isPaidSlot!,
          slotDetails: selectedSlot?.slotDetail!,
        },
      };
    }
    saveModifyInput['patientId'] = selectedProfile.id;
    const apiPayload: any = {
      saveModifyInput: saveModifyInput,
      paymentMode: modifyFlowOrder?.paymentType,
      patientId: modifyFlowOrder?.patientId,
    };
    axios
      .post(MODIFY_ORDER, apiPayload, {
        headers: {
          Authorization: localStorage.getItem('aphToken'),
        },
      })
      .then(({ data }) => {
        if (data?.saveModifyOrder?.status) {
          setOrderResult({
            amountToBePaid: remainingAmountToBePaid,
            paymentLink: data?.saveModifyOrder?.paymentLink,
            displayId: data?.saveModifyOrder?.displayId,
            previousOrderAmount: paidAmount,
            updatedOrderAmount: orderAmount,
            refundAmount: paidAmount - orderAmount,
          });
        } else if (
          !data?.saveModifyOrder?.status &&
          data?.saveModifyOrder?.attributes?.conflictedItems?.length
        ) {
          const dupeCheckPayload = [
            {
              ...data?.saveModifyOrder,
              patientID: modifyFlowOrder?.patientId,
            },
          ];
          checkAndRemoveDuplicates(dupeCheckPayload);
        } else {
          setOrderResult({
            errorMessage: 'Unable to process order',
          });
        }
      })
      .catch((err) => {
        const { data, message } = err?.response?.data;
        const errorMsg = err?.response?.data?.data?.message;
        if (errorMsg && RESCHEDULE_ERROR_ORDER_STATUS?.includes(errorMsg)) {
          setIsSlotError(true);
          setShowModifyCodDialog(false);
        } else {
          const errorMessage = message
            ? message
            : typeof data === 'string'
            ? data
            : `Something went Wrong! Couldn't place order.`;
          setOrderResult({
            errorMessage:
              ERROR_MESSAGES[errorMsg] ??
              errorMsg === ERROR_MESSAGES['MODIFY_IN_PROGRESS']
                ? errorMsg
                : errorMessage,
          });
        }
      })
      .finally(() => {
        setPlaceOrderLoading(false);
      });
  };

  const processCodOrder = () => {
    setOrderResult(null);
    setShowCodOrderDetail(true);
    setPlaceOrderLoading(true);
    const multiPatientOrder = patientLineItems.length > 1;
    const useMrp =
      !!couponApplied &&
      !!cartArrayAfterDiscount?.some((items) =>
        items?.lineItems?.some((lineItem) => !!lineItem?.isCouponOnMrp)
      );
    const bookDiagHomeCollectionInfo: DiagnosticOrderInput = {
      patientAddressID: selectedAddress?.id || '',
      patientObjWithLineItems: !couponApplied
        ? sanitizeLineItems(patientLineItems, selectedNonCartFlowOrder)
        : sanitizeLineItems(cartArrayAfterDiscount!, selectedNonCartFlowOrder),
      selectedDate: formatDate(selectedSlotDate, 'yyyy-MM-dd'),
      slotInfo: {
        paidSlot: selectedSlot?.isPaidSlot!,
        slotDetails: selectedSlot?.slotDetail!,
      },
      serviceability: serviceabilityData,
      bookingSource: orderSource,
      storeId: storeId ?? '',
      deviceType: 'DESKTOP',
      collectionCharges: homeCollectionCharges,
      collectionChargesV2: phleboCharges,
      userSubscriptionId: circleSubscriptionObj
        ? circleSubscriptionObj._id
        : '',
      subscriptionInclusionId: '',
      couponCode: couponApplied ? couponSelected : '',
      isNonCartFlow: isNonCartFlowActivated,
      slotByClusterConfig: true,
      useMrp,
      doctorName: doctorName?.length ? 'Dr. ' + doctorName : '',
      allowHCWaiveOffForCirclePurchase: true,
    };

    axios
      .post(BOOK_HOME_COLLECTION, {
        diagnosticOrderInput: bookDiagHomeCollectionInfo,
        mobileNumber: '+91' + userPhoneNumber,
      })
      .then((res) => {
        const { data } = res;

        setPlaceOrderLoading(false);
        if (data?.processHCOrder && data.saveBookHCOrder) {
          if (!multiPatientOrder) {
            if (
              data.processHCOrder.status &&
              data.saveBookHCOrder.patientsObjWithOrderIDs?.[0]
            ) {
              setOrderResult(data);
              const orderAmount = couponApplied
                ? cartDiscountObj.toPay +
                  homeCollectionCharges.charges +
                  homeCollectionCharges.distanceCharges
                : totalAmountToPay;
              const itemIds = finalCartItems?.map(
                (cartItem) => cartItem?.itemId
              );
              const itemNames = finalCartItems?.map(
                (cartItem) => cartItem?.itemName
              );
              const webEngageData = {
                ...getCommonPatientAttributes(selectedProfile),
                Source: 'OPT',
                'Order id':
                  data?.saveBookHCOrder?.patientsObjWithOrderIDs[0].displayId ||
                  '',
                'Payment mode': 'COD',
                'Total items in order': finalCartItems?.length || '',
                'Order amount': orderAmount || 0,
                Pincode: localStorage.getItem('currentZip') || '',
                'Circle discount': '',
                'Circle member': isUserCare ? 'Yes' : 'No',
                'Appointment Date':
                  formatDate(selectedSlotDate, 'yyyy-MM-dd') || '',
                'Appointment time': selectedSlot?.slotDetail?.slotDisplayTime,
                'No of patients':
                  data?.saveBookHCOrder?.patientsObjWithOrderIDs?.length || 0,
                itemIds: JSON.stringify(itemIds),
                itemNames: JSON.stringify(itemNames),
                'Cart Value': orderAmount || 0,
                'Coupon Code': couponCode,
                'Circle cashback': circleCashback,
              };
              sendClevertTapEvent(
                'Diagnostic order placed',
                userPhoneNumber,
                webEngageData
              );
            } else {
              setOrderResult({
                errorMessage: data.processHCOrder.message,
              });
            }
          } else {
            handleMultiPatientOrderResult(data);
          }
        } else {
          const patientsObjArray =
            data?.saveBookHCOrder?.patientsObjWithOrderIDs;
          const isDuplicatesFound = patientsObjArray.some(
            (pObj: any) => pObj?.attributes?.conflictedItems?.length > 0
          );
          if (isDuplicatesFound) {
            checkAndRemoveDuplicates(patientsObjArray);
          } else {
            const errorMessage =
              data.saveBookHCOrder.errorMessageToDisplay ||
              `Something went Wrong! Couldn't place order.`;
            setOrderResult({
              errorMessage,
            });
          }
        }
      })
      .catch((err) => {
        const { error, data, message } = err?.response?.data;
        if (error) {
          console.error('Following error while placing order', message);
          if (message?.includes(ERROR_MESSAGES?.GENDER_MAPPING)) {
            setShowCodOrderDetail(false);
            setShowPaymentOptionDialog(false);
            setIsItemRemoved(true);
          } else {
            const errorMessage = message
              ? message
              : typeof data === 'string'
              ? data
              : `Something went Wrong! Couldn't place order.`;

            setOrderResult({
              errorMessage,
            });
          }
        } else {
          setOrderResult({
            errorMessage: `Something went Wrong! Couldn't place order.`,
          });
        }
        setPlaceOrderLoading(false);
      });
  };

  const generatePaymentLink = () => {
    setOrderResult(null);
    setShowPaymentLinkOrderDetail(true);
    setPlaceOrderLoading(true);
    const useMrp =
      !!couponApplied &&
      !!cartArrayAfterDiscount?.some((items) =>
        items?.lineItems?.some((lineItem) => !!lineItem?.isCouponOnMrp)
      );
    const bookDiagHomeCollectionInfo: DiagnosticOrderInput = {
      allowHCWaiveOffForCirclePurchase: true,
      patientAddressID: selectedAddress?.id || '',
      patientObjWithLineItems: !couponApplied
        ? sanitizeLineItems(patientLineItems, selectedNonCartFlowOrder)
        : sanitizeLineItems(cartArrayAfterDiscount!, selectedNonCartFlowOrder),
      selectedDate: formatDate(selectedSlotDate, 'yyyy-MM-dd'),
      slotInfo: {
        paidSlot: selectedSlot?.isPaidSlot!,
        slotDetails: selectedSlot?.slotDetail!,
      },
      serviceability: serviceabilityData,
      bookingSource: orderSource,
      storeId: orderSource === 'OP_STORE' ? storeId : '',
      deviceType: 'DESKTOP',
      collectionChargesV2: phleboCharges,
      userSubscriptionId: circleSubscriptionObj
        ? circleSubscriptionObj._id
        : '',
      subscriptionInclusionId: '',
      couponCode: couponApplied ? couponSelected : '',
      isNonCartFlow: isNonCartFlowActivated,
      useMrp,
      doctorName: doctorName?.length ? 'Dr. ' + doctorName : '',
    };

    if (circleMemberShipPlan) {
      bookDiagHomeCollectionInfo.userSubscription = {
        plan_id: CIRCLE_PLAN_ID,
        source_meta_data: {
          purchase_type: PlanPurchaseType.first_time,
        },
        sub_plan_id: circleMemberShipPlan?.subPlanId,
        transaction_date_time: new Date().toISOString(),
        amount: circleMemberShipPlan?.price,
      };
    }

    axios
      .post(
        SAVE_AND_SEND_PAYMENT_LINK,
        {
          diagnosticOrderInput: bookDiagHomeCollectionInfo,
          mobileNumber: '+91' + userPhoneNumber,
        },
        {
          headers: {
            Authorization: localStorage.getItem('aphToken'),
          },
        }
      )
      .then((res) => {
        const { data } = res;

        setPaymentLink({
          paymentLink: data?.saveBookHCOrder?.paymentLink || '',
          paymentLinkExpiry: data?.saveBookHCOrder?.paymentLinkExpiry || '',
          parentOrderId: data?.saveBookHCOrder?.parentOrderId || '',
        });
        const orderSuccess =
          data?.saveBookHCOrder?.patientsObjWithOrderIDs?.every(
            (patientsObj) => patientsObj?.status
          );
        if (orderSuccess) {
          if (data.saveBookHCOrder.patientsObjWithOrderIDs?.[0]) {
            setOrderResult(data);
            const orderAmount = couponApplied
              ? cartDiscountObj.toPay +
                homeCollectionCharges.charges +
                homeCollectionCharges.distanceCharges
              : totalAmountToPay;
            const itemIds = finalCartItems?.map((cartItem) => cartItem?.itemId);
            const itemNames = finalCartItems?.map(
              (cartItem) => cartItem?.itemName
            );
            const webEngageData = {
              ...getCommonPatientAttributes(selectedProfile),
              Source: 'OPT',
              'Order id':
                data?.saveBookHCOrder?.patientsObjWithOrderIDs[0].displayId ||
                '',
              'Payment mode': 'COD',
              'Total items in order': finalCartItems?.length || '',
              'Order amount': orderAmount || 0,
              Pincode: localStorage.getItem('currentZip') || '',
              'Circle discount': '',
              'Circle member': isUserCare ? 'Yes' : 'No',
              'Appointment Date':
                formatDate(selectedSlotDate, 'yyyy-MM-dd') || '',
              'Appointment time': selectedSlot?.slotDetail?.slotDisplayTime,
              'No of patients':
                data?.saveBookHCOrder?.patientsObjWithOrderIDs?.length || 0,
              itemIds: JSON.stringify(itemIds),
              itemNames: JSON.stringify(itemNames),
              'Cart Value': orderAmount || 0,
              'Coupon Code': couponCode,
              'Circle cashback': circleCashback,
            };
            sendClevertTapEvent(
              'Diagnostic order placed',
              userPhoneNumber,
              webEngageData
            );
          } else {
            setOrderResult({
              errorMessage:
                data?.saveBookHCOrder?.patientsObjWithOrderIDs[0]
                  .errorMessageToDisplay ||
                `Something went Wrong! Couldn't place order.`,
            });
          }
        } else {
          const patientsObjArray =
            data?.saveBookHCOrder?.patientsObjWithOrderIDs;
          const isDuplicatesFound = patientsObjArray?.some(
            (pObj: any) => pObj?.attributes?.conflictedItems?.length > 0
          );
          if (isDuplicatesFound) {
            checkAndRemoveDuplicates(patientsObjArray);
          } else {
            const errorMessage =
              data.saveBookHCOrder.errorMessageToDisplay ||
              `Something went Wrong! Couldn't place order.`;
            setOrderResult({
              errorMessage,
            });
          }
        }
      })
      .catch((err) => {
        if (err?.response?.data?.error) {
          const { data, message } = err?.response?.data;
          console.error('Following error while placing order', data);
          if (message?.includes(ERROR_MESSAGES?.GENDER_MAPPING)) {
            setShowCodOrderDetail(false);
            setShowPaymentOptionDialog(false);
            setIsItemRemoved(true);
          } else {
            const errorMessage = message
              ? message
              : typeof data === 'string'
              ? data
              : `Something went Wrong! Couldn't place order.`;
            setOrderResult({
              errorMessage,
            });
          }
        } else {
          setOrderResult({
            errorMessage: `Something went Wrong! Couldn't place order.`,
          });
        }
      })
      .finally(() => setPlaceOrderLoading(false));
  };

  const resendPaymentLink = () => {
    setShowPaymentLinkOrderDetail(true);
    setPlaceOrderLoading(true);
    const useMrp =
      !!couponApplied &&
      !!cartArrayAfterDiscount?.some((items) =>
        items?.lineItems?.some((lineItem) => !!lineItem?.isCouponOnMrp)
      );
    const bookDiagHomeCollectionInfo: DiagnosticOrderInput = {
      allowHCWaiveOffForCirclePurchase: true,
      patientAddressID: selectedAddress?.id || '',
      patientObjWithLineItems: !couponApplied
        ? sanitizeLineItems(patientLineItems, selectedNonCartFlowOrder)
        : sanitizeLineItems(cartArrayAfterDiscount!, selectedNonCartFlowOrder),
      selectedDate: formatDate(selectedSlotDate, 'yyyy-MM-dd'),
      slotInfo: {
        paidSlot: selectedSlot?.isPaidSlot!,
        slotDetails: selectedSlot?.slotDetail!,
      },
      serviceability: serviceabilityData,
      bookingSource: orderSource,
      storeId: orderSource === 'OP_STORE' ? storeId : '',
      deviceType: 'DESKTOP',
      collectionChargesV2: phleboCharges,
      userSubscriptionId: circleSubscriptionObj
        ? circleSubscriptionObj._id
        : '',
      subscriptionInclusionId: '',
      couponCode: couponApplied ? couponSelected : '',
      isNonCartFlow: isNonCartFlowActivated,
      slotByClusterConfig: true,
      useMrp,
      doctorName: doctorName?.length ? 'Dr. ' + doctorName : '',
    };

    if (circleMemberShipPlan) {
      bookDiagHomeCollectionInfo.userSubscription = {
        plan_id: CIRCLE_PLAN_ID,
        source_meta_data: {
          purchase_type: PlanPurchaseType.first_time,
        },
        sub_plan_id: circleMemberShipPlan?.subPlanId,
        transaction_date_time: new Date().toISOString(),
        amount: circleMemberShipPlan?.price,
      };
    }

    const apiPayload = {
      diagnosticOrderInput: bookDiagHomeCollectionInfo,
      mobileNumber: '+91' + userPhoneNumber,
      resendPaymentLink: true,
      parentOrderId: paymentLink.parentOrderId,
    };

    axios
      .post(SAVE_AND_SEND_PAYMENT_LINK, apiPayload, {
        headers: {
          Authorization: localStorage.getItem('aphToken'),
        },
      })
      .then(({ data }) => {
        setPaymentLink({
          paymentLink: data?.saveBookHCOrder?.paymentLink || '',
          paymentLinkExpiry: data?.saveBookHCOrder?.paymentLinkExpiry || '',
          parentOrderId: data?.saveBookHCOrder?.parentOrderId || '',
        });
      })
      .catch((err) => {
        console.error('Error while resending payment link', err);
      })
      .finally(() => setPlaceOrderLoading(false));
  };

  const removeCoupon = () => {
    setCouponErrMsg('');
    setCouponSelected('');
    setRemoveHCCharges(false);
    setCartDiscountObj({ ...couponPricingAccumulator });
    setCouponApplied(false);
    setCartArrayAfterDiscount([]);
    if (isUserCare) {
      setCircleBenefitsApplied(true);
    }
  };

  const handleViewPrescriptions = (patientId: string) => {
    setPrescriptionsLoader(true);
    setPatientPrescriptions([]);
    let prescriptionList: any = [];
    axios
      .post(GET_PRESCRIPTIONS, {
        patientId: patientId,
        mobileNumber: userPhoneNumber,
      })
      .then(({ data }) => {
        if (data?.getPatientPrescriptionsResp?.response?.length) {
          const pres = data.getPatientPrescriptionsResp.response.map(
            (item: any) => ({
              type: 'vc',
              ...item,
            })
          );
          prescriptionList = [...pres, ...prescriptionList];
        }
        if (
          data?.getPatientPrismMedicalRecordsResp?.prescriptions?.response
            ?.length
        ) {
          const pres =
            data.getPatientPrismMedicalRecordsResp.prescriptions.response.map(
              (item: any) => ({
                type: 'phr',
                ...item,
              })
            );
          prescriptionList = [...pres, ...prescriptionList];
        }
      })
      .catch((err) => {
        console.error('Error while fetching prescriptions', err);
      })
      .finally(() => {
        setPatientPrescriptions(prescriptionList);
        setPrescriptionsLoader(false);
      });
    setViewPrescription(true);
  };

  const handlePrescriptionOrders = () => {
    setIsNonCartFlowActivated(true);
    setRadiologyTest(false);
  };
  const handleRadiologyTests = () => {
    setRadiologyTest(true);
  };

  const handleOrderCancel = (order: any) => {
    setOrderCancelType(ORDER_CANCEL_TYPE?.ORDER_CANCEL);
    setIsCancellationLoading(true);
    setShowActiveOrdersDialog(false);
    setCancelledOrder(order);
    axios
      .post(GET_ORDER_CANCELLATION_REASONS, {
        source: SOURCE.OPS_DASHBOARD,
        mobileNumber: '+91' + userPhoneNumber,
      })
      .then(({ data }) => {
        const reasons = data?.cancellationReasonsv2?.map(
          (reason) => reason?.reason
        );
        setCancellationReasons(reasons);
        setShowCancellationReasonDialog(true);
      })
      .catch((err) => {
        console.error('Error while fetching reasons', err);
      })
      .finally(() => {
        setIsCancellationLoading(false);
      });
  };

  const handleNonCartOrderCancel = () => {
    setOrderCancelType(ORDER_CANCEL_TYPE?.PRESCRIPTION_ORDER_CANCEL);
    setIsCancellationLoading(true);
    axios
      .get(GET_CANCELLATION_REASONS)
      .then(({ data }) => {
        setCancellationReasons(data?.reasons);
        setShowCancellationReasonDialog(true);
      })
      .catch((err) => {
        console.error('Error while fetching reasons', err);
      })
      .finally(() => {
        setIsCancellationLoading(false);
      });
  };

  const submitOrderCancellation = () => {
    const reason =
      selectedReasonIndex === cancellationReasons?.length - 1
        ? cancellationReason
        : cancellationReasons?.[selectedReasonIndex];
    const payload = {
      cancellationDiagnosticsInput: {
        orderIds: cancelledOrder?.id,
        patientId: cancelledOrder?.patientId,
        reason: reason,
        comment: '',
        allowCancellationRequest: false,
      },
      mobileNumber: '+91' + userPhoneNumber,
    };
    setIsCancellationLoading(true);
    axios
      .post(CANCEL_ORDER, payload)
      .then(({ data }) => {
        if (data?.cancelDiagnosticOrdersv2?.status) {
          setShowOrderCancelDailog(true);
        } else {
          setShowOrderCancelFailedDailog(true);
        }
      })
      .catch((err) => {
        setShowOrderCancelFailedDailog(true);
        console.error('Error while fetching reasons', err);
      })
      .finally(() => {
        setShowCancellationReasonDialog(false);
        setIsCancellationLoading(false);
        setCancellationReason('');
        setSelectedReasonIndex(-1);
      });
  };

  const submitNonCartOrderCancel = () => {
    const reason =
      selectedReasonIndex === cancellationReasons?.length - 1
        ? cancellationReason
        : cancellationReasons?.[selectedReasonIndex];
    const payload = {
      diagnosticOrdersId: selectedNonCartFlowOrder?.id,
      reason: reason,
      mobileNumber: '+91' + userPhoneNumber,
    };
    setIsCancellationLoading(true);
    axios
      .post(UPDATE_ORDER_STATUS, payload)
      .then(({ data }) => {
        if (data?.status) {
          setShowOrderCancelDailog(true);
        } else {
          setShowOrderCancelFailedDailog(true);
        }
      })
      .catch((err) => {
        setShowOrderCancelFailedDailog(true);
        console.error('Error while fetching reasons', err);
      })
      .finally(() => {
        setShowCancellationReasonDialog(false);
        setIsCancellationLoading(false);
        setCancellationReason('');
        setSelectedReasonIndex(-1);
      });
  };

  const submitCancelOrder = () => {
    if (orderCancelType === ORDER_CANCEL_TYPE.ORDER_CANCEL) {
      submitOrderCancellation();
    } else {
      submitNonCartOrderCancel();
    }
  };

  const handleOrderCancelSuccess = () => {
    if (orderCancelType === ORDER_CANCEL_TYPE.ORDER_CANCEL) {
      setShowActiveOrdersDialog(false);
    } else {
      setSelectedNonCartFlowOrder(null);
      setIsNonCartFlowActivated(true);
    }
    setShowOrderCancelDailog(false);
  };

  const handleModifyOrder = async (order) => {
    setSelectedProfile(null);
    setModifyOrderSelectedProfile(null);
    setShowActiveOrdersDialog(false);
    axios
      .post(GET_ORDER_DETAILS, {
        diagnosticOrderId: order?.id,
        phoneNo: '+91' + userPhoneNumber,
      })
      .then(({ data }) => {
        const modifyOrder = updateModifyOrderPrice(data?.ordersList);
        if (modifyOrder?.modifyInProgress) {
          setIsModifyInProgress(true);
          setModifyPaymentLink(modifyOrder?.paymentLinkURL);
        }
        setShowModifyFlow(false);
        setModifyFlowOrder(modifyOrder);
        setHomeCollectionCharges({
          charges: modifyOrder?.collectionCharges ?? 0,
          distanceCharges: 0,
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const showAddCircleSection =
    diagnosticCartItems?.length > 0 && !circleSubscriptionObj?._id;
  // && (!couponApplied || (couponApplied && circleBenefitsApplied));
  if ((isNonCartFlowActivated && !selectedNonCartFlowOrder) || radiologyTest) {
    return (
      <div className={classes.root}>
        <Header />
        <div className={classes.container}>
          <div className={classes.orderPunchingTool}>
            {radiologyTest ? <Radiology /> : <PrescriptionOrders />}
          </div>
        </div>
      </div>
    );
  }

  if (showModifyFlow) {
    return (
      <div className={classes.root}>
        <Header />
        <div className={classes.container}>
          <div className={classes.orderPunchingTool}>
            <ModifyOrderFlow />
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className={classes.root}>
      <Header />
      <div className={classes.container}>
        <div className={classes.orderPunchingTool}>
          {modifyFlowOrder && (
            <div className={classes.modifyOrderFlowContainer}>
              <div>
                <div>
                  <span>Mobile Number:</span>
                  <span>{userPhoneNumber}</span>
                </div>
                <div>
                  <span>Order ID:</span>
                  <span>#{modifyFlowOrder?.displayId}</span>
                </div>
              </div>
              <div>
                <AphButton
                  disabled={!hasAccessToCancelOrder}
                  onClick={() => {
                    if (hasAccessToCancelOrder) {
                      handleOrderCancel(modifyFlowOrder);
                    }
                  }}
                >
                  CANCEL ORDER
                </AphButton>
              </div>
            </div>
          )}
          <div
            className={`${classes.ctaHeading} ${
              modifyFlowOrder && classes.hideOnModifyFlow
            }`}
          >
            <>
              <div className={` ${classes.prescriptionOrders}`}>
                <AphButton onClick={handlePrescriptionOrders} color="primary">
                  PRESCRIPTION ORDERS
                </AphButton>
              </div>
              <div className={` ${classes.prescriptionOrders}`}>
                <AphButton onClick={handleRadiologyTests} color="primary">
                  Book Radiology Tests
                </AphButton>
              </div>
              <div className={` ${classes.prescriptionOrders}`}>
                <AphButton
                  onClick={() => setShowModifyFlow(true)}
                  color="primary"
                >
                  Modify Orders
                </AphButton>
              </div>
            </>
          </div>
          <div
            className={`${classes.inputs} ${
              modifyFlowOrder && classes.hideOnModifyFlow
            }`}
          >
            <div className={classes.formGroup}>
              <label>Phone number</label>
              <TextField
                className={classes.textField}
                placeholder="Phone Number"
                onChange={(event) => {
                  if (
                    isNumber(event.target.value) ||
                    allowCopyPaste(event.target.value) ||
                    event.target.value === ''
                  ) {
                    handleMobileNumberChange(event);
                  }
                }}
                inputProps={{
                  pattern: '^[0-9]{10}$',
                }}
                onKeyPress={(e) => {
                  if (e.key === 'Enter' && e.shiftKey === false) {
                    e.preventDefault();
                    userPhoneNumberValid && handleUserRegistrationStatus();
                  }
                }}
                value={userPhoneNumber}
                FormHelperTextProps={{
                  className: classes.helperText,
                }}
                helperText={
                  mobileError ? 'Please enter 10 digit mobile number' : ''
                }
                error={mobileError}
              />
            </div>
            {detailsFetchedFlag && (
              <div className={classes.formGroup}>
                <label>Select Order Source</label>
                <Select
                  className={classes.selectField}
                  onChange={(
                    e: React.ChangeEvent<{
                      name?: string | undefined;
                      value: unknown;
                    }>
                  ) => {
                    setOrderSource(e.target.value as string);
                    setStoreId('');
                  }}
                  value={orderSource}
                  MenuProps={{
                    classes: {
                      paper: classes.menuPopover,
                    },
                    getContentAnchorEl: null,
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                  }}
                >
                  {ORDER_SOURCES.map((source, idx) => {
                    return (
                      <MenuItem
                        value={source.value}
                        classes={{
                          selected: classes.menuSelected,
                        }}
                        key={`order-source-` + idx}
                      >
                        {source.label}
                      </MenuItem>
                    );
                  })}
                </Select>
              </div>
            )}
            {detailsFetchedFlag &&
              (orderSource === 'OP_WHATSAPP' ||
                orderSource === 'OP_CALL_CENTER' ||
                orderSource === 'OP_OMT_TEAM' ||
                orderSource === 'OP_CAMPAIGN') && (
                <div className={classes.formGroup}>
                  <label>Enter Campaign Name</label>
                  <AphTextField
                    placeholder="Campaign Name"
                    className={classes.storeIdInput}
                    onChange={(e) => setStoreId(e.target.value)}
                    value={storeId}
                  />
                </div>
              )}
            {detailsFetchedFlag && orderSource === 'OP_STORE' && (
              <div className={classes.formGroup}>
                <label>Enter Store ID</label>
                <AphTextField
                  placeholder="Store ID"
                  className={classes.storeIdInput}
                  onChange={(e) => setStoreId(e.target.value)}
                  value={storeId}
                />
              </div>
            )}
            {detailsFetchedFlag && orderSource === 'OP_CLINIC' && (
              <div className={classes.formGroup}>
                <label>Enter Clinic ID</label>
                <AphTextField
                  placeholder="Clinic ID"
                  className={classes.storeIdInput}
                  onChange={(e) => setStoreId(e.target.value)}
                  value={storeId}
                />
              </div>
            )}
            {detailsFetchedFlag && orderSource === 'OP_PARTNER' && (
              <div className={classes.formGroup}>
                <label>Enter Partner Name</label>
                <AphTextField
                  placeholder="Partner Name"
                  className={classes.storeIdInput}
                  onChange={(e) => setStoreId(e.target.value)}
                  value={storeId}
                />
              </div>
            )}
            {detailsFetchedFlag && orderSource === 'OP_CAMP' && (
              <div className={classes.formGroup}>
                <label>Enter Camp Name</label>
                <AphTextField
                  placeholder="Camp Name"
                  className={classes.storeIdInput}
                  onChange={(e) => setStoreId(e.target.value)}
                  value={storeId}
                />
              </div>
            )}

            {selectedProfile && !isNonCartFlowActivated && (
              <div
                className={`${classes.formGroup} ${classes.viewPrescription}`}
              >
                <Button
                  onClick={() => {
                    handleViewPrescriptions(selectedProfile.id);
                  }}
                >
                  VIEW PRESCRIPTIONS
                </Button>
              </div>
            )}
          </div>
          <div
            className={`${classes.inputs} ${
              modifyFlowOrder && classes.hideOnModifyFlow
            }`}
          >
            {isUserCare && (
              <>
                <img
                  src={require('../images/circle_logo.png')}
                  alt="Circle Icon"
                  className={classes.circleImage}
                />
                User
              </>
            )}
          </div>
          {isNonCartFlowActivated && (
            <div className={classes.inputs}>
              <p className={classes.orderId}>
                Order ID: {selectedNonCartFlowOrder?.displayId}
              </p>
              <AphButton
                onClick={() => handleNonCartOrderCancel()}
                color="primary"
              >
                CANCEL ORDER
              </AphButton>
            </div>
          )}

          {!loading ? (
            <>
              {!dashboardErr && detailsFetchedFlag && (
                <div className={classes.detailSections}>
                  <div className={classes.detailBoxes}>
                    <div>
                      <div className={classes.shadowedBox}>
                        <CustomerProfiles
                          handleUserRegistrationStatus={
                            handleUserRegistrationStatus
                          }
                        />
                      </div>

                      {selectedNonCartFlowOrder && (
                        <div className={classes.shadowedBox}>
                          <Prescriptions setDoctorName={setDoctorName} />
                        </div>
                      )}
                      {selectedProfile && (
                        <>
                          <div className={classes.shadowedBox}>
                            <Addresses />
                          </div>
                          {/* field for doctor name */}
                          <div className={classes.shadowedBox}>
                            <h3>Doctor</h3>
                            <div className={classes.docName}>
                              <label>Doctor Name (optional)</label>
                              <div>
                                <span>Dr.</span>
                                <TextField
                                  value={doctorName}
                                  onChange={(e) =>
                                    setDoctorName(e.target.value as string)
                                  }
                                  placeholder="Enter doctor name"
                                />
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                    <div>
                      <div
                        className={`${classes.shadowedBox} ${classes.selectedBox}`}
                      >
                        <PatientCartItems
                          setFinalCartItems={setFinalCartItems}
                          couponApplied={couponApplied}
                          cartArrayAfterDiscount={cartArrayAfterDiscount!}
                        />
                      </div>
                      <PackageRecommendation />
                      <TestRecommendation />
                    </div>
                    <div>
                      <div className={classes.shadowedBox}>
                        <h3>Schedule Slots</h3>
                        <div>
                          <Slots
                            setCollectionChargesLoader={
                              setCollectionChargesLoader
                            }
                          />
                        </div>
                      </div>

                      {showAddCircleSection && (
                        <div className={`${classes.shadowedBox}`}>
                          <AddCircle
                            cartDiscountObj={cartDiscountObj}
                            couponApplied={couponApplied}
                          />
                        </div>
                      )}
                      {modifyFlowOrder && (
                        <div
                          className={`${classes.shadowedBox} ${classes.orderSummary}`}
                        >
                          <PreviousOrderSummary />
                        </div>
                      )}

                      <div
                        className={`${classes.shadowedBox} ${classes.orderSummary}`}
                      >
                        <OrderSummary
                          setShowModifyOrderConfirmationDialog={
                            setShowModifyOrderConfirmationDialog
                          }
                          maxReportTAT={maxReportTAT}
                          finalCartItems={finalCartItems}
                          handlePlaceOrderClick={getDiagCODCtaSettings}
                          placeOrderLoading={placeOrderLoading}
                          orderSource={orderSource}
                          couponSelected={couponSelected}
                          setCouponSelected={setCouponSelected}
                          setCartArrayAfterDiscount={setCartArrayAfterDiscount}
                          cartDiscountObj={cartDiscountObj}
                          setCartDiscountObj={setCartDiscountObj}
                          removeCouponCallBack={removeCoupon}
                          collectionChargesLoader={collectionChargesLoader}
                          setTotalAmountToPay={setTotalAmountToPay}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {dashboardErr && 'Something went wrong! Please Try Again!'}
            </>
          ) : (
            <div className={`${classes.detailSections} ${classes.loaderDiv}`}>
              <CircularProgress size={40} />
            </div>
          )}
        </div>
      </div>
      <ViewPrescription
        viewPrescription={viewPrescription}
        closeViewPrescription={() => setViewPrescription(false)}
        prescriptions={patientPrescriptions}
        isLoading={prescriptionsLoader}
      />
      <AphDialog open={showPaymentOptionDialog} maxWidth="sm">
        <AphDialogTitle className={classes.paymentOptionDialogTitle}>
          Choose Payment Method
          <AphButton
            onClick={() => {
              !orderResult || orderResult.errorMessage
                ? resetPaymentSettings()
                : setShouldResetDashboard(true);
              closePopupPrescription && setClosePopupPrescription(false);
            }}
          >
            <img
              src={require('../images/ic_cross_popup_secondary.svg')}
              alt="Close"
            />
          </AphButton>
        </AphDialogTitle>
        {paymentOptionLoader ? (
          <div className={classes.loader}>
            <CircularProgress size={32} />
          </div>
        ) : showCodOrderDetail ? (
          <div className={classes.codOrderDetail}>
            <div>
              <span>Pay by Cash</span>
              <AphButton variant="text" disabled>
                COD
              </AphButton>
            </div>
            {placeOrderLoading ? (
              <div className={classes.loader}>
                <CircularProgress size={32} />
              </div>
            ) : (
              <div>
                {!orderResult?.errorMessage && (
                  <p className="headerLabel">Order placed</p>
                )}
                <p>
                  {!orderResult?.errorMessage
                    ? `COD order #${
                        displayIds?.join(', #') || ''
                      } placed successfully`
                    : orderResult?.errorMessage}
                </p>

                {!orderResult?.errorMessage && (
                  <div className={classes.resend}>
                    <AphTextField
                      inputProps={{
                        pattern: '^[0-9]{10}$',
                      }}
                      onChange={(event) => {
                        handleAlternateMobileNumberChange(event);
                      }}
                      onClick={() => {
                        setConfirmationMsg('');
                      }}
                      onBlur={() => {
                        verifyAlternateMobileNumber(altMobNumber);
                      }}
                      value={altMobNumber}
                      FormHelperTextProps={{
                        className: classes.helperText,
                      }}
                      helperText={
                        isAlternateMobNumError
                          ? 'Please enter 10 digit mobile number'
                          : ''
                      }
                      error={isAlternateMobNumError}
                      label="Do you want to send the confirmation message to alternate number?"
                      placeholder="Enter Mobile Number"
                    />
                    {confirmationMsg && <p>{confirmationMsg}</p>}
                    <AphButton
                      disabled={
                        isAlternateMobNumError ||
                        !isMobileNumberValid(altMobNumber)
                      }
                      onClick={() => handleSendConfirmationMsg()}
                    >
                      Send Confirmation Message
                    </AphButton>
                  </div>
                )}
                {orderResult && (
                  <>
                    {!orderResult.errorMessage && (
                      <p className="startNewOrderBtn">
                        <AphButton
                          onClick={() => {
                            setShouldResetDashboard(true);
                            closePopupPrescription &&
                              setClosePopupPrescription(false);
                          }}
                        >
                          Start New Order
                        </AphButton>
                      </p>
                    )}
                  </>
                )}
              </div>
            )}
          </div>
        ) : showPaymentLinkOrderDetail ? (
          <div className={classes.paymentLinkOrderDetail}>
            <div>
              <span>Generate Payment Link</span>
              {paymentLink?.paymentLink && (
                <AphButton
                  variant="text"
                  className={classes.resendLink}
                  onClick={resendPaymentLink}
                >
                  Resend link
                </AphButton>
              )}
            </div>
            {placeOrderLoading ? (
              <div className={classes.loader}>
                <CircularProgress size={32} />
              </div>
            ) : (
              <div>
                {!orderResult?.errorMessage && (
                  <>
                    <p className="paymentLink">
                      <span>
                        {paymentLink?.paymentLink || 'Payment Link NA'}
                      </span>
                      <img
                        src={`${CDN_URL}/images/copy-grey.svg`}
                        onClick={() => {
                          copyTextToClipboard(paymentLink?.paymentLink);
                        }}
                        alt="copy"
                      />
                    </p>
                    <p>{`Payment link sent successfully to ${userPhoneNumber}.`}</p>
                  </>
                )}
                <p>
                  {' '}
                  {!orderResult?.errorMessage
                    ? `Online payment should be completed before ${paymentLink?.paymentLinkExpiry} to confirm the order.`
                    : orderResult?.errorMessage}
                </p>
                {!orderResult?.errorMessage && (
                  <div className={classes.resend}>
                    <AphTextField
                      inputProps={{
                        pattern: '^[0-9]{10}$',
                      }}
                      onChange={(event) => {
                        handleAlternateMobileNumberChange(event);
                      }}
                      onBlur={() => {
                        verifyAlternateMobileNumber(altMobNumber);
                      }}
                      onClick={() => {
                        setConfirmationMsg('');
                      }}
                      value={altMobNumber}
                      FormHelperTextProps={{
                        className: classes.helperText,
                      }}
                      helperText={
                        isAlternateMobNumError
                          ? 'Please enter 10 digit mobile number'
                          : ''
                      }
                      error={isAlternateMobNumError}
                      label="Do you want to send the payment link to alternate mobile number?"
                      placeholder="Enter Mobile Number"
                    />
                    {confirmationMsg && <p>{confirmationMsg}</p>}
                    <AphButton
                      color="secondary"
                      disabled={
                        isAlternateMobNumError ||
                        !isMobileNumberValid(altMobNumber)
                      }
                      onClick={handleSendPaymentLink}
                    >
                      SEND PAYMENT LINK
                    </AphButton>
                  </div>
                )}

                {orderResult && (
                  <>
                    {!orderResult.errorMessage && (
                      <p className="startNewOrderBtn">
                        <AphButton
                          onClick={() => {
                            setShouldResetDashboard(true);
                            closePopupPrescription &&
                              setClosePopupPrescription(false);
                          }}
                        >
                          Start New Order
                        </AphButton>
                      </p>
                    )}
                  </>
                )}
              </div>
            )}
          </div>
        ) : (
          <div className={classes.paymentOptionDialogContainer}>
            {showPaymentOptionRetry && (
              <div>
                <span>Failed to fetch COD Settings</span>
                <AphButton
                  variant="text"
                  onClick={() => getDiagCODCtaSettings()}
                >
                  Retry
                </AphButton>
              </div>
            )}
            {!paymentOptionLoader && (
              <>
                {!showPaymentOptionRetry && !circleMemberShipPlan && (
                  <>
                    <div>
                      <div className={classes.codSection}>
                        <span>Pay by Cash</span>
                        <AphButton
                          disabled={isHcSelected || !codEnabled}
                          className={
                            (isHcSelected || !codEnabled) &&
                            classes.disabledCODBtn
                          }
                          onClick={processCodOrder}
                        >
                          COD
                        </AphButton>
                      </div>
                      <p> {!isHcSelected && codMessage}</p>
                    </div>
                  </>
                )}
                <div>
                  <span>Generate Payment Link</span>
                  <AphButton variant="text" onClick={generatePaymentLink}>
                    Generate Link
                  </AphButton>
                </div>
              </>
            )}
          </div>
        )}
      </AphDialog>
      <AphDialog open={showCancellationReasonDialog} maxWidth="sm">
        <AphDialogTitle className={classes.cancelTitle}>
          SELECT REASON FOR CANCELLATION
        </AphDialogTitle>
        <AphDialogClose
          onClick={() => setShowCancellationReasonDialog(false)}
          title={'close'}
        />
        <>
          {isCancellationLoading ? (
            <div className={classes.loader}>
              <CircularProgress size={32} />
            </div>
          ) : (
            <>
              <div className={classes.cancellationReasons}>
                <ul>
                  {cancellationReasons?.map((reason, idx) => {
                    const isSelected = selectedReasonIndex === idx;
                    return (
                      <li
                        className={`${isSelected && classes.selectedReason}`}
                        onClick={() => setSelectedReasonIndex(idx)}
                        key={`reason-${idx}`}
                      >
                        <div>
                          <div>{reason}</div>
                          {isSelected ? (
                            <img
                              src={require('../images/ic_checkactive.png')}
                              alt=""
                            />
                          ) : (
                            <img
                              src={require('../images/ic_checkdefault.png')}
                              alt=""
                            />
                          )}
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
              {selectedReasonIndex === cancellationReasons?.length - 1 && (
                <div className={classes.customReason}>
                  <AphTextField
                    onChange={(e) => {
                      setCancellationReason(e.target.value);
                    }}
                    value={cancellationReason}
                    label="Reason"
                    placeholder="Enter your reason here"
                  />
                </div>
              )}
              <div className={classes.footer}>
                <AphButton
                  onClick={() => submitCancelOrder()}
                  disabled={
                    selectedReasonIndex < 0 ||
                    (selectedReasonIndex === cancellationReasons?.length - 1 &&
                      cancellationReason === '')
                  }
                  color="primary"
                >
                  SUBMIT
                </AphButton>
              </div>
            </>
          )}
        </>
      </AphDialog>
      <AphDialog open={showOrderCancelFailedDailog} maxWidth="sm">
        <AphDialogTitle className={classes.cancelTitle}>
          ORDER CANCEL FAILED
        </AphDialogTitle>
        <AphDialogClose
          onClick={() => setShowOrderCancelFailedDailog(false)}
          title={'close'}
        />
        <div className={classes.orderCancelled}>
          <div>{DIAG_NON_CART_FLOW_MESSAGES?.orderCancelFailed}</div>
          <AphButton
            onClick={() => setShowOrderCancelFailedDailog(false)}
            color="primary"
          >
            TRY AGAIN
          </AphButton>
        </div>
      </AphDialog>
      <AphDialog open={showOrderCancelDialog} maxWidth="sm">
        <AphDialogTitle className={classes.cancelTitle}>
          ORDER CANCELLED
        </AphDialogTitle>
        <AphDialogClose
          onClick={() => handleOrderCancelSuccess()}
          title={'close'}
        />
        <div className={classes.orderCancelled}>
          <img src={require('../images/ic_success.png')} alt="" />
          <div>{DIAG_NON_CART_FLOW_MESSAGES?.orderCancel}</div>
          <p>{`Cancelled on ${formatDate(
            new Date(),
            'MMMM, yyyy | hh:mm a'
          )}`}</p>

          <AphButton onClick={() => handleOrderCancelSuccess()} color="primary">
            DONE
          </AphButton>
        </div>
      </AphDialog>
      <AphDialog
        open={showModifyPaymentDialog}
        className={classes.modifyPaymentDialog}
        maxWidth="md"
      >
        <AphDialogClose
          onClick={() => {
            setShowModifyPaymentDialog(false);
          }}
          title={'close'}
        />
        {placeOrderLoading ? (
          <div className={classes.loader}>
            <CircularProgress size={32} />
          </div>
        ) : (
          <div className={classes.modifyOrderOnline}>
            {orderResult?.errorMessage ? (
              <>
                <p>{orderResult?.errorMessage}</p>
                <AphButton
                  onClick={() => setShowModifyPaymentDialog(false)}
                  color="primary"
                >
                  OK
                </AphButton>
              </>
            ) : (
              <>
                <img src={require('../images/ic_success.png')} alt="" />
                <div className={classes.modifyOrderPayment}>
                  <p className="successMsg">
                    Order #{modifyFlowOrder?.displayId} has been updated
                    successfully
                  </p>
                  <p className="paymentMode">
                    Mode of Payment: <b>Online</b>
                  </p>
                  <div className="amount">
                    <p>Previous Order Amount:</p>
                    <p>₹ {orderResult?.previousOrderAmount}</p>
                  </div>
                  <div className="amount">
                    <p>Updated Order Amount:</p>
                    <p>₹ {orderResult?.updatedOrderAmount}</p>
                  </div>
                  <div className="amount">
                    <b>Additional need to be paid:</b>
                    <b>₹ {orderResult?.amountToBePaid}</b>
                  </div>
                </div>
                <div className={classes.modfiyOnlineConfirmation}>
                  <p>{orderResult?.paymentLink}</p>
                  <p>
                    Payment link sent successfully to {userPhoneNumber}. <br />
                    <br /> Payment should be completed to confirm the order
                    modification
                  </p>
                  <div>
                    <AphTextField
                      inputProps={{
                        pattern: '^[0-9]{10}$',
                      }}
                      onChange={(event) => {
                        handleAlternateMobileNumberChange(event);
                      }}
                      onClick={() => {
                        setConfirmationMsg('');
                      }}
                      onBlur={() => {
                        verifyAlternateMobileNumber(altMobNumber);
                      }}
                      value={altMobNumber}
                      FormHelperTextProps={{
                        className: classes.helperText,
                      }}
                      helperText={
                        isAlternateMobNumError
                          ? 'Please enter 10 digit mobile number'
                          : ''
                      }
                      error={isAlternateMobNumError}
                      label="Do you want to send the confirmation message to alternate number?"
                      placeholder="Enter Mobile Number"
                    />
                    {confirmationMsg && <p>{confirmationMsg}</p>}
                    <AphButton
                      color="secondary"
                      disabled={
                        isAlternateMobNumError ||
                        !isMobileNumberValid(altMobNumber)
                      }
                      onClick={handleSendModifyPaymentLink}
                    >
                      SEND PAYMENT LINK
                    </AphButton>
                  </div>
                  <AphButton
                    onClick={() => {
                      setShowModifyPaymentDialog(false);
                      setShouldResetDashboard(true);
                    }}
                    color="primary"
                  >
                    START NEW ORDER
                  </AphButton>
                </div>
              </>
            )}
          </div>
        )}
      </AphDialog>
      <AphDialog
        className={classes.dialogSmallScreen}
        open={showModifyCodDialog}
        maxWidth="sm"
      >
        <AphDialogClose
          onClick={() => {
            setShowModifyCodDialog(false);
          }}
          title={'close'}
        />
        {placeOrderLoading ? (
          <div className={classes.loader}>
            <CircularProgress size={32} />
          </div>
        ) : (
          <div className={classes.modifyOrderCod}>
            {orderResult?.errorMessage ? (
              <>
                <p>{orderResult?.errorMessage}</p>
                <AphButton
                  onClick={() => setShowModifyCodDialog(false)}
                  color="primary"
                >
                  OK
                </AphButton>
              </>
            ) : (
              <>
                <img src={require('../images/ic_success.png')} alt="" />
                <p className="successMsg">
                  Order #{modifyFlowOrder?.displayId} has been updated
                  successfully
                </p>
                <p className="paymentMode">
                  Mode of Payment:{' '}
                  <b>
                    {modifyFlowOrder?.paymentType === 'ONLINE_PAYMENT'
                      ? 'Online'
                      : 'COD'}
                  </b>
                </p>
                <div className={classes.refundContainer}>
                  <div className="amount">
                    <p>Previous Order Amount:</p>
                    <p>₹ {orderResult?.previousOrderAmount}</p>
                  </div>
                  <div className="amount">
                    <p>Updated Order Amount:</p>
                    <p>₹ {orderResult?.updatedOrderAmount}</p>
                  </div>
                  {modifyFlowOrder?.paymentType === 'ONLINE_PAYMENT' &&
                    orderResult?.amountToBePaid >= 0 && (
                      <div className="amount">
                        <p>Additional need to be paid:</p>
                        <p>₹ {orderResult?.amountToBePaid}</p>
                      </div>
                    )}
                  {modifyFlowOrder?.paymentType === 'COD' && (
                    <div className="amount">
                      <p>
                        <b>Total Amount to be paid:</b>
                      </p>
                      <p>
                        <b>₹ {orderResult?.updatedOrderAmount}</b>
                      </p>
                    </div>
                  )}
                </div>

                <AphButton
                  onClick={() => {
                    setShowModifyCodDialog(false);
                    setShouldResetDashboard(true);
                  }}
                  color="primary"
                >
                  DONE
                </AphButton>
              </>
            )}
          </div>
        )}
      </AphDialog>
      <AphDialog open={showModifyOrderConfirmationDialog} maxWidth="sm">
        <div className={classes.modifyConfirmation}>
          <p>Are you sure you want to update the order?</p>
          <div className="actions">
            <AphButton
              onClick={() => setShowModifyOrderConfirmationDialog(false)}
              color="secondary"
              variant="text"
            >
              No
            </AphButton>
            <AphButton
              onClick={() => {
                processModifyOrder();
                setShowModifyOrderConfirmationDialog(false);
              }}
              color="primary"
            >
              Yes
            </AphButton>
          </div>
        </div>
      </AphDialog>
      <AphDialog open={isItemRemoved} maxWidth="sm">
        <div className={classes.modifyConfirmation}>
          <p>
            One or more tests selected are not available for the selected
            patients.
          </p>
          <div className="actions">
            <AphButton
              onClick={() => {
                setIsItemRemoved(false);
              }}
              color="primary"
              variant="text"
            >
              Ok
            </AphButton>
          </div>
        </div>
      </AphDialog>
      <AphDialog open={isSlotError} maxWidth="sm">
        <div className={classes.modifyConfirmation}>
          <p>Selected slot is not available. Please select another slot</p>
          <div className="actions">
            <AphButton
              onClick={() => {
                setIsSlotError(false);
                setSelectedSlot(null);
              }}
              color="primary"
              variant="text"
            >
              SELECT SLOT
            </AphButton>
          </div>
        </div>
      </AphDialog>
      <AphDialog
        className={classes.dialogSmallScreen}
        open={showRefundDialog}
        maxWidth="sm"
      >
        {placeOrderLoading ? (
          <div className={classes.loader}>
            <CircularProgress size={32} />
          </div>
        ) : (
          <>
            {orderResult?.errorMessage ? (
              <div className={classes.modifyConfirmation}>
                <p>{orderResult?.errorMessage}</p>
                <div className="actions">
                  <AphButton
                    onClick={() => {
                      setShowRefundDialog(false);
                      setShouldResetDashboard(true);
                    }}
                    color="primary"
                    variant="text"
                  >
                    Done
                  </AphButton>
                </div>
              </div>
            ) : (
              <div className={classes.refundContainer}>
                <img
                  src={require('../images/ic_success.png')}
                  width="48"
                  height="48"
                  alt=""
                />
                <span className="heading">
                  Order #{modifyFlowOrder?.displayId} has been updated
                  successfully
                </span>
                <p className="paymentMode">
                  Mode of Payment:{' '}
                  <b>
                    {modifyFlowOrder?.paymentType === 'ONLINE_PAYMENT'
                      ? 'Online'
                      : 'COD'}
                  </b>
                </p>
                <div className="amount">
                  <p>Previous Order Amount:</p>
                  <p>₹ {orderResult?.previousOrderAmount}</p>
                </div>
                <div className="amount">
                  <p>Updated Order Amount:</p>
                  <p>₹ {orderResult?.updatedOrderAmount}</p>
                </div>
                <div className="refund-amount">
                  <p>Refund Amount:</p>
                  <p>
                    ₹{' '}
                    {(
                      orderResult?.previousOrderAmount -
                      orderResult?.updatedOrderAmount
                    )?.toFixed(2)}
                  </p>
                </div>
                <p className="refund-message">
                  The refund will be credited to the original payment source in
                  4-7 working days
                </p>
                <div className="actions">
                  <AphButton
                    onClick={() => {
                      setShowRefundDialog(false);
                      setShouldResetDashboard(true);
                    }}
                    color="primary"
                    variant="text"
                  >
                    Done
                  </AphButton>
                </div>
              </div>
            )}
          </>
        )}
      </AphDialog>
      <AphDialog open={showActiveOrdersDialog} maxWidth="lg">
        <AphDialogTitle className={classes.activeOrderTitle}>
          Order Details
        </AphDialogTitle>
        <AphDialogClose
          onClick={() => setShowActiveOrdersDialog(false)}
          title={'close'}
        />
        {activeOrders?.length > 0 && (
          <div className={classes.activeOrders}>
            <p className={classes.orderHeading}>
              Active Orders ({activeOrders?.length})
            </p>
            <span>
              There is an active order for this user already with below order
              details.
            </span>
            <div
              className={`${classes.activeOrdersList} ${
                prescriptionOrders?.length
                  ? classes.listWithPrescription
                  : classes.listWithoutPrescription
              }`}
            >
              {activeOrders?.map((order) => (
                <OrderCard
                  key={order?.displayId}
                  order={order}
                  handleModifyOrder={handleModifyOrder}
                  handleOrderCancel={handleOrderCancel}
                />
              ))}
            </div>
            {prescriptionOrders?.length === 0 && (
              <AphButton
                onClick={() => setShowActiveOrdersDialog(false)}
                color="primary"
              >
                PLACE ANOTHER ORDER
              </AphButton>
            )}
          </div>
        )}
        {prescriptionOrders?.length > 0 && (
          <div className={classes.activeOrders}>
            <p className={classes.orderHeading}>
              Prescription Orders ({prescriptionOrders.length})
            </p>
            <span>
              This user has already uploaded a prescription. Please confirm the
              same order.
            </span>
            <div
              className={`${classes.prescriptionOrdersList} ${
                activeOrders?.length
                  ? classes.listWithActiveOrders
                  : classes.listWithoutActiveOrders
              }`}
            >
              {prescriptionOrders?.map((order) => (
                <OrderCard key={order?.displayId} order={order} />
              ))}
            </div>
            <AphButton
              onClick={() => setShowActiveOrdersDialog(false)}
              color="primary"
            >
              PLACE ANOTHER ORDER
            </AphButton>
          </div>
        )}
      </AphDialog>
      <AphDialog
        open={isModifyInProgress}
        className={classes.modifyPaymentDialog}
        maxWidth="md"
      >
        <AphDialogClose
          onClick={() => {
            setIsModifyInProgress(false);
            setShouldResetDashboard(true);
            setConfirmationMsg('');
          }}
          title={'close'}
        />

        <div className={classes.modifyInProgress}>
          <div>
            <p>{ORDER_MODIFY_PROGRESS_TEXT}</p>
            <p>Please complete the pending payment to modify the order</p>
            <p className="paymentLink">
              <span>{modifyPaymentLink || 'Payment Link NA'}</span>
              <img
                src={`${CDN_URL}/images/copy-grey.svg`}
                onClick={() => {
                  copyTextToClipboard(modifyPaymentLink);
                }}
                alt="copy"
              />
            </p>
            <div>
              {confirmationMsg && <p>{confirmationMsg}</p>}
              <AphButton
                color="primary"
                onClick={handleResendModifyPaymentLink}
              >
                Resend Payment Link
              </AphButton>
            </div>
          </div>
        </div>
      </AphDialog>
    </div>
  );
};

export default Dashboard;
