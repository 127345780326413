import React, { useEffect, useRef, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
  Button,
  CircularProgress,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
} from '@material-ui/core';
import {
  dummyNewAddress,
  PatientAddress,
  pinCodeServiceabilityApi,
} from './helpers/commonHelpers';
import AphDialog from './AphDialog';
import AphDialogClose from './AphDialogClose';
import AphDialogTitle from './AphDialogTitle';
import axios from '../axios';
import { ADD_ADDRESS_URL, ALLOW_COMBINED_ADDRESS_FORM } from '../appConfig';
import {
  sendClevertTapEvent,
  getCommonPatientAttributes,
} from './helpers/eventTracking';
import { AddNewAddress } from './AddNewAddress';
import AphButton from './AphButton';
import { useOrderContext } from '../OrderProvider';
import { Virtuoso } from 'react-virtuoso';
import CombinedAddressForm from './CombinedAddressForm';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    address: {
      listStyleType: 'none',
      padding: '19px 10px',
      cursor: 'pointer',
      borderBottom: '1px solid #D4D4D4',
      '&>div': {
        display: 'flex',
        '&>div': {
          '&>p': {
            '&:first-child': {
              fontSize: 14,
              fontWeight: 600,
              margin: 0,
              paddingBottom: 4,
            },
            margin: 0,
            fontSize: 12,
            fontWeight: 500,
          },
          '&:first-child': {
            paddingRight: 10,
          },
        },
      },
    },
    activeAddress: {
      background: '#00B38E',
      color: '#fff',
      position: 'relative',
    },
    viewAll: {
      background: '#FFF3E1',
      textAlign: 'center',
      padding: '8px 0px',
      fontWeight: 'bold',
      fontSize: 13,
      color: '#FCA317',
      textTransform: 'uppercase',
      '& img': {
        verticalAlign: 'middle',
        paddingLeft: 10,
      },
    },
    tabContainer: {
      padding: '16px',
    },
    formGroup: {
      position: 'relative',
      paddingTop: 20,
      '& input': {
        fontSize: '13px',
        fontWeight: 500,
        padding: '7px 6px',
      },
      '& div': {
        fontSize: '13px',
        fontWeight: 500,
      },
      '& label': {
        fontSize: '14px',
        fontWeight: 'normal',
        display: 'block',
      },
    },
    textField: {
      width: '100%',
      marginTop: '5px',
      background: '#FAFBFC',
      border: '2px solid #DFE1E6',
      boxSizing: 'border-box',
      borderRadius: 8,
      '&>div:after': {
        display: 'none !important',
      },
      '&>div:before': {
        display: 'none !important',
      },
    },
    errorMsg: {
      paddingTop: '8px',
      fontSize: '10px',
      lineHeight: '13px',
      color: '#AB2300',
      '& img': {
        verticalAlign: 'middle',
      },
    },
    absolutedBtn: {
      position: 'absolute',
      top: 48,
      right: 6,
      color: '#FCB716',
      '& button': {
        padding: 0,
        boxShadow: 'unset',
        color: '#FCB716',
        textTransform: 'uppercase',
        background: 'none !important',
        minWidth: 'unset',
        '& img': {
          paddingRight: '6px',
        },
        '& span': {
          fontWeight: 'bold',
        },
      },
    },
    gridWidth: {
      position: 'relative',
      flexBasis: '45%',
      '&>div': {
        padding: 0,
      },
    },
    gridCont: {
      justifyContent: 'space-between',
    },
    btnGroup: {
      paddingTop: '10px',
    },
    questionControl: {
      margin: 0,
      '& span': {
        fontSize: '14px',
      },
      '&>span': {
        paddingRight: '4px',
      },
    },
    radio: {
      color: '#00B38E',
      '&$checked': {
        color: '#00B38E',
      },
    },
    checked: {},
    fwdBtn: {
      textAlign: 'right',
    },
    yellowBtn: {
      background: '#FCB716 !important',
      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
      borderRadius: '5px',
      color: '#fff',
      fontSize: '13px',
      padding: '8px 25px',
      fontWeight: 'bold',
      minWidth: 143,
    },
    popupError: {
      margin: 0,
      textAlign: 'center',
      fontSize: 12,
      fontWeight: 500,
      lineHeight: '16px',
      padding: '12px 16px',
      display: 'flex',
      gap: 8,
      color: '#832541',
      background: '#FCF2F1',
      borderRadius: 12,
      marginTop: 10,
    },
    dialogBox: {
      '& >div': {
        '& >div': {
          marginTop: '50px',
          maxWidth: 400,
          [theme.breakpoints.down('xs')]: {
            borderRadius: 0,
            margin: 0,
            height: '100vh',
            maxHeight: 'inherit',
            background: '#F7F8F5',
            maxWidth: '100% !important',
          },
        },
      },
    },
    largeDialogBox: {
      '& >div': {
        '& >div': {
          maxWidth: 1024,
          [theme.breakpoints.down('xs')]: {
            borderRadius: 0,
            margin: 0,
            height: '100vh',
            maxHeight: 'inherit',
            background: '#F7F8F5',
            maxWidth: '100% !important',
          },
        },
      },
    },
    useMap: {
      padding: '5px 8px',
      fontSize: 12,
      position: 'absolute',
      right: 17,
      zIndex: 4,
    },
    disable: {
      opacity: 0.5,
      cursor: 'not-allowed',
    },
  })
);

export const Addresses: React.FC = () => {
  const classes = useStyles();
  const {
    patientAddresses,
    setPatientAddresses,
    selectedAddress,
    setSelectedAddress,
    selectedProfile,
    userPhoneNumber,
    isNonCartFlowActivated,
    selectedNonCartFlowOrder,
    setServiceabilityData,
    setDiagnosticCartItems,
    setPatientLineItems,
    setCouponErrMsg,
    setCircleMemberShipPlan,
    setSlotDaysByCity,
    modifyFlowOrder,
  } = useOrderContext();

  const [serviceable, setServiceable] = useState<boolean>(true);
  const virtuoso = useRef(null);
  const handleChangeAddress = (patientAddressId: string) => {
    const address = patientAddresses.find(
      (addressItem) => addressItem.id === patientAddressId
    );
    address && setSelectedAddress(address);
    setServiceable(true);
    setDiagnosticCartItems([]);
    setPatientLineItems([]);
    setCircleMemberShipPlan(null);
    setCouponErrMsg('');
    if (address) {
      localStorage.setItem('currentZip', address.zipcode);
      if (!address?.latitude || !address?.longitude) {
        geocodeLatLng(
          address.zipcode,
          `${address.addressLine1}, ${address.addressLine2}`
        ).then((result) => {
          if (result.status && result.lat && result.lng) {
            checkServiceability(result.lat, result.lng, address);
          } else {
            setServiceable(false);
            setServiceabilityData({
              cityID: 0,
              stateID: 0,
            });
            const eventDetails = {
              ...getCommonPatientAttributes(selectedProfile),
              latitude: result.lat || '',
              longitude: result.lng || '',
              city: '',
              state: '',
              pincode: address?.zipcode || '',
              Serviceability: 'No',
              Source: 'OPT',
              'Selection Type': 'Manual',
            };
            !!address &&
              sendClevertTapEvent(
                'Diagnostic address selected',
                userPhoneNumber,
                eventDetails
              );
          }
        });
      } else {
        checkServiceability(address?.latitude, address?.longitude, address);
      }
    }
  };

  const [showNewAddressPopup, setShowNewAddressPopup] =
    useState<boolean>(false);
  const [showLocationPicker, setShowLocationPicker] = useState<boolean>(false);
  const [showCombinedAddressForm, setShowCombinedAddressForm] =
    useState<boolean>(false);
  const [addressAdditionError, setAddressAdditionError] = useState<string>('');
  const [addAddressLoader, setAddAddressLoader] = useState<boolean>(false);
  const [newPatientAddress, setNewPatientAddress] =
    useState<PatientAddress>(dummyNewAddress);
  const [pincodeErr, setPincodeErr] = useState<string>('');
  const [zeroResultsError, setZeroResultsError] = useState<boolean>(false);
  const [serviceableLoader, setServiceableLoader] = useState<boolean>(false);

  const geocodeLatLng = async (zipcode: string, addressString?: string) => {
    setZeroResultsError(false);
    return await new google.maps.Geocoder()
      .geocode({
        componentRestrictions: {
          country: 'IN',
          postalCode: zipcode,
        },
        address: addressString,
      })
      .then(({ results }) => {
        if (Array.isArray(results) && results.length) {
          const lat = results[0].geometry.location.lat();
          const lng = results[0].geometry.location.lng();
          return {
            status: true,
            lat,
            lng,
          };
        } else {
          console.error('Could not find geocode');
          return {
            status: false,
            lat: null,
            lng: null,
          };
        }
      })
      .catch((e) => {
        console.error('Geocoder failed due to: ' + e);
        if (e.code === 'ZERO_RESULTS') {
          setZeroResultsError(true);
        }
        return {
          status: false,
          lat: null,
          lng: null,
        };
      });
  };

  const checkServiceability = async (
    latitude: number,
    longitude: number,
    address?: PatientAddress
  ) => {
    let eventDetails = {};
    if (!!latitude && !!longitude) {
      return await pinCodeServiceabilityApi(latitude, longitude)
        .then((res: any) => {
          if (res?.data?.status && res?.data?.serviceability) {
            setSlotDaysByCity(res.data.serviceability.slotDaysByCity || 3);
            setServiceable(true);
            setServiceabilityData({
              cityID: res?.data?.serviceability?.cityID || 0,
              stateID: res?.data?.serviceability?.stateID || 0,
            });
            res.city = res?.data?.serviceability?.city || '';
            res.state = res?.data?.serviceability?.state || '';
            eventDetails = {
              ...getCommonPatientAttributes(selectedProfile),
              latitude: latitude || '',
              longitude: longitude || '',
              city: res.city || '',
              state: res.state || '',
              pincode: address?.zipcode || '',
              Serviceability: 'Yes',
              Source: 'OPT',
              'Selection Type': 'Manual',
            };
          } else {
            setServiceable(false);
            setServiceabilityData({
              cityID: 0,
              stateID: 0,
            });
            res.city = '';
            res.state = '';
            eventDetails = {
              ...getCommonPatientAttributes(selectedProfile),
              latitude: latitude || '',
              longitude: longitude || '',
              city: res.city || '',
              state: res.state || '',
              pincode: address?.zipcode || '',
              Serviceability: 'No',
              Source: 'OPT',
              'Selection Type': 'Manual',
            };
          }
          return res;
        })
        .catch((e) => {
          setServiceable(false);
          setServiceabilityData({
            cityID: 0,
            stateID: 0,
          });
          eventDetails = {
            ...getCommonPatientAttributes(selectedProfile),
            latitude: latitude || '',
            longitude: longitude || '',
            city: '',
            state: '',
            pincode: address?.zipcode || '',
            Serviceability: 'No',
            Source: 'OPT',
            'Selection Type': 'Manual',
          };
        })
        .finally(() => {
          !!address &&
            sendClevertTapEvent(
              'Diagnostic address selected',
              userPhoneNumber,
              eventDetails
            );
        });
    } else {
      setServiceable(false);
      setServiceabilityData({
        cityID: 0,
        stateID: 0,
      });
      eventDetails = {
        ...getCommonPatientAttributes(selectedProfile),
        latitude: latitude || '',
        longitude: longitude || '',
        city: '',
        state: '',
        pincode: address?.zipcode || '',
        Serviceability: 'Yes',
        Source: 'OPT',
        'Selection Type': 'Manual',
      };
      !!address &&
        sendClevertTapEvent(
          'Diagnostic address selected',
          userPhoneNumber,
          eventDetails
        );
    }
  };

  const handleChangeNewAddress = (
    event: React.ChangeEvent<HTMLInputElement>,
    property: string
  ) => {
    let val = (event.target as HTMLInputElement).value;
    val = val?.replace(/^\s+|\s+(?=\s)/g, '').slice(0, 60); // remove leading and multiple spaces and limit length to 60
    const newAddress = { ...newPatientAddress };

    switch (property) {
      case 'zipcode':
        setPincodeErr('');
        setAddressAdditionError('');
        setZeroResultsError(false);
        setServiceable(false);
        newAddress[property] = val;
        newAddress.city = '';
        newAddress.state = '';
        setNewPatientAddress(newAddress);
        break;
      case 'addressLine1':
      case 'addressLine2':
        setServiceable(false);
        setZeroResultsError(false);
        setAddressAdditionError('');
        newAddress[property] = val;
        setNewPatientAddress(newAddress);
        break;
      case 'addressType':
        setZeroResultsError(false);
        setAddressAdditionError('');
        newAddress[property] = val;
        setNewPatientAddress(newAddress);
        break;
      default:
        break;
    }
  };

  const getNewAddressSaveCondition = () => {
    const conditionObjSaveNewAddress = newPatientAddress && {
      zipcode:
        !!newPatientAddress.zipcode && newPatientAddress.zipcode.length === 6,
      addressLine1: !!newPatientAddress.addressLine1,
      addressType: !!newPatientAddress.addressType,
      city: !!newPatientAddress.city,
      state: !!newPatientAddress.state,
    };
    const canProceedToSaveAddress = Object.values(
      conditionObjSaveNewAddress
    ).reduce((prev, curr) => {
      return prev && curr;
    });
    return canProceedToSaveAddress;
  };

  const handleSaveAddress = async (
    patientAddress: PatientAddress,
    saveLatLng?: boolean
  ) => {
    const {
      addressLine1,
      addressLine2,
      city,
      state,
      zipcode,
      addressType,
      landmark,
      latitude,
      longitude,
    } = patientAddress;

    setAddAddressLoader(true);

    const addAddressPayload = {
      patientId: selectedProfile?.id || '',
      mobileNumber: '+91' + userPhoneNumber,
      addressLine1,
      addressLine2,
      city,
      state,
      zipcode,
      landmark: landmark ?? '',
      addressType: addressType.toUpperCase(),
      otherAddressType: '',
      name: selectedProfile?.firstName,
    };

    if (saveLatLng) {
      addAddressPayload['latitude'] = latitude;
      addAddressPayload['longitude'] = longitude;
    }

    await axios
      .post(ADD_ADDRESS_URL, addAddressPayload)
      .then(({ data }) => {
        if (data?.id) {
          let updatedAddressList = [...patientAddresses];
          updatedAddressList.unshift(data);
          setPatientAddresses(updatedAddressList);
          setNewPatientAddress(dummyNewAddress);
          setSelectedAddress(data);
          localStorage.setItem('currentZip', data.zipcode);
          setDiagnosticCartItems([]);
          setPatientLineItems([]);
          setShowNewAddressPopup(false);
          setShowLocationPicker(false);
          setShowCombinedAddressForm(false);
          setServiceable(true);
        }
      })
      .catch((err) => {
        console.error(err);
        setAddressAdditionError('Something went wrong.');
      })
      .finally(() => {
        setAddAddressLoader(false);
      });
  };

  const renderErrorAtBottom = (errorMsg: string) => {
    return (
      <p className={`${classes.errorMsg} ${classes.popupError}`}>
        <img src={require('../images/ic_info_red.svg')} />
        {errorMsg}
      </p>
    );
  };

  const showManualAddressDialog = (showAddressForm: boolean) => {
    if (showAddressForm) {
      setShowLocationPicker(false);
      setShowNewAddressPopup(true);
    } else {
      setNewPatientAddress(dummyNewAddress);
      setShowNewAddressPopup(false);
      setServiceable(true);
      setPincodeErr('');
      setShowLocationPicker(true);
    }
  };

  // Modify flow prepopulate patient address
  useEffect(() => {
    if (modifyFlowOrder && patientAddresses?.length) {
      handleChangeAddress(modifyFlowOrder?.patientAddressId);
    }
  }, [modifyFlowOrder, patientAddresses]);

  // scroll to selected patient address
  useEffect(() => {
    if (patientAddresses?.length && modifyFlowOrder) {
      const selectedAddIndex = patientAddresses?.findIndex(
        (address) => address?.id === modifyFlowOrder?.patientAddressId
      );
      setTimeout(() => {
        virtuoso?.current?.scrollToIndex({
          index: selectedAddIndex,
          behavior: 'smooth',
        });
      }, 500);
    }
  }, [patientAddresses, modifyFlowOrder]);

  // Noncart flow prepopulate patient address
  useEffect(() => {
    if (
      isNonCartFlowActivated &&
      selectedNonCartFlowOrder &&
      patientAddresses?.length
    ) {
      handleChangeAddress(selectedNonCartFlowOrder?.patientAddressId);
    }
  }, [isNonCartFlowActivated, selectedNonCartFlowOrder, patientAddresses]);

  //scroll to selected patient address
  useEffect(() => {
    if (
      patientAddresses?.length &&
      isNonCartFlowActivated &&
      selectedNonCartFlowOrder
    ) {
      const selectedAddIndex = patientAddresses?.findIndex(
        (address) => address?.id === selectedNonCartFlowOrder?.patientAddressId
      );
      setTimeout(() => {
        virtuoso?.current?.scrollToIndex({
          index: selectedAddIndex,
          behavior: 'smooth',
        });
      }, 500);
    }
  }, [patientAddresses, isNonCartFlowActivated, selectedNonCartFlowOrder]);

  const validateAddress = () => {
    setZeroResultsError(false);
    setAddressAdditionError('');
    setServiceableLoader(true);
    if (newPatientAddress?.zipcode?.length < 6) {
      setPincodeErr('Enter Correct Pincode/Zipcode');
      setServiceableLoader(false);
    } else {
      geocodeLatLng(
        newPatientAddress.zipcode,
        `${newPatientAddress.addressLine1}, ${newPatientAddress.addressLine2}`
      )
        .then((result) => {
          if (result.status && result.lat && result.lng) {
            checkServiceability(result.lat, result.lng).then((res) => {
              if (res?.data?.status) {
                const { city, state } = res?.data?.serviceability;
                newPatientAddress.city = city?.toLowerCase() || '';
                newPatientAddress.state = state?.toLowerCase() || '';
                newPatientAddress.latitude = result?.lat;
                newPatientAddress.longitude = result?.lng;
                setNewPatientAddress({ ...newPatientAddress });
              } else {
                setServiceable(false);
                setAddressAdditionError(
                  'The above address is not serviceable.'
                );
              }
            });
          } else {
            setServiceable(false);
          }
        })
        .catch((err) => {
          console.error('Error checking serviceability', err);
        })
        .finally(() => {
          setServiceableLoader(false);
        });
    }
  };

  const showOnlySelectedAddressOnModifyFlow =
    modifyFlowOrder && patientAddresses?.length && selectedAddress?.id;

  if (showOnlySelectedAddressOnModifyFlow) {
    const activeAddress = patientAddresses?.find(
      (address) => address?.id === selectedAddress?.id
    );

    return (
      <>
        <h3>
          <span>Address</span>
        </h3>
        <div>
          <div
            className={`${classes.address} ${classes.activeAddress}`}
            onClick={() =>
              !modifyFlowOrder && handleChangeAddress(activeAddress?.id)
            }
          >
            <div>
              <div>
                <img src={require('../images/ic_checkactive.png')} alt="" />
              </div>
              <div>
                <p>{activeAddress?.addressType}</p>
                <p>
                  {`${activeAddress?.addressLine1 || ''}, ${
                    activeAddress?.addressLine2 || ''
                  } ${activeAddress?.city || ''} ${
                    activeAddress?.state || ''
                  } ${activeAddress?.zipcode || ''}`}
                </p>
              </div>
            </div>
            {!serviceable && selectedAddress?.id === activeAddress?.id && (
              <span className={classes.errorMsg}>
                This address is not serviceable
              </span>
            )}
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <h3>
        <span>Address</span>
        <Button
          onClick={() => {
            setSelectedAddress(null);
            setServiceabilityData({ cityID: 0, stateID: 0 });
            setZeroResultsError(false);
            if (ALLOW_COMBINED_ADDRESS_FORM) {
              setShowCombinedAddressForm(true);
            } else {
              showManualAddressDialog(true);
            }
          }}
        >
          + NEW ADDRESS
        </Button>
      </h3>
      <div>
        <Virtuoso
          ref={virtuoso}
          style={{ height: '250px' }}
          data={patientAddresses}
          itemContent={(idx, address) => {
            return (
              <div
                className={`${classes.address} ${
                  selectedAddress?.id === address?.id
                    ? classes.activeAddress
                    : ''
                }`}
                key={'address-' + idx}
                onClick={() => handleChangeAddress(address?.id)}
              >
                <div>
                  <div>
                    <img
                      src={require(selectedAddress?.id === address?.id
                        ? '../images/ic_checkactive.png'
                        : '../images/ic_checkdefault.png')}
                      alt=""
                    />
                  </div>
                  <div>
                    <p>{address.addressType}</p>
                    <p>
                      {`${address.addressLine1 || ''}, ${
                        address.addressLine2 || ''
                      } ${address.city || ''} ${address.state || ''} ${
                        address.zipcode || ''
                      }`}
                    </p>
                  </div>
                </div>
                {!serviceable && selectedAddress?.id === address?.id && (
                  <span className={classes.errorMsg}>
                    This address is not serviceable
                  </span>
                )}
              </div>
            );
          }}
        />
      </div>

      <AphDialog open={showNewAddressPopup} maxWidth="sm">
        <AphDialogClose
          onClick={() => {
            setNewPatientAddress(dummyNewAddress);
            setShowNewAddressPopup(false);
            setServiceable(true);
            setPincodeErr('');
          }}
          title={'Close'}
        />
        <AphDialogTitle>Add new address</AphDialogTitle>
        <div className={classes.tabContainer}>
          <AphButton
            className={classes.useMap}
            onClick={() => showManualAddressDialog(false)}
          >
            Use Map
          </AphButton>
          <div className={classes.formGroup}>
            <label>Pincode *</label>
            <TextField
              className={classes.textField}
              placeholder="Enter pincode"
              value={newPatientAddress.zipcode}
              onChange={(e: any) => {
                handleChangeNewAddress(e, 'zipcode');
              }}
              onBlur={(e) => {
                const val = e.target.value;
                const newAddress = { ...newPatientAddress };
                let regex = /\D/;
                if (!(val.length < 6) && !regex.test(val)) {
                  newAddress.zipcode = val;
                } else {
                  setPincodeErr('Enter Correct Pincode/Zipcode');
                }
              }}
              inputProps={{
                pattern: '^[0-9]{6}$',
                maxLength: 6,
              }}
              disabled={serviceableLoader}
            />
            {pincodeErr && (
              <p className={classes.errorMsg} style={{ margin: 0 }}>
                <img
                  src={require('../images/ic_error_outline.png')}
                  alt="Edit"
                />{' '}
                {pincodeErr}
              </p>
            )}
            {/* {newPatientAddress.zipcode &&
              newPatientAddress.zipcode.length === 6 &&
              !serviceable && (
                <p className={classes.errorMsg} style={{ margin: 0 }}>
                  <img
                    src={require('../images/ic_error_outline.png')}
                    alt="Edit"
                  />{' '}
                  Service currently unavailable in your area.
                </p>
              )} */}

            {/* {newPatientAddress.zipcode &&
              newPatientAddress.zipcode.length === 6 &&
              serviceable && (
                <div className={classes.absolutedBtn}>
                  <Button>
                    <img
                      src={require('../images/ic_tickmark.png')}
                      alt=" Add more"
                    />
                  </Button>
                </div>
              )} */}
          </div>

          <div className={classes.formGroup}>
            <label>Address Line 1 *</label>
            <TextField
              className={classes.textField}
              placeholder="Address line 1"
              value={newPatientAddress.addressLine1}
              onChange={(e: any) => {
                handleChangeNewAddress(e, 'addressLine1');
              }}
              disabled={serviceableLoader}
            />
          </div>

          <div className={classes.formGroup}>
            <label>Address Line 2 *</label>
            <TextField
              className={classes.textField}
              placeholder="Address line 2"
              value={newPatientAddress.addressLine2}
              onChange={(e: any) => {
                handleChangeNewAddress(e, 'addressLine2');
              }}
              disabled={serviceableLoader}
            />
          </div>

          <div className={classes.formGroup}>
            <Grid container className={classes.gridCont}>
              <Grid item sm={6} className={classes.gridWidth}>
                <label>City *</label>
                <TextField
                  className={classes.textField}
                  placeholder="City"
                  value={newPatientAddress.city}
                  disabled
                />
              </Grid>
              <Grid item sm={6} className={classes.gridWidth}>
                <label>State *</label>
                <TextField
                  className={classes.textField}
                  placeholder="State"
                  value={newPatientAddress.state}
                  disabled
                />
              </Grid>
            </Grid>
          </div>

          <div className={classes.formGroup}>
            <label>Address type *</label>
            <RadioGroup
              aria-label="sort"
              name="sort"
              value={newPatientAddress.addressType}
              onChange={(e: any) => {
                handleChangeNewAddress(e, 'addressType');
              }}
            >
              <Grid container className={classes.btnGroup}>
                <Grid item xs={4} sm={4}>
                  <FormControlLabel
                    className={classes.questionControl}
                    value="Home"
                    control={
                      <Radio
                        classes={{
                          root: classes.radio,
                          checked: classes.checked,
                        }}
                      />
                    }
                    label="Home"
                  />
                </Grid>
                <Grid item xs={4} sm={4}>
                  <FormControlLabel
                    className={classes.questionControl}
                    value="Office"
                    control={
                      <Radio
                        classes={{
                          root: classes.radio,
                          checked: classes.checked,
                        }}
                      />
                    }
                    label="Office"
                  />
                </Grid>
                <Grid item xs={4} sm={4}>
                  <FormControlLabel
                    className={classes.questionControl}
                    value="Other"
                    control={
                      <Radio
                        classes={{
                          root: classes.radio,
                          checked: classes.checked,
                        }}
                      />
                    }
                    label="Other"
                  />
                </Grid>
              </Grid>
            </RadioGroup>
          </div>

          <div className={classes.fwdBtn}>
            <Button
              className={classes.yellowBtn}
              onClick={() => {
                getNewAddressSaveCondition() && serviceable
                  ? handleSaveAddress(newPatientAddress)
                  : validateAddress();
              }}
              disabled={addAddressLoader || serviceableLoader}
              style={{
                opacity: addAddressLoader || serviceableLoader ? 0.5 : 1,
              }}
            >
              {addAddressLoader ? (
                <CircularProgress size={24} />
              ) : (
                <span>{`${
                  !getNewAddressSaveCondition() || !serviceable
                    ? 'VERIFY'
                    : 'PROCEED'
                }`}</span>
              )}
            </Button>
            {addressAdditionError.length > 0 &&
              renderErrorAtBottom(addressAdditionError)}
            {zeroResultsError &&
              renderErrorAtBottom(
                'No Results found from Google for this pincode and address combination'
              )}
            {/*: !serviceable &&
                renderErrorAtBottom('The above address is not serviceable.')*/}
          </div>
        </div>
      </AphDialog>

      <AphDialog open={showLocationPicker} className={classes.dialogBox}>
        <AphDialogClose
          onClick={() => {
            setShowLocationPicker(false);
          }}
          title={'Close'}
        />
        <AddNewAddress
          setShowLocationPicker={setShowLocationPicker}
          checkServiceability={checkServiceability}
          handleSaveAddress={handleSaveAddress}
          serviceable={serviceable}
          geocodeLatLng={geocodeLatLng}
          showManualAddressDialog={showManualAddressDialog}
          addAddressLoader={addAddressLoader}
          addressAdditionError={addressAdditionError}
          zeroResultsError={zeroResultsError}
          renderErrorAtBottom={renderErrorAtBottom}
          setZeroResultsError={setZeroResultsError}
          setAddressAdditionError={setAddressAdditionError}
        />
      </AphDialog>

      <AphDialog
        open={showCombinedAddressForm}
        className={classes.largeDialogBox}
      >
        <AphDialogClose
          onClick={() => {
            setShowCombinedAddressForm(false);
          }}
          title={'Close'}
        />
        <CombinedAddressForm
          checkServiceability={checkServiceability}
          handleSaveAddress={handleSaveAddress}
          serviceable={serviceable}
          geocodeLatLng={geocodeLatLng}
          addAddressLoader={addAddressLoader}
          addressAdditionError={addressAdditionError}
          zeroResultsError={zeroResultsError}
          renderErrorAtBottom={renderErrorAtBottom}
          setZeroResultsError={setZeroResultsError}
          setAddressAdditionError={setAddressAdditionError}
        />
      </AphDialog>
    </>
  );
};
