import React, { createContext, useCallback, useContext, useState } from 'react';
import {
  DiagnosticCartItem,
  getTestsCirclePrice,
  PatientAddress,
  PatientObjWithLineItems,
  PatientProfile,
  PlanSummary,
  SlotObject,
} from './Components/helpers/commonHelpers';
import { phleboChargesV2 } from './types/globalTypes';

export interface ServiceabilityData {
  cityID: number;
  stateID: number;
}

export interface HomeCollectionCharges {
  charges: number;
  distanceCharges: number;
  couponDiscApplied?: boolean;
}

type PaymentLinkData = {
  paymentLink: string;
  paymentLinkExpiry: string;
  parentOrderId: string;
};

type amountBreakup = {
  subTotal: number;
  cartSavings: number;
  circleSavings: number;
  couponSavings: number;
};
interface OrderContextProps {
  resetDashboard: () => void;
  resetPaymentSettings: () => void;
  userProfiles: PatientProfile[];
  setUserProfiles: (value: PatientProfile[]) => void;
  selectedProfile: PatientProfile | null;
  setSelectedProfile: (value: PatientProfile | null) => void;
  patientAddresses: PatientAddress[];
  setPatientAddresses: (value: PatientAddress[]) => void;
  selectedAddress: PatientAddress | null;
  setSelectedAddress: (value: PatientAddress | null) => void;
  diagnosticCartItems: DiagnosticCartItem[];
  setDiagnosticCartItems: (value: DiagnosticCartItem[]) => void;
  deletedDiagnosticCartItems: DiagnosticCartItem[];
  setDeletedDiagnosticCartItems: (value: DiagnosticCartItem[]) => void;
  addedDiagnosticCartPackageItemdId: number | null;
  setAddedDiagnosticCartPackageItemId: (value: number) => void;
  patientLineItems: PatientObjWithLineItems[];
  setPatientLineItems: (value: PatientObjWithLineItems[]) => void;
  isUserCare: boolean;
  setIsUserCare: (value: boolean) => void;
  couponApplied: boolean;
  setCouponApplied: (value: boolean) => void;
  circleBenefitsApplied: boolean;
  setCircleBenefitsApplied: (value: boolean) => void;
  searchText: string;
  setSearchText: (value: string) => void;
  serviceabilityData: ServiceabilityData;
  setServiceabilityData: (value: ServiceabilityData) => void;
  selectedSlotDate: Date;
  setSelectedSlotDate: (value: Date) => void;
  selectedSlot: SlotObject | null;
  setSelectedSlot: (value: SlotObject | null) => void;
  homeCollectionCharges: HomeCollectionCharges;
  setHomeCollectionCharges: (value: HomeCollectionCharges) => void;
  orderResult: any;
  setOrderResult: (value: any) => void;
  removeHCCharges: boolean;
  setRemoveHCCharges: (value: boolean) => void;
  hasDupes: boolean;
  setHasDupes: (value: boolean) => void;
  enableSlotSelection: boolean;
  setEnableSlotSelection: (value: boolean) => void;
  shouldResetDashboard: boolean;
  setShouldResetDashboard: (value: boolean) => void;
  detailsFetchedFlag: boolean;
  setDetailsFetchedFlag: (value: boolean) => void;
  userFound: boolean;
  setUserFound: (value: boolean) => void;
  couponValidateErr: string;
  setCouponValidateErr: (value: string) => void;
  couponErrMsg: string;
  setCouponErrMsg: (value: string) => void;
  userPhoneNumber: string;
  setUserPhoneNumber: (value: string) => void;
  paymentLink: PaymentLinkData;
  setPaymentLink: (value: PaymentLinkData) => void;
  showCodOrderDetail: boolean;
  setShowCodOrderDetail: (value: boolean) => void;
  showPaymentLinkOrderDetail: boolean;
  setShowPaymentLinkOrderDetail: (value: boolean) => void;
  showPaymentOptionRetry: boolean;
  setShowPaymentOptionRetry: (value: boolean) => void;
  showPaymentOptionDialog: boolean;
  setShowPaymentOptionDialog: (value: boolean) => void;
  codEnabled: boolean;
  setCodEnabled: (value: boolean) => void;
  codMessage: string;
  setCodMessage: (value: string) => void;
  circleMemberShipPlan: PlanSummary;
  setCircleMemberShipPlan: (plan: PlanSummary) => void;
  updatePatientLineItemPricing: (applyCirclePricing: boolean) => void;
  circleSubscriptionObj: any;
  setCircleSubscriptionObj: (value: any) => void;
  deletedPatientLineItems: PatientObjWithLineItems;
  setDeletedPatientLineItems: (value: PatientObjWithLineItems) => void;
  userSubscriptions: any;
  setUserSubscriptions: (value: any) => void;
  isNonCartFlowActivated: boolean;
  setIsNonCartFlowActivated: (value: boolean) => void;
  selectedNonCartFlowOrder: any;
  setSelectedNonCartFlowOrder: (value: any) => void;
  isHcSelected: boolean;
  setIsHcSelected: (value: boolean) => void;
  radiologyTest: boolean;
  setRadiologyTest: (value: boolean) => void;
  orderSource: string;
  setOrderSource: (value: string) => void;
  couponCode: string;
  setCouponCode: (value: string) => void;
  circleCashback: number;
  setCircleCashback: (value: number) => void;
  slotDaysByCity: number;
  setSlotDaysByCity: (value: number) => void;
  showActiveOrdersDialog: boolean;
  setShowActiveOrdersDialog: (value: boolean) => void;
  closePopupPrescription: boolean;
  setClosePopupPrescription: (value: boolean) => void;
  showModifyFlow: boolean;
  setShowModifyFlow: (value: boolean) => void;
  modifyFlowOrder: any;
  setModifyFlowOrder: (value: any) => void;
  modifyOrderPaidAmountBreakup: amountBreakup;
  setModifyOrderPaidAmountBreakup: (value: amountBreakup) => void;
  userAccess: string[];
  setUserAccess: (value: string[]) => void;
  isItemRemoved: boolean;
  setIsItemRemoved: (value: boolean) => void;
  modifyOrderSelectedProfile: PatientProfile | null;
  setModifyOrderSelectedProfile: (value: PatientProfile | null) => void;
  hasAccessToModifyItems: boolean;
  setHasAccessToModifyItems: (value: boolean) => void;
  hasAccessToChangeProfile: boolean;
  setHasAccessToChangeProfile: (value: boolean) => void;
  hasAccessToReschedule: boolean;
  setHasAccessToReschedule: (value: boolean) => void;
  hasAccessToCancelOrder: boolean;
  setHasAccessToCancelOrder: (value: boolean) => void;
  isModifyInProgress: boolean;
  setIsModifyInProgress: (value: boolean) => void;
  modifyPaymentLink: string;
  setModifyPaymentLink: (value: string) => void;
  phleboCharges: phleboChargesV2;
  setPhleboCharges: (value: phleboChargesV2) => void;
}

export const OrderContext = createContext<OrderContextProps>({
  resetDashboard: null,
  resetPaymentSettings: null,
  userProfiles: null,
  setUserProfiles: null,
  selectedProfile: null,
  setSelectedProfile: null,
  patientAddresses: null,
  setPatientAddresses: null,
  selectedAddress: null,
  setSelectedAddress: null,
  diagnosticCartItems: null,
  setDiagnosticCartItems: null,
  deletedDiagnosticCartItems: null,
  setDeletedDiagnosticCartItems: null,
  addedDiagnosticCartPackageItemdId: null,
  setAddedDiagnosticCartPackageItemId: null,
  patientLineItems: null,
  setPatientLineItems: null,
  isUserCare: null,
  setIsUserCare: null,
  searchText: null,
  setSearchText: null,
  couponApplied: null,
  setCouponApplied: null,
  circleBenefitsApplied: null,
  setCircleBenefitsApplied: null,
  serviceabilityData: null,
  setServiceabilityData: null,
  selectedSlotDate: null,
  setSelectedSlotDate: null,
  selectedSlot: null,
  setSelectedSlot: null,
  homeCollectionCharges: null,
  setHomeCollectionCharges: null,
  orderResult: null,
  setOrderResult: null,
  removeHCCharges: null,
  setRemoveHCCharges: null,
  hasDupes: null,
  setHasDupes: null,
  couponValidateErr: null,
  setCouponValidateErr: null,
  couponErrMsg: null,
  setCouponErrMsg: null,
  enableSlotSelection: null,
  setEnableSlotSelection: null,
  shouldResetDashboard: null,
  setShouldResetDashboard: null,
  detailsFetchedFlag: null,
  setDetailsFetchedFlag: null,
  userFound: null,
  setUserFound: null,
  userPhoneNumber: null,
  setUserPhoneNumber: null,
  paymentLink: null,
  setPaymentLink: null,
  showCodOrderDetail: null,
  setShowCodOrderDetail: null,
  showPaymentLinkOrderDetail: null,
  setShowPaymentLinkOrderDetail: null,
  showPaymentOptionRetry: null,
  setShowPaymentOptionRetry: null,
  showPaymentOptionDialog: null,
  setShowPaymentOptionDialog: null,
  codEnabled: null,
  setCodEnabled: null,
  codMessage: null,
  setCodMessage: null,
  circleMemberShipPlan: null,
  setCircleMemberShipPlan: null,
  updatePatientLineItemPricing: null,
  circleSubscriptionObj: null,
  setCircleSubscriptionObj: null,
  deletedPatientLineItems: null,
  setDeletedPatientLineItems: null,
  userSubscriptions: null,
  setUserSubscriptions: null,
  isNonCartFlowActivated: null,
  setIsNonCartFlowActivated: null,
  selectedNonCartFlowOrder: null,
  setSelectedNonCartFlowOrder: null,
  isHcSelected: null,
  setIsHcSelected: null,
  radiologyTest: null,
  setRadiologyTest: null,
  orderSource: null,
  setOrderSource: null,
  couponCode: null,
  setCouponCode: null,
  circleCashback: null,
  setCircleCashback: null,
  slotDaysByCity: null,
  setSlotDaysByCity: null,
  showActiveOrdersDialog: null,
  setShowActiveOrdersDialog: null,
  closePopupPrescription: null,
  setClosePopupPrescription: null,
  modifyFlowOrder: null,
  setModifyFlowOrder: null,
  setShowModifyFlow: null,
  showModifyFlow: null,
  setModifyOrderPaidAmountBreakup: null,
  modifyOrderPaidAmountBreakup: null,
  setUserAccess: null,
  userAccess: null,
  isItemRemoved: null,
  setIsItemRemoved: null,
  modifyOrderSelectedProfile: null,
  setModifyOrderSelectedProfile: null,
  hasAccessToModifyItems: null,
  hasAccessToChangeProfile: null,
  hasAccessToReschedule: null,
  hasAccessToCancelOrder: null,
  setHasAccessToCancelOrder: null,
  setHasAccessToChangeProfile: null,
  setHasAccessToModifyItems: null,
  setHasAccessToReschedule: null,
  isModifyInProgress: null,
  setIsModifyInProgress: null,
  modifyPaymentLink: null,
  setModifyPaymentLink: null,
  phleboCharges: null,
  setPhleboCharges: null,
});

export const OrderProvider: React.FC = (props) => {
  /**************************** State Variables ****************************/
  const [phleboCharges, setPhleboCharges] = useState<phleboChargesV2>(null);
  const [userProfiles, setUserProfiles] = useState<PatientProfile[]>([]);
  const [selectedProfile, setSelectedProfile] = useState<PatientProfile | null>(
    null
  );
  const [circleSubscriptionObj, setCircleSubscriptionObj] = useState<any>(null);
  const [userSubscriptions, setUserSubscriptions] = useState<any>(null);
  const [patientAddresses, setPatientAddresses] = useState<PatientAddress[]>(
    []
  );
  const [selectedAddress, setSelectedAddress] = useState<PatientAddress | null>(
    null
  );
  const [diagnosticCartItems, setDiagnosticCartItems] = useState<
    DiagnosticCartItem[]
  >([]);
  const [deletedDiagnosticCartItems, setDeletedDiagnosticCartItems] = useState<
    DiagnosticCartItem[]
  >([]);
  const [
    addedDiagnosticCartPackageItemdId,
    setAddedDiagnosticCartPackageItemId,
  ] = useState<number | null>(null);
  const [patientLineItems, setPatientLineItems] = useState<
    PatientObjWithLineItems[]
  >([]);
  const [deletedPatientLineItems, setDeletedPatientLineItems] =
    useState<PatientObjWithLineItems | null>(null);
  const [isUserCare, setIsUserCare] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>('');
  const [couponApplied, setCouponApplied] = useState<boolean>(false);
  const [circleBenefitsApplied, setCircleBenefitsApplied] =
    useState<boolean>(false);
  const [serviceabilityData, setServiceabilityData] =
    useState<ServiceabilityData>({ cityID: 0, stateID: 0 });
  const [selectedSlotDate, setSelectedSlotDate] = useState<Date>(new Date());
  const [selectedSlot, setSelectedSlot] = useState<SlotObject | null>(null);
  const [homeCollectionCharges, setHomeCollectionCharges] =
    useState<HomeCollectionCharges>({ charges: 0, distanceCharges: 0 });
  const [orderResult, setOrderResult] = useState<any>(null);
  const [removeHCCharges, setRemoveHCCharges] = useState<boolean>(false);
  const [hasDupes, setHasDupes] = useState<boolean>(false);
  const [couponValidateErr, setCouponValidateErr] = useState<string>('');
  const [couponErrMsg, setCouponErrMsg] = useState<string>('');
  const [enableSlotSelection, setEnableSlotSelection] =
    useState<boolean>(false);
  const [shouldResetDashboard, setShouldResetDashboard] =
    useState<boolean>(false);
  const [detailsFetchedFlag, setDetailsFetchedFlag] = useState<boolean>(false);
  const [userFound, setUserFound] = useState<boolean>(false);
  const [userPhoneNumber, setUserPhoneNumber] = useState<string>('');
  const [showCodOrderDetail, setShowCodOrderDetail] = useState<boolean>(false);
  const [showPaymentLinkOrderDetail, setShowPaymentLinkOrderDetail] =
    useState<boolean>(false);
  const [paymentLink, setPaymentLink] = useState<PaymentLinkData>(null);
  const [showPaymentOptionRetry, setShowPaymentOptionRetry] =
    useState<boolean>(false);
  const [codEnabled, setCodEnabled] = useState<boolean>(false);
  const [codMessage, setCodMessage] = useState<string>('');
  const [showPaymentOptionDialog, setShowPaymentOptionDialog] =
    useState<boolean>(false);
  const [circleMemberShipPlan, setCircleMemberShipPlan] =
    useState<PlanSummary>(null);
  const [isNonCartFlowActivated, setIsNonCartFlowActivated] =
    useState<boolean>(false);
  const [selectedNonCartFlowOrder, setSelectedNonCartFlowOrder] =
    useState<any>(null);
  const [isHcSelected, setIsHcSelected] = useState<boolean>(false);
  const [radiologyTest, setRadiologyTest] = useState<boolean>(false);
  const [orderSource, setOrderSource] = useState<string>('OP_WHATSAPP');
  const [couponCode, setCouponCode] = useState<string>('');
  const [circleCashback, setCircleCashback] = useState<number>(0);
  const [slotDaysByCity, setSlotDaysByCity] = useState<number>(null);
  const [showActiveOrdersDialog, setShowActiveOrdersDialog] =
    useState<boolean>(false);
  const [closePopupPrescription, setClosePopupPrescription] =
    useState<boolean>(false);
  const [showModifyFlow, setShowModifyFlow] = useState<boolean>(false);
  const [modifyFlowOrder, setModifyFlowOrder] = useState<any>(null);
  const [modifyOrderPaidAmountBreakup, setModifyOrderPaidAmountBreakup] =
    useState<amountBreakup>({
      subTotal: 0,
      cartSavings: 0,
      circleSavings: 0,
      couponSavings: 0,
    });
  const [userAccess, setUserAccess] = useState<string[]>([]);
  const [isItemRemoved, setIsItemRemoved] = useState<boolean>(false);
  const [modifyOrderSelectedProfile, setModifyOrderSelectedProfile] =
    useState<PatientProfile | null>(null);
  const [hasAccessToModifyItems, setHasAccessToModifyItems] =
    useState<boolean>(false);
  const [hasAccessToChangeProfile, setHasAccessToChangeProfile] =
    useState<boolean>(false);
  const [hasAccessToReschedule, setHasAccessToReschedule] =
    useState<boolean>(false);
  const [hasAccessToCancelOrder, setHasAccessToCancelOrder] =
    useState<boolean>(false);
  const [isModifyInProgress, setIsModifyInProgress] = useState<boolean>(false);
  const [modifyPaymentLink, setModifyPaymentLink] = useState<string>('');
  const updatePatientLineItemPricing = (applyCirclePricing: boolean) => {
    const newPatientLineItems: PatientObjWithLineItems[] =
      patientLineItems?.reduce((acc, patientLineItem) => {
        let totalPrice = 0;
        const newLineItems = patientLineItem?.lineItems?.map((lineItem) => {
          const cartItem = diagnosticCartItems?.find(
            (diagCartItem) => diagCartItem?.itemId === lineItem?.itemId
          );
          const itemPrices = getTestsCirclePrice(
            cartItem?.diagnosticPricing,
            applyCirclePricing
          );
          const { price, groupPlan, mrp, priceBeforeDiscount, cashbackAmount } =
            itemPrices;

          totalPrice += price;

          return {
            itemName: cartItem.itemName,
            priceBeforeDiscount,
            mrp,
            price,
            groupPlan,
            itemId: cartItem.itemId,
            couponDiscAmount: 0,
            isCouponOnMrp: false,
            groupPlanDiscAmount: 0,
            reportTAT: cartItem.reportTAT,
            circleCashback: cashbackAmount,
            testParametersCount: cartItem?.testParametersCount || 1,
          };
        });

        acc.push({
          patientID: patientLineItem?.patientID,
          totalPrice,
          lineItems: newLineItems,
        });

        return acc;
      }, [] as PatientObjWithLineItems[]);
    setPatientLineItems(newPatientLineItems);
  };

  const resetPaymentSettings = useCallback(() => {
    setShowPaymentOptionDialog(false);
    setCodEnabled(false);
    setCodMessage('');
    setShowPaymentOptionRetry(false);
    setShowCodOrderDetail(false);
    setShowPaymentLinkOrderDetail(false);
    setPaymentLink(null);
  }, []);

  const resetDashboard = useCallback(() => {
    setUserProfiles([]);
    setSelectedProfile(null);
    setSelectedAddress(null);
    setIsUserCare(false);
    setCircleBenefitsApplied(false);
    setDiagnosticCartItems([]);
    setPatientLineItems([]);
    setSearchText('');
    setSelectedSlot(null);
    setHomeCollectionCharges({
      charges: 0,
      distanceCharges: 0,
    });
    setServiceabilityData({ cityID: 0, stateID: 0 });
    setOrderResult(null);
    setHasDupes(false);
    setCouponErrMsg('');
    setCouponValidateErr('');
    setRemoveHCCharges(false);
    setEnableSlotSelection(false);
    setShouldResetDashboard(false);
    setDetailsFetchedFlag(false);
    setUserFound(false);
    setUserPhoneNumber('');
    setCircleMemberShipPlan(null);
    setUserSubscriptions(null);
    resetPaymentSettings();
    setIsNonCartFlowActivated(false);
    setSelectedNonCartFlowOrder(null);
    setSlotDaysByCity(null);
    setPhleboCharges(null);
  }, [resetPaymentSettings]);

  return (
    <OrderContext.Provider
      value={{
        resetDashboard,
        resetPaymentSettings,
        userFound,
        setUserFound,
        userProfiles,
        setUserProfiles,
        selectedProfile,
        setSelectedProfile,
        patientAddresses,
        setPatientAddresses,
        selectedAddress,
        setSelectedAddress,
        diagnosticCartItems,
        setDiagnosticCartItems,
        deletedDiagnosticCartItems,
        setDeletedDiagnosticCartItems,
        addedDiagnosticCartPackageItemdId,
        setAddedDiagnosticCartPackageItemId,
        patientLineItems,
        setPatientLineItems,
        isUserCare,
        setIsUserCare,
        searchText,
        setSearchText,
        couponApplied,
        setCouponApplied,
        circleBenefitsApplied,
        setCircleBenefitsApplied,
        serviceabilityData,
        setServiceabilityData,
        selectedSlotDate,
        setSelectedSlotDate,
        selectedSlot,
        setSelectedSlot,
        homeCollectionCharges,
        setHomeCollectionCharges,
        orderResult,
        setOrderResult,
        removeHCCharges,
        setRemoveHCCharges,
        hasDupes,
        setHasDupes,
        couponValidateErr,
        setCouponValidateErr,
        couponErrMsg,
        setCouponErrMsg,
        enableSlotSelection,
        setEnableSlotSelection,
        shouldResetDashboard,
        setShouldResetDashboard,
        detailsFetchedFlag,
        setDetailsFetchedFlag,
        userPhoneNumber,
        setUserPhoneNumber,
        paymentLink,
        setPaymentLink,
        showCodOrderDetail,
        setShowCodOrderDetail,
        showPaymentLinkOrderDetail,
        setShowPaymentLinkOrderDetail,
        showPaymentOptionRetry,
        setShowPaymentOptionRetry,
        showPaymentOptionDialog,
        setShowPaymentOptionDialog,
        codEnabled,
        setCodEnabled,
        codMessage,
        setCodMessage,
        circleMemberShipPlan,
        setCircleMemberShipPlan,
        updatePatientLineItemPricing,
        circleSubscriptionObj,
        setCircleSubscriptionObj,
        deletedPatientLineItems,
        setDeletedPatientLineItems,
        userSubscriptions,
        setUserSubscriptions,
        isNonCartFlowActivated,
        setIsNonCartFlowActivated,
        selectedNonCartFlowOrder,
        setSelectedNonCartFlowOrder,
        isHcSelected,
        setIsHcSelected,
        radiologyTest,
        setRadiologyTest,
        orderSource,
        setOrderSource,
        couponCode,
        setCouponCode,
        circleCashback,
        setCircleCashback,
        slotDaysByCity,
        setSlotDaysByCity,
        showActiveOrdersDialog,
        setShowActiveOrdersDialog,
        closePopupPrescription,
        setClosePopupPrescription,
        modifyFlowOrder,
        setModifyFlowOrder,
        setShowModifyFlow,
        showModifyFlow,
        modifyOrderPaidAmountBreakup,
        setModifyOrderPaidAmountBreakup,
        setUserAccess,
        userAccess,
        isItemRemoved,
        setIsItemRemoved,
        modifyOrderSelectedProfile,
        setModifyOrderSelectedProfile,
        hasAccessToCancelOrder,
        hasAccessToChangeProfile,
        hasAccessToModifyItems,
        hasAccessToReschedule,
        setHasAccessToCancelOrder,
        setHasAccessToChangeProfile,
        setHasAccessToModifyItems,
        setHasAccessToReschedule,
        isModifyInProgress,
        setIsModifyInProgress,
        modifyPaymentLink,
        setModifyPaymentLink,
        phleboCharges,
        setPhleboCharges,
      }}
    >
      {props.children}
    </OrderContext.Provider>
  );
};

export const useOrderContext = () =>
  useContext<OrderContextProps>(OrderContext);
