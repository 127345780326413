import React, { useState, useEffect, useMemo } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { KeyboardDatePicker } from '@material-ui/pickers';
import axios from 'axios';
import format from 'date-fns/format';
import subDays from 'date-fns/subDays';
import AphButton from './AphButton';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import { DataGrid, GridColDef, GridCellParams } from '@material-ui/data-grid';
import {
  DIAGNOSTIC_USER_ACCESS,
  GET_PRESCRIPTION_ORDERS,
  GET_PRESCRIPTION_ORDER_DETAILS,
  ORDER_TABLE_PAGE_SIZES,
  ORDER_TABLE_ROWS_PER_PAGE,
  SEARCH_FILTERS,
  SEARCH_FILTERS_OPTIONS,
  SEARCH_FILTER_ORDER_STATUS,
  SEARCH_FILTER_ORDER_STATUS_OPTIONS,
} from '../appConfig';
import { useOrderContext } from '../OrderProvider';
import RenderImage from './RenderImage';
import InputLabel from '@material-ui/core/InputLabel';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    '@global': {
      '*::-webkit-scrollbar': {
        width: '10px',
        height: 10,
      },
      '*::-webkit-scrollbar-track': {
        backgroundColor: ' #ECEEF0',
        borderRadius: 10,
      },
      '*::-webkit-scrollbar-thumb': {
        backgroundColor: '#A8A8A8',
        borderRadius: 10,
      },
    },
    documentViewer: {
      '& >div': {
        width: '25px !important',
        height: '35px !important',
        '& canvas': {
          width: '25px !important',
          height: '35px !important',
        },
        '& div': {
          width: '25px !important',
          height: '35px !important',
        },
      },
    },
    inputs: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'start',
      alignItems: 'end',
      margin: '12px 0px 30px',
      '&>span': {
        fontSize: 14,
        fontWeight: 500,
        paddingRight: 25,
        alignSelf: 'center',
        [theme.breakpoints.down('xs')]: {
          padding: '15px 0px',
        },
      },
      '&>div': {
        width: '25%',
        paddingRight: 25,
        [theme.breakpoints.down('xs')]: {
          flexBasis: '100%',
          paddingRight: 0,
        },
      },
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        flexWrap: 'wrap',
      },
    },
    formGroup: {
      position: 'relative',
      '& input': {
        fontSize: '14px',
        fontWeight: 500,
        padding: '7px 6px',
        height: 40,
        boxSizing: 'border-box',
        color: '#7A869A',
      },
      '& div': {
        fontSize: '13px',
        fontWeight: 500,
      },
      '& label': {
        fontSize: '14px',
        fontWeight: 'normal',
        display: 'block',
      },
      '&>button': {
        width: 109,
        border: 'none',
        height: 40,
        padding: '0px 25px',
        fontSize: 14,
        [theme.breakpoints.down('xs')]: {
          marginTop: 15,
        },
      },
    },
    btnGroup: {
      width: '40% !important',
      '&>button:last-child': {
        marginLeft: 25,
      },
    },
    selectField: {
      width: '100%',
      marginTop: '7px',
      background: '#FAFBFC',
      border: '2px solid #DFE1E6',
      boxSizing: 'border-box',
      borderRadius: 3,
      height: 40,
      '&:after': {
        display: 'none !important',
      },
      '&:before': {
        display: 'none !important',
      },
      '&>div': {
        paddingLeft: 5,
        color: '#02475B',
        padding: '10px 9px',
        fontSize: 14,
        fontWeight: 500,
        '&:focus': {
          backgroundColor: '#0000',
        },
      },
      '& svg': {
        fill: '#01475b',
      },
    },
    menuPopover: {
      boxShadow: '0 5px 20px 0 rgba(0, 0, 0, 0.3)',
      '& ul': {
        padding: '10px 20px',
        '& li': {
          fontSize: 14,
          fontWeight: 500,
          color: '#01475b',
          minHeight: 'auto',
          paddingLeft: 0,
          paddingRight: 0,
          borderBottom: '1px solid rgba(1,71,91,0.2)',
          backgroundColor: 'transparent !important',
          '&:last-child': {
            borderBottom: 'none',
          },
        },
      },
    },
    menuSelected: {
      backgroundColor: 'transparent !important',
      color: '#00b38e !important',
      '&:focus': {
        backgroundColor: 'transparent !important',
      },
    },
    textField: {
      width: '100%',
      marginTop: '5px',
      background: '#FAFBFC',
      border: '2px solid #DFE1E6',
      boxSizing: 'border-box',
      borderRadius: 3,
      height: 40,
      '&>div:after': {
        display: 'none !important',
      },
      '&>div:before': {
        display: 'none !important',
      },
    },
    datePickerWrap: {
      '&>div': {
        margin: 0,
        width: '100%',
      },
      '& .MuiInputBase-root': {
        background: '#FAFBFC',
        border: '2px solid #DFE1E6',
        boxSizing: 'border-box',
        borderRadius: '3px',
        height: '40px',
        '&:before': {
          display: 'none !important',
        },
        '&:after': {
          display: 'none !important',
        },
        '& input': {
          fontSize: 14,
          fontWeight: 500,
          color: '#7A869A',
          padding: '10px 9px',
        },
      },
      '& .Mui-focused': {
        border: '2px solid #4C9AFF',
        '&:before': {
          borderBottom: '2px solid #00b38e',
        },
        '&:after': {
          borderBottom: '2px solid #00b38e',
        },
      },
    },
    datePickerCalander: {
      '& button': {
        padding: '0px 12px',
        color: '#42526E',
        backgroundColor: 'unset',
        transition: 'unset',
        '&:hover': {
          background: 'unset',
        },
        '& svg': {
          width: 20,
          height: 20,
        },
      },
    },
    navigate: {
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    activeNavigate: {
      cursor: 'pointer',
    },
    thumbnailList: {
      display: 'flex',
      justifyContent: 'flex-start',
      marginRight: 8,
      gap: 4,
    },
    dataTable: {
      height: 439,
      '& .MuiTablePagination-input': {
        '& svg': { color: '#02475b !important' },
      },
    },
    dataGrid: {
      fontFamily: 'inherit !important',
      '& .rendering-zone': {
        fontSize: 12,
        fontWeight: 500,
      },
      '& .MuiDataGrid-cell': {
        borderRight: '1px solid rgba(66,82,110,0.1)',
        padding: '0px 14px',
        outline: 'none !important',
      },
      '& .MuiDataGrid-columnsContainer': {
        minHeight: `44px !important`,
      },
      '& .MuiDataGrid-columnHeaderWrapper': {
        '& :nth-child(2) > div >div ': {
          justifyContent: 'center',
        },
        '& :nth-child(5) > div >div ': {
          justifyContent: 'center',
        },
      },
      '& .MuiDataGrid-window': {
        top: `44px !important`,
      },
      '& .MuiDataGrid-row': {
        '& :last-child': {
          padding: '0px',
        },
      },
    },
    tableHeader: {
      backgroundColor: '#02475B',
      color: '#fff',
      fontSize: 16,
      fontWeight: 500,
      height: 44,
      '& .MuiDataGrid-columnSeparator': {
        display: 'none',
      },
    },
    overlay: {
      position: 'absolute',
      top: 10,
      bottom: 0,
      left: 0,
      right: 0,
      height: '34px',
      width: '100%',
      opacity: 0,
      transition: '.3s ease',
      backgroundColor: ' rgba(0,0,0,0.4)',
      textAlign: 'center',
      '&>img': {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      },
    },
    commentsCol: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      '& p': {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
    preFix: {
      paddingLeft: 10,
      '&>p': {
        fontSize: 14,
      },
    },
  })
);

const DEFAULT_GRID_STATE = {
  defaultStartDate: subDays(new Date(), 7),
  defaultEndDate: new Date(),
  defaultSelectedField: SEARCH_FILTERS.CUSTOMER_MOBILE_NUMBER,
  defaultSelectedFieldValue: '',
  defaultSelectedStatus: '',
  defaultRowsPerPage: ORDER_TABLE_ROWS_PER_PAGE,
  defaultOffset: 1,
  defaultPage: 0,
};

export const PrescriptionOrders = () => {
  const classes = useStyles();
  const {
    defaultEndDate,
    defaultOffset,
    defaultRowsPerPage,
    defaultSelectedField,
    defaultSelectedFieldValue,
    defaultSelectedStatus,
    defaultStartDate,
    defaultPage,
  } = DEFAULT_GRID_STATE;
  const {
    setUserPhoneNumber,
    setIsNonCartFlowActivated,
    userAccess,
    setSelectedNonCartFlowOrder,
  } = useOrderContext();
  const [rowsPerPage, setRowsPerPage] = useState<number>(defaultRowsPerPage);
  const [page, setPage] = useState<number>(defaultPage);
  const [offset, setOffset] = useState<number>(defaultOffset);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [startDate, setStartDate] = useState<Date>(defaultStartDate);
  const [endDate, setEndDate] = useState<Date>(defaultEndDate);
  const [selectedField, setSelectedField] =
    useState<string>(defaultSelectedField);
  const [selectedFieldValue, setSelectedFieldValue] = useState<string>(
    defaultSelectedFieldValue
  );
  const [selectedStatus, setSelectedStatus] = useState<string>(
    defaultSelectedStatus
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [orders, setOrders] = useState([]);
  const [enableResetCTA, setEnableResetCTA] = useState<boolean>(false);

  const orderStatuses = SEARCH_FILTER_ORDER_STATUS_OPTIONS?.map(
    (orderStatus: any) => orderStatus?.key
  );

  const hasAllPrescriptionViewAccess = useMemo(() => {
    return !!userAccess?.includes(
      DIAGNOSTIC_USER_ACCESS.ALL_PRESCRIPTION_ORDER_VIEW
    );
  }, [userAccess]);

  useEffect(() => {
    if (hasAllPrescriptionViewAccess) {
      getPrescriptionOrders(getPayload(), true);
    }
  }, [hasAllPrescriptionViewAccess]);

  const hasAccessToEditOrder = (order) => {
    return (
      (order?.status === SEARCH_FILTER_ORDER_STATUS.NEW_LEAD ||
        order?.status === SEARCH_FILTER_ORDER_STATUS.LEAD_DROPPED) &&
      order?.enableEdit
    );
  };

  const columns: GridColDef[] = [
    {
      field: 'phoneno',
      sortable: false,
      headerName: 'Phone No.',
      width: 150,
      align: 'center',
    },
    {
      field: 'displayid',
      sortable: false,
      headerName: 'Display id',
      width: 145,
      align: 'center',
      renderCell: (params: GridCellParams) => {
        return <p>#{params?.value?.toString()}</p>;
      },
    },
    {
      field: 'comments',
      sortable: false,
      headerName: 'Comments',
      width: 347,
      renderCell: (params: GridCellParams) => {
        const prescriptionURLs = params?.row?.comments?.prescriptions?.map(
          (prescription: any) => prescription?.prescriptionUrl
        );
        const comments = params?.row?.comments?.comments;
        return (
          <div className={classes.commentsCol}>
            <div>
              {prescriptionURLs?.length > 0 && (
                <div className={classes.thumbnailList}>
                  {prescriptionURLs?.map(
                    (prescriptionURL: any, idx: number) => (
                      <RenderImage
                        key={idx}
                        url={prescriptionURL}
                        height={35}
                        width={25}
                      />
                    )
                  )}
                </div>
              )}
            </div>
            <p title={comments}>{comments}</p>
          </div>
        );
      },
    },

    {
      field: 'bookingtime',
      sortable: false,
      width: 180,
      headerName: 'Booking Time',
      renderCell: (params: GridCellParams) => {
        return (
          <p>{format(new Date(params?.value?.toString()), 'dd/MM/yyyy p')}</p>
        );
      },
    },
    {
      field: 'status',
      sortable: false,
      width: 150,
      align: 'center',
      headerName: 'Status',
      renderCell: (params: GridCellParams) => {
        return <p>{getOrderStatusLabel(params?.value?.toString())}</p>;
      },
    },
    {
      field: 'navigate',
      sortable: false,
      width: 40,
      headerName: ' ',
      renderCell: (params: GridCellParams) => (
        <>
          {hasAccessToEditOrder(params?.row) ? (
            <div
              onClick={() => handleNavigate(params)}
              className={`${classes.navigate} ${classes.activeNavigate}`}
            >
              <img src={require('../images/Path.png')} alt=" " />
            </div>
          ) : (
            <div className={classes.navigate}>
              <img src={require('../images/Pathgrey.png')} alt=" " />
            </div>
          )}
        </>
      ),
    },
  ];

  const [cols, setCols] = useState<GridColDef[]>(columns);

  useEffect(() => {
    setCols(
      columns.map((colDefItem) => ({
        ...colDefItem,
        headerClassName: classes.tableHeader,
      }))
    );
    // eslint-disable-next-line
  }, []);

  const handleNavigate = (params: GridCellParams) => {
    getPrescriptionOrderDetails(params?.id, params?.row?.phoneno);
  };

  const getPrescriptionOrderDetails = (orderId, phoneNo) => {
    axios
      .post(GET_PRESCRIPTION_ORDER_DETAILS, {
        diagnosticOrderId: orderId,
        phoneNo: phoneNo,
      })
      .then(({ data }) => {
        setUserPhoneNumber(phoneNo?.slice(3));
        setSelectedNonCartFlowOrder(data?.ordersList);
        setIsNonCartFlowActivated(true);
      })
      .catch((e) => console.error(e));
  };
  const getPrescriptionOrders = (payload: any, isNewReq: boolean) => {
    setLoading(true);
    setEnableResetCTA(true);
    axios
      .post(GET_PRESCRIPTION_ORDERS, payload)
      .then(({ data }) => {
        if (data?.orders?.length) {
          setTotalCount(data?.count);
          const gridOrderData = convertToGridFormat(data?.orders);
          if (isNewReq) {
            setOrders(gridOrderData);
          } else {
            setOrders([...orders, ...gridOrderData]);
          }
        } else {
          setTotalCount(0);
          setOrders([]);
        }
      })
      .catch((e) => console.error(e))
      .finally(() => setLoading(false));
  };

  const getOrderStatusLabel = (orderStatusKey: string) => {
    const order = SEARCH_FILTER_ORDER_STATUS_OPTIONS?.find(
      (order) => order?.key === orderStatusKey
    );
    return order?.label || 'Order Placed';
  };

  const convertToGridFormat = (orders: any) => {
    return orders?.map((order: any) => ({
      id: order?.id,
      phoneno: order?.patientObj?.mobileNumber,
      displayid: order?.displayId,
      comments: order?.prescriptionItemsObj,
      bookingtime: order?.createdDate,
      status: order?.orderStatus,
      navigate: '',
      enableEdit: order?.enableEdit,
    }));
  };

  const getPayload = () => {
    const fieldValue = selectedFieldValue
      ? selectedField === SEARCH_FILTERS.CUSTOMER_MOBILE_NUMBER
        ? `+91${selectedFieldValue}`
        : selectedFieldValue
      : '';
    return {
      offset: offset,
      limit: rowsPerPage,
      fromDate: format(startDate, 'yyyy-MM-dd'),
      toDate: format(endDate, 'yyyy-MM-dd'),
      status: selectedStatus ? [selectedStatus] : orderStatuses,
      searchFilter: selectedField ? [selectedField] : '',
      searchValue: fieldValue,
    };
  };

  // check if data already present in ordersList
  const isOrderDataPresent = (pageNumber: number): boolean => {
    if (pageNumber * rowsPerPage < orders?.length) {
      return true;
    }
    return false;
  };

  const handlePageNumberChange = (pageNumber: number) => {
    setPage(pageNumber);
    if (!isOrderDataPresent(pageNumber)) {
      const payload = {
        ...getPayload(),
        offset: pageNumber + 1,
      };
      getPrescriptionOrders(payload, false);
    }
    setOffset(pageNumber + 1);
  };

  const handlePageSizeChange = (pageSize: number) => {
    setRowsPerPage(pageSize);
    const payload = {
      ...getPayload(),
      limit: pageSize,
    };
    getPrescriptionOrders(payload, true);
    setOffset(1);
  };

  const handleSearch = () => {
    setOffset(defaultOffset);
    setTotalCount(0);
    setPage(defaultPage);

    const payload = {
      ...getPayload(),
      offset: defaultOffset,
    };
    getPrescriptionOrders(payload, true);
  };

  const handleReset = () => {
    setStartDate(defaultStartDate);
    setEndDate(defaultEndDate);
    setSelectedField(defaultSelectedField);
    setSelectedFieldValue(defaultSelectedFieldValue);
    setSelectedStatus(defaultSelectedStatus);
    setRowsPerPage(defaultRowsPerPage);
    setOffset(defaultOffset);
    setTotalCount(0);
    setPage(defaultPage);
    const defaultPayload = {
      offset: defaultOffset,
      limit: defaultRowsPerPage,
      fromDate: format(defaultStartDate, 'yyyy-MM-dd'),
      toDate: format(defaultEndDate, 'yyyy-MM-dd'),
      status: orderStatuses,
      searchFilter: [defaultSelectedField],
      searchValue: defaultSelectedFieldValue,
    };
    getPrescriptionOrders(defaultPayload, true);
  };

  const disableCTA =
    selectedField === SEARCH_FILTERS.CUSTOMER_MOBILE_NUMBER
      ? selectedFieldValue?.length !== 10
      : selectedField === SEARCH_FILTERS.DISPLAYID
      ? selectedFieldValue?.length === 0
      : false;

  return (
    <>
      <div className={classes.inputs}>
        <div className={classes.formGroup}>
          <div className={classes.datePickerWrap}>
            <KeyboardDatePicker
              format="dd/MM/yyyy"
              margin="normal"
              id="date-picker-inline"
              value={startDate}
              onChange={setStartDate}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              rightArrowButtonProps={{ color: 'primary' }}
              leftArrowButtonProps={{ color: 'primary' }}
              autoOk={true}
              InputProps={{ disabled: true }}
              maxDate={new Date()}
              className={classes.datePickerCalander}
            />
          </div>
        </div>

        <span>To</span>

        <div className={classes.formGroup}>
          <div className={classes.datePickerWrap}>
            <KeyboardDatePicker
              format="dd/MM/yyyy"
              margin="normal"
              id="date-picker-inline"
              value={endDate}
              onChange={setEndDate}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              rightArrowButtonProps={{ color: 'primary' }}
              leftArrowButtonProps={{ color: 'primary' }}
              autoOk={true}
              InputProps={{ disabled: true }}
              maxDate={new Date()}
              className={classes.datePickerCalander}
            />
          </div>
        </div>
        <div className={`${classes.formGroup} ${classes.btnGroup}`}>
          <AphButton
            onClick={() => {
              handleSearch();
            }}
            disabled={disableCTA && !hasAllPrescriptionViewAccess}
            color="primary"
          >
            SEARCH
          </AphButton>
          <AphButton
            disabled={
              (defaultStartDate === startDate && defaultEndDate === endDate) ||
              !enableResetCTA
            }
            onClick={() => {
              handleReset();
            }}
            color="primary"
          >
            RESET
          </AphButton>
        </div>
      </div>

      <div className={classes.inputs}>
        <div className={classes.formGroup}>
          <Select
            className={classes.selectField}
            value={selectedField}
            onChange={(e) => {
              setSelectedFieldValue('');
              setSelectedField(e.target.value.toString());
            }}
            MenuProps={{
              classes: {
                paper: classes.menuPopover,
              },
              getContentAnchorEl: null,
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
            }}
          >
            {SEARCH_FILTERS_OPTIONS?.map((option) => (
              <MenuItem
                value={option.key}
                classes={{
                  selected: classes.menuSelected,
                }}
                key={option.key}
              >
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </div>
        <div className={classes.formGroup}>
          <TextField
            value={selectedFieldValue}
            onChange={(e) => {
              setSelectedFieldValue(e.target.value.toString());
            }}
            className={classes.textField}
            InputProps={{
              startAdornment: selectedField ===
                SEARCH_FILTERS.CUSTOMER_MOBILE_NUMBER && (
                <InputAdornment className={classes.preFix} position="start">
                  +91
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className={classes.formGroup}>
          <InputLabel id="order_status">Order Status</InputLabel>
          <Select
            id="order_status"
            className={classes.selectField}
            value={selectedStatus}
            onChange={(e) => {
              setSelectedStatus(e.target.value.toString());
            }}
            placeholder="Select Status"
            MenuProps={{
              classes: {
                paper: classes.menuPopover,
              },
              getContentAnchorEl: null,
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
            }}
          >
            {SEARCH_FILTER_ORDER_STATUS_OPTIONS?.map((status: any) => (
              <MenuItem
                key={status?.key}
                value={status?.key}
                classes={{
                  selected: classes.menuSelected,
                }}
              >
                {status?.label}
              </MenuItem>
            ))}
          </Select>
        </div>
        <div className={classes.formGroup}>
          <AphButton
            onClick={() => {
              handleSearch();
            }}
            disabled={disableCTA && !hasAllPrescriptionViewAccess}
            color="primary"
          >
            FILTER
          </AphButton>
        </div>
      </div>

      <div className={classes.dataTable}>
        <DataGrid
          loading={loading}
          className={classes.dataGrid}
          columns={cols}
          rows={orders}
          onPageChange={(pageNumber) => {
            handlePageNumberChange(pageNumber);
          }}
          rowCount={totalCount}
          pageSize={rowsPerPage}
          rowsPerPageOptions={ORDER_TABLE_PAGE_SIZES}
          onPageSizeChange={(pageSize) => {
            handlePageSizeChange(pageSize);
          }}
          page={page}
          pagination
          disableColumnMenu
          disableSelectionOnClick
          disableColumnSelector
        />
      </div>
    </>
  );
};
