import React, { useState, useEffect } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import axios from 'axios';
import format from 'date-fns/format';
import subDays from 'date-fns/subDays';
import AphButton from './AphButton';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import { DataGrid, GridColDef, GridCellParams } from '@material-ui/data-grid';
import {
  GET_MODIFIABLE_ORDERS,
  GET_ORDER_DETAILS,
  MODIFY_ORDER_SEARCH_FILTERS_OPTIONS,
  ORDER_TABLE_PAGE_SIZES,
  ORDER_TABLE_ROWS_PER_PAGE,
  SEARCH_FILTERS,
  SEARCH_FILTER_ORDER_STATUS,
} from '../appConfig';
import { useOrderContext } from '../OrderProvider';
import { updateModifyOrderPrice } from './helpers/commonHelpers';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    '@global': {
      '*::-webkit-scrollbar': {
        width: '10px',
        height: 10,
      },
      '*::-webkit-scrollbar-track': {
        backgroundColor: ' #ECEEF0',
        borderRadius: 10,
      },
      '*::-webkit-scrollbar-thumb': {
        backgroundColor: '#A8A8A8',
        borderRadius: 10,
      },
    },
    inputs: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'start',
      alignItems: 'center',
      margin: '12px 0px 30px',
      '&>span': {
        fontSize: 14,
        fontWeight: 500,
        paddingRight: 25,
        [theme.breakpoints.down('xs')]: {
          padding: '15px 0px',
        },
      },
      '&>div': {
        width: '25%',
        paddingRight: 25,
        [theme.breakpoints.down('xs')]: {
          flexBasis: '100%',
          paddingRight: 0,
        },
      },
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        flexWrap: 'wrap',
      },
    },
    formGroup: {
      position: 'relative',
      '& input': {
        fontSize: '14px',
        fontWeight: 500,
        padding: '7px 6px',
        height: 40,
        boxSizing: 'border-box',
        color: '#7A869A',
      },
      '& div': {
        fontSize: '13px',
        fontWeight: 500,
      },
      '& label': {
        fontSize: '14px',
        fontWeight: 'normal',
        display: 'block',
      },
      '&>button': {
        width: 109,
        border: 'none',
        height: 40,
        padding: '0px 25px',
        fontSize: 14,
        [theme.breakpoints.down('xs')]: {
          marginTop: 15,
        },
      },
    },
    btnGroup: {
      width: '40% !important',
      '&>button:last-child': {
        marginLeft: 25,
      },
    },
    selectField: {
      width: '100%',
      marginTop: '7px',
      background: '#FAFBFC',
      border: '2px solid #DFE1E6',
      boxSizing: 'border-box',
      borderRadius: 3,
      height: 40,
      '&:after': {
        display: 'none !important',
      },
      '&:before': {
        display: 'none !important',
      },
      '&>div': {
        paddingLeft: 5,
        color: '#02475B',
        padding: '10px 9px',
        fontSize: 14,
        fontWeight: 500,
        '&:focus': {
          backgroundColor: '#0000',
        },
      },
      '& svg': {
        fill: '#01475b',
      },
    },
    menuPopover: {
      boxShadow: '0 5px 20px 0 rgba(0, 0, 0, 0.3)',
      '& ul': {
        padding: '10px 20px',
        '& li': {
          fontSize: 14,
          fontWeight: 500,
          color: '#01475b',
          minHeight: 'auto',
          paddingLeft: 0,
          paddingRight: 0,
          borderBottom: '1px solid rgba(1,71,91,0.2)',
          backgroundColor: 'transparent !important',
          '&:last-child': {
            borderBottom: 'none',
          },
        },
      },
    },
    menuSelected: {
      backgroundColor: 'transparent !important',
      color: '#00b38e !important',
      '&:focus': {
        backgroundColor: 'transparent !important',
      },
    },
    textField: {
      width: '100%',
      marginTop: '5px',
      background: '#FAFBFC',
      border: '2px solid #DFE1E6',
      boxSizing: 'border-box',
      borderRadius: 3,
      height: 40,
      '&>div:after': {
        display: 'none !important',
      },
      '&>div:before': {
        display: 'none !important',
      },
    },
    navigate: {
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    activeNavigate: {
      cursor: 'pointer',
    },
    dataTable: {
      height: 439,
      '& .MuiTablePagination-input': {
        '& svg': { color: '#02475b !important' },
      },
    },
    dataGrid: {
      fontFamily: 'inherit !important',
      '& .rendering-zone': {
        fontSize: 12,
        fontWeight: 500,
      },
      '& .MuiDataGrid-cell': {
        borderRight: '1px solid rgba(66,82,110,0.1)',
        padding: '0px 14px',
        outline: 'none !important',
      },
      '& .MuiDataGrid-columnsContainer': {
        minHeight: `44px !important`,
      },
      '& .MuiDataGrid-columnHeaderWrapper': {
        '& :nth-child(2) > div >div ': {
          justifyContent: 'center',
        },
        '& :nth-child(5) > div >div ': {
          justifyContent: 'center',
        },
      },
      '& .MuiDataGrid-window': {
        top: `44px !important`,
      },
      '& .MuiDataGrid-row': {
        '& :last-child': {
          padding: '0px',
        },
      },
    },
    tableHeader: {
      backgroundColor: '#02475B',
      color: '#fff',
      fontSize: 16,
      fontWeight: 500,
      height: 44,
      '& .MuiDataGrid-columnSeparator': {
        display: 'none',
      },
    },
    preFix: {
      paddingLeft: 10,
      '&>p': {
        fontSize: 14,
      },
    },
    modifyBtn: {
      fontWeight: 700,
      fontSize: 13,
      textAlign: 'center',
      color: '#FC9916',
      margin: 'auto',
      cursor: 'pointer',
    },
    bookedBy: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    disable: {
      opacity: 0.5,
      cursor: 'not-allowed',
    },
    progressContainer: {
      height: '50vh',
      width: '100vw',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  })
);

const DEFAULT_GRID_STATE = {
  defaultStartDate: subDays(new Date(), 7),
  defaultEndDate: new Date(),
  defaultSelectedField: SEARCH_FILTERS.CUSTOMER_MOBILE_NUMBER,
  defaultSelectedFieldValue: '',
  defaultSelectedStatus: SEARCH_FILTER_ORDER_STATUS.NEW_LEAD,
  defaultRowsPerPage: ORDER_TABLE_ROWS_PER_PAGE,
  defaultOffset: 1,
  defaultPage: 0,
};

export const ModifyOrder = () => {
  const classes = useStyles();
  const {
    defaultOffset,
    defaultRowsPerPage,
    defaultSelectedField,
    defaultSelectedFieldValue,
    defaultPage,
  } = DEFAULT_GRID_STATE;
  const {
    setUserPhoneNumber,
    setModifyFlowOrder,
    setShowModifyFlow,
    userAccess,
    setModifyOrderSelectedProfile,
    setSelectedProfile,
    setHomeCollectionCharges,
    setIsModifyInProgress,
    setModifyPaymentLink,
  } = useOrderContext();
  const [rowsPerPage, setRowsPerPage] = useState<number>(defaultRowsPerPage);
  const [page, setPage] = useState<number>(defaultPage);
  const [offset, setOffset] = useState<number>(defaultOffset);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [selectedField, setSelectedField] =
    useState<string>(defaultSelectedField);
  const [selectedFieldValue, setSelectedFieldValue] = useState<string>(
    defaultSelectedFieldValue
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [modifyDetailsLoading, setModifyDetailsLoading] =
    useState<boolean>(false);
  const [orders, setOrders] = useState([]);
  const [paymentLink, setPaymentLink] = useState<string>('');
  const hasAccessToModifyOrder = userAccess?.length > 0;

  const columns: GridColDef[] = [
    {
      field: 'phoneno',
      sortable: false,
      headerName: 'Phone No.',
      width: 140,
      align: 'center',
    },
    {
      field: 'displayid',
      sortable: false,
      headerName: 'Display ID',
      width: 140,
      align: 'center',
      renderCell: (params: GridCellParams) => {
        return <p>#{params?.value?.toString()}</p>;
      },
    },
    {
      field: 'prebookingid',
      sortable: false,
      headerName: 'Prebooking ID',
      width: 140,
      align: 'center',
      renderCell: (params: GridCellParams) => {
        return <p>#{params?.value?.toString()}</p>;
      },
    },

    {
      field: 'patientname',
      sortable: false,
      headerName: 'Patient Name',
      width: 140,
      align: 'center',
      renderCell: (params: GridCellParams) => {
        return <p>{params?.value?.toString()}</p>;
      },
    },

    {
      field: 'appointmenttime',
      sortable: false,
      width: 180,
      headerName: 'Appt Time',
      renderCell: (params: GridCellParams) => {
        return (
          <p>{format(new Date(params?.value?.toString()), 'dd/MM/yyyy p')}</p>
        );
      },
    },
    {
      field: 'bookedby',
      sortable: false,
      width: 160,
      align: 'center',
      headerName: 'Booked By',
      renderCell: (params: GridCellParams) => {
        return (
          <p className={classes.bookedBy} title={params?.value?.toString()}>
            {params?.value?.toString()}
          </p>
        );
      },
    },
    {
      field: 'navigate',
      sortable: false,
      width: 122,
      headerName: 'Action',
      renderCell: (params: GridCellParams) => (
        <>
          <span
            onClick={() => {
              if (hasAccessToModifyOrder && params?.row?.enableEdit) {
                handleNavigate(params?.row);
              }
            }}
            className={`${classes.modifyBtn} ${
              (!hasAccessToModifyOrder || !params?.row?.enableEdit) &&
              classes.disable
            }`}
          >
            MODIFY
          </span>
        </>
      ),
    },
  ];

  const [cols, setCols] = useState<GridColDef[]>(columns);

  useEffect(() => {
    setCols(
      columns.map((colDefItem) => ({
        ...colDefItem,
        headerClassName: classes.tableHeader,
      }))
    );
    // eslint-disable-next-line
  }, [hasAccessToModifyOrder]);

  const getModifyOrders = (payload: any, isNewReq: boolean) => {
    setLoading(true);
    axios
      .post(GET_MODIFIABLE_ORDERS, payload)
      .then(({ data }) => {
        if (data?.orders?.length) {
          setTotalCount(data?.count);
          const gridOrderData = convertToGridFormat(data?.orders);
          if (isNewReq) {
            setOrders(gridOrderData);
          } else {
            setOrders([...orders, ...gridOrderData]);
          }
        } else {
          setTotalCount(0);
          setOrders([]);
        }
      })
      .catch((e) => console.error(e))
      .finally(() => setLoading(false));
  };

  const convertToGridFormat = (orders: any) => {
    return orders?.map((order: any) => ({
      id: order?.id,
      phoneno: order?.mobileNumber,
      displayid: order?.displayId,
      prebookingid: order?.preBookingId,
      appointmenttime: order?.slotDateTimeInUTC,
      bookedby: order?.attributesObj?.agentLoginID
        ? order?.attributesObj?.agentLoginID
        : order?.patientObj?.firstName,
      patientname: `${order?.patientObj?.firstName} ${order?.patientObj?.lastName}`,
      modifyInProgress: order?.modifyInProgress,
      navigate: '',
      enableEdit: order?.enableEdit,
      paymentLinkURL: order?.paymentLinkURL,
    }));
  };

  const getPayload = () => {
    const fieldValue = selectedFieldValue
      ? selectedField === SEARCH_FILTERS.CUSTOMER_MOBILE_NUMBER
        ? `+91${selectedFieldValue}`
        : selectedFieldValue
      : '';
    return {
      offset: offset,
      limit: rowsPerPage,
      searchFilter: selectedField,
      searchValue: fieldValue,
    };
  };

  // check if data already present in ordersList
  const isOrderDataPresent = (pageNumber: number): boolean => {
    if (pageNumber * rowsPerPage < orders?.length) {
      return true;
    }
    return false;
  };

  const handlePageNumberChange = (pageNumber: number) => {
    setPage(pageNumber);
    if (!isOrderDataPresent(pageNumber)) {
      const payload = {
        ...getPayload(),
        offset: pageNumber + 1,
      };
      getModifyOrders(payload, false);
    }
    setOffset(pageNumber + 1);
  };

  const handlePageSizeChange = (pageSize: number) => {
    setRowsPerPage(pageSize);
    const payload = {
      ...getPayload(),
      limit: pageSize,
    };
    getModifyOrders(payload, true);
    setOffset(1);
  };

  const handleSearch = () => {
    setOffset(defaultOffset);
    setTotalCount(0);
    setPage(defaultPage);

    const payload = {
      ...getPayload(),
      offset: defaultOffset,
    };
    getModifyOrders(payload, true);
  };

  const handleReset = () => {
    setSelectedField(defaultSelectedField);
    setSelectedFieldValue(defaultSelectedFieldValue);
    setRowsPerPage(defaultRowsPerPage);
    setOffset(defaultOffset);
    setTotalCount(0);
    setPage(defaultPage);
    setOrders([]);
  };

  const handleNavigate = (row: any) => {
    setModifyDetailsLoading(true);
    setModifyOrderSelectedProfile(null);
    setSelectedProfile(null);
    axios
      .post(GET_ORDER_DETAILS, {
        diagnosticOrderId: row?.id,
        phoneNo: row?.phoneno,
      })
      .then(({ data }) => {
        const modifyOrder = updateModifyOrderPrice(data?.ordersList);
        if (modifyOrder?.modifyInProgress) {
          setIsModifyInProgress(true);
          setModifyPaymentLink(modifyOrder?.paymentLinkURL);
        }
        setShowModifyFlow(false);
        setModifyDetailsLoading(false);
        setModifyFlowOrder(modifyOrder);
        setHomeCollectionCharges({
          charges: modifyOrder?.collectionCharges ?? 0,
          distanceCharges: 0,
        });
        setUserPhoneNumber(row?.phoneno?.slice(3));
      })
      .catch((err) => console.error('error while fetching order details', err))
      .finally(() => {
        setShowModifyFlow(false);
      });
  };

  const disableCTA =
    selectedField === SEARCH_FILTERS.CUSTOMER_MOBILE_NUMBER
      ? selectedFieldValue?.length !== 10
      : [
          SEARCH_FILTERS.DISPLAYID.toString(),
          SEARCH_FILTERS.PREBOOKINGID.toString(),
        ].includes(selectedField?.toString())
      ? selectedFieldValue?.length === 0
      : false;

  return (
    <>
      <div className={classes.inputs}>
        <div className={classes.formGroup}>
          <Select
            className={classes.selectField}
            value={selectedField}
            onChange={(e) => {
              setSelectedFieldValue('');
              setSelectedField(e.target.value.toString());
            }}
            MenuProps={{
              classes: {
                paper: classes.menuPopover,
              },
              getContentAnchorEl: null,
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
            }}
          >
            {MODIFY_ORDER_SEARCH_FILTERS_OPTIONS?.map((option) => (
              <MenuItem
                value={option.key}
                classes={{
                  selected: classes.menuSelected,
                }}
                key={option.key}
              >
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </div>
        <div className={classes.formGroup}>
          <TextField
            value={selectedFieldValue}
            onChange={(e) => {
              setSelectedFieldValue(e.target.value.toString());
            }}
            className={classes.textField}
            InputProps={{
              startAdornment: selectedField ===
                SEARCH_FILTERS.CUSTOMER_MOBILE_NUMBER && (
                <InputAdornment className={classes.preFix} position="start">
                  +91
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className={`${classes.formGroup} ${classes.btnGroup}`}>
          <AphButton
            onClick={() => {
              handleSearch();
            }}
            disabled={disableCTA}
            color="primary"
          >
            SEARCH
          </AphButton>
          <AphButton
            onClick={() => {
              handleReset();
            }}
            color="primary"
          >
            RESET
          </AphButton>
        </div>
      </div>

      <div className={classes.dataTable}>
        {modifyDetailsLoading ? (
          <div className={classes.progressContainer}>
            <CircularProgress />
          </div>
        ) : (
          <DataGrid
            loading={loading}
            className={classes.dataGrid}
            columns={cols}
            rows={orders}
            onPageChange={(pageNumber) => {
              handlePageNumberChange(pageNumber);
            }}
            rowCount={totalCount}
            pageSize={rowsPerPage}
            rowsPerPageOptions={ORDER_TABLE_PAGE_SIZES}
            onPageSizeChange={(pageSize) => {
              handlePageSizeChange(pageSize);
            }}
            page={page}
            pagination
            disableColumnMenu
            disableSelectionOnClick
            disableColumnSelector
          />
        )}
      </div>
    </>
  );
};

export default ModifyOrder;
