import makeStyles from '@material-ui/styles/makeStyles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import React from 'react';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      margin: 0,
      padding: 0,
    },
    colorPrimary: {
      color: '#00b38e',
      '&$checked': {
        color: '#00b38e',
      },
    },
    checked: {
      color: '#00b38e',
    },
  };
});

const AphCheckbox: React.FC<CheckboxProps> = (props) => {
  const defaultClasses = useStyles({});
  const classes = props.classes || defaultClasses;

  return <Checkbox classes={classes} {...props} />;
};
export default AphCheckbox;
