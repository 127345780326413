import React, { useEffect, useRef, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
  NewProfile,
  PatientProfile,
  getPatientAge,
  defaultNewUserData,
  isEmailValid,
} from './helpers/commonHelpers';
import {
  Button,
  CircularProgress,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import AphDialog from './AphDialog';
import AphDialogClose from './AphDialogClose';
import AphDialogTitle from './AphDialogTitle';
import { KeyboardDatePicker } from '@material-ui/pickers';
import {
  ADD_NEW_MEMBER,
  GENDER_LIST,
  RELATION_LIST,
  SAVE_USER_PROFILE,
  UPDATE_GUEST_PROFILE,
} from '../appConfig';
import axios from '../axios';
import { format } from 'date-fns';
import { useOrderContext } from '../OrderProvider';
import { Virtuoso } from 'react-virtuoso';
import { Relation } from '../types/globalTypes';
import { AlertToast } from './Alert/AlertToast';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    customerList: {
      margin: 0,
      padding: 0,
      direction: 'ltr',
    },
    listItem: {
      listStyleType: 'none',
      cursor: 'pointer',
      padding: 12,
      borderBottom: '1px solid #D4D4D4',
      '&>div:first-child': {
        display: 'flex',
        justifyContent: 'space-between',
        fontSize: 12,
        fontWeight: 600,
        alignItems: 'center',
        '& img': {
          verticalAlign: 'middle',
          paddingRight: 5,
        },

        '&>div:first-child': {
          display: 'flex',
          alignItems: 'center',
          '& span': {
            display: 'block',
          },
        },
      },
      '&>div:last-child': {
        fontSize: 12,
        fontWeight: 600,
        display: 'flex',
        margin: '4px 0px 0px 23px',
        justifyContent: 'space-between',
        '&>p:first-child': {
          whiteSpace: 'nowrap',
          color: '#347B9A',
          fontSize: 10,
          fontWeight: 400,
          margin: 0,
        },
        '&>p:last-child': {
          whiteSpace: 'nowrap',
          color: '#121414',
          fontSize: 10,
          fontWeight: 400,
          margin: 0,
        },
      },
    },
    active: {
      background: '#00B38E',
      color: '#fff !important',
      opacity: `1 !important`,
      position: 'relative',
      '&:after': {
        content: '""',
        width: 0,
        height: 0,
        borderTop: '10px solid transparent',
        borderBottom: '10px solid transparent',
        borderLeft: '10px solid #00B38E',
        position: 'absolute',
        top: '50%',
        transform: 'translate(0px, -50%)',
        right: -10,
        display: 'none',
      },
      '& p': {
        color: '#fff !important',
      },
    },
    selected: {
      color: '#00B38E',
    },
    viewAll: {
      background: '#FFF3E1',
      textAlign: 'center',
      padding: '8px 0px',
      fontWeight: 'bold',
      fontSize: 13,
      color: '#FCA317',
      textTransform: 'uppercase',
      '& img': {
        verticalAlign: 'middle',
        paddingLeft: 10,
      },
    },
    noProfiles: {
      padding: 20,
    },
    addNewMember: {
      padding: '15px',
      '&>div': {
        margin: '0px 0px 9px 0px',
        [theme.breakpoints.down('xs')]: {
          margin: '0px 0px 4px 0px',
        },
      },
      [theme.breakpoints.down('xs')]: {
        maxHeight: '70vh',
        overflow: 'auto',
        paddingTop: 8,
      },
    },
    formGroup: {
      position: 'relative',
      padding: '20px 0px',

      '& input': {
        fontSize: '13px',
        fontWeight: 500,
        padding: '7px 6px',
      },
      '& div': {
        fontSize: '13px',
        fontWeight: 500,
      },
      '& label': {
        fontSize: '14px',
        fontWeight: 'normal',
        display: 'block',
      },
    },
    popupField: {
      padding: 10,
      paddingBottom: 0,
      [theme.breakpoints.down('xs')]: {
        padding: '3px 10px',
      },
    },
    textField: {
      width: '100%',
      marginTop: '5px',
      background: '#FAFBFC',
      border: '2px solid #DFE1E6',
      boxSizing: 'border-box',
      borderRadius: 8,
      '&>div:after': {
        display: 'none !important',
      },
      '&>div:before': {
        display: 'none !important',
      },
    },
    gridWidth: {
      position: 'relative',
      '&>div': {
        padding: 0,
        marginTop: 7,
      },
      '& svg': {
        fill: '#01475b',
      },
    },
    dateGrid: {
      flexBasis: '50%',
      '& button': {
        padding: 5,
      },
    },
    genderGrid: {
      flexBasis: '45%',
    },
    gridCont: {
      justifyContent: 'space-between',
    },
    selectField: {
      width: '100%',
      marginTop: '7px',
      background: '#FAFBFC',
      border: '2px solid #DFE1E6',
      boxSizing: 'border-box',
      borderRadius: 8,
      '&:after': {
        display: 'none !important',
      },
      '&:before': {
        display: 'none !important',
      },
      '&>div': {
        paddingLeft: 5,
      },
      '& svg': {
        fill: '#01475b',
      },
    },
    yellowBtn: {
      background: '#FCB716 !important',
      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
      borderRadius: '5px',
      color: '#fff',
      fontSize: '13px',
      padding: '8px 25px',
      fontWeight: 'bold',
      minWidth: 143,
    },
    menuPopover: {
      boxShadow: '0 5px 20px 0 rgba(0, 0, 0, 0.3)',
      '& ul': {
        padding: '10px 20px',
        '& li': {
          fontSize: 14,
          fontWeight: 500,
          color: '#01475b',
          minHeight: 'auto',
          paddingLeft: 0,
          paddingRight: 0,
          borderBottom: '1px solid rgba(1,71,91,0.2)',
          backgroundColor: 'transparent !important',
          '&:last-child': {
            borderBottom: 'none',
          },
        },
      },
    },
    menuSelected: {
      backgroundColor: 'transparent !important',
      color: '#00b38e !important',
      '&:focus': {
        backgroundColor: 'transparent !important',
      },
    },
    errorMsg: {
      paddingTop: 8,
      fontSize: 11,
      lineHeight: '13px',
      color: '#AB2300',
      '& img': {
        verticalAlign: 'middle',
      },
    },
    popupError: {
      margin: 0,
      textAlign: 'center',
      fontSize: 12,
    },
    imInfo: {
      fontSize: '12px',
      lineHeight: '16px',
      color: '#007C9D',
      opacity: 0.8,
    },
    submitBtn: {
      paddingTop: '10px',
      textAlign: 'right',
      display: 'flex',
      '& button': {
        boxShadow: 'unset',
        border: 'none !important',
        '&:focus': {
          border: 'none !important',
        },
      },
      '& button:first-child': {
        marginRight: '10px',
      },
    },
    loader: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    profilesWrapper: {
      minHeight: 200,
      maxHeight: 370,
    },
    disable: {
      opacity: 0.5,
      cursor: 'not-allowed',
    },
    changedTag: {
      background: '#FFEBE6',
      borderRadius: 10,
      fontWeight: 600,
      fontSize: 10,
      color: ' #BF2600',
      padding: 4,
      marginLeft: 4,
    },
    profileContainer: {
      display: 'flex',
      alignItems: 'center',
    },
  })
);

interface CustomerProfilesProps {
  handleUserRegistrationStatus: () => void;
}
export const CustomerProfiles: React.FC<CustomerProfilesProps> = ({
  handleUserRegistrationStatus,
}) => {
  const classes = useStyles();
  const {
    userProfiles,
    userPhoneNumber,
    setSelectedProfile,
    selectedProfile,
    detailsFetchedFlag,
    userFound,
    setUserProfiles,
    patientLineItems,
    isNonCartFlowActivated,
    selectedNonCartFlowOrder,
    setDiagnosticCartItems,
    setPatientLineItems,
    modifyFlowOrder,
    hasAccessToChangeProfile,
    setModifyOrderSelectedProfile,
  } = useOrderContext();
  const [newUserProfile, setNewUserProfile] =
    useState<NewProfile>(defaultNewUserData);
  const [showNewProfilePopup, setShowNewProfilePopup] =
    useState<boolean>(false);
  const [isMailIdValid, setIsMailIdValid] = useState<boolean>(false);
  const [loadingSaveProfile, setLoadingSaveProfile] = useState<boolean>(false);
  const [profileAdditionError, setProfileAdditionError] = useState<string>('');
  const virtuoso = useRef(null);
  const hasPrimaryProfile = userProfiles?.some(
    (profile) => profile?.uhid && profile.relation === Relation.ME
  );
  const guestProfile = userProfiles?.find((profile) => !profile?.uhid?.length);

  const enableProfileChange =
    (modifyFlowOrder && hasAccessToChangeProfile) || !modifyFlowOrder;

  const handleSelectProfile = (profile: PatientProfile) => {
    if (profile?.uhid) {
      setSelectedProfile(profile);
    } else {
      showAddProfileDialog();
    }
  };

  const getNewUserSaveCondition = () => {
    const conditionObjSaveNewProfile = newUserProfile && {
      firstName: !!newUserProfile.firstName,
      lastName: !!newUserProfile.lastName,
      mobileNumber: !!newUserProfile.mobileNumber,
      gender: !!newUserProfile.gender,
      dateOfBirth: !!newUserProfile.dateOfBirth,
      emailAddress:
        detailsFetchedFlag && userFound
          ? newUserProfile.emailAddress !== ''
            ? isMailIdValid
            : true
          : true,
      relation:
        detailsFetchedFlag && userFound
          ? hasPrimaryProfile
            ? !!newUserProfile.relation &&
              newUserProfile.relation !== Relation.ME
            : !!newUserProfile.relation
          : true,
    };
    const canProceedToSaveProfile = Object.values(
      conditionObjSaveNewProfile
    ).reduce((prev, curr) => {
      return prev && curr;
    });
    return canProceedToSaveProfile;
  };

  const showAddProfileDialog = () => {
    const newUser = { ...newUserProfile };
    newUser.mobileNumber = '+91' + userPhoneNumber;
    setNewUserProfile(newUser);
    setShowNewProfilePopup(true);
    setProfileAdditionError('');
  };

  // Called on Add New CTA Click - when no profiles exist for a number
  const saveProfile = async () => {
    setLoadingSaveProfile(true);
    const profileForApiPayload: any = { ...newUserProfile };
    delete profileForApiPayload.relation;
    await axios
      .post(SAVE_USER_PROFILE, {
        ...profileForApiPayload,
      })
      .then(({ data }) => {
        if (data && data.length > 0) {
          handleUserRegistrationStatus();
          setNewUserProfile(defaultNewUserData);
          setShowNewProfilePopup(false);
        }
      })
      .catch((err) => {
        console.error('Error in saveProfile', err);
        setProfileAdditionError('Something went wrong.');
      })
      .finally(() => {
        setLoadingSaveProfile(false);
      });
  };

  // Called on Add New CTA Click - when >= 1 profile exists for a number
  const addNewProfile = async () => {
    setLoadingSaveProfile(true);
    const profileForApiPayload: any = { ...newUserProfile };
    delete profileForApiPayload.source;
    newUserProfile['photoUrl'] = '';
    await axios
      .post(ADD_NEW_MEMBER, {
        patientProfileInput: { ...profileForApiPayload },
      })
      .then(({ data }) => {
        if (data && data.id) {
          let updatedProfiles = userProfiles;
          updatedProfiles.unshift(data);
          setUserProfiles(updatedProfiles);
          setShowNewProfilePopup(false);
          setNewUserProfile(defaultNewUserData);
          if (modifyFlowOrder) {
            if (enableProfileChange) {
              setModifyOrderSelectedProfile(data);
            }
          } else {
            setSelectedProfile(data);
          }
        }
      })
      .catch((e) => {
        console.error(`Couldn't add member`, e);
        setProfileAdditionError('Something went wrong.');
      })
      .finally(() => {
        setLoadingSaveProfile(false);
      });
  };

  // Called on Add New CTA Click - when guest profile exists for a number
  const updateGuestProfile = async () => {
    try {
      setLoadingSaveProfile(true);
      const payload = {
        patientInput: {
          id: guestProfile?.id,
          mobileNumber: '+91' + userPhoneNumber,
          firstName: newUserProfile.firstName,
          lastName: newUserProfile.lastName,
          gender: newUserProfile.gender,
          dateOfBirth: newUserProfile.dateOfBirth,
          emailAddress: newUserProfile.emailAddress,
          relation: newUserProfile.relation,
        },
      };

      const { data } = await axios.post(UPDATE_GUEST_PROFILE, payload);
      if (data?.patient?.uhid) {
        setNewUserProfile(defaultNewUserData);
        setLoadingSaveProfile(false);
        setShowNewProfilePopup(false);
        handleUserRegistrationStatus();
      } else {
        setProfileAdditionError('Something went wrong.');
      }
    } catch (e) {
      console.error('Error updating Guest Profile', e);
      setProfileAdditionError('Something went wrong.');
      setLoadingSaveProfile(false);
    }
  };

  const handleChangeNewProfile = (
    event: React.ChangeEvent<HTMLInputElement>,
    property: string
  ) => {
    const val = (event.target as HTMLInputElement).value;
    const newProfile = { ...newUserProfile };
    switch (property) {
      case 'firstName':
      case 'lastName':
      case 'gender':
      case 'emailAddress':
      case 'relation':
        newProfile[property] = val;
        setNewUserProfile(newProfile);
        break;
      default:
        break;
    }
  };

  const handleSelectedDOBChange = (date: Date | null) => {
    let newProfile = { ...newUserProfile };
    if (date && !isNaN(date.getTime())) {
      newProfile.dateOfBirth = format(date, 'yyyy-MM-dd');
    } else {
      newProfile.dateOfBirth = '';
    }
    setNewUserProfile(newProfile);
  };

  //Modify Flow prepopulate patient proffile
  useEffect(() => {
    if (modifyFlowOrder && !selectedProfile) {
      handleSelectProfile({
        ...modifyFlowOrder.patientObj,
        id: modifyFlowOrder?.patientId,
      });
    }
  }, [modifyFlowOrder, selectedProfile]);

  // scroll to selected patient
  useEffect(() => {
    if (userProfiles?.length && modifyFlowOrder) {
      const selectedProfIndex = userProfiles?.findIndex(
        (profile) => profile?.id === modifyFlowOrder?.patientId
      );
      setTimeout(() => {
        virtuoso?.current?.scrollToIndex({
          index: selectedProfIndex,
          behavior: 'smooth',
        });
      }, 500);
    }
  }, [userProfiles, modifyFlowOrder]);

  //Noncart flow prepopulate patient profile
  useEffect(() => {
    if (isNonCartFlowActivated && selectedNonCartFlowOrder) {
      handleSelectProfile({
        ...selectedNonCartFlowOrder.patientObj,
        id: selectedNonCartFlowOrder?.patientId,
      });
    }
  }, [isNonCartFlowActivated, selectedNonCartFlowOrder]);

  //scroll to selected patient profile
  useEffect(() => {
    if (
      userProfiles?.length &&
      isNonCartFlowActivated &&
      selectedNonCartFlowOrder
    ) {
      const selectedProfIndex = userProfiles?.findIndex(
        (profile) => profile?.id === selectedNonCartFlowOrder?.patientId
      );
      setTimeout(() => {
        virtuoso?.current?.scrollToIndex({
          index: selectedProfIndex,
          behavior: 'smooth',
        });
      }, 500);
    }
  }, [userProfiles, isNonCartFlowActivated]);

  // remove previous selected profile if Noncart flow is activated
  useEffect(() => {
    if (isNonCartFlowActivated) {
      setDiagnosticCartItems([]);
      setPatientLineItems([]);
    }
  }, [selectedProfile]);

  const isRelationError =
    hasPrimaryProfile &&
    !!newUserProfile.relation &&
    newUserProfile.relation === Relation.ME;

  const isModifyOrderPatientChanged = (patientId) => {
    return (
      modifyFlowOrder?.patientId === patientId &&
      selectedProfile?.id !== patientId
    );
  };

  return (
    <>
      <h3>
        <span>Customer Name</span>
        {enableProfileChange && (
          <Button onClick={showAddProfileDialog}>+ ADD NEW</Button>
        )}
      </h3>
      {userProfiles?.length > 0 ? (
        <Virtuoso
          ref={virtuoso}
          style={{ height: '350px' }}
          data={userProfiles}
          itemContent={(idx, profile) => {
            const currentPatient = patientLineItems?.find(
              (patientObj) => patientObj.patientID === profile.id
            );
            return (
              <div
                className={`${classes.listItem} ${
                  currentPatient &&
                  currentPatient?.lineItems?.length > 0 &&
                  classes.selected
                } ${
                  selectedProfile?.id === profile?.id
                    ? classes.active
                    : !enableProfileChange && classes.disable
                }`}
                key={'profile-' + idx}
                onClick={() => {
                  if (modifyFlowOrder) {
                    if (enableProfileChange) {
                      setModifyOrderSelectedProfile(profile);
                    }
                  } else {
                    handleSelectProfile(profile);
                  }
                }}
              >
                <div>
                  <div>
                    <img
                      src={require(selectedProfile?.id === profile?.id ||
                        (currentPatient &&
                          currentPatient?.lineItems?.length > 0)
                        ? '../images/ic_checkactive.png'
                        : '../images/ic_checkdefault.png')}
                      alt=""
                    />
                    <div>
                      <div className={classes.profileContainer}>
                        <span>
                          {profile?.uhid
                            ? `${profile?.firstName} ${profile?.lastName}`
                            : 'Guest'}
                        </span>
                        {modifyFlowOrder &&
                          isModifyOrderPatientChanged(profile?.id) && (
                            <span className={classes.changedTag}>Changed</span>
                          )}
                      </div>

                      <span>
                        {currentPatient?.lineItems.length ?? 0} tests added
                      </span>
                    </div>
                  </div>
                  <div>
                    {profile?.uhid
                      ? `${profile?.gender || ''}, ${
                          getPatientAge(profile?.dateOfBirth) || ''
                        }`
                      : ''}
                  </div>
                </div>
                <div>
                  <p>
                    <b>Last Booked</b>:{' '}
                    {profile?.lastBooked
                      ? format(new Date(profile?.lastBooked), 'dd MMM, yyyy')
                      : 'NA'}
                  </p>
                  <p>
                    <b>DOB</b>:{' '}
                    {profile?.dateOfBirth
                      ? format(new Date(profile?.dateOfBirth), 'dd MMM, yyyy')
                      : 'NA'}
                  </p>
                </div>
              </div>
            );
          }}
        />
      ) : (
        <div className={classes.noProfiles}>No Profiles Found</div>
      )}
      <AphDialog open={showNewProfilePopup} maxWidth="sm">
        <AphDialogClose
          onClick={() => {
            setNewUserProfile(defaultNewUserData);
            setShowNewProfilePopup(false);
          }}
          title={'Close'}
        />
        <AphDialogTitle>Add new member</AphDialogTitle>
        <AlertToast
          alertMessage={profileAdditionError}
          setAlertMessage={setProfileAdditionError}
        />
        <div className={classes.addNewMember}>
          <div className={`${classes.formGroup} ${classes.popupField}`}>
            <label>First Name</label>
            <TextField
              className={classes.textField}
              placeholder="First Name"
              onChange={(e: any) => {
                handleChangeNewProfile(e, 'firstName');
              }}
              value={newUserProfile.firstName}
            />
          </div>

          <div className={`${classes.formGroup} ${classes.popupField}`}>
            <label>Last Name</label>
            <TextField
              className={classes.textField}
              placeholder="Last Name"
              value={newUserProfile.lastName}
              onChange={(e: any) => {
                handleChangeNewProfile(e, 'lastName');
              }}
            />
          </div>

          <div className={`${classes.formGroup} ${classes.popupField}`}>
            <Grid container className={classes.gridCont}>
              <Grid
                item
                sm={6}
                className={`${classes.gridWidth} ${classes.dateGrid}`}
              >
                <label>Date of Birth</label>
                <KeyboardDatePicker
                  format="yyyy-MM-dd"
                  margin="normal"
                  id="date-picker-inline"
                  value={newUserProfile.dateOfBirth}
                  onChange={handleSelectedDOBChange}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  rightArrowButtonProps={{ color: 'primary' }}
                  leftArrowButtonProps={{ color: 'primary' }}
                  autoOk={true}
                  InputProps={{ disabled: true }}
                  maxDate={new Date()}
                />
              </Grid>
              <Grid
                item
                sm={6}
                className={`${classes.gridWidth} ${classes.genderGrid}`}
              >
                <label>Gender</label>

                <Select
                  className={classes.selectField}
                  value={newUserProfile.gender}
                  onChange={(e: any) => {
                    handleChangeNewProfile(e, 'gender');
                  }}
                  MenuProps={{
                    classes: {
                      paper: classes.menuPopover,
                    },
                    anchorOrigin: {
                      vertical: 'top',
                      horizontal: 'right',
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'right',
                    },
                  }}
                >
                  {GENDER_LIST &&
                    GENDER_LIST.map((gender, idx) => {
                      return (
                        <MenuItem
                          value={gender}
                          classes={{
                            selected: classes.menuSelected,
                          }}
                          key={`gender-item` + idx}
                        >
                          {gender}
                        </MenuItem>
                      );
                    })}
                </Select>
              </Grid>
            </Grid>
          </div>

          {detailsFetchedFlag && userFound && (
            <>
              <div className={`${classes.formGroup} ${classes.popupField}`}>
                <label>Relation</label>
                <Select
                  className={classes.selectField}
                  MenuProps={{
                    classes: {
                      paper: classes.menuPopover,
                    },
                    anchorOrigin: {
                      vertical: 'top',
                      horizontal: 'right',
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'right',
                    },
                  }}
                  value={newUserProfile.relation}
                  onChange={(e: any) => {
                    handleChangeNewProfile(e, 'relation');
                  }}
                >
                  {RELATION_LIST &&
                    RELATION_LIST.map((rel, idx) => {
                      return (
                        <MenuItem
                          value={rel}
                          classes={{
                            selected: classes.menuSelected,
                          }}
                          key={'relation-item-' + idx}
                        >
                          {rel}
                        </MenuItem>
                      );
                    })}
                </Select>
                {isRelationError && (
                  <FormHelperText className={classes.errorMsg} component="span">
                    Relation can be set as Me for only 1 profile
                  </FormHelperText>
                )}
              </div>

              <div className={`${classes.formGroup} ${classes.popupField}`}>
                <label>Email id (Optional)</label>
                <TextField
                  className={classes.textField}
                  placeholder="Email id"
                  value={newUserProfile.emailAddress}
                  onChange={(e: any) => {
                    setIsMailIdValid(isEmailValid(e.target.value));
                    handleChangeNewProfile(e, 'emailAddress');
                  }}
                />
                {newUserProfile.emailAddress !== '' && !isMailIdValid && (
                  <FormHelperText className={classes.errorMsg} component="div">
                    Invalid email id
                  </FormHelperText>
                )}
              </div>
            </>
          )}

          <div className={classes.imInfo}>
            Important info: You will not be able to edit these details once you
            have saved them.
          </div>

          <div className={classes.submitBtn}>
            <Button
              className={classes.yellowBtn}
              disabled={loadingSaveProfile}
              onClick={() => {
                setShowNewProfilePopup(false);
                setNewUserProfile(defaultNewUserData);
              }}
            >
              CANCEL
            </Button>
            <Button
              className={classes.yellowBtn}
              disabled={!getNewUserSaveCondition() || loadingSaveProfile}
              style={{
                opacity:
                  !getNewUserSaveCondition() || loadingSaveProfile ? 0.5 : 1,
              }}
              onClick={() => {
                if (getNewUserSaveCondition()) {
                  if (detailsFetchedFlag && !userFound) {
                    saveProfile();
                  } else {
                    !!guestProfile ? updateGuestProfile() : addNewProfile();
                  }
                }
              }}
            >
              {!loadingSaveProfile ? (
                'ADD PROFILE'
              ) : (
                <div className={classes.loader}>
                  <CircularProgress size={22} />
                </div>
              )}
            </Button>
          </div>
        </div>
      </AphDialog>
    </>
  );
};
