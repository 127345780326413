import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import makeStyles from '@material-ui/styles/makeStyles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import {
  createMappedDataForInclusions,
  DiagnosticCartItem,
  getActiveInclusions,
  getObservationCount,
  getTestsCirclePrice,
  getWebTdpUrl,
  PatientObjWithLineItems,
  toTitleCase,
} from './helpers/commonHelpers';
import { useOrderContext } from '../OrderProvider';
import {
  CDN_URL,
  DIAGNOSTICS_GROUPPLAN,
  GET_DIAG_PACKAGE_RECOMMENDATIONS,
  GET_REPORT_TAT,
} from '../appConfig';
import AphButton from './AphButton';
import AphDialog from './AphDialog';
import AphDialogTitle from './AphDialogTitle';
import AphDialogClose from './AphDialogClose';
import { GetDiagnosticPackageRecommendationsBulk_getDiagnosticPackageRecommendationsBulk_packageRecommendationsBulk_packageRecommendations as DiagnosticPackageRecommendation } from '../types/GetDiagnosticPackageRecommendationsBulk';
import { TagText } from './TagText';

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      background: '#E8FAFF',
      border: '1px solid #D4D4D4',
      borderRadius: 8,
      marginBottom: 12,
    },
    card: {
      padding: 12,
    },
    banner: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      height: 38,
      borderRadius: '5px 5px 0px 0px',
      background: '#007C9D',
      marginBottom: 5,
      '& > h2': {
        fontSize: 14,
        fontWeight: 700,
        padding: 10,
        margin: 0,
        color: '#fff',
      },
    },
    packageInfoWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      marginBottom: 10,
    },
    packageTitle: {
      fontSize: 16,
      fontWeight: 600,
      color: '#02475B',
      '& a': {
        textDecoration: 'none',
        color: 'inherit',
        outline: 0,
      },
    },
    packageParams: {
      fontSize: 10,
      fontWeight: 600,
      color: '#02475B',
      display: 'block',
      paddingTop: 6,
      width: '80%',
    },
    priceGroup: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
      justifyContent: 'flex-end',
      gap: 4,
      position: 'relative',
      '& div > span': {
        marginRight: 4,
        '&:last-of-type': {
          marginRight: 0,
        },
      },
      '& p': {
        margin: 0,
        fontSize: 14,
        fontWeight: 700,
      },
    },
    price: {
      fontSize: '14px !important',
      lineHeight: '14px',
      fontWeight: 700,
      color: '#02475B',
      whiteSpace: 'nowrap',
    },
    inclusionCount: {
      color: '#02475B',
      fontWeight: 600,
      lineHeight: '16px',
      fontSize: 12,
      margin: '6px 0px 0px 0px',
    },
    inclusionList: {
      background: '#fff',
      border: '1px solid #D4D4D4',
      borderRadius: 10,
      maxHeight: 300,
      overflow: 'scroll',
    },
    inclusionHeading: {
      fontSize: 12,
      fontWeight: 600,
      color: '#007C9D',
      lineHeight: '16px',
      letterSpacing: '0.25px',
      display: 'flex',
      alignItems: 'flexStart',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      gap: 8,
      maxWidth: 200,
    },
    inclusionRoot: {
      minHeight: 40,
      padding: '0px 8px !important',
      '&.MuiAccordionSummary-expandIcon': {
        marginRight: '0px !important',
        minHeight: 'auto !important',
        padding: 0,
      },
    },
    disableinclusionRoot: {
      minHeight: 40,
      cursor: 'default !important',
    },
    inclusionRootExpanded: {
      margin: '0px !important',
    },
    inclusionExpanded: {
      margin: '0px !important',
      minHeight: '40px !important',
      alignItems: 'center',
      '&.MuiAccordionSummary-expandIcon': {
        marginRight: '0px !important',
        minHeight: 'auto !important',
        padding: 0,
      },
    },
    inclusionDetails: {
      padding: '0px 20px 0px 20px',
    },
    observationCount: {
      fontSize: 10,
      fontWeight: 400,
      color: '#02475B',
      opacity: 0.8,
      flexBasis: '100%',
    },
    observationItem: {
      fontSize: 12,
      lineHeight: '16px',
      fontWeight: 400,
      color: '#02475B',
      paddingBottom: '8px',
      '& > ul': {
        listStyleType: 'disc',
        paddingTop: 6,
        paddingInlineStart: 25,
        '& li': {
          paddingBottom: 6,
          '&:last-of-type': {
            padding: 0,
          },
        },
      },
    },
    observationListContainer: {
      margin: '8px 0px',
      paddingInlineStart: 30,
    },
    packageReportInfoWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: 4,
      flexWrap: 'wrap',
      gap: 10,
    },
    packageReportTime: {
      display: 'flex',
      alignItems: 'center',
      gap: 4,
      '& span': {
        fontWeight: 500,
        fontSize: 10,
        color: '#01475B',
        opacity: 0.6,
      },
    },
    packageTestPreparation: {
      display: 'flex',
      alignItems: 'center',
      gap: 4,
      '& span': {
        fontWeight: 500,
        fontSize: 10,
        color: '#01475B',
        opacity: 0.6,
      },
    },
    selectpackageButtonWrapper: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: 16,
    },
    selectPackageButton: {
      background: '#FFFFFF',
      border: '1px solid #FC8416',
      borderRadius: 10,
      fontWeight: 700,
      textAlign: 'center',
      color: '#FC9916',
      padding: '4px 8px',
      lineHeight: '24px',
      width: 300,
      boxShadow: 'unset',
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
      '&:hover': {
        background: '#FEFEFE',
        border: '1px solid #FC8416',
      },
    },
    accodionRoot: {
      '& button': {
        pointer: 'none',
      },
    },
    slashPrice: {
      fontSize: 12,
      lineHeight: '16px',
      fontWeight: 600,
      opacity: 0.8,
      textDecoration: 'line-through',
      color: '#02475B',
    },
    savingsText: {
      fontSize: 10,
      lineHeight: '14px',
      fontWeight: 600,
      opacity: 0.87,
      color: '#02475B',
      marginRight: 4,
    },
    cashBackContainer: {
      display: 'flex',
      alignItems: 'center',
      background: '#F3F3F3',
      borderRadius: 4,
      padding: '4px 8px',
      fontSize: 10,
      fontWeight: 500,
      marginTop: 4,
      color: '#02475B',
      '& img': {
        height: 12,
        marginRight: 5,
      },
      '& div': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        minHeight: 'auto',
        '& span': {
          whiteSpace: 'nowrap',
          '&.cashback': {
            color: '#0087BA',
          },
          '&.showPlus:before': {
            color: '#0087BA',
            content: "'+'",
            marginRight: '3px',
          },
          '&.showExtra:before': {
            color: '#0087BA',
            content: "'Extra'",
            marginRight: '3px',
          },
        },
      },
    },
    percentDisc: {
      background: '#00B38E',
      color: '#fff',
      borderRadius: 60,
      padding: '1px 7px',
      lineHeight: '13px',
      fontSize: 10,
      fontWeight: 600,
      whiteSpace: 'nowrap',
    },
    recommPkgBtnContainer: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: 16,
    },
    recommPkgBtn: {
      color: '#FC9916',
      boxShadow: 'none',
      borderRadius: 10,
      fontSize: 13,
      height: 32,
      '&:hover': {
        backgroundColor: '#FAFAFA',
      },
    },
    recommendedRibbon: {
      padding: '0px 12px 14px',
    },
    showPackageRecommBtn: {
      width: '100%',
      display: 'flex',
      boxShadow: 'none',
      justifyContent: 'space-between',
      alignItems: 'center',
      fontWeight: 600,
      fontSize: 13,
      color: '#FFFFFF',
      borderRadius: '4px',
      background: '#007C9D !important',
      padding: '0px 12px',
      height: '32px',
      textTransform: 'capitalize',
      '& img': {
        width: 26,
        height: 20,
        transform: 'rotate(90deg)',
        '&.invert': {
          transform: 'rotate(-90deg)',
        },
      },
    },
    packageRecommContainer: {
      display: 'flex',
      padding: '16px 10px',
      flexDirection: 'column',
      backgroundColor: '#E8FAFF',
      borderRadius: '0px 0px 10px 10px',
      '&>div': {
        display: 'flex',
        '&:first-child': {
          paddingBottom: 10,
          justifyContent: 'space-between',
          alignItems: 'center',
          '&>span': {
            fontSize: 14,
            '&:first-child': {
              fontWeight: 600,
              flexBasis: '55%',
              [theme.breakpoints.down('xs')]: {
                marginBottom: 10,
              },
            },
            '&:last-child': {
              fontWeight: 700,
              display: 'flex',
              alignItems: 'center',
              '& em': {
                fontSize: '10px',
                opacity: 0.6,
                textDecoration: 'line-through',
                paddingRight: 4,
                fontStyle: 'normal',
              },
            },
          },
          [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            alignItems: 'flex-start',
          },
        },
        '&:last-child': {
          flexDirection: 'column',
          alignItems: 'flex-start',
          fontSize: 12,
          fontWeight: 400,
          '&>span': {
            background: '#fff',
            padding: '4px 12px',
            borderRadius: '4px',
            fontSize: '12px',
            fontWeight: 600,
          },
          '& ul': {
            padding: '12px 0px 0px 0px',
            margin: 0,
            '& li': {
              listStyleType: 'none',

              fontWeight: 500,
              padding: 0,
              borderBottom: 'none',
              textTransform: 'capitalize',
              display: 'inline-block',
              paddingRight: '24px',
              paddingBottom: '8px',
              '& img': {
                verticalAlign: 'middle',
                marginRight: 5,
              },
              [theme.breakpoints.down('xs')]: {
                display: 'flex',
                justifyContent: 'flex-start',
              },
            },
          },
        },
      },
    },
    addBtn: {
      background: '#FFFFFF',
      color: '#FC9916',
      border: '1px solid #FC9916',
      boxShadow: 'none',
      borderRadius: 10,
      fontSize: 13,

      height: 32,
      marginLeft: 10,
      '&:hover': {
        backgroundColor: '#FAFAFA',
      },
    },
    additionalTest: {
      padding: '2px 3px',
    },
    missingTest: {
      fontWeight: 400,
      fontSize: 12,
      color: ' #02475b',
      opacity: 0.8,
      textDecoration: 'line-through',
      paddingLeft: 4,
    },
    inclusionContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
    },
    incCount: {
      fontWeight: 500,
      fontSize: 12,
      color: '#0087BA',
    },
    selectHeaderPackageButton: {
      padding: '8px 16px',
      height: 30,
      marginRight: 8,
    },
    totalCount: {
      fontWeight: 600,
      fontSize: '12px',
      lineHeight: '16px',
      letterSpacing: '0.25px',
      color: '#02475B',
      marginBottom: 4,
    },
    tagContain: {
      width: 'max-content',
    },
  };
});

export default function PackageRecommendation() {
  const classes = useStyles();
  const {
    diagnosticCartItems,
    selectedProfile,
    selectedAddress,
    serviceabilityData,
    isUserCare,
    circleMemberShipPlan,
    patientLineItems,
    couponApplied,
    circleBenefitsApplied,
    setPatientLineItems,
    setDiagnosticCartItems,
    setDeletedPatientLineItems,
    setDeletedDiagnosticCartItems,
    setAddedDiagnosticCartPackageItemId,
    orderSource,
  } = useOrderContext();
  const [expanded, setExpanded] = useState<string>('');
  const [packageRecommendation, setPackageRecommendation] =
    useState<DiagnosticPackageRecommendation>(null);
  const [packageRecommendations, setPackageRecommendations] = useState<
    DiagnosticPackageRecommendation[]
  >([]);
  const [openViewMorePkgRecomm, setOpenViewMorePkgRecomm] =
    useState<boolean>(false);

  const handleChange = (panel: string) => (event: any, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : '');
  };

  const selectedPatientLineItems = patientLineItems?.find(
    (item) => item?.patientID === selectedProfile?.id
  );
  const selectedpatientLineItemIds = selectedPatientLineItems?.lineItems?.map(
    (lineItem) => lineItem?.itemId
  );

  const selectedpatientLineItemIdString =
    selectedpatientLineItemIds?.toString();

  useEffect(() => {
    if (selectedProfile?.id) {
      const selectedPatientLineItems = patientLineItems?.find(
        (item) => item?.patientID === selectedProfile?.id
      );
      if (selectedPatientLineItems?.lineItems?.length > 0) {
        let itemObjects = selectedPatientLineItems?.lineItems?.map((item) => {
          let cartItem = diagnosticCartItems?.find(
            (diagnosticCartItem) => diagnosticCartItem?.itemId === item?.itemId
          );
          const itemType =
            cartItem?.inclusions?.length > 1 ? 'PACKAGE' : 'LABTEST';
          return {
            itemId: item?.itemId,
            itemType: itemType,
          };
        });

        axios
          .post(`${GET_DIAG_PACKAGE_RECOMMENDATIONS}`, {
            recommendationInputItems: itemObjects,
            cityId: serviceabilityData.cityID,
            genderFilters: [selectedProfile.gender],
            groupPlan: isUserCare
              ? DIAGNOSTICS_GROUPPLAN.circle
              : DIAGNOSTICS_GROUPPLAN.all,
          })
          .then(({ data }) => {
            if (data?.packageRecommendations?.length > 0) {
              const packageRecommendations = data?.packageRecommendations;
              const packageItemIds = data?.packageRecommendations?.map(
                (recommPackage) => recommPackage?.itemId
              );
              axios
                .post(`${GET_REPORT_TAT}`, {
                  itemIds: packageItemIds,
                  cityId: serviceabilityData.cityID,
                  pincode: Number(localStorage.getItem('currentZip')),
                  latitude: selectedAddress?.latitude || null,
                  longitude: selectedAddress?.longitude || null,
                  bookingSource: orderSource,
                })
                .then(({ data }) => {
                  if (data) {
                    const modifiedPackageRecommendations =
                      packageRecommendations?.map((recommPackage) => {
                        return {
                          ...recommPackage,
                          reportTAT: data?.preOrderReportTATMessage,
                        };
                      });
                    setPackageRecommendation(
                      modifiedPackageRecommendations?.[0]
                    );
                    setPackageRecommendations(modifiedPackageRecommendations);
                  }
                });
            } else {
              setPackageRecommendation(null);
            }
          });
      } else {
        setPackageRecommendation(null);
      }
    }
  }, [selectedpatientLineItemIdString, selectedProfile, serviceabilityData]);

  const getIncludedTest = (
    packageRecommendation: DiagnosticPackageRecommendation
  ) => {
    const patientCartItems = selectedPatientLineItems?.lineItems?.map(
      (item) => {
        return diagnosticCartItems?.find(
          (cartLineItems) =>
            item?.itemId?.toString() === cartLineItems?.itemId?.toString()
        );
      }
    );

    const packageInclusions = packageRecommendation?.diagnosticInclusions;
    const includedInclusions = [];
    packageInclusions?.forEach((packageInclusion) => {
      const existCartPackage = patientCartItems?.find(
        (cartItem) =>
          cartItem?.inclusionIds?.includes(packageInclusion?.itemId) ||
          cartItem?.inclusions?.some(
            (inc) =>
              inc?.toLowerCase() === packageInclusion?.name?.toLowerCase()
          )
      );
      if (
        existCartPackage &&
        !includedInclusions?.find(
          (inclusion) => inclusion?.name === existCartPackage?.itemName
        )
      ) {
        includedInclusions.push({
          name: existCartPackage?.itemName,
          id: existCartPackage?.itemId,
        });
      } else {
        const existCartItem = patientCartItems?.find(
          (cartItem) => Number(cartItem?.itemId) === packageInclusion?.itemId
        );
        if (existCartItem) {
          includedInclusions.push({
            name: packageInclusion?.name,
            id: packageInclusion?.itemId,
          });
        }
      }
    });
    return includedInclusions;
  };
  const handleAddRecomm = (packageRecommendation) => {
    // clear selected cart items before adding pacakge
    const itemsIdsToRemove = getIncludedTest(packageRecommendation)?.map(
      (item) => item?.id
    );
    const deletedPatientLineItems = selectedPatientLineItems?.lineItems?.filter(
      (lineItem) => itemsIdsToRemove?.includes(lineItem?.itemId)
    );

    let updatedLinesItem = patientLineItems?.map((patientLineItems) => {
      if (patientLineItems?.patientID === selectedProfile.id) {
        const newPatientLineItems = patientLineItems?.lineItems?.filter(
          (lineItem) => !itemsIdsToRemove.includes(lineItem?.itemId)
        );
        return {
          ...patientLineItems,
          lineItems: newPatientLineItems,
          totalPrice: newPatientLineItems?.reduce(
            (sum, cur) => sum + cur?.price,
            0
          ),
        };
      }
      return patientLineItems;
    });

    setDeletedPatientLineItems({
      ...selectedPatientLineItems,
      lineItems: deletedPatientLineItems,
    });

    let newPackageItem: DiagnosticCartItem = {
      diagnosticPricing: packageRecommendation?.diagnosticPricing,
      inclusions: packageRecommendation?.diagnosticInclusions?.map(
        (diagnosticInclusion) => diagnosticInclusion?.name
      ),
      itemId: packageRecommendation?.itemId,
      itemName: packageRecommendation?.itemName,
      itemType: packageRecommendation?.itemType,
      patientId: selectedProfile?.id,
      reportTAT: packageRecommendation?.reportTAT,
      gender: packageRecommendation?.gender,
      testParametersCount:
        getPackageObservationsCount(packageRecommendation) || 1,
      canonicalTag: packageRecommendation?.canonicalTag,
      diagnosticInclusions: packageRecommendation?.diagnosticInclusions,
    };
    let filterSelectedPatientsTestItems = diagnosticCartItems?.filter(
      (diagnosticCartItem) =>
        !itemsIdsToRemove.includes(diagnosticCartItem?.itemId)
    );
    let deletedDiagnosticCartItems = diagnosticCartItems?.filter(
      (diagnosticCartItem) =>
        itemsIdsToRemove.includes(diagnosticCartItem?.itemId)
    );
    setDeletedDiagnosticCartItems(deletedDiagnosticCartItems);
    setAddedDiagnosticCartPackageItemId(newPackageItem?.itemId);
    let updatedItems = [...filterSelectedPatientsTestItems, newPackageItem];
    setDiagnosticCartItems(updatedItems);

    let patientItemsObj: PatientObjWithLineItems[] = [...updatedLinesItem];
    let currentPatientIdx = patientItemsObj.findIndex(
      (patientItem) => patientItem.patientID === selectedProfile?.id
    );
    const { mrp, price, priceBeforeDiscount, groupPlan, cashbackAmount } =
      getPackagePricing(packageRecommendation) ?? {};

    const lineItem = {
      itemName: packageRecommendation?.itemName,
      priceBeforeDiscount,
      mrp,
      price,
      groupPlan,
      itemId: packageRecommendation?.itemId,
      couponDiscAmount: 0,
      isCouponOnMrp: false,
      groupPlanDiscAmount: 0,
      reportTAT: packageRecommendation?.reportTAT,
      circleCashback: cashbackAmount,
      testParametersCount: getPackageObservationsCount(packageRecommendation),
    };
    if (currentPatientIdx > -1) {
      patientItemsObj[currentPatientIdx]?.lineItems.push(lineItem);
      patientItemsObj[currentPatientIdx].totalPrice += price;
    } else {
      const newPatientObj: PatientObjWithLineItems = {
        patientID: selectedProfile?.id || '',
        lineItems: [lineItem],
        totalPrice: price,
      };
      patientItemsObj.push(newPatientObj);
    }
    setPatientLineItems(patientItemsObj);
    setPackageRecommendation(null);
    setOpenViewMorePkgRecomm(false);
  };

  const getExcludedTest = (
    packageRecommendation: DiagnosticPackageRecommendation
  ) => {
    const patientCartItems = selectedPatientLineItems?.lineItems?.map(
      (item) => {
        return diagnosticCartItems?.find(
          (cartLineItems) =>
            item?.itemId?.toString() === cartLineItems?.itemId?.toString()
        );
      }
    );

    const checkLinePackageInclusionIdsExistInRecommPkg = (
      inclusionIds: number[]
    ) => {
      return inclusionIds?.every((inclusion) =>
        packageRecommendation?.diagnosticInclusions?.find(
          (packageInclusion) => packageInclusion?.itemId === inclusion
        )
      );
    };

    return patientCartItems?.filter((cartItem) => {
      if (cartItem?.itemType === 'PACKAGE') {
        return !checkLinePackageInclusionIdsExistInRecommPkg(
          cartItem?.inclusionIds
        );
      }
      return !packageRecommendation?.diagnosticInclusions?.find(
        (packageInclusion) => packageInclusion?.itemId === cartItem?.itemId
      );
    });
  };

  const getPackageObservationsCount = (
    packageRecommendation: DiagnosticPackageRecommendation
  ) => {
    return getObservationCount(packageRecommendation?.diagnosticInclusions);
  };

  const getAllTests = (packageRecommendation) => {
    const includedItems = [];
    const additionalItems = [];
    const patientCartItems = selectedPatientLineItems?.lineItems?.map(
      (item) => {
        return diagnosticCartItems?.find(
          (cartLineItems) =>
            item?.itemId?.toString() === cartLineItems?.itemId?.toString()
        );
      }
    );

    packageRecommendation?.diagnosticInclusions?.forEach((inclusion) => {
      const isItemIncludedInLineItems = selectedpatientLineItemIds?.includes(
        inclusion?.itemId
      );

      const isIncludedInLinePackage = patientCartItems?.some((cartItem) => {
        return cartItem?.inclusionIds?.includes(inclusion?.itemId);
      });

      if (isItemIncludedInLineItems || isIncludedInLinePackage) {
        includedItems.push({
          ...inclusion,
          included: true,
          inclusionCount: getActiveInclusions(inclusion?.allObservations),
        });
      } else {
        additionalItems.push({
          ...inclusion,
          included: false,
          inclusionCount: getActiveInclusions(inclusion?.allObservations),
        });
      }
    });
    return [...includedItems, ...additionalItems];
  };

  const getPackagePricing = (
    packageRecommendation: DiagnosticPackageRecommendation
  ) => {
    return getTestsCirclePrice(
      packageRecommendation?.diagnosticPricing ?? [],
      isUserCare || !!circleMemberShipPlan
    );
  };

  const {
    mrp,
    price,
    priceBeforeDiscount,
    groupPlan,
    showDiscountPercent,
    cashbackText,
  } = getPackagePricing(packageRecommendation) ?? {};
  const circleApplied =
    (isUserCare || !!circleMemberShipPlan) &&
    groupPlan === DIAGNOSTICS_GROUPPLAN.circle;

  const showCashback =
    (isUserCare || !!circleMemberShipPlan) &&
    (!couponApplied || (couponApplied && circleBenefitsApplied)) &&
    getPackagePricing(packageRecommendation).cashbackText?.length > 0;

  const totalDiscount = mrp - price;
  const totalDiscountPercent = Math.round((totalDiscount / mrp) * 100);
  const allInclusions = getAllTests(packageRecommendation);
  const getInclusionCount = getObservationCount(
    packageRecommendation?.diagnosticInclusions
  );
  return (
    <>
      {patientLineItems.length !== 0 &&
        packageRecommendation &&
        !selectedpatientLineItemIds?.includes(
          packageRecommendation?.itemId
        ) && (
          <div className={classes.root}>
            <div className={classes.banner}>
              <h2>{packageRecommendation?.packageHeader}</h2>
            </div>
            <div className={classes.card}>
              <div className={classes.packageInfoWrapper}>
                <div>
                  <span className={classes.packageTitle}>
                    <a
                      href={getWebTdpUrl(packageRecommendation?.canonicalTag)}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {toTitleCase(packageRecommendation?.itemName)}
                    </a>
                  </span>
                  <div className={classes.packageReportInfoWrapper}>
                    {packageRecommendation?.reportTAT && (
                      <div className={classes.packageReportTime}>
                        <img src={require('../images/clock.svg')} alt="" />
                        <span>{packageRecommendation?.reportTAT}</span>
                      </div>
                    )}

                    {packageRecommendation?.testPreparationData && (
                      <div className={classes.packageTestPreparation}>
                        <img src={require('../images/caution.svg')} alt="" />
                        <span>
                          {packageRecommendation?.testPreparationData}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
                <div className={classes.priceGroup}>
                  <p>
                    {totalDiscount > 0 && !showDiscountPercent && (
                      <span
                        className={classes.savingsText}
                      >{`save ₹${totalDiscount}`}</span>
                    )}
                    ₹{priceBeforeDiscount!}
                  </p>
                  <div>
                    {totalDiscountPercent > 0 && showDiscountPercent && (
                      <span
                        className={classes.percentDisc}
                      >{`${totalDiscountPercent}% off`}</span>
                    )}
                    {totalDiscount > 0 && mrp !== priceBeforeDiscount && (
                      <span className={classes.slashPrice}>₹{mrp}</span>
                    )}
                  </div>

                  {(circleApplied || showCashback) && (
                    <div className={classes.cashBackContainer}>
                      <img
                        src={`${CDN_URL}/images/circle_logo.png`}
                        alt="circle logo"
                      />
                      <div>
                        <span>{circleApplied ? 'Discount Applied' : ''}</span>
                        {showCashback && (
                          <span
                            className={`cashback ${
                              circleApplied ? 'showPlus' : 'showExtra'
                            }`}
                          >{`${cashbackText}`}</span>
                        )}
                      </div>
                    </div>
                  )}
                  {packageRecommendation?.packageWorthText && (
                    <div className={classes.tagContain}>
                      <TagText
                        text={packageRecommendation.packageWorthText}
                        lightBackground={true}
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className={classes.totalCount}>
                TOTAL TESTS : {getInclusionCount}
              </div>
              <div className={classes.inclusionList}>
                {allInclusions?.map((inclusion: any, idx: number) => {
                  const observationCount = getActiveInclusions(
                    inclusion?.allObservations
                  );
                  const manipulatedObservationData =
                    createMappedDataForInclusions(inclusion?.allObservations);

                  return (
                    <Accordion
                      key={idx}
                      classes={{
                        expanded: classes.inclusionRootExpanded,
                      }}
                      expanded={expanded === `${idx}`}
                      onChange={handleChange(`${idx}`)}
                    >
                      <AccordionSummary
                        expandIcon={
                          observationCount > 0 && (
                            <img
                              src={`${CDN_URL}/images/ic_accordion_down.svg`}
                              alt=""
                            />
                          )
                        }
                        classes={{
                          root:
                            observationCount === 0
                              ? classes.disableinclusionRoot
                              : classes.inclusionRoot,
                          expanded: classes.inclusionExpanded,
                        }}
                      >
                        <div className={classes.inclusionContainer}>
                          <span className={classes.inclusionHeading}>
                            <img
                              width={16}
                              height={16}
                              src={`${
                                inclusion?.included
                                  ? require('../images/ic_check.svg')
                                  : require('../images/ic_add.svg')
                              }`}
                              alt="tick"
                            />
                            {toTitleCase(inclusion?.name)}
                          </span>
                          <span className={classes.incCount}>
                            {inclusion?.inclusionCount} Test
                            {inclusion?.inclusionCount > 1 && 's'}
                          </span>
                        </div>
                      </AccordionSummary>
                      {observationCount > 0 && (
                        <AccordionDetails
                          classes={{
                            root: classes.inclusionDetails,
                          }}
                        >
                          <div>
                            <span className={classes.observationCount}>
                              {observationCount} Test
                              {observationCount > 1 && 's'} included
                            </span>
                            <ul className={classes.observationListContainer}>
                              {manipulatedObservationData?.map(
                                (observation, observationIndex) => {
                                  const isNonMandatoryInclusion =
                                    observation?.mandatoryValue === '0';
                                  const hasSubObservations =
                                    observation?.hasOwnProperty('sub');
                                  return (
                                    <li
                                      key={'param' + observationIndex}
                                      className={classes.observationItem}
                                    >
                                      {toTitleCase(
                                        observation?.observationName
                                      )}
                                      {isNonMandatoryInclusion && '*'}
                                      {hasSubObservations && (
                                        <ul>
                                          {observation?.sub?.map(
                                            (item, index) => {
                                              const isNonMandatoryObs =
                                                item?.mandatoryValue === '0';
                                              return (
                                                <li key={`subParam-${index}`}>
                                                  {item?.observationName}
                                                  {isNonMandatoryObs && '*'}
                                                </li>
                                              );
                                            }
                                          )}
                                        </ul>
                                      )}
                                    </li>
                                  );
                                }
                              )}
                            </ul>
                          </div>
                        </AccordionDetails>
                      )}
                    </Accordion>
                  );
                })}
                {getExcludedTest(packageRecommendation)?.map(
                  (exclusionCartItem: DiagnosticCartItem, idx: number) => {
                    const observationCount =
                      exclusionCartItem?.testParametersCount;
                    return (
                      <Accordion
                        key={idx}
                        classes={{
                          expanded: classes.inclusionRootExpanded,
                        }}
                        expanded={expanded === `excluded${idx}`}
                        onChange={handleChange(`excluded${idx}`)}
                      >
                        <AccordionSummary
                          expandIcon={
                            observationCount > 0 && (
                              <img
                                src={`${CDN_URL}/images/ic_accordion_down.svg`}
                                alt=""
                              />
                            )
                          }
                          classes={{
                            root:
                              observationCount === 0
                                ? classes.disableinclusionRoot
                                : classes.inclusionRoot,
                            expanded: classes.inclusionExpanded,
                          }}
                        >
                          <div className={classes.inclusionContainer}>
                            <span className={classes.inclusionHeading}>
                              <img
                                width={16}
                                height={16}
                                src={`${require('../images/ic_removed.svg')}`}
                                alt="tick"
                              />
                              {exclusionCartItem?.itemName}
                            </span>
                            <span className={classes.incCount}>
                              {observationCount} Test
                              {observationCount > 1 && 's'}
                            </span>
                          </div>
                        </AccordionSummary>
                        {observationCount > 0 && (
                          <AccordionDetails
                            classes={{
                              root: classes.inclusionDetails,
                            }}
                          >
                            <div>
                              <span className={classes.observationCount}>
                                {observationCount} Test
                                {observationCount > 1 && 's'} included
                              </span>
                              <ul className={classes.observationListContainer}>
                                {exclusionCartItem?.observations?.map(
                                  (observation, observationIndex) => {
                                    const isNonMandatoryInclusion =
                                      observation?.mandatoryValue === '0';
                                    const hasSubObservations =
                                      observation?.hasOwnProperty('sub');
                                    return (
                                      <li
                                        key={'param' + observationIndex}
                                        className={classes.observationItem}
                                      >
                                        {toTitleCase(
                                          observation?.observationName
                                        )}
                                        {isNonMandatoryInclusion && '*'}
                                        {hasSubObservations && (
                                          <ul>
                                            {observation?.sub?.map(
                                              (item, index) => {
                                                const isNonMandatoryObs =
                                                  item?.mandatoryValue === '0';
                                                return (
                                                  <li key={`subParam-${index}`}>
                                                    {item?.observationName}
                                                    {isNonMandatoryObs && '*'}
                                                  </li>
                                                );
                                              }
                                            )}
                                          </ul>
                                        )}
                                      </li>
                                    );
                                  }
                                )}
                              </ul>
                            </div>
                          </AccordionDetails>
                        )}
                      </Accordion>
                    );
                  }
                )}
              </div>
              <div className={classes.selectpackageButtonWrapper}>
                <AphButton
                  onClick={() => handleAddRecomm(packageRecommendation)}
                  className={classes.selectPackageButton}
                  color="primary"
                  variant="outlined"
                >
                  SELECT PACKAGE
                </AphButton>
              </div>
              {packageRecommendations?.length > 1 && (
                <div className={classes.recommPkgBtnContainer}>
                  <AphButton
                    onClick={() => {
                      setOpenViewMorePkgRecomm(true);
                    }}
                    className={classes.recommPkgBtn}
                  >
                    VIEW MORE RECOMMENDED PACKAGES
                  </AphButton>
                </div>
              )}
            </div>
          </div>
        )}
      <AphDialog open={openViewMorePkgRecomm} maxWidth="md">
        <AphDialogTitle>RECOMMENDED PACKAGES</AphDialogTitle>
        <AphDialogClose
          onClick={() => setOpenViewMorePkgRecomm(false)}
          title={'close'}
        />
        <div style={{ overflow: 'auto', maxHeight: '80vh', padding: 40 }}>
          {packageRecommendations?.map((packageRecommendation, index) => {
            const allInclusions = getAllTests(packageRecommendation);
            const getInclusionCount = getObservationCount(
              packageRecommendation?.diagnosticInclusions
            );
            const {
              mrp,
              price,
              priceBeforeDiscount,
              groupPlan,
              showDiscountPercent,
              cashbackText,
            } = getPackagePricing(packageRecommendation) ?? {};

            const circleApplied =
              (isUserCare || !!circleMemberShipPlan) &&
              groupPlan === DIAGNOSTICS_GROUPPLAN.circle;

            const showCashback =
              (isUserCare || !!circleMemberShipPlan) &&
              (!couponApplied || (couponApplied && circleBenefitsApplied)) &&
              cashbackText?.length > 0;

            const totalDiscount = (mrp - price)?.toFixed(2);
            const totalDiscountPercent = Math.round(
              (Number(totalDiscount) / mrp) * 100
            );
            return (
              <div key={index} className={classes.root}>
                <div className={classes.banner}>
                  <h2>{packageRecommendation?.packageHeader}</h2>
                  <AphButton
                    onClick={() => handleAddRecomm(packageRecommendation)}
                    className={classes.selectHeaderPackageButton}
                    color="primary"
                  >
                    SELECT
                  </AphButton>
                </div>
                <div className={classes.card}>
                  <div className={classes.packageInfoWrapper}>
                    <div>
                      <span className={classes.packageTitle}>
                        <a
                          href={getWebTdpUrl(
                            packageRecommendation?.canonicalTag
                          )}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {toTitleCase(packageRecommendation?.itemName)}
                        </a>
                      </span>
                      <div className={classes.packageReportInfoWrapper}>
                        {packageRecommendation?.reportTAT && (
                          <div className={classes.packageReportTime}>
                            <img src={require('../images/clock.svg')} alt="" />
                            <span>{packageRecommendation?.reportTAT}</span>
                          </div>
                        )}
                        {packageRecommendation?.testPreparationData && (
                          <div className={classes.packageTestPreparation}>
                            <img
                              src={require('../images/caution.svg')}
                              alt=""
                            />
                            <span>
                              {packageRecommendation?.testPreparationData}
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className={classes.priceGroup}>
                      <p>
                        {Number(totalDiscount) > 0 && !showDiscountPercent && (
                          <span
                            className={classes.savingsText}
                          >{`save ₹${totalDiscount}`}</span>
                        )}
                        ₹{priceBeforeDiscount!}
                      </p>
                      <div>
                        {totalDiscountPercent > 0 && showDiscountPercent && (
                          <span
                            className={classes.percentDisc}
                          >{`${totalDiscountPercent}% off`}</span>
                        )}
                        {mrp !== priceBeforeDiscount && (
                          <span className={classes.slashPrice}>₹{mrp}</span>
                        )}
                      </div>

                      {(circleApplied || showCashback) && (
                        <div className={classes.cashBackContainer}>
                          <img
                            src={`${CDN_URL}/images/circle_logo.png`}
                            alt="circle logo"
                          />
                          <div>
                            <span>
                              {circleApplied ? 'Discount Applied' : ''}
                            </span>
                            {showCashback && (
                              <span
                                className={`cashback ${
                                  circleApplied ? 'showPlus' : 'showExtra'
                                }`}
                              >{`${cashbackText}`}</span>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className={classes.totalCount}>
                    TOTAL TESTS : {getInclusionCount}
                  </div>
                  <div className={classes.inclusionList}>
                    {allInclusions?.map((inclusion: any, idx: number) => {
                      const observationCount = getActiveInclusions(
                        inclusion?.allObservations
                      );
                      const manipulatedObservationData =
                        createMappedDataForInclusions(
                          inclusion?.allObservations
                        );

                      return (
                        <Accordion
                          key={idx}
                          classes={{
                            expanded: classes.inclusionRootExpanded,
                          }}
                          expanded={expanded === `${idx}`}
                          onChange={handleChange(`${idx}`)}
                        >
                          <AccordionSummary
                            expandIcon={
                              observationCount > 0 && (
                                <img
                                  src={`${CDN_URL}/images/ic_accordion_down.svg`}
                                  alt=""
                                />
                              )
                            }
                            classes={{
                              root:
                                observationCount === 0
                                  ? classes.disableinclusionRoot
                                  : classes.inclusionRoot,
                              expanded: classes.inclusionExpanded,
                            }}
                          >
                            <div className={classes.inclusionContainer}>
                              <span className={classes.inclusionHeading}>
                                <img
                                  width={16}
                                  height={16}
                                  src={`${
                                    inclusion?.included
                                      ? require('../images/ic_check.svg')
                                      : require('../images/ic_add.svg')
                                  }`}
                                  alt="tick"
                                />
                                {toTitleCase(inclusion?.name)}
                              </span>
                              <span className={classes.incCount}>
                                {inclusion?.inclusionCount} Test
                                {inclusion?.inclusionCount > 1 && 's'}
                              </span>
                            </div>
                          </AccordionSummary>
                          {observationCount > 0 && (
                            <AccordionDetails
                              classes={{
                                root: classes.inclusionDetails,
                              }}
                            >
                              <div>
                                <span className={classes.observationCount}>
                                  {observationCount} Test
                                  {observationCount > 1 && 's'} included
                                </span>
                                <ul
                                  className={classes.observationListContainer}
                                >
                                  {manipulatedObservationData?.map(
                                    (observation, observationIndex) => {
                                      const isNonMandatoryInclusion =
                                        observation?.mandatoryValue === '0';
                                      const hasSubObservations =
                                        observation?.hasOwnProperty('sub');
                                      return (
                                        <li
                                          key={'param' + observationIndex}
                                          className={classes.observationItem}
                                        >
                                          {toTitleCase(
                                            observation?.observationName
                                          )}
                                          {isNonMandatoryInclusion && '*'}
                                          {hasSubObservations && (
                                            <ul>
                                              {observation?.sub?.map(
                                                (item, index) => {
                                                  const isNonMandatoryObs =
                                                    item?.mandatoryValue ===
                                                    '0';
                                                  return (
                                                    <li
                                                      key={`subParam-${index}`}
                                                    >
                                                      {item?.observationName}
                                                      {isNonMandatoryObs && '*'}
                                                    </li>
                                                  );
                                                }
                                              )}
                                            </ul>
                                          )}
                                        </li>
                                      );
                                    }
                                  )}
                                </ul>
                              </div>
                            </AccordionDetails>
                          )}
                        </Accordion>
                      );
                    })}
                    {getExcludedTest(packageRecommendation)?.map(
                      (exclusionCartItem: DiagnosticCartItem, idx: number) => {
                        const observationCount =
                          exclusionCartItem?.testParametersCount;
                        return (
                          <Accordion
                            key={idx}
                            classes={{
                              expanded: classes.inclusionRootExpanded,
                            }}
                            expanded={expanded === `excluded${idx}`}
                            onChange={handleChange(`excluded${idx}`)}
                          >
                            <AccordionSummary
                              expandIcon={
                                observationCount > 0 && (
                                  <img
                                    src={`${CDN_URL}/images/ic_accordion_down.svg`}
                                    alt=""
                                  />
                                )
                              }
                              classes={{
                                root:
                                  observationCount === 0
                                    ? classes.disableinclusionRoot
                                    : classes.inclusionRoot,
                                expanded: classes.inclusionExpanded,
                              }}
                            >
                              <div className={classes.inclusionContainer}>
                                <span className={classes.inclusionHeading}>
                                  <img
                                    width={16}
                                    height={16}
                                    src={`${require('../images/ic_removed.svg')}`}
                                    alt="tick"
                                  />
                                  {exclusionCartItem?.itemName}
                                </span>
                                <span className={classes.incCount}>
                                  {observationCount} Test
                                  {observationCount > 1 && 's'}
                                </span>
                              </div>
                            </AccordionSummary>
                            {observationCount > 0 && (
                              <AccordionDetails
                                classes={{
                                  root: classes.inclusionDetails,
                                }}
                              >
                                <div>
                                  <span className={classes.observationCount}>
                                    {observationCount} Test
                                    {observationCount > 1 && 's'} included
                                  </span>
                                  <ul
                                    className={classes.observationListContainer}
                                  >
                                    {exclusionCartItem?.observations?.map(
                                      (observation, observationIndex) => {
                                        const isNonMandatoryInclusion =
                                          observation?.mandatoryValue === '0';
                                        const hasSubObservations =
                                          observation?.hasOwnProperty('sub');
                                        return (
                                          <li
                                            key={'param' + observationIndex}
                                            className={classes.observationItem}
                                          >
                                            {toTitleCase(
                                              observation?.observationName
                                            )}
                                            {isNonMandatoryInclusion && '*'}
                                            {hasSubObservations && (
                                              <ul>
                                                {observation?.sub?.map(
                                                  (item, index) => {
                                                    const isNonMandatoryObs =
                                                      item?.mandatoryValue ===
                                                      '0';
                                                    return (
                                                      <li
                                                        key={`subParam-${index}`}
                                                      >
                                                        {item?.observationName}
                                                        {isNonMandatoryObs &&
                                                          '*'}
                                                      </li>
                                                    );
                                                  }
                                                )}
                                              </ul>
                                            )}
                                          </li>
                                        );
                                      }
                                    )}
                                  </ul>
                                </div>
                              </AccordionDetails>
                            )}
                          </Accordion>
                        );
                      }
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </AphDialog>
    </>
  );
}
