import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
  DiagnosticCartItem,
  getTestsCirclePrice,
  getWebTdpUrl,
  LineItemsObject,
  SearchResult,
} from './helpers/commonHelpers';
import { CDN_URL, DIAGNOSTICS_GROUPPLAN } from '../appConfig';
import { useOrderContext } from '../OrderProvider';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import { TagText } from './TagText';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listLHS: {
      flexBasis: '61%',
      '& a': {
        textDecoration: 'none',
        color: 'inherit',
        outline: 0,
        display: 'flex',
        alignItems: 'flex-start',
        gap: 8,
        '& p': {
          margin: 0,
          color: '#00B38E',
          fontSize: 14,
          fontWeight: 500,
          overflow: 'hidden',
          display: '-webkit-box',
          lineClamp: 2,
          boxOrient: 'vertical',
          paddingBottom: 0,
        },
      },
      '& span': {
        fontWeight: 400,
        fontSize: 12,
        color: ' #01475B',
        paddingLeft: 32,
      },
    },
    listRHS: {
      display: 'flex',
      textAlign: 'right',
      alignItems: 'flex-start',
      '&>div': {
        '&:first-child': {
          '& div:first-child>span': {
            marginRight: 4,
            '&:last-of-type': {
              marginRight: 0,
            },
          },
          '& p': {
            margin: 0,
            '&:nth-of-type(1)': {
              fontSize: 14,
              fontWeight: 'bold',
              margin: '3px 0px',
            },
          },
        },
        '&:last-child': {
          '& img': {
            cursor: 'pointer',
            position: 'relative',
            right: -4,
          },
        },
      },
    },
    cashBackContainer: {
      display: 'flex',
      alignItems: 'center',
      background: '#F3F3F3',
      borderRadius: 4,
      padding: '4px 8px',
      fontSize: 10,
      fontWeight: 500,
      marginTop: 4,
      '& img': {
        height: 12,
        marginRight: 5,
      },
      '& div': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        minHeight: 'auto',
        '& span': {
          whiteSpace: 'nowrap',
          '&.cashback': {
            color: '#0087BA',
          },
          '&.showPlus:before': {
            color: '#0087BA',
            content: "'+'",
            marginRight: '3px',
          },
          '&.showExtra:before': {
            color: '#0087BA',
            content: "'Extra'",
            marginRight: '3px',
          },
        },
      },
    },
    percentDisc: {
      background: '#00B38E',
      color: '#fff',
      borderRadius: 60,
      padding: '1px 7px',
      lineHeight: '13px',
      fontSize: 10,
      fontWeight: 600,
      whiteSpace: 'nowrap',
    },
    slashPrice: {
      fontSize: 10,
      lineHeight: '16px',
      fontWeight: 600,
      opacity: 0.8,
      textDecoration: 'line-through',
      color: '#02475B',
    },
    mrpLabel: {
      fontSize: 10,
      lineHeight: '16px',
      fontWeight: 600,
      opacity: 0.8,
      color: '#02475B',
    },
    savingsText: {
      fontSize: 10,
      lineHeight: '13px',
      fontWeight: 500,
      opacity: 0.87,
      color: '#02475B',
    },
    info: {
      position: 'absolute',
      bottom: '4px',
      color: 'red',
    },
    tagContain: {
      margin: '6px 0 0 32px',
      width: 'max-content',
    },
  })
);
interface SearchItemProps {
  item: SearchResult;
  shouldBlockItemAddition: boolean;
  isItemExistInCart: boolean;
  isGenderRestricted?: boolean;
  isAgeRestricted?: boolean;
}

export const SearchItem: React.FC<SearchItemProps> = (props) => {
  const classes = useStyles();
  const {
    isUserCare,
    circleMemberShipPlan,
    couponApplied,
    circleBenefitsApplied,
  } = useOrderContext();

  const {
    item,
    shouldBlockItemAddition,
    isItemExistInCart,
    isAgeRestricted,
    isGenderRestricted,
  } = props;

  const cartItem: DiagnosticCartItem = {
    diagnosticPricing: item?.diagnostic_item_price,
    itemId: item?.diagnostic_item_id,
    inclusions: item?.diagnostic_inclusions,
    itemName: item?.diagnostic_item_name,
    itemType: item?.diagnostic_item_itemType,
    patientId: '',
    reportTAT: '',
    testParametersCount: item?.testParametersCountWithHeaderLogic,
    canonicalTag: item?.diagnostic_item_canonicalTag,
    packageWorthText: item?.diagnostic_item_package_worth_text || '',
  };

  const diagnosticPricing = getTestsCirclePrice(
    cartItem?.diagnosticPricing,
    isUserCare || !!circleMemberShipPlan
  );

  const {
    cashbackText,
    showDiscountPercent,
    mrp,
    priceBeforeDiscount,
    price,
    cashbackAmount,
    groupPlan,
  } = diagnosticPricing;

  const lineItem: LineItemsObject = {
    itemName: cartItem.itemName,
    priceBeforeDiscount,
    mrp,
    price,
    groupPlan,
    itemId: cartItem.itemId,
    couponDiscAmount: 0,
    isCouponOnMrp: false,
    groupPlanDiscAmount: 0,
    reportTAT: '',
    circleCashback: cashbackAmount,
    testParametersCount: cartItem?.testParametersCount,
  };

  const renderCashbackSection = (lineItem: LineItemsObject) => {
    const circleApplied =
      (isUserCare || !!circleMemberShipPlan) &&
      lineItem?.groupPlan === DIAGNOSTICS_GROUPPLAN.circle;

    const showCashback =
      (isUserCare || !!circleMemberShipPlan) &&
      (!couponApplied || (couponApplied && circleBenefitsApplied)) &&
      cashbackText?.length > 0;

    return (
      <>
        {(circleApplied || showCashback) && (
          <div className={classes.cashBackContainer}>
            <img src={`${CDN_URL}/images/circle_logo.png`} alt="circle logo" />
            <div>
              <span>{circleApplied ? 'Discount Applied' : ''}</span>
              {showCashback && (
                <span
                  className={`cashback ${
                    circleApplied ? 'showPlus' : 'showExtra'
                  }`}
                >{`${cashbackText}`}</span>
              )}
            </div>
          </div>
        )}
      </>
    );
  };

  const totalDiscount = mrp - priceBeforeDiscount;

  const totalDiscountPercent = Math.round((totalDiscount / mrp) * 100);

  return (
    <>
      <div className={classes.listLHS}>
        <a
          href={getWebTdpUrl(cartItem?.canonicalTag)}
          target="_blank"
          rel="noopener noreferrer"
        >
          {cartItem?.inclusions?.length > 1 ? (
            <img src={require('../images/ic_package-item.svg')} alt="" />
          ) : (
            <img src={require('../images/ic_test-item.svg')} alt="" />
          )}
          <Tooltip title={cartItem.itemName}>
            <p>{cartItem?.itemName}</p>
          </Tooltip>
        </a>
        <span>{`${item?.testParametersCountWithHeaderLogic} Test${
          item?.testParametersCountWithHeaderLogic > 1 ? 's' : ''
        } included`}</span>
        {cartItem?.packageWorthText && (
          <div className={classes.tagContain}>
            <TagText text={cartItem.packageWorthText} />
          </div>
        )}
      </div>
      <div className={classes.listRHS}>
        <div>
          <div>
            {totalDiscountPercent > 0 && showDiscountPercent && (
              <span
                className={classes.percentDisc}
              >{`${totalDiscountPercent}% off`}</span>
            )}
            <span className={classes.mrpLabel}>MRP</span>
            {totalDiscount > 0 && mrp !== priceBeforeDiscount && (
              <span className={classes.slashPrice}>₹{mrp}</span>
            )}
          </div>
          <p>
            {totalDiscount > 0 && !showDiscountPercent && (
              <span
                className={classes.savingsText}
              >{`save ₹${totalDiscount}`}</span>
            )}
            ₹{priceBeforeDiscount!}
          </p>
          {renderCashbackSection(lineItem)}
        </div>
        {!isItemExistInCart && !shouldBlockItemAddition && (
          <Button>
            <img src={require('../images/ic_addyllow.png')} alt="" />
          </Button>
        )}
      </div>
      {(isAgeRestricted || isGenderRestricted) && (
        <span className={classes.info}>
          {`This ${
            item?.diagnostic_inclusions?.length > 1 ? 'package' : 'test'
          } is
          not available for the selected patient due to 
          ${isAgeRestricted ? 'age' : ''} ${
            isGenderRestricted ? 'gender' : ''
          }.`}
        </span>
      )}
    </>
  );
};
