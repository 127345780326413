import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { createMuiTheme } from '@material-ui/core';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ThemeProvider } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
import aphTheme from './aphTheme';
import { OktaAuth } from '@okta/okta-auth-js';
import { LoginCallback, Security } from '@okta/okta-react';
import Home from './Home';
import oktaConfig from './oktaConfig';
import { OrderProvider } from './OrderProvider';

const oktaAuth = new OktaAuth(oktaConfig.oidc);
const theme = createMuiTheme({ ...aphTheme });

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    app: {
      height: '100vh',
      background: '#E5E5E5',
    },
    container: {
      maxWidth: 1064,
      margin: '0 auto',
      height: '100%',
      background: '#fff',
      [theme.breakpoints.down(900)]: {
        width: 800,
      },
      [theme.breakpoints.down(768)]: {
        width: '100%',
      },
    },
  })
);
function App() {
  const classes = useStyles();
  return (
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <OrderProvider>
          <Router>
            <Security oktaAuth={oktaAuth}>
              <div className={classes.app}>
                <div className={classes.container}>
                  <Switch>
                    <Route path="/" exact component={Home} />
                    <Route path="/modify-order" exact component={Home} />
                    <Route path="/login/callback" component={LoginCallback} />
                  </Switch>
                </div>
              </div>
            </Security>
          </Router>
        </OrderProvider>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
}

export default App;
