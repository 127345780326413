import { useOktaAuth } from '@okta/okta-react';
import React, { useEffect, useState } from 'react';
import HomeProtected from './Components/HomeProtected';
import OktaLoginForm from './Components/OktaLoginForm';
import { useOrderContext } from './OrderProvider';

const Home = () => {
  const { authState, oktaAuth } = useOktaAuth();
  const [userInfo, setUserInfo] = useState(null);
  const { resetDashboard } = useOrderContext();

  useEffect(() => {
    if (!authState.isAuthenticated) {
      // When user isn't authenticated, forget any user info
      setUserInfo(null);
      resetDashboard();
    } else {
      oktaAuth.getUser().then((info) => {
        setUserInfo(info);
      });
    }
  }, [authState, oktaAuth, resetDashboard]); // Update if authState changes

  const login = async () => {
    oktaAuth.signInWithRedirect();
  };

  return (
    <>
      {authState.isAuthenticated && userInfo && (
        <>
          <HomeProtected userName={userInfo.name} />
        </>
      )}
      {!authState.isAuthenticated && <OktaLoginForm userLogin={login} />}
    </>
  );
};
export default Home;
