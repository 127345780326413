import Typography from '@material-ui/core/Typography';

import { Theme } from '@material-ui/core/styles/createMuiTheme';
import makeStyles from '@material-ui/styles/makeStyles';

import { default as React, useState, useEffect } from 'react';
import Scrollbars from 'react-custom-scrollbars';

import AphButton from './AphButton';
import AphDialog from './AphDialog';
import AphDialogClose from './AphDialogClose';
import AphDialogTitle from './AphDialogTitle';
import { LazyIntersection } from './lib/LazyIntersection';
import AphTextField from './AphTextField';
import { AlertToast } from './Alert/AlertToast';
import { ValidateResponse } from './helpers/commonHelpers';
import { DiagCoupon } from './OrderSummary';
import { CouponCard } from './CouponCard';
import { useOrderContext } from '../OrderProvider';
import { CDN_URL } from '../appConfig';

const useStyles = makeStyles((theme: Theme) => {
  return {
    couponWrap: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'stretch',
      fontSize: 13,
      fontWeight: 700,
      marginBottom: 0,
    },
    loading: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      zIndex: 2,
      backgroundColor: '#0c0c0c',
      borderRadius: '0px 0px 10px 10px',
      opacity: 0.4,
    },
    applyCoupon: {
      padding: '0px 35px',
      position: 'relative',
      display: 'flex',
      justifyContent: 'flex-start',
      flexGrow: 1,
      height: 43,
      color: '#FC9916',
      backgroundColor: '#FFFFFF',
      border: '1px solid #FC9916',
      boxShadow: 'none',
      borderRadius: 4,

      '&:hover': {
        background: '#f8f8f8',
      },
      '& img': {
        '&:last-child': {
          position: 'absolute',
          right: 0,
          top: 10,
          filter:
            'invert(55%) sepia(98%) saturate(446%) hue-rotate(349deg) brightness(101%) contrast(98%)',
          marginLeft: 20,
        },
      },
    },
    couponApplied: {
      background: 'rgba(0, 179, 142, 0.1)',
      border: '2px solid #00B38E',
      borderRadius: 4,
      color: '#01475B',
      fontSize: 14,
      fontWeight: 500,
      padding: '0px 5px',
      marginTop: '10px',
      marginLeft: '10px',
      display: 'flex',
      width: '90%',
      flexDirection: 'column',
      alignItems: 'stretch',
      '&>div': {
        '&:first-child': {
          flexGrow: 1,
          display: 'flex',
          alignItems: 'center',
          padding: '10px 0px',
          whiteSpace: 'nowrap',
          '&>span': {
            marginLeft: 5,
            background: '#FCFDDA',
            border: '1px dashed #00B38E',
            textTransform: 'uppercase',
            boxSizing: 'border-box',
            borderRadius: 4,
            color: '#00B38E',
            fontSize: 14,
            fontWeight: 600,
            padding: '2px 5px',
            maxWidth: 'min(20vw, 75px)',
            overflowX: 'clip',
            textOverflow: 'ellipsis',
            [theme.breakpoints.down('xs')]: {
              maxWidth: 'fit-content',
            },
          },
          '&>button': {
            display: 'flex',
            justifyContent: 'center',
            position: 'relative',
            padding: 0,
            marginLeft: 'auto',
            left: 5,
            borderRadius: 22,
            minWidth: 24,
            boxShadow: 'none',
          },
        },
      },
      '&>span': {
        color: '#00B38E',
        fontSize: 12,
        padding: '0px 32px 8px 32px',
        whiteSpace: 'nowrap',
        overflowX: 'clip',
        textOverflow: 'ellipsis',
      },
    },
    couponPopup: {
      position: 'relative',
      background: '#F0F1EC',
      borderRadius: 10,
      paddingBottom: 40,
      '&>p': {
        margin: 0,
        fontSize: 12,
        padding: 16,
      },
    },
    couponWrapIn: {
      background: '#FFFFFF',
      padding: '12px 16px',
      '& button': {
        color: '#FC9916',
        fontSize: 14,
        fontWeight: 600,
      },
      '& h6': {
        textAlign: 'center',
        padding: '30px 0px 290px 0px',
        [theme.breakpoints.down('xs')]: {
          padding: '30px 0px 210px 0px',
        },
      },
    },
    couponWrapper: {
      display: 'flex',
      alignItems: 'center',
      width: '93%',
      position: 'relative',
      padding: '8px',
    },
    couponCode: {
      padding: '0px',
      display: 'flex',
      alignItems: 'center',
      position: 'relative',
      flexDirection: 'row',
      flexWrap: 'wrap',

      background: '#F7F8F5',
      border: '1px solid rgba(0, 0, 0, 0.1)',
      borderRadius: 5,
      '&>div:last-child': {
        borderBottom: 'none',
        margin: 0,
      },
    },
    searchInput: {
      '&>div': {
        '&:after': {
          display: 'none !important',
        },
        '&:before': {
          display: 'none !important',
        },
      },
      '& input': {
        fontSize: 14,
        padding: 13,
      },
    },
    applyBtn: {
      position: 'absolute',
      right: 12,
      marginLeft: 'auto',
      padding: 0,
      boxShadow: 'none',
      backgroundColor: 'transparent !important',
      minWidth: 'auto',
      '&:disabled': {
        opacity: 0.4,
        color: '#FC9916',
      },
    },
    disabledButton: {
      background: '#FDD983',
    },
    dialogTitle: {
      padding: 15,
      '&>h2': { fontSize: 13, textTransform: 'uppercase', fontWeight: 'bold' },
    },
    fullWidth: {
      padding: 0,
    },
    couponWarning: {
      color: '#BF2600',
      fontSize: '10px',
      paddingTop: '7px',
    },
    couponBtnText: {
      position: 'relative',
      bottom: -1,
    },
    addressNotSelected: {
      background: '#FCFDDA',
      color: '#01475B',
      minHeight: 32,
      fontSize: 12,
      fontWeight: 500,
      padding: '5px 10px',
      margin: '10px 0px 20px',
      borderRadius: 4,
    },
  };
});

type CouponProps = {
  availableCoupons: DiagCoupon[];
  activeCouponData: ValidateResponse;
  couponApplied: boolean;
  removeCoupon: () => void;
  alertMessage?: string;
  setAlertMessage: (msg: string) => void;
  couponLoading: boolean;
  showCouponDialog: boolean;
  setCouponSelected: (coupon: string) => void;
  setShowCouponDialog: (value: boolean) => void;
  handleCouponApply: () => void;
  setActiveCoupon: (value: string) => void;
  activeCoupon?: string;
};

export const Coupon: React.FC<CouponProps> = (props) => {
  const { diagnosticCartItems, setCouponCode } = useOrderContext();
  const {
    availableCoupons,
    couponApplied,
    activeCouponData,
    removeCoupon,
    alertMessage: errorMessage,
    setAlertMessage: setErrorMessage,
    couponLoading,
    showCouponDialog,
    setShowCouponDialog,
    setActiveCoupon,
  } = props;
  const classes = useStyles({});

  const [couponText, setCouponText] = useState<string>('');

  const applyHandler = () => {
    !errorMessage && setActiveCoupon(couponText?.trim());
  };

  useEffect(() => {
    couponApplied && setShowCouponDialog(false);
  }, [couponApplied, setShowCouponDialog]);

  const applyCoupon = (coupon: string) =>
    !errorMessage && setActiveCoupon(coupon);

  useEffect(() => {
    couponApplied
      ? setCouponCode(activeCouponData?.coupon)
      : setCouponCode('NA');
  }, [couponApplied]);

  return (
    <>
      {diagnosticCartItems?.length > 0 && (
        <>
          <div>
            {couponApplied ? (
              <div className={classes.couponApplied}>
                <div>
                  <LazyIntersection
                    style={{ width: 24, height: 24, marginRight: 8 }}
                    src={`${CDN_URL}/images/diag-coupon-green.svg`}
                    alt=""
                  />
                  Coupon Applied{' '}
                  <span title={activeCouponData?.coupon}>
                    {activeCouponData?.coupon}
                  </span>
                  <AphButton onClick={removeCoupon}>
                    <LazyIntersection
                      style={{ width: 22, height: 22 }}
                      src={`${CDN_URL}/images/ic_cross.svg`}
                      alt=""
                    />
                  </AphButton>
                </div>
                {activeCouponData?.textOffer && (
                  <span>{activeCouponData.textOffer}</span>
                )}
              </div>
            ) : (
              <div className={classes.couponWrapper}>
                <AphButton
                  className={classes.applyCoupon}
                  onClick={() => setShowCouponDialog(true)}
                >
                  <LazyIntersection
                    style={{
                      width: 20,
                      height: 20,
                      marginRight: 8,
                      marginLeft: -25,
                    }}
                    src={`${CDN_URL}/images/diag-coupon.svg`}
                    alt=""
                  />
                  <span className={classes.couponBtnText}>
                    Offers and Coupons
                  </span>
                  <LazyIntersection
                    style={{ width: 22, height: 22, marginLeft: 15 }}
                    src={`${CDN_URL}/images/ic_arrow_right.svg`}
                    alt=""
                  />
                </AphButton>
              </div>
            )}
          </div>

          <AphDialog open={showCouponDialog}>
            <AphDialogClose
              onClick={() => {
                setShowCouponDialog(false);
                setErrorMessage('');
              }}
              title={'Close'}
            />

            <AphDialogTitle className={classes.dialogTitle}>
              Apply Coupon
            </AphDialogTitle>
            <AlertToast
              alertMessage={errorMessage}
              setAlertMessage={setErrorMessage}
            />
            <div>
              <Scrollbars
                autoHide={true}
                autoHeight
                autoHeightMax={'65vh'}
                autoHeightMin={'65vh'}
              >
                <div className={classes.couponPopup}>
                  {couponLoading && <div className={classes.loading}></div>}
                  <p>Apply Coupon Code</p>
                  <div className={classes.couponWrapIn}>
                    <div className={classes.couponCode}>
                      <AphTextField
                        placeholder="Enter Coupon Code"
                        className={classes.searchInput}
                        value={couponText}
                        onChange={(e) => setCouponText(e?.target?.value)}
                        onKeyDown={(e) => {
                          if (e?.key === 'Enter') {
                            applyHandler();
                          }
                        }}
                      />
                      <AphButton
                        disabled={!couponText?.trim().length}
                        className={classes.applyBtn}
                        onClick={applyHandler}
                      >
                        APPLY
                      </AphButton>
                    </div>
                    {availableCoupons?.length > 0 ? (
                      <>
                        <p>Coupons for You</p>
                        <div
                          className={`${classes.couponWrapIn} ${classes.fullWidth}`}
                        >
                          {availableCoupons?.map(
                            (coupon: DiagCoupon, index) =>
                              coupon && (
                                <CouponCard
                                  key={coupon.id}
                                  coupon={coupon}
                                  setActiveCoupon={applyCoupon}
                                />
                              )
                          )}
                        </div>
                      </>
                    ) : (
                      <Typography variant="h6">No Coupons Available</Typography>
                    )}
                  </div>
                </div>
              </Scrollbars>
            </div>
          </AphDialog>
        </>
      )}
    </>
  );
};
