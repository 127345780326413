import React, { useState, useRef, FunctionComponent, useEffect } from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import { Theme } from '@material-ui/core';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Popper from '@material-ui/core/Popper';
import { useOrderContext } from '../OrderProvider';

const useStyles = makeStyles((theme: Theme) => ({
  priceRowWithInfo: {
    display: 'flex',
    alignItems: 'center',
    padding: '6px 12px !important',
    position: 'relative',
    '& p': {
      display: 'flex',
      alignItems: 'center',
      gap: 4,
      margin: 0,
      whiteSpace: 'nowrap',
    },
    '& >span': {
      display: 'flex',
      alignItems: 'center',
      position: 'absolute',
      top: 28,
      left: 13,
      fontSize: 11,
      color: '#D96D2A',
      gap: 4,
      '& >img': {
        height: 12,
        width: 18,
      },
    },
  },
  priceCol: {
    marginLeft: 'auto',
  },
  homeCollectionChargesCont: {
    width: 150,
    height: 50,
    borderRadius: 6,
    background: '#121414',
    padding: '8px 16px',
    display: 'flex',
    alignItems: 'center',
    color: '#CCCCC9',
    fontSize: 12,
  },
  additionalChargesContainer: {
    width: 210,
    height: 65,
    borderRadius: 6,
    background: '#121414',
    padding: '8px 16px',
    display: 'flex',
    alignItems: 'center',
    color: '#CCCCC9',
    fontSize: 12,
  },
  discountMark: {
    color: '#D96D2A',
  },
  slashPrice: {
    color: '#01475B',
    textDecoration: 'line-through',
    opacity: 0.6,
    fontSize: 14,
    whiteSpace: 'nowrap',
    fontWeight: 400,
  },
  appliedPrice: {
    fontWeight: 600,
    whiteSpace: 'nowrap',
    wordSpacing: '-3px',
  },
  benefitSpace: {
    paddingBottom: '24px !important',
  },
  popper: {
    zIndex: 1,
    top: `6px !important`,
    '&[x-placement*="bottom"] $arrow': {
      top: 0,
      left: 0,
      marginTop: '-0.9em',
      width: '3em',
      height: '1em',
      '&::before': {
        borderWidth: '0 1em 1em 1em',
        borderColor: `transparent transparent #1f1f1f transparent`,
      },
    },
    '&[x-placement*="top"] $arrow': {
      bottom: 0,
      left: 0,
      marginBottom: '-0.9em',
      width: '3em',
      height: '1em',
      '&::before': {
        borderWidth: '1em 1em 0 1em',
        borderColor: `#1f1f1f transparent transparent transparent`,
      },
    },
    '&[x-placement*="right"] $arrow': {
      left: 0,
      marginLeft: '-0.9em',
      height: '3em',
      width: '1em',
      '&::before': {
        borderWidth: '1em 1em 1em 0',
        borderColor: `transparent #1f1f1f transparent transparent`,
      },
    },
    '&[x-placement*="left"] $arrow': {
      right: 0,
      marginRight: '-0.9em',
      height: '3em',
      width: '1em',
      '&::before': {
        borderWidth: '1em 0 1em 1em',
        borderColor: `transparent transparent transparent #1f1f1f`,
      },
    },
  },
  arrow: {
    position: 'absolute',
    fontSize: 7,
    width: '3em',
    height: '3em',
    '&::before': {
      content: '""',
      margin: 'auto',
      display: 'block',
      width: 0,
      height: 0,
      borderStyle: 'solid',
    },
  },
}));

const PhleboCharges: FunctionComponent = () => {
  const { phleboCharges } = useOrderContext();
  const classes = useStyles({});
  const [isAdditionalPopOverOpen, setIsAdditionalPopOverOpen] =
    useState<boolean>(false);
  const [isHomeCollectionPopOverOpen, setIsHomeCollectionPopOverOpen] =
    useState<boolean>(false);
  const additionalChargesRef = useRef<HTMLDivElement>(null);
  const homeCollectionRef = useRef<HTMLDivElement>(null);
  const [arrowRef, setArrowRef] = useState<HTMLSpanElement>(null);
  const [showAdditionalChargesForFewSecs, setShowAdditionalChargesForFewSecs] =
    useState<boolean>(false);

  useEffect(() => {
    if (phleboCharges?.additionalCharges > 0) {
      setShowAdditionalChargesForFewSecs(true);
    }
  }, [phleboCharges?.additionalCharges]);

  useEffect(() => {
    let timerId = null;
    if (showAdditionalChargesForFewSecs) {
      setIsAdditionalPopOverOpen(true);
      timerId = setTimeout(() => {
        setIsAdditionalPopOverOpen(false);
      }, 5000);
    }
    return () => timerId && clearTimeout(timerId);
  }, [showAdditionalChargesForFewSecs]);

  const isAdditionalChargesDiscountApplied =
    phleboCharges?.slashedAdditionalCharges - phleboCharges?.additionalCharges >
    0;
  const isHomeCollectionChargesDiscountApplied =
    phleboCharges?.slashedCollectionCharges - phleboCharges?.collectionCharges >
    0;

  const showAggregateBenefits =
    isAdditionalChargesDiscountApplied &&
    isHomeCollectionChargesDiscountApplied;

  return (
    <>
      <div
        className={`${classes.priceRowWithInfo} ${
          isHomeCollectionChargesDiscountApplied && !showAggregateBenefits
            ? classes.benefitSpace
            : ''
        }`}
      >
        <p>
          Home Collection Charges
          {phleboCharges?.paidSlotMessage?.length > 0 && (
            <p
              onClick={() => {
                setIsHomeCollectionPopOverOpen(
                  (isHomeCollectionPopOverOpen) => !isHomeCollectionPopOverOpen
                );
              }}
              ref={homeCollectionRef}
            >
              <img src={require('../images/grey_info.svg')} alt="" />
            </p>
          )}
        </p>
        {isHomeCollectionChargesDiscountApplied && !showAggregateBenefits && (
          <span>
            {phleboCharges?.isCircleBenefits ? (
              <>
                <img src={require('../images/circle_logo_v2.png')} alt="" />
                *Circle Benefits
              </>
            ) : (
              '* Package Benefits'
            )}
          </span>
        )}
        <p className={classes.priceCol}>
          {isHomeCollectionChargesDiscountApplied && (
            <span className={classes.slashPrice}>
              ₹{phleboCharges?.slashedCollectionCharges}
            </span>
          )}
          <span className={classes.appliedPrice}>
            ₹{phleboCharges?.collectionCharges || 0}{' '}
            {isHomeCollectionChargesDiscountApplied && (
              <span className={classes.discountMark}>*</span>
            )}
          </span>
        </p>
      </div>
      {phleboCharges?.slashedAdditionalCharges > 0 && (
        <div
          className={`${classes.priceRowWithInfo} ${
            isAdditionalChargesDiscountApplied ? classes.benefitSpace : ''
          }`}
        >
          <p>
            Additional Charges
            {phleboCharges?.additionalChargesMessage?.length > 0 && (
              <p
                onClick={() => {
                  setIsAdditionalPopOverOpen(
                    (isAdditionalPopOverOpen) => !isAdditionalPopOverOpen
                  );
                }}
                ref={additionalChargesRef}
              >
                <img src={require('../images/grey_info.svg')} alt="" />
              </p>
            )}
          </p>
          {(isAdditionalChargesDiscountApplied || showAggregateBenefits) && (
            <>
              {phleboCharges?.isCircleBenefits ? (
                <span>
                  <img src={require('../images/circle_logo_v2.png')} alt="" /> *
                  Circle Benefits
                </span>
              ) : (
                <span>*Package Benefits</span>
              )}
            </>
          )}
          <p className={classes.priceCol}>
            {isAdditionalChargesDiscountApplied && (
              <span className={classes.slashPrice}>
                ₹{phleboCharges?.slashedAdditionalCharges}
              </span>
            )}
            {
              <span className={classes.appliedPrice}>
                ₹{phleboCharges?.additionalCharges}{' '}
                {isAdditionalChargesDiscountApplied && (
                  <span className={classes.discountMark}>*</span>
                )}
              </span>
            }
          </p>
        </div>
      )}
      {isAdditionalPopOverOpen && (
        <Popper
          className={classes.popper}
          open={isAdditionalPopOverOpen}
          anchorEl={additionalChargesRef?.current}
          placement="bottom"
          modifiers={{
            arrow: {
              enabled: true,
              element: arrowRef,
            },
          }}
        >
          <ClickAwayListener
            onClickAway={() => {
              setIsAdditionalPopOverOpen(false);
            }}
          >
            <div>
              <span ref={setArrowRef} className={classes.arrow} />
              <div className={classes.additionalChargesContainer}>
                {phleboCharges?.additionalChargesMessage}
              </div>
            </div>
          </ClickAwayListener>
        </Popper>
      )}
      {isHomeCollectionPopOverOpen && (
        <Popper
          className={classes.popper}
          open={isHomeCollectionPopOverOpen}
          anchorEl={homeCollectionRef?.current}
          placement="bottom"
          modifiers={{
            arrow: {
              enabled: true,
              element: arrowRef,
            },
          }}
        >
          <ClickAwayListener
            onClickAway={() => {
              setIsHomeCollectionPopOverOpen(false);
            }}
          >
            <div>
              <span ref={setArrowRef} className={classes.arrow} />
              <div className={classes.homeCollectionChargesCont}>
                {phleboCharges?.paidSlotMessage}
              </div>
            </div>
          </ClickAwayListener>
        </Popper>
      )}
    </>
  );
};
export default PhleboCharges;
