export const getPackageIds = (userSubscriptions: any) => {
  let packageIds = [];

  userSubscriptions &&
    Object.keys(userSubscriptions).forEach((subscription) => {
      userSubscriptions[subscription].forEach((item) => {
        if (item.status.toLowerCase() === 'active')
          packageIds.push(`${subscription.toUpperCase()}:${item.plan_id}`);
      });
    });
  return packageIds;
};
