export const BASE_URL = process.env.REACT_APP_API_ENDPOINT;
export const WEB_BASE_URL = process.env.REACT_APP_WEB_API_ENDPOINT;
export const CDN_URL = `${process.env.REACT_APP_247_CDN_URL}`;
export const FIND_USER_BY_MOBILE_URL = `${BASE_URL}/findUserByMobile`;
export const UPDATE_GUEST_PROFILE = `${BASE_URL}/updatePatient`;
export const FIND_BY_ITEMIDS_AND_CITYID = `${BASE_URL}/diagnostic/findByItemIDsAndCityID`;
export const LOGIN_URL = `${BASE_URL}/login`;
export const ADD_ADDRESS_URL = `${BASE_URL}/addUserAddress`;
export const DIAGNOSTIC_SEARCH_URL = `${BASE_URL}/diagnostic/elasticSearchItem`;
export const SAVE_USER_PROFILE = `${BASE_URL}/saveUser`;
export const SERVICEABILITY_URL = `${BASE_URL}/diagnostic/getServiceability`;
export const DIAGNOSTIC_GET_AREAS = `${BASE_URL}/diagnostic/getAreas`;
export const DIAGNOSTIC_SLOTS_WITH_AREAID = `${BASE_URL}/diagnostic/getSlotsCustomized`;
export const GET_SUBSCRIPTION_STATUS = `${BASE_URL}/getSubscriptionsOfUserByStatus`;
export const GET_HOME_COLLECTION_CHARGES = `${BASE_URL}/diagnostic/getPhleboChargesV2`;
export const BOOK_HOME_COLLECTION = `${BASE_URL}/diagnostic/saveAndProcessHCOrder`;
export const VALIDATE_COUPON = `${BASE_URL}/diagnostic/vaidateDiagnosticCoupon`;
export const ADD_NEW_MEMBER = `${BASE_URL}/addNewProfile`;
export const GET_CUSTOMIZED_SLOT_V2 = `${BASE_URL}/diagnostic/getSlotsCustomizedv2`;
export const GET_REPORT_TAT = `${BASE_URL}/diagnostic/getConfigurableReportTAT`;
export const GET_PRESCRIPTIONS = `${BASE_URL}/diagnostic/getPatientConsultAndPrismPrescriptions`;
export const SAVE_AND_SEND_PAYMENT_LINK = `${BASE_URL}/diagnostic/saveAndSendPaymentLink`;
export const GET_CIRCLE_PLANS = `${BASE_URL}/diagnostic/getPlanDetailsByPlanId`;
export const GET_DIAG_ITEM_RECOMMENDATIONS = `${BASE_URL}/diagnostic/getDiagnosticItemRecommendations`;
export const GET_DIAG_PACKAGE_RECOMMENDATIONS = `${BASE_URL}/diagnostic/getDiagnosticPackageRecommendationsv2`;
export const GET_CART_ITEM_PREPARATION_DATA = `${BASE_URL}/diagnostic/getCMSCartItems`;
export const GET_PRESCRIPTION_ORDERS = `${BASE_URL}/diagnostic/getPrescriptionOrders`;
export const GET_PRESCRIPTION_ORDER_DETAILS = `${BASE_URL}/diagnostic/getPrescriptionOrderDetails`;
export const GET_CANCELLATION_REASONS = `${BASE_URL}/diagnostic/getPrescriptionOrderCancellationReasons`;
export const UPDATE_ORDER_STATUS = `${BASE_URL}/diagnostic/updatePrescriptionOrderStatus`;
export const WEB_TDP_URL = `https://www.apollo247.com/lab-tests/`;
export const GET_ACTIVE_ORDERS = `${BASE_URL}/diagnostic/getActiveOrders`;
export const GET_ONE_APOLLO = `${BASE_URL}/getOneApolloUser`;
export const GET_MODIFIABLE_ORDERS = `${BASE_URL}/diagnostic/getModifiableOrders`;
export const GET_ORDER_DETAILS = `${BASE_URL}/diagnostic/getDiagnosticOrderDetails`;
export const MODIFY_ORDER = `${BASE_URL}/diagnostic/modifyOrder`;
export const USER_ACCESS = `${BASE_URL}/diagnostic/getDiagnosticUserAccess`;
export const GET_ORDER_CANCELLATION_REASONS = `${BASE_URL}/diagnostic/getCancellationReasons`;
export const CANCEL_ORDER = `${BASE_URL}/diagnostic/cancelOrder`;
export const SEND_CLEVERTAP_EVENT = `${BASE_URL}/diagnostic/sendClevertapEvent`;
export const PACKAGE_RECOMM_CONFIG = `${CDN_URL}/files/diagCartPageConfig.json`;
export const DISABLE_COD_OPTION = `${BASE_URL}/diagnostic/isCodAllowed`;
export const MIN_AGE_CONFIG = `${CDN_URL}/files/labTestConfig.json`;

export const ORDER_SOURCES = [
  { value: 'OP_WHATSAPP', label: 'Whatsapp' },
  { value: 'OP_CALL_CENTER', label: 'Call Center' },
  { value: 'OP_OMT_TEAM', label: 'OMT Team' },
  { value: 'PHLEBO_APP', label: 'Phlebo App' },
  { value: 'OP_STORE', label: 'Apollo Pharmacy Store' },
  { value: 'OP_CLINIC', label: 'Clinic' },
  { value: 'OP_PARTNER', label: 'Partner' },
  { value: 'OP_CAMP', label: 'Camps' },
  { value: 'OP_CAMPAIGN', label: 'Campaign' },
];

export const DIAGNOSTICS_GROUPPLAN = {
  all: 'ALL',
  circle: 'CIRCLE',
  special: 'SPECIALDISCOUNTS',
};

export const GENDER_LIST = ['MALE', 'FEMALE', 'OTHER'];
export const GENDER_MAP = {
  F: 'FEMALE',
  M: 'MALE',
  B: 'OTHER',
};

export const RELATION_LIST = [
  'BROTHER',
  'COUSIN',
  'DAUGHTER',
  'FATHER',
  'GRANDDAUGHTER',
  'GRANDFATHER',
  'GRANDMOTHER',
  'GRANDSON',
  'HUSBAND',
  'ME',
  'MOTHER',
  'OTHER',
  'SISTER',
  'SON',
  'WIFE',
];

export const COVID_TEST_IDS =
  process.env.REACT_APP_COVID_TEST_IDS?.split(',').map((item) =>
    Number(item)
  ) || [];

export enum ADDADDRESS_TITLES {
  ADD_NEW_ADDRESS = 'Add New Address',
  SET_LOCATION = 'Set Location',
}

export const REACT_APP_GOOGLE_MAP_API = `${process.env.REACT_APP_GOOGLE_MAP_API}`;
export const REACT_APP_GOOGLE_API_KEY = `${process.env.REACT_APP_GOOGLE_API_KEY}`;
export const libraries: (
  | 'drawing'
  | 'geometry'
  | 'localContext'
  | 'places'
  | 'visualization'
)[] = ['places'];

export const ALLOW_COMBINED_ADDRESS_FORM =
  process.env.REACT_APP_ALLOW_COMBINED_ADDRESS_FORM === 'true';
export const PINCODE_MAXLENGTH = 6;
export const MAXLENGTH_ADDRESS_LINE_ONE = 60;
export const MAXLENGTH_ADDRESS_LINE_TWO = 60;
export const MAXLENGTH_ADDRESS_LANDMARK = 60;

export enum SOURCE {
  WEB = 'WEB',
  MOBILE = 'MOBILE',
  OPS_DASHBOARD = 'OPS_DASHBOARD',
  AD = 'AD',
}

export const ORDER_TABLE_PAGE_SIZES = [5, 10, 20, 50];

export const ORDER_TABLE_ROWS_PER_PAGE = 10;

export enum SEARCH_FILTERS {
  CUSTOMER_MOBILE_NUMBER = 'CUSTOMER_MOBILE_NUMBER',
  DISPLAYID = 'DISPLAYID',
  PREBOOKINGID = 'PREBOOKINGID',
}

export enum SEARCH_FILTER_ORDER_STATUS {
  LEAD_DROPPED = 'LEAD_DROPPED',
  NEW_LEAD = 'NEW_LEAD',
  ORDER_CONFIRMED = 'ORDER_CONFIRMED',
}

export const SEARCH_FILTERS_OPTIONS = [
  {
    label: 'Customer Mobile No',
    key: SEARCH_FILTERS.CUSTOMER_MOBILE_NUMBER,
  },
  {
    label: 'Display ID',
    key: SEARCH_FILTERS.DISPLAYID,
  },
];

export const MODIFY_ORDER_SEARCH_FILTERS_OPTIONS = [
  {
    label: 'Customer Mobile No',
    key: SEARCH_FILTERS.CUSTOMER_MOBILE_NUMBER,
  },
  {
    label: 'Display ID',
    key: SEARCH_FILTERS.DISPLAYID,
  },
  {
    label: 'PreBooking ID',
    key: SEARCH_FILTERS.PREBOOKINGID,
  },
];

export const SEARCH_FILTER_ORDER_STATUS_OPTIONS = [
  {
    label: 'New Lead',
    key: SEARCH_FILTER_ORDER_STATUS.NEW_LEAD,
  },
  {
    label: 'Order Placed',
    key: SEARCH_FILTER_ORDER_STATUS.ORDER_CONFIRMED,
  },
  {
    label: 'Order Aborted',
    key: SEARCH_FILTER_ORDER_STATUS.LEAD_DROPPED,
  },
];

export const DIAG_NON_CART_FLOW_MESSAGES = {
  orderCancel: 'Order Cancelled Successfully',
  orderCancelFailed: 'Unable to cancel order Please try again',
};

export const SEARCH_KEYWORD_LIMIT = 20;

export const DIAG_CIRCLE_MEMBERSHIP_MESSAGES = {
  circle: {
    heading: 'Circle plan added to cart!',
    upperLeftText: 'You ',
    isCircleAddedToCart: 'Your effective price is ',
    isCashbackAvailable: 'Get additional cashback of ',
  },
  nonCircle: {
    heading: 'Buy Circle Membership',
    upperLeftText: 'You can ',
    isCircleAddedToCart: 'Effective price would be ',
    isCashbackAvailable: 'Get additional cashback of ',
  },
};

export const CIRCLE_PLAN_CONFIG_TEXT =
  'Get extra cashback and discounts on lab tests and medicine orders';

export const CIRCLE_PLAN_ID = 'CIRCLEPlan';

export const EXCLUDE_AUTH_HEADER_URLS = [
  `${REACT_APP_GOOGLE_MAP_API}`,
  `${process.env.REACT_APP_COUPON_VALIDATION_ENDPOINT}`,
  `${process.env.REACT_APP_COUPON_ENDPOINT}`,
];

export const SLOT_EXPIRE_DURATION_IN_HOURS = 1;

export const ORDER_MODIFY_PROGRESS_TEXT =
  'Order modification is in progress already. New modification is not allowed.';

export const MAXIMUM_LIMIT_TEXT =
  'The maximum limit for the coupon has reached. Please try a different coupon';

export const NA_STRING = 'NA';

export enum DIAGNOSTIC_USER_ACCESS {
  CANCEL_ORDER = 'CANCEL_ORDER',
  MODIFY_ORDER = 'MODIFY_ORDER',
  UPSELL = 'UPSELL',
  EDIT_PATIENT = 'EDIT_PATIENT',
  RESCHEDULE_ORDER = 'RESCHEDULE_ORDER',
  ALL_PRESCRIPTION_ORDER_VIEW = 'ALL_PRESCRIPTION_ORDER_VIEW',
}

export const RESCHEDULE_ERROR_ORDER_STATUS = [
  'FAILED_RESCHEDULING_FOR_MULTIUHID',
];

export const ERROR_MESSAGES = {
  RESCHEDULE_COUNT_EXCEEDED:
    'The maximum limit for reschedule count has reached.',
  GENDER_MAPPING: 'Gender Mapping',
  MODIFY_IN_PROGRESS:
    'Your current order is in progress. You cant modify order now.',
};

export const RECOMM_CONFIG_KEY = 'recommConfig';
export const MIN_AGE_FOR_ORDER_CONFIG = 'minAgeConfig';

export const CACHE_EXPIRE_IN_HOURS = 2;

export const phleboCallOnModify = {
  allowForAll: true,
  allowedItemIds: [1020, 2499],
};
