import AphDialog from './AphDialog';
import AphDialogClose from './AphDialogClose';
import AphDialogTitle from './AphDialogTitle';
import React, { useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { format } from 'date-fns/esm/fp';
import { parse } from 'date-fns';
import { CircularProgress } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      padding: '15px 0px',
      '& h2': {
        fontSize: 13,
        fontWeight: 600,
      },
    },
    viewPrescription: {
      height: 400,
      overflow: 'scroll',
      '&>ul': {
        padding: 0,
        margin: 0,
        '& li': {
          padding: '15px 12px',
          display: 'flex',
          justifyContent: 'space-between',
          borderBottom: '1px solid #D4D4D4',
          '&:last-child': {
            border: 'unset',
          },
        },
      },
    },
    lhs: {
      fontSize: 14,
      fontWeight: 600,
      flexBasis: '60%',
      wordBreak: 'break-all',
      '& p': {
        fontSize: 12,
        fontWeight: 500,
        margin: 0,
        paddingTop: 4,
        '& span': {
          color: '#FC9916',
        },
      },
    },
    rhs: {
      textAlign: 'right',
      display: 'flex',
      alignItems: 'flex-start',
      flexBasis: '40%',
      paddingLeft: 10,
      justifyContent: 'flex-end',
      boxSizing: 'border-box',
      '& p': {
        margin: 0,
        fontSize: 12,
        fontWeight: 500,
      },
      '& img': {
        paddingLeft: 10,
      },
    },
    loader: {
      display: 'flex',
      height: 'inherit',
      alignItems: 'center',
      justifyContent: 'center',
    },
  })
);

interface ViewPrescriptionProps {
  viewPrescription: boolean;
  closeViewPrescription: () => void;
  prescriptions: any[];
  isLoading: boolean;
}

export const ViewPrescription: React.FC<ViewPrescriptionProps> = (props) => {
  const classes = useStyles();
  const { viewPrescription, closeViewPrescription, prescriptions, isLoading } =
    props;
  const [currentExpandedItem, setCurrentExpandedItem] = useState<string>();
  return (
    <AphDialog open={viewPrescription}>
      <AphDialogClose onClick={() => closeViewPrescription()} title={'Close'} />
      <AphDialogTitle className={classes.title}>PRESCRIPTIONS</AphDialogTitle>
      <div className={classes.viewPrescription}>
        {isLoading ? (
          <div className={classes.loader}>
            <CircularProgress size={40} />
          </div>
        ) : (
          <ul>
            {prescriptions?.length > 0 ? (
              prescriptions
                .filter((item) => item.date || item.prescriptionDateTime)
                .map((item, inx) => {
                  const presDate =
                    item.type === 'phr'
                      ? item.date
                      : item.prescriptionDateTime ?? '';
                  const formattedDate = presDate
                    ? format(
                        'dd MMM yyyy',
                        parse(presDate, 'yyyy-MM-dd', new Date())
                      )
                    : '';
                  const presName =
                    item.type === 'phr'
                      ? item.prescriptionName || ''
                      : item.doctorName || '';
                  const prescribedItems =
                    item.type !== 'phr'
                      ? item?.caseSheet?.diagnosticPrescription?.length > 0
                        ? item?.caseSheet?.diagnosticPrescription.map(
                            (prescribedItem: {
                              itemname: string;
                              testInstruction: string;
                            }) => prescribedItem.itemname
                          )
                        : []
                      : '';
                  return (
                    <li key={`${item.type}-${inx}`}>
                      <div className={classes.lhs}>
                        {presName}
                        {item.type !== 'phr' && (
                          <p>
                            {currentExpandedItem === item.type + '-' + inx
                              ? prescribedItems.join(', ')
                              : prescribedItems?.slice(0, 3).join(', ')}
                            {!(currentExpandedItem === item.type + '-' + inx) &&
                              prescribedItems?.length > 3 && (
                                <span
                                  onClick={() => {
                                    setCurrentExpandedItem(
                                      item.type + '-' + inx
                                    );
                                  }}
                                >
                                  {' '}
                                  & {prescribedItems?.length - 3} more
                                </span>
                              )}
                          </p>
                        )}
                      </div>
                      <div className={classes.rhs}>
                        <div>
                          <p>{formattedDate}</p>
                          <p>{`${
                            item.type === 'phr'
                              ? 'Self Upload'
                              : 'VC Prescription'
                          }`}</p>
                        </div>
                        <div
                          style={{
                            opacity: item.type !== 'phr' ? '0.5' : 1,
                            cursor: item.type === 'phr' ? 'pointer' : 'default',
                          }}
                          onClick={() => {
                            item.type === 'phr' &&
                              window.open(item.fileUrl, '_blank');
                          }}
                        >
                          {' '}
                          <img src={require('../images/dawnload.png')} alt="" />
                        </div>
                      </div>
                    </li>
                  );
                })
            ) : (
              <li key="no-prescription-data">
                No Prescriptions for this patient
              </li>
            )}
          </ul>
        )}
      </div>
    </AphDialog>
  );
};
