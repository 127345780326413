import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
  getTestsCirclePrice,
  getWebTdpUrl,
  LineItemsObject,
} from './helpers/commonHelpers';
import {
  CDN_URL,
  DIAGNOSTICS_GROUPPLAN,
  GET_CART_ITEM_PREPARATION_DATA,
} from '../appConfig';
import { useOrderContext } from '../OrderProvider';
import Tooltip from '@material-ui/core/Tooltip';
import axios from 'axios';
import { TagText } from './TagText';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listLHS: {
      flexBasis: '61%',
      '& a': {
        textDecoration: 'none',
        color: 'inherit',
        outline: 0,
      },
      '& p': {
        '&:first-child': {
          margin: 0,
          marginBottom: 8,
          fontSize: 14,
          fontWeight: 500,
          height: 32,
          overflow: 'hidden',
          display: '-webkit-box',
          lineClamp: 2,
          boxOrient: 'vertical',
          paddingBottom: 0,
        },
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        wordBreak: 'break-word',
        margin: 0,
        padding: '4px 0px',
        fontSize: 14,
        fontWeight: 500,
        display: 'flex',
        alignItems: 'center',
      },
    },
    listRHS: {
      display: 'flex',
      textAlign: 'right',
      alignItems: 'center',
      '&>div': {
        '&:first-child': {
          '& div:first-child>span': {
            marginRight: 4,
            '&:last-of-type': {
              marginRight: 0,
            },
          },
          '& p': {
            margin: 0,
            '&:nth-of-type(1)': {
              fontSize: 14,
              fontWeight: 'bold',
              margin: '3px 0px',
            },
          },
        },
        '&:last-child': {
          marginTop: 14,
          '& img': {
            cursor: 'pointer',
            position: 'relative',
            right: -4,
          },
        },
      },
    },
    tat: {
      borderRadius: 4,
      display: 'inline-block',
      fontSize: '10px !important',
      fontWeight: 500,
      margin: '0 !important',
      '& img': {
        paddingRight: 5,
        verticalAlign: 'middle',
      },
    },
    instructions: {
      fontSize: '10px !important',
      fontWeight: 500,
      display: 'flex',
      '& img': {
        width: 12,
        verticalAlign: 'middle',
        paddingRight: 5,
      },
    },
    inclusions: {
      fontSize: '10px !important',
    },
    cashBackContainer: {
      display: 'flex',
      alignItems: 'center',
      background: '#F3F3F3',
      borderRadius: 4,
      padding: '4px 8px',
      fontSize: 10,
      fontWeight: 500,
      marginTop: 4,
      '& img': {
        height: 12,
        marginRight: 5,
      },
      '& div': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        minHeight: 'auto',
        '& span': {
          whiteSpace: 'nowrap',
          '&.cashback': {
            color: '#0087BA',
          },
          '&.showPlus:before': {
            color: '#0087BA',
            content: "'+'",
            marginRight: '3px',
          },
          '&.showExtra:before': {
            color: '#0087BA',
            content: "'Extra'",
            marginRight: '3px',
          },
        },
      },
    },
    percentDisc: {
      background: '#00B38E',
      color: '#fff',
      borderRadius: 60,
      padding: '1px 7px',
      lineHeight: '13px',
      fontSize: 10,
      fontWeight: 600,
      whiteSpace: 'nowrap',
    },
    slashPrice: {
      fontSize: 10,
      lineHeight: '16px',
      fontWeight: 600,
      opacity: 0.8,
      textDecoration: 'line-through',
      color: '#02475B',
    },
    mrpLabel: {
      fontSize: 10,
      lineHeight: '16px',
      fontWeight: 600,
      opacity: 0.8,
      color: '#02475B',
    },
    savingsText: {
      fontSize: 10,
      lineHeight: '13px',
      fontWeight: 500,
      opacity: 0.87,
      color: '#02475B',
    },
    tagContain: {
      width: 'max-content',
      float: 'right',
    },
  })
);

interface PatientLineItemProps {
  lineItem: LineItemsObject;
  patientId: string;
  setItemToRemove: (
    itemToRemove: { itemId: number; patientId: string } | null
  ) => void;
}
export const PatientLineItem: React.FC<PatientLineItemProps> = (props) => {
  const classes = useStyles();
  const {
    isUserCare,
    circleMemberShipPlan,
    diagnosticCartItems,
    couponApplied,
    circleBenefitsApplied,
    modifyFlowOrder,
  } = useOrderContext();
  const { lineItem, setItemToRemove, patientId } = props;
  const [itemPrepration, setItemPrepration] = useState<string>('');

  useEffect(() => {
    axios
      .post(GET_CART_ITEM_PREPARATION_DATA, { itemIds: [lineItem?.itemId] })
      .then(({ data }) => {
        setItemPrepration(data?.[0]?.itemPrepration);
      })
      .catch((e) => console.error(e));
  }, [lineItem]);
  const { mrp, priceBeforeDiscount } = lineItem;

  const cartItem = diagnosticCartItems?.find(
    (cartItem) => cartItem?.itemId === lineItem?.itemId
  );

  const canonicalTag = cartItem?.canonicalTag;

  const diagnosticPricing = getTestsCirclePrice(
    cartItem?.diagnosticPricing,
    isUserCare || !!circleMemberShipPlan
  );

  const { cashbackText, showDiscountPercent } = diagnosticPricing;

  const handleItemRemove = (itemId: number, patientId: string) => {
    setItemToRemove({ itemId, patientId });
  };

  const renderCashbackSection = (lineItem: LineItemsObject) => {
    const circleApplied =
      (isUserCare || !!circleMemberShipPlan) &&
      lineItem?.groupPlan === DIAGNOSTICS_GROUPPLAN.circle;

    const showCashback =
      (isUserCare || !!circleMemberShipPlan) &&
      (!couponApplied || (couponApplied && circleBenefitsApplied)) &&
      cashbackText?.length > 0;

    return (
      <>
        {(circleApplied || showCashback) && (
          <div className={classes.cashBackContainer}>
            <img src={`${CDN_URL}/images/circle_logo.png`} alt="circle logo" />
            <div>
              <span>{circleApplied ? 'Discount Applied' : ''}</span>
              {showCashback && (
                <span
                  className={`cashback ${
                    circleApplied ? 'showPlus' : 'showExtra'
                  }`}
                >{`${cashbackText}`}</span>
              )}
            </div>
          </div>
        )}
      </>
    );
  };

  const priceToUse = lineItem?.isCouponOnMrp ? mrp : priceBeforeDiscount;
  const totalDiscount = mrp - priceToUse;

  const totalDiscountPercent = Math.round((totalDiscount / mrp) * 100);
  const showDiscountPerc = showDiscountPercent || modifyFlowOrder;
  return (
    <>
      <div className={classes.listLHS}>
        <a
          href={getWebTdpUrl(canonicalTag)}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Tooltip title={lineItem.itemName}>
            <p>{lineItem.itemName}</p>
          </Tooltip>
        </a>

        <p className={classes.inclusions}>{`${
          lineItem?.testParametersCount
        } Test${lineItem?.testParametersCount > 1 ? 's' : ''} included`}</p>
        <p className={classes.tat}>
          <img src={require('../images/ic_clock.png')} alt="" />
          {lineItem.reportTAT}
        </p>
        {itemPrepration?.length > 0 && (
          <p className={classes.instructions}>
            <img src={require('../images/ic_info.png')} alt="" />
            {itemPrepration}
          </p>
        )}
      </div>
      <div className={classes.listRHS}>
        <div>
          <div>
            {totalDiscountPercent > 0 && showDiscountPerc && (
              <span
                className={classes.percentDisc}
              >{`${totalDiscountPercent}% off`}</span>
            )}
            <span className={classes.mrpLabel}>MRP</span>
            {totalDiscount > 0 && mrp !== priceBeforeDiscount && (
              <span className={classes.slashPrice}>₹{mrp}</span>
            )}
          </div>
          <p>
            {totalDiscount > 0 && !showDiscountPerc && (
              <span
                className={classes.savingsText}
              >{`save ₹${totalDiscount}`}</span>
            )}
            ₹{priceToUse!}
          </p>
          {renderCashbackSection(lineItem)}
          {lineItem?.packageWorthText && (
            <div className={classes.tagContain}>
              <TagText text={lineItem.packageWorthText} />
            </div>
          )}
        </div>
        <div onClick={() => handleItemRemove(lineItem.itemId, patientId)}>
          <img src={require('../images/ic_cross.png')} alt="" />
        </div>
      </div>
    </>
  );
};
