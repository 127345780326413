import AphButton from './AphButton';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { makeStyles } from '@material-ui/styles';
import { DiagCoupon } from './OrderSummary';
import { default as React } from 'react';

export const useStyles = makeStyles((theme: Theme) => {
  return {
    couponSections: {
      flexBasis: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      borderBottom: '1px solid #ddd',
      padding: '8px 0px 12px 0px',
      marginBottom: 8,
      '&>div': {
        '&>div:first-child': {
          maxWidth: 230,
          overflowX: 'clip',
          textOverflow: 'ellipsis',
          fontWeight: 500,
          fontSize: 16,
          color: '#00B38E',
          paddingBottom: 4,
          background: '#FCFDDA',
          border: '1px dashed #00B38E',
          boxSizing: 'border-box',
          borderRadius: 4,
          padding: '4px 10px',
          display: 'inline-block',
          marginBottom: 10,
        },
        '&>div:nth-of-type(2)': {
          fontWeight: 500,
          fontSize: 14,
          marginBottom: 10,
        },
        '&>div:last-of-type': {
          fontSize: 12,
          color: '#85A4AD',
          '&>span': {
            color: '#FC9916',
          },
        },
      },
      '& button': {
        background: 'none',
        boxShadow: 'none !important',
        padding: '2px 6px',
        color: '#FC9916',
        fontSize: 14,
        fontWeight: 600,
        minWidth: 'fit-content',
      },
    },
    disabledCoupon: {
      '&>div': {
        '&>div:first-child': {
          background: 'none',
          opacity: 0.6,
        },
        '&>div:nth-of-type(2)': {
          opacity: 0.6,
        },
        '&>div:last-of-type': {
          opacity: 0.6,
        },
        '&>p': {
          margin: 0,
          paddingTop: 8,
          color: '#BF2600',
          fontSize: 12,
          fontWeight: 500,
        },
        '&:last-child button': {
          color: '#FC9916',
          opacity: 0.6,
        },
      },
    },
    couponError: {
      color: '#BF2600',
      paddingTop: '5px',
      fontSize: '12px',
    },
  };
});

type CouponCardProps = {
  coupon: DiagCoupon;
  setActiveCoupon: (coupon: string) => void;
};
export const CouponCard: React.FC<CouponCardProps> = ({
  coupon,
  setActiveCoupon,
}) => {
  const classes = useStyles({});

  return (
    <div
      className={`${classes.couponSections} ${
        coupon?.disabled && classes.disabledCoupon
      }`}
    >
      <div>
        <div>{coupon?.coupon}</div>
        <div>{coupon?.textOffer}</div>
        <div>
          {coupon?.message} <span> TnC Apply</span>
        </div>
        {coupon?.couponError && (
          <p className={classes.couponError}>{coupon?.couponError}</p>
        )}
      </div>
      <div>
        <AphButton
          disabled={coupon?.disabled}
          onClick={() => {
            !coupon?.disabled && setActiveCoupon(coupon?.coupon);
          }}
        >
          Apply
        </AphButton>
      </div>
    </div>
  );
};
