import { Theme, createStyles, makeStyles } from '@material-ui/core';
import React from 'react';

interface TagTextProps {
  text: string;
  lightBackground?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tagContainer: {
      width: 'fit-content',
      padding: '2px 8px 2px 8px',
      borderRadius: '4px',
    },
    light: {
      background: '#FFFFFF',
    },
    dark: {
      background: '#E9FAEE',
    },
    text: {
      margin: 0,
      color: '#1E8E3E',
      fontSize: '10px !important',
      lineHeight: '14px',
      fontWeight: 'bold',
    },
  })
);

export const TagText: React.FC<TagTextProps> = (props) => {
  const classes = useStyles();
  return (
    <div
      className={`${classes.tagContainer} ${
        props.lightBackground ? classes.light : classes.dark
      }`}
    >
      <p className={classes.text}>{props.text}</p>
    </div>
  );
};
