import React, { useEffect, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import {
  CIRCLE_PLAN_CONFIG_TEXT,
  CIRCLE_PLAN_ID,
  DIAGNOSTICS_GROUPPLAN,
  DIAG_CIRCLE_MEMBERSHIP_MESSAGES,
  GET_CIRCLE_PLANS,
} from '../appConfig';
import AphDialog from './AphDialog';
import AphDialogClose from './AphDialogClose';
import AphButton from './AphButton';
import {
  CartDiscountObj,
  DiagnosticCartItem,
  getTestsCirclePrice,
  LineItemsObject,
  PatientObjWithLineItems,
  PlanSummary,
} from './helpers/commonHelpers';
import { useOrderContext } from '../OrderProvider';
import axios from 'axios';
import { CircularProgress } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => {
  return {
    circle: {
      marginTop: 24,
      [theme.breakpoints.down('xs')]: {
        marginTop: 15,
      },
    },
    circleWrap: {
      border: '1px solid #D1D1D1',
      boxSizing: 'border-box',
      borderRadius: '10px',
      display: 'flex',
      flexDirection: 'column',
      background: 'linear-gradient(92.82deg, #FFF6DE 0.97%, #FFDDD6 98.19%)',
      padding: 12,
      justifyContent: 'space-between',
      color: '#01475B',
      '&.removeLowerBorder': {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        borderBottom: 0,
      },
    },
    topSection: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingBottom: 12,
      '&>div': {
        flexBasis: '80%',
        '&>h3': {
          fontSize: 14,
          margin: 0,
          fontWeight: 600,
          paddingBottom: 5,
        },
        '&>p': {
          fontWeight: 500,
          fontSize: 12,
          margin: 0,
          padding: '1px 0px',
          '&>span': {
            color: '#00B38E',
            fontWeight: 600,
          },
        },
      },
      '&>img': {
        alignSelf: 'flex-start',
      },
    },
    bottomSection: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      [theme.breakpoints.down('xs')]: {
        justifyContent: 'space-between',
        flexBasis: '100%',
        marginTop: 12,
      },
    },
    plan: {
      cursor: 'pointer',
      background: '#FFFFFF',
      border: '1px solid #00B38E',
      boxSizing: 'border-box',
      backdropFilter: 'blur(40px)',
      padding: '8px 6px',
      display: 'flex',
      alignItems: 'center',
      borderRadius: 4,
      fontSize: 10,
      fontWeight: 500,
      '&>img': {
        paddingLeft: 10,
      },
      '&>span': {
        fontSize: 13,
        fontWeight: 'bold',
        paddingRight: 4,
      },
    },
    viewPlan: {
      cursor: 'pointer',
      background: '#FFFFFF',
      border: '1px solid #FC9916',
      boxSizing: 'border-box',
      padding: '7px 12px',
      borderRadius: 4,
      marginLeft: 10,
      display: 'flex',
      alignItems: 'center',
      '&>div': {
        color: '#FC9916',
        fontSize: 11,
        display: 'flex',
        alignItems: 'center',
        fontWeight: 'bold',
      },
    },
    planInCart: {
      display: 'flex',
      alignItems: 'center',
      background: '#FFFFFF',
      border: '1px solid #D1D1D1',
      boxSizing: 'border-box',
      borderRadius: '0px 0px 10px 10px',
      justifyContent: 'space-between',
      padding: '9px 12px',
      '&>div': {
        fontSize: 12,
        fontWeight: 500,
        '&>img': {
          verticalAlign: 'middle',
        },
        '&:last-child': {
          fontWeight: 'bold',
          display: 'flex',
          alignItems: 'center',
          '& span': {
            paddingRight: 7,
          },
        },
        '&:first-child': {
          [theme.breakpoints.down('xs')]: {
            flexBasis: '70%',
          },
        },
      },
    },
    circleDialog: {
      padding: 16,
    },
    header: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      fontSize: 16,
      fontWeight: 700,
    },
    savings: {
      display: 'flex',
      alignItems: 'center',
      marginTop: 10,
      '& img': {
        paddingRight: 8,
      },
      '& p': {
        margin: 0,
        fontSize: 12,
        fontWeight: 500,
        '&>span': {
          color: '#00B38E',
          fontWeight: 600,
        },
      },
    },
    planList: {
      padding: 0,
      '&>li': {
        cursor: 'pointer',
        listStyleType: 'none',
        marginBottom: 10,
        '&>div': {
          background: '#FFFFFF',
          border: '1px solid #D4D4D4',
          boxSizing: 'border-box',
          borderRadius: 4,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '13px 12px',
          fontSize: 12,
          fontWeight: 500,
          '& span': {
            fontSize: 20,
            fontWeight: 600,
            paddingRight: 5,
          },
        },
      },
    },
    activeList: {
      '&>div': {
        border: '2px solid #00B38E !important',
      },
    },
    circleFact: {
      background: '#E4F7FF',
      borderRadius: 4,
      fontSize: 11,
      fontWeight: 600,
      padding: '6px 14px',
      '&>span': {
        padding: '0px 3px',
        fontWeight: 'bold',
      },
    },
    footer: {
      display: 'flex',
      justifyContent: 'center',
      '&>button': {
        flexBasis: '50%',
        padding: '8px 0px',
        boxShadow: '0px 2px 4px 0px #00000033',
        borderRadius: 10,
      },
    },
    activePlan: {
      background: '#00B38E',
      color: '#fff',
    },
    loader: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center !important',
      minHeight: 45,
    },
  };
});

type LineItemPricing = {
  subTotal: number;
};

interface AddCircleProps {
  couponApplied: boolean;
  cartDiscountObj: CartDiscountObj;
}

export const AddCircle: React.FC<AddCircleProps> = (props) => {
  const classes = useStyles();
  const {
    patientLineItems,
    diagnosticCartItems,
    homeCollectionCharges,
    circleMemberShipPlan,
    circleBenefitsApplied,
    setCircleMemberShipPlan,
    updatePatientLineItemPricing,
    modifyFlowOrder,
  } = useOrderContext();

  const { couponApplied, cartDiscountObj } = props;
  const [plansLoader, setPlansLoader] = useState<boolean>(false);
  const [circleMembershipPopup, setCircleMembershipPop] =
    useState<boolean>(false);
  const [circlePlan, setCirclePlan] =
    useState<PlanSummary>(circleMemberShipPlan);
  const [selectedCirclePlans, setSelectedCirclePlans] =
    useState<PlanSummary>(circleMemberShipPlan);
  const [circlePlans, setCirclePlans] = useState<PlanSummary[]>(null);
  const [defaultCirclePlans, setDefaultCirclePlans] =
    useState<PlanSummary>(circleMemberShipPlan);

  useEffect(() => {
    setPlansLoader(true);
    axios
      .post(GET_CIRCLE_PLANS, { planId: CIRCLE_PLAN_ID })
      .then(({ data }) => {
        setCirclePlans(data?.GetPlanDetailsByPlanId?.response?.plan_summary);
        data?.GetPlanDetailsByPlanId?.response?.plan_summary?.map(
          (plan: PlanSummary) => {
            if (plan?.defaultPack === true) {
              setDefaultCirclePlans(plan);
              setCirclePlan(plan);
            }
          }
        );
      })
      .catch((err) => console.error('Error fetching Circle Plans', err))
      .finally(() => setPlansLoader(false));
  }, []);

  const getPatientLineItemsDiscount = (): LineItemPricing => {
    const patientLineItemPricing = {
      subTotal: 0,
    };
    patientLineItems?.forEach((patientItems: PatientObjWithLineItems) => {
      patientLineItemPricing.subTotal =
        patientLineItemPricing.subTotal +
        patientItems?.lineItems
          ?.map((lineItems: LineItemsObject) => {
            if (lineItems?.groupPlan === DIAGNOSTICS_GROUPPLAN.special) {
              return lineItems?.price;
            }
            return lineItems?.mrp;
          })
          .reduce((prevCount, currentCount) => prevCount + currentCount, 0);
    });
    return patientLineItemPricing;
  };

  const findPatientLineItems = (id: string): number => {
    let count = 0;
    patientLineItems?.forEach((patientItem: PatientObjWithLineItems) => {
      const findIndex = patientItem?.lineItems?.findIndex(
        (lineItem: LineItemsObject) => lineItem?.itemId === Number(id)
      );
      findIndex > -1 && count++;
    });
    return count;
  };

  const getDiagnoticsCircleDiscount = () => {
    const priceObject = {
      subTotal: 0,
      totalCircleDiscount: 0,
      totalCircleCashback: 0,
      totalCartSavings: 0,
    };
    if (diagnosticCartItems.length > 0) {
      const itemsArray: number[] = [];
      diagnosticCartItems.forEach((item: DiagnosticCartItem) => {
        if (itemsArray?.indexOf(item?.itemId) === -1) {
          itemsArray.push(item?.itemId);
          const circlePricing = getTestsCirclePrice(
            item?.diagnosticPricing,
            true
          );
          const totalItemCount = findPatientLineItems(item?.itemId?.toString());
          priceObject.totalCartSavings +=
            circlePricing.nonCircleMrp - circlePricing.nonCirclePrice;
          if (circlePricing?.isCircleDiscountGreater) {
            priceObject.subTotal += circlePricing.nonCircleMrp * totalItemCount;
            priceObject.totalCircleDiscount +=
              (circlePricing.nonCirclePrice - circlePricing.price) *
              totalItemCount;
          }
          if (circlePricing?.cashbackAmount > 0) {
            priceObject.totalCircleCashback +=
              circlePricing.cashbackAmount * totalItemCount;
          }
        }
      });
    }
    return priceObject;
  };

  const showCircleCard = circlePlans?.length > 0 && !modifyFlowOrder;
  const finalCircleDiscount = getDiagnoticsCircleDiscount().totalCircleDiscount;
  const finalCartSavings = getDiagnoticsCircleDiscount().totalCartSavings;
  const finalCircleCashback = getDiagnoticsCircleDiscount().totalCircleCashback;
  const finalEffectivePrice =
    getPatientLineItemsDiscount().subTotal -
    finalCircleDiscount -
    finalCartSavings -
    cartDiscountObj?.totalDiscount +
    circlePlan?.price +
    homeCollectionCharges?.charges;

  return (
    <>
      {plansLoader ? (
        <div className={classes.loader}>
          <CircularProgress size={32} />
        </div>
      ) : showCircleCard ? (
        <>
          <div className={classes.circle}>
            <div
              className={`${classes.circleWrap} ${
                selectedCirclePlans ? 'removeLowerBorder' : ''
              }`}
            >
              <div className={classes.topSection}>
                <div>
                  <h3>
                    {selectedCirclePlans
                      ? DIAG_CIRCLE_MEMBERSHIP_MESSAGES?.circle?.heading
                      : DIAG_CIRCLE_MEMBERSHIP_MESSAGES?.nonCircle?.heading}
                  </h3>
                  {(couponApplied && !circleBenefitsApplied) ||
                  (finalCircleDiscount === 0 && finalCircleCashback === 0) ? (
                    <p>{CIRCLE_PLAN_CONFIG_TEXT}</p>
                  ) : (
                    <>
                      {finalCircleDiscount > 0 && (
                        <>
                          <p>
                            {selectedCirclePlans
                              ? DIAG_CIRCLE_MEMBERSHIP_MESSAGES?.circle
                                  ?.upperLeftText
                              : DIAG_CIRCLE_MEMBERSHIP_MESSAGES?.nonCircle
                                  ?.upperLeftText}
                            <span>save ₹{finalCircleDiscount}</span> on this
                            order with Circle!
                          </p>
                          <p>
                            {selectedCirclePlans
                              ? DIAG_CIRCLE_MEMBERSHIP_MESSAGES?.circle
                                  ?.isCircleAddedToCart
                              : DIAG_CIRCLE_MEMBERSHIP_MESSAGES?.nonCircle
                                  ?.isCircleAddedToCart}
                            <span>₹{finalEffectivePrice}</span>
                          </p>
                        </>
                      )}
                      {finalCircleCashback > 0 && (
                        <p>
                          {selectedCirclePlans
                            ? DIAG_CIRCLE_MEMBERSHIP_MESSAGES?.circle
                                ?.isCashbackAvailable
                            : DIAG_CIRCLE_MEMBERSHIP_MESSAGES?.nonCircle
                                ?.isCashbackAvailable}
                          <span>₹{finalCircleCashback}</span>
                        </p>
                      )}
                    </>
                  )}
                </div>
                <img src={require('../images/ic_circleweb.png')} alt="" />
              </div>
              <div className={classes.bottomSection}>
                <div
                  className={`${classes.plan} ${
                    selectedCirclePlans && classes.activePlan
                  }`}
                  onClick={() => {
                    if (!selectedCirclePlans) {
                      setSelectedCirclePlans(defaultCirclePlans);
                      setCirclePlan(defaultCirclePlans);
                      setCircleMemberShipPlan(defaultCirclePlans);
                      updatePatientLineItemPricing(true);
                    } else {
                      setSelectedCirclePlans(null);
                      setCircleMemberShipPlan(null);
                      updatePatientLineItemPricing(false);
                    }
                  }}
                >
                  <span>₹{defaultCirclePlans?.price}</span>for{' '}
                  {defaultCirclePlans?.valid_duration &&
                    defaultCirclePlans?.valid_duration / 30}{' '}
                  months
                  <img
                    src={require(`../images/${
                      selectedCirclePlans
                        ? 'ic_checkactive.png'
                        : 'ic_checkdefault.png'
                    }`)}
                    alt=""
                  />
                </div>

                <div
                  className={classes.viewPlan}
                  onClick={() => {
                    setCircleMembershipPop(true);
                  }}
                >
                  <div>
                    View Plans{' '}
                    <img
                      src={require('../images/ic_accordion_up.svg')}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            {selectedCirclePlans && (
              <div className={classes.planInCart}>
                <div>
                  Circle Membership for{' '}
                  {selectedCirclePlans &&
                    selectedCirclePlans?.valid_duration / 30}{' '}
                  months added to cart
                </div>
                <div>
                  <span>₹{selectedCirclePlans?.price}</span>
                  <img
                    onClick={() => {
                      setSelectedCirclePlans(null);
                      setCircleMemberShipPlan(null);
                      updatePatientLineItemPricing(false);
                    }}
                    src={require('../images/ic_cross_onorange_small.svg')}
                    alt=""
                  />{' '}
                </div>
              </div>
            )}
          </div>
          <AphDialog open={circleMembershipPopup} maxWidth="sm">
            <AphDialogClose
              onClick={() => setCircleMembershipPop(false)}
              title={'Close'}
            />
            <div className={classes.circleDialog}>
              <div className={classes.header}>
                <div>Circle Membership</div>
                <div>
                  <img src={require('../images/ic_circleweb.png')} alt="" />
                </div>
              </div>

              <div className={classes.savings}>
                <div>
                  <img src={require('../images/ic_savings.png')} alt="" />
                </div>
                <div>
                  {(couponApplied && !circleBenefitsApplied) ||
                  (finalCircleDiscount === 0 && finalCircleCashback === 0) ? (
                    <p>{CIRCLE_PLAN_CONFIG_TEXT}</p>
                  ) : (
                    <>
                      {finalCircleDiscount > 0 && (
                        <>
                          <p>
                            {selectedCirclePlans
                              ? DIAG_CIRCLE_MEMBERSHIP_MESSAGES?.circle
                                  ?.upperLeftText
                              : DIAG_CIRCLE_MEMBERSHIP_MESSAGES?.nonCircle
                                  ?.upperLeftText}
                            <span>save ₹{finalCircleDiscount}</span> on this
                            order with Circle!
                          </p>
                          <p>
                            {selectedCirclePlans
                              ? DIAG_CIRCLE_MEMBERSHIP_MESSAGES?.circle
                                  ?.isCircleAddedToCart
                              : DIAG_CIRCLE_MEMBERSHIP_MESSAGES?.nonCircle
                                  ?.isCircleAddedToCart}
                            <span>₹{finalEffectivePrice}</span>
                          </p>
                        </>
                      )}
                      {finalCircleCashback > 0 && (
                        <p>
                          {selectedCirclePlans
                            ? DIAG_CIRCLE_MEMBERSHIP_MESSAGES?.circle
                                ?.isCashbackAvailable
                            : DIAG_CIRCLE_MEMBERSHIP_MESSAGES?.nonCircle
                                ?.isCashbackAvailable}
                          <span>₹{finalCircleCashback}</span>
                        </p>
                      )}
                    </>
                  )}
                </div>
              </div>

              <ul className={classes.planList}>
                {circlePlans?.length > 0 &&
                  circlePlans?.map(
                    (plan: PlanSummary, index: number) =>
                      plan?.subPlanId !== 'Circle_Monthly' && (
                        <li
                          key={plan?.subPlanId}
                          className={
                            circlePlan?.subPlanId === plan?.subPlanId
                              ? classes.activeList
                              : ''
                          }
                          onClick={() => setCirclePlan(plan)}
                        >
                          <div>
                            <div>
                              <span>₹{plan?.price}</span>for{' '}
                              {plan?.durationInMonth}
                              months
                            </div>
                            <img
                              src={require(`../images/${
                                circlePlan?.subPlanId === plan?.subPlanId
                                  ? 'ic_checkactive.png'
                                  : 'ic_checkdefault.png'
                              }`)}
                              alt=""
                            />
                          </div>
                        </li>
                      )
                  )}
              </ul>

              <div className={classes.footer}>
                <AphButton
                  color="primary"
                  onClick={() => {
                    setDefaultCirclePlans(circlePlan);
                    setSelectedCirclePlans(circlePlan);
                    setCircleMembershipPop(false);
                    setCircleMemberShipPlan(circlePlan);
                    updatePatientLineItemPricing(true);
                  }}
                >
                  SELECT PLAN
                </AphButton>
              </div>
            </div>
          </AphDialog>
        </>
      ) : (
        <></>
      )}
    </>
  );
};
