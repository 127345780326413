import { default as React } from 'react';
import { makeStyles } from '@material-ui/styles';
import { LazyIntersection } from './lib/LazyIntersection';
import AphButton from './AphButton';
import { CDN_URL } from '../appConfig';

const useStyles = makeStyles(() => {
  return {
    '@keyframes appearIn': {
      '0%': {
        opacity: 0,
        transform: 'scale(0)',
      },
      '100%': {
        opacity: 1,
        transform: 'scale(1.0)',
      },
    },
    success: {
      padding: '60px 0px',
      textAlign: 'center',
      '& h5': {
        fontSize: 24,
        fontWeight: 600,
      },
      '& p': {
        fontSize: 14,
        fontWeight: 500,
        margin: 0,
        marginBottom: 36,
      },
      '& img': {
        width: 103,
        height: 83,
        animation: '$appearIn 0.9s ease-in-out',
      },
    },
    gotIt: {
      color: '#fff',
      position: 'absolute',
      bottom: 30,
      left: 48,
      width: 'calc(100% - 96px)',
      background: '#31CD95 !important',
      padding: '11px 0px',
      fontSize: 14,
    },
    tryAgain: {
      background: '#E94825 !important',
    },
    addWordBreak: {
      wordBreak: 'break-word',
    },
  };
});

interface RadiologyConfirmationProps {
  showConfirmation: boolean;
  isSuccess: boolean;
  btnAction: () => void;
  userName: string;
  department: string[];
  testDescription?: string;
}

const RadiologyConfirmation: React.FC<RadiologyConfirmationProps> = (props) => {
  const {
    isSuccess,
    showConfirmation,
    btnAction,
    userName,
    department,
    testDescription,
  } = props;

  const classes = useStyles({});
  return showConfirmation ? (
    <div className={classes.success}>
      {isSuccess ? (
        <>
          <LazyIntersection
            src={`${CDN_URL}/images/Radiology/ic_success.svg`}
            alt=""
          />
          <h5>Hey, {userName}!</h5>
          <p>
            We've received your query request for {department?.toString()}{' '}
            <p className={classes.addWordBreak}>
              {testDescription?.length > 0 ? `for ${testDescription}` : ''}
            </p>
            .
          </p>
          <AphButton className={classes.gotIt} onClick={btnAction}>
            Start New Order
          </AphButton>
        </>
      ) : (
        <>
          <LazyIntersection
            src={`${CDN_URL}/images/Radiology/ic_error.svg`}
            alt=""
          />
          <h5>Uh oh.. :(</h5>
          <p>
            {`This generally doesn’t happen, we will look into this doesn't happen
            again.`}
          </p>
          <p>Please try again.</p>
          <AphButton
            className={`${classes.gotIt} ${classes.tryAgain}`}
            onClick={btnAction}
          >
            TRY AGAIN!
          </AphButton>
        </>
      )}
    </div>
  ) : (
    <></>
  );
};

export default RadiologyConfirmation;
