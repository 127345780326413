export enum RadiologyInputFields {
  USER_NAME = 'userName',
  MOBILE_NUMBER = 'mobileNumber',
  LOCATION = 'location',
  APPOINTMENT_DATE = 'appointmentDate',
  DEPARTMENT = 'department',
  TEST_DESCRIPTION = 'testDescription',
  HOSPITAL = 'unitId',
  UPLOAD_FILE = 'uploadedFile',
}

export type RadiologyFormField = {
  isTouched: boolean;
  isValid: boolean;
  value: string;
  helperText: string;
};

export type RadiologyPrescriptionData = {
  isTouched: boolean;
  isValid: boolean;
  value: string;
  helperText: string;
  fileName: string;
};

export type RadiologyUploadedFileData = {
  mimeType: string;
  base64Content: string;
  url: string;
};
export type RadiolofyFormTestField = {
  isTouched: boolean;
  isValid: boolean;
  value: string[];
  helperText: string;
};

export type RadiologyFormData = {
  userName: RadiologyFormField;
  mobileNumber: RadiologyFormField;
  location: RadiologyFormField;
  unitId: RadiologyFormField;
  department: RadiolofyFormTestField;
  testDescription: RadiologyFormField;
  appointmentDate: RadiologyFormField;
  uploadedFile: RadiologyPrescriptionData;
};

export interface GetRadiologyLocations {
  __typename: 'ILocations';
  location: string | null;
  locationId: string | null;
}

export enum RadiologyUnitTypes {
  CLINIC = 'CLINIC',
  HOSPITAL = 'HOSPITAL',
}

export interface HospitalClinicData {
  __typename: 'IRadiologyHospitals';
  unitName: string | null;
  unitType: RadiologyUnitTypes | null;
  unitId: string | null;
}

export interface TestData {
  __typename: 'IDepartment';
  department: string | null;
  departmentId: string | null;
}

export interface SubTestData {
  __typename: 'ITest';
  serviceName: string | null;
  serviceId: string | null;
}

export interface radiologyPrescriptionFileProperties {
  content: string;
  mimeType: string;
}

export interface RadiologyBookingParams {
  userName: string;
  mobileNumber: string;
  testNames: (string | null)[];
  location: string;
  testDetails: string;
  appointmentDate?: any | null;
  unitId: string;
  prescriptionLink?: string | null;
  agentId: string;
  source: string;
}
