import React, { useState } from 'react';
import AphDialogTitle from './AphDialogTitle';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { GoogleMap, Marker } from '@react-google-maps/api';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import Scrollbars from 'react-custom-scrollbars';
import { findAddrComponents, PatientAddress } from './helpers/commonHelpers';
import axios from '../axios';
import { CircularProgress, Grid, Typography } from '@material-ui/core';
import {
  ADDADDRESS_TITLES,
  REACT_APP_GOOGLE_API_KEY,
  REACT_APP_GOOGLE_MAP_API,
  SEARCH_KEYWORD_LIMIT,
} from '../appConfig';
import useDebounce from '../hooks/useDebounce';
import AphTextField from './AphTextField';
import AphButton from './AphButton';

const useStyles = makeStyles((theme: Theme) => {
  return {
    mapContentMain: {
      width: '100%',
      height: '100%',
      [theme.breakpoints.down('xs')]: {
        height: '74vh',
        '& div.gmnoprint': {
          display: 'none',
        },
      },
      '& div.gm-style-mtc': {
        display: 'none',
      },
      '& div.gm-svpc': {
        display: 'none',
      },
      '& button.gm-fullscreen-control': {
        display: 'none',
      },
    },
    markerTitle: {
      position: 'absolute',
      padding: '0 10px',
      zIndex: 1,
      right: '32%',
      top: '23%',
      fontSize: 11,
      fontWeight: 600,
      backgroundColor: '#01475B',
      color: '#fff',
      height: 35,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: 10,
      [theme.breakpoints.down('xs')]: {
        top: '50%',
        marginTop: '-88px',
      },
    },
    searchItemList: {
      paddingLeft: 35,
      position: 'relative',
      '& img': {
        position: 'absolute',
        maxWidth: 30,
        left: 0,
        top: 5,
      },
    },
    progressLoader: {
      textAlign: 'center',
      padding: 20,
    },
    searchResult: {
      '-webkit-box-orient': 'vertical',
      '-webkit-line-clamp': '1',
      overflow: 'hidden',
      display: '-webkit-box',
    },
    noResults: {
      textAlign: 'center',
      backgroundColor: '#02475b',
      color: '#fff',
      padding: 10,
    },
    shadowHide: {
      overflow: 'hidden',
    },
    dialogTitle: {
      zIndex: 1,
      paddingTop: 20,
      paddingBottom: 20,
      '& h2': {
        fontSize: 16,
        fontWeight: 500,
      },
    },
    pl_25: {
      paddingLeft: 25,
    },
    goBack: {
      display: 'none',
      [theme.breakpoints.down('xs')]: {
        display: 'block',
        position: 'absolute',
        top: 10,
        zIndex: 2,
        boxShadow: 'none',
      },
    },
    goBackMap: {
      display: 'flex',
      alignItems: 'center',
      position: 'absolute',
      top: 10,
      zIndex: 2,
      boxShadow: 'none',
      paddingLeft: 0,
      paddingRight: 0,
    },
    searchPlace: {
      display: 'none',
      [theme.breakpoints.down('xs')]: {
        display: 'block',
        position: 'absolute',
        top: 10,
        zIndex: 2,
        boxShadow: 'none',
      },
    },
    dialogContent: {
      paddingTop: 10,
      position: 'relative',
    },
    locateContainer: {
      [theme.breakpoints.down('xs')]: {
        height: '100%',
      },
    },
    locateContent: {
      background: '#fff',
      padding: 25,
      position: 'relative',
      borderRadius: '0 0 10px 10px',
      '& h2': {
        fontSize: 16,
        fontWeight: 700,
        margin: '0 0 10px',
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down(300)]: {
          fontSize: 13,
          marginTop: '26px',
        },
        '& img': {
          margin: '0 10px 0 0',
        },
      },
      '& p': {
        fontSize: 13,
        lineHeight: '17px',
        margin: '0 0 20px',
      },

      [theme.breakpoints.down('xs')]: {
        position: 'fixed',
        bottom: 0,
        padding: '10px 15px 20px',
        width: '100%',
        height: 'auto',
        zIndex: 99,
        boxSizing: 'border-box',
      },
    },
    confirmLocation: {
      width: '100%',
    },
    changeLocation: {
      position: 'absolute',
      top: 5,
      right: 10,
      padding: '5px 20px',
      textAlign: 'center',
      fontSize: 11,
      fontWeight: 500,
      background: 'rgb(255 153 22 / 0.3)',
      textTransform: 'uppercase',
      borderRadius: 10,
      boxShadow: 'none',
      '&:hover': {
        background: 'rgb(255 153 22 / 0.3)',
      },
    },
    mapContainer: {
      background: '#F7F8F5',
      [theme.breakpoints.down('xs')]: {
        height: 'calc(100% - 180px)',
      },
    },
    mapcontent: {
      width: '100%',
      height: 330,
      position: 'relative',
      [theme.breakpoints.down('xs')]: {
        height: '100%',
      },
    },
    buttonDisable: {
      backgroundColor: '#fed984',
      boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.2) !important',
    },
    notServiceable: {
      backgroundColor: '#D23D3D',
      '&:hover': {
        backgroundColor: '#d75050',
      },
    },
    addAddressContent: {
      padding: 20,
      '&::-webkit-scrollbar': {
        width: 4,
      },
      '&::-webkit-scrollbar-track': {
        background: '#f1f1f1',
      },
      '&::-webkit-scrollbar-thumb': {
        background: ' #888',
      },
      [theme.breakpoints.down('xs')]: {
        height: '120%',
      },
    },
    addressTitle: {
      fontSize: 13,
      fontWeight: 600,
      margin: '0 0 10px 10px',
      textTransform: 'uppercase',
    },
    addressGroup: {
      borderRadius: 10,
      boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.2)',
      backgroundColor: '#f7f8f5',
      padding: 10,
      paddingBottom: 5,
      marginBottom: 10,
    },
    formGroup: {
      paddingBottom: 10,
      fontSize: 16,
      color: '#01475b',
      fontWeight: 500,
      '& label': {
        fontSize: 14,
        fontWeight: 500,
        color: '#01475b',
      },
      '& textarea': {
        padding: 0,
        border: 'none',
        borderBottom: '2px solid #00b38e',
        borderRadius: 0,
        paddingTop: 9,
        paddingBottom: 5,
      },
    },
    helpText: {
      paddingLeft: 20,
      paddingRight: 20,
    },
    addressOptions: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
      '& >div': {
        width: '45%',
        '& label': {
          display: 'block',
          margin: '0 0 -10px',
        },
      },
    },
    btnGroup: {
      paddingTop: 7,
      '& button': {
        width: '100%',
        color: '#00b38e',
        backgroundColor: theme.palette.common.white,
        fontSize: 16,
        fontWeight: 500,
      },
    },
    btnActive: {
      backgroundColor: '#00b38e !important',
      color: '#fff !important',
    },
    genderBtns: {
      boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.2)',
      padding: '7px 13px 7px 13px',
      textTransform: 'capitalize',
      borderRadius: 10,
    },
    editUser: {
      position: 'absolute',
      top: 10,
      right: 10,
      boxShadow: 'none',
    },
    dialogActions: {
      padding: 20,
      paddingTop: 10,
      boxShadow: '0 -5px 20px 0 #ffffff',
      position: 'relative',
      '& button': {
        borderRadius: 10,
      },
    },
    searchContainer: {
      margin: 20,
      padding: 15,
      backgroundColor: '#fff',
      boxShadow: '0px 5px 20px rgba(0, 0, 0, 0.25)',
      borderRadius: 10,
    },
    searchInput: {
      position: 'relative',
      '& >div:first-child': {
        width: 'calc(100% - 40px)',
      },
    },
    popLocationIcon: {
      position: 'absolute',
      left: 5,
      top: 9,
    },
    locationAutoComplete: {
      color: '#02475b',
      paddingTop: 10,
      height: 330,
      overflow: 'auto',
      '&::-webkit-scrollbar': {
        width: 4,
      },
      '&::-webkit-scrollbar-track': {
        background: '#f1f1f1',
      },
      '&::-webkit-scrollbar-thumb': {
        background: ' #888',
      },
      [theme.breakpoints.down('xs')]: {
        height: 'auto',
      },
      '& ul': {
        margin: 0,
        padding: 0,
        '& li': {
          color: '#02475b',
          cursor: 'pointer',
          padding: '8px 0',
          fontSize: 16,
          fontWeight: 500,
          borderBottom: '0.5px solid rgba(2,71,91,0.3)',
          listStyleType: 'none',
          '&:last-child': {
            paddingBottom: 0,
            borderBottom: 'none',
          },
        },
      },
    },
    inputSearchIcon: {
      '& input': {
        paddingLeft: 30,
      },
    },
    loading: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      zIndex: 10,
      backgroundColor: '#0c0c0c',
      opacity: 0.4,
    },
    topSection: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: 10,
      '& > button': {
        padding: '5px 8px',
        fontSize: 12,
      },
    },
  };
});

enum PATIENT_ADDRESS_TYPE {
  HOME = 'HOME',
  OFFICE = 'OFFICE',
  OTHER = 'OTHER',
}

type AddNewAddressProps = {
  setShowLocationPicker: (showLocationPicker: boolean) => void;
  handleSaveAddress: (
    patientAddress: PatientAddress,
    saveLatLng?: boolean
  ) => void;
  checkServiceability: (
    latitude: number,
    longitude: number,
    address?: PatientAddress
  ) => Promise<any>;
  serviceable: boolean;
  geocodeLatLng: (
    zipcode: string,
    addressString?: string
  ) => Promise<{
    status: boolean;
    lat: any;
    lng: any;
  }>;
  showManualAddressDialog: (showManualAddressDialog: boolean) => void;
  renderErrorAtBottom: (errorMsg: string) => void;
  addAddressLoader: boolean;
  addressAdditionError: string;
  zeroResultsError: boolean;
  setZeroResultsError: (value: boolean) => void;
  setAddressAdditionError: (value: string) => void;
};

export const AddNewAddress: React.FC<AddNewAddressProps> = (props) => {
  const classes = useStyles();

  const {
    setShowLocationPicker,
    handleSaveAddress,
    checkServiceability,
    serviceable,
    geocodeLatLng,
    showManualAddressDialog,
    addAddressLoader,
    addressAdditionError,
    zeroResultsError,
    renderErrorAtBottom,
    setZeroResultsError,
    setAddressAdditionError,
  } = props;

  const [mapRef, setMapRef] = useState<any>(null);
  const [latitude, setLatitude] = useState<number>(null);
  const [longitude, setLongitude] = useState<number>(null);
  const [addressScreen, setAddressScreen] = useState<string>('');
  const [formattedAddress, setFormattedAddress] = useState<string>('');
  const [state, setState] = useState<string>('');
  const [fetchLocation, setFetchLocation] = useState<boolean>(true);
  const [fetchingAddress, setFetchingAddress] = useState<boolean>(false);
  const [address1, setAddress1] = useState<string>('');
  const [address2, setAddress2] = useState<string>('');
  const [landmark, setLandmark] = useState<string>('');
  const [pincode, setPincode] = useState<string>('');
  const [addressType, setAddressType] = useState<string>(
    PATIENT_ADDRESS_TYPE.HOME
  );
  const [addressCity, setAddressCity] = React.useState<string>(null);
  const [searchInput, setSearchInput] = React.useState<string>('');
  const debounceSearchInput = useDebounce(searchInput, 500);

  const setAddressDetails = (lat: number, lng: number) => {
    setFetchingAddress(true);
    setZeroResultsError(false);
    setAddressAdditionError('');
    checkServiceability(lat, lng).then((res) => {
      const googleMapsApiUrl = `${REACT_APP_GOOGLE_MAP_API}?latlng=${lat},${lng}&key=${REACT_APP_GOOGLE_API_KEY}`;
      axios
        .get(googleMapsApiUrl)
        .then(({ data }) => {
          if (data && data.results[0] && data.results[0].address_components) {
            const addrComponents = data.results[0].address_components || [];
            const areaDetails = [
              findAddrComponents('premise', addrComponents, 'state'),
              findAddrComponents('street_number', addrComponents, 'state'),
              findAddrComponents('route', addrComponents, 'state'),
              findAddrComponents('neighborhood', addrComponents, 'state'),
              findAddrComponents(
                'sublocality_level_3',
                addrComponents,
                'state'
              ),
              findAddrComponents('sublocality_level_2', addrComponents),
              findAddrComponents('sublocality_level_1', addrComponents),
              findAddrComponents('locality', addrComponents),
            ].filter((i) => i);
            setFormattedAddress(data.results[0].formatted_address);

            const selectedState = findAddrComponents(
              'administrative_area_level_1',
              addrComponents,
              'state'
            );
            const selectedPincode = findAddrComponents(
              'postal_code',
              addrComponents
            );
            const city = (
              addrComponents.find(
                (item: any) =>
                  item.types.indexOf('locality') > -1 ||
                  item.types.indexOf('administrative_area_level_2') > -1
              ) || {}
            ).long_name;
            setState(selectedState);
            setLatitude(lat);
            setLongitude(lng);
            setPincode(selectedPincode);
            setAddressCity(city);
            areaDetails &&
              areaDetails.length &&
              setAddress2(areaDetails.join(', '));
            geocodeLatLng(selectedPincode, areaDetails.join(', ')).then(
              (data) => console.log('B ', { lat: data?.lat, lng: data?.lng })
            );
          }
        })
        .catch((e: any) => {
          console.error(e);
        })
        .finally(() => {
          setFetchingAddress(false);
          setFetchLocation(false);
        });
    });
  };

  const renderMap = () => {
    return (
      <>
        <GoogleMap
          onLoad={(map: any) => setMapRef(map)}
          onCenterChanged={() => {
            const latLng = mapRef ? mapRef?.getCenter()?.toJSON() : {};
            setLatitude(latLng?.lat || latitude);
            setLongitude(latLng?.lng || longitude);
          }}
          onDragEnd={() => {
            const latLng = mapRef ? mapRef.getCenter().toJSON() : {};
            setAddressDetails(latLng && latLng.lat, latLng && latLng.lng);
          }}
          center={{ lat: latitude, lng: longitude }}
          zoom={18}
          mapContainerClassName={classes.mapContentMain}
          options={{
            gestureHandling: 'greedy',
            scrollwheel: false,
          }}
        >
          <Marker
            position={{ lat: latitude, lng: longitude }}
            icon={{
              url: require(`../images/ic_place.svg`),
            }}
          />
          {latitude && longitude && (
            <div className={classes.markerTitle}>MOVE MAP TO ADJUST</div>
          )}
        </GoogleMap>
      </>
    );
  };

  const renderSuggestion = (mainText: string, subText: string) => {
    return (
      <div className={classes.searchItemList}>
        <img
          src={require('../images/ic_location.svg')}
          alt="Location"
          title={'gps'}
        />
        <span
          style={{ fontWeight: 600, fontSize: 14 }}
          className={classes.searchResult}
        >
          {mainText}
        </span>
        <div
          style={{ fontWeight: 400, fontSize: 11 }}
          className={classes.searchResult}
        >
          {subText}
        </div>
      </div>
    );
  };

  const patientAddressTypes = [
    PATIENT_ADDRESS_TYPE.HOME,
    PATIENT_ADDRESS_TYPE.OFFICE,
    PATIENT_ADDRESS_TYPE.OTHER,
  ];

  const disableSubmit =
    !serviceable ||
    address1.length === 0 ||
    address2.length === 0 ||
    addressType.length <= 0 ||
    pincode.length < 6;

  const prepareAddress = () => {
    const newAddress: PatientAddress = {
      addressLine1: address1,
      addressLine2: address2,
      addressType: addressType,
      city: addressCity,
      id: '',
      landmark: landmark,
      latitude: latitude,
      longitude: longitude,
      mobileNumber: '',
      otherAddressType: '',
      state: state,
      zipcode: pincode,
    };

    if (newAddress?.latitude && newAddress?.longitude) {
      checkServiceability(newAddress?.latitude, newAddress?.longitude).then(
        (res) => {
          if (res?.data?.status) {
            handleSaveAddress(newAddress);
          }
        }
      );
    }
  };

  const handleChange = (address: string) => {
    setSearchInput(address);
  };

  const handleSelect = (address: string) => {
    geocodeByAddress(address)
      .then((results: any) => getLatLng(results[0]))
      .then((resObj: any) => {
        if (resObj) {
          setLatitude(resObj.lat);
          setLongitude(resObj.lng);
          setAddressDetails(resObj.lat, resObj.lng);
          setAddressScreen('map');
          setSearchInput('');
        }
      })
      .catch((error: any) => console.error(error));
  };

  return (
    <div className={classes.shadowHide}>
      <AphButton
        onClick={() =>
          addressScreen.toLowerCase() === 'details' ||
          addressScreen.toLowerCase() === 'searchplace'
            ? setAddressScreen('map')
            : setShowLocationPicker(false)
        }
        title={'Close'}
        className={`${
          addressScreen.toLowerCase() === 'map'
            ? classes.goBack
            : addressScreen.toLowerCase() === 'details' ||
              addressScreen.toLowerCase() === 'searchplace'
            ? classes.goBackMap
            : classes.searchPlace
        }`}
      >
        <img
          width="25"
          height="24"
          src={require('../images/ic_back.svg')}
          alt="Back Button"
        />
      </AphButton>

      <AphDialogTitle
        className={`${classes.dialogTitle} ${
          addressScreen.toLowerCase() === 'details' ||
          addressScreen.toLowerCase() === 'searchplace'
            ? classes.pl_25
            : ''
        }`}
      >
        {addressScreen === 'details'
          ? ADDADDRESS_TITLES.ADD_NEW_ADDRESS
          : ADDADDRESS_TITLES.SET_LOCATION}
      </AphDialogTitle>
      <div className={classes.dialogContent}>
        {(fetchingAddress || addAddressLoader) && (
          <div className={classes.loading}></div>
        )}
        {addressScreen === 'map' ? (
          <Scrollbars autoHide={true} autoHeight autoHeightMax={'75vh'}>
            <div className={classes.locateContainer}>
              <div className={classes.mapContainer}>
                <div className={classes.mapcontent}>{renderMap()}</div>
              </div>
              <div className={classes.locateContent}>
                <AphButton
                  className={classes.changeLocation}
                  onClick={() => setAddressScreen('searchPlace')}
                >
                  Change
                </AphButton>
                <Typography component="h2">
                  <img src={require('../images/ic_location.svg')} alt="" /> Help
                  us locate your address
                </Typography>
                <Typography>
                  {formattedAddress ||
                    `${fetchLocation ? '-' : 'Location Not Found'}`}
                </Typography>
                <AphButton
                  color="primary"
                  disabled={!latitude || !longitude || !pincode}
                  className={`${classes.confirmLocation} ${
                    !serviceable
                      ? classes.notServiceable
                      : !latitude || !longitude || !pincode
                      ? classes.buttonDisable
                      : ''
                  }`}
                  onClick={() =>
                    serviceable
                      ? setAddressScreen('details')
                      : setAddressScreen('searchPlace')
                  }
                >
                  {serviceable ? `Confirm Location` : `Not Serviceable`}
                </AphButton>
              </div>
            </div>
          </Scrollbars>
        ) : addressScreen === 'details' ? (
          <>
            <Scrollbars autoHide={true} autoHeight autoHeightMax={'65vh'}>
              <div>
                <div className={classes.addAddressContent}>
                  <Typography component="h2" className={classes.addressTitle}>
                    Address Details
                  </Typography>
                  <div className={classes.addressGroup}>
                    <div className={classes.formGroup}>
                      <AphTextField
                        multiline
                        placeholder="*House no | Apartment name"
                        label="*House no | Apartment name"
                        onChange={(e) => {
                          setZeroResultsError(false);
                          setAddressAdditionError('');
                          let value = e.target.value;
                          value = value.replace(/^\s+|\s+(?=\s)/g, ''); // remove leading and multiple spaces
                          setAddress1(value);
                        }}
                        inputProps={{
                          maxLength: 100,
                        }}
                        value={address1}
                      />
                    </div>
                    <div className={classes.formGroup}>
                      <AphTextField
                        placeholder="*Area Details"
                        label="*Area Details"
                        disabled
                        onChange={(e) => {
                          let value = e.target.value;
                          value = value.replace(/^\s+|\s+(?=\s)/g, ''); // remove leading and multiple spaces
                          setAddress2(value);
                        }}
                        inputProps={{
                          maxLength: 100,
                        }}
                        value={address2}
                      />
                    </div>
                    <div className={classes.addressOptions}>
                      <div className={classes.formGroup}>
                        <AphTextField
                          label="*Pincode"
                          placeholder="*Pincode"
                          disabled
                          onChange={(e) => {
                            if (e.target.value.length !== 6) {
                              setPincode('');
                            }
                            setPincode(e.target.value);
                          }}
                          onKeyPress={(e) => {
                            if (e.key !== 'Enter' && isNaN(parseInt(e.key, 10)))
                              e.preventDefault();
                          }}
                          inputProps={{
                            maxLength: 6,
                          }}
                          value={pincode}
                        />
                      </div>
                      <div className={classes.formGroup}>
                        <label>City</label>
                        <AphTextField
                          value={addressCity}
                          disabled
                          onChange={(e) => setAddressCity(e.target.value)}
                          placeholder="City"
                        />
                      </div>
                    </div>
                    <div className={classes.formGroup}>
                      <AphTextField
                        placeholder="Landmark (Optional)"
                        onChange={(e) => {
                          setZeroResultsError(false);
                          setAddressAdditionError('');
                          let value = e.target.value;
                          value = value.replace(/^\s+|\s+(?=\s)/g, ''); // remove leading and multiple spaces
                          setLandmark(value);
                        }}
                        inputProps={{
                          maxLength: 60,
                        }}
                        value={landmark}
                      />
                    </div>
                    <div className={classes.formGroup}>
                      <AphTextField
                        label="State"
                        placeholder="State"
                        disabled
                        inputProps={{
                          maxLength: 100,
                        }}
                        value={state}
                        onChange={(e) => setState(e.target.value)}
                      />
                    </div>
                    <div className={classes.formGroup}>
                      <label>Choose Address type</label>
                      <Grid container spacing={2} className={classes.btnGroup}>
                        {patientAddressTypes.map((addressTypeValue) => {
                          return (
                            <Grid
                              item
                              xs={4}
                              sm={4}
                              key={`address_${addressTypeValue}`}
                            >
                              <AphButton
                                color="secondary"
                                className={`${classes.genderBtns} ${
                                  addressType === addressTypeValue
                                    ? classes.btnActive
                                    : ''
                                }`}
                                onClick={() => {
                                  setAddressType(addressTypeValue);
                                }}
                                value={addressType}
                              >
                                {addressTypeValue}
                              </AphButton>
                            </Grid>
                          );
                        })}
                      </Grid>
                    </div>
                    {addressAdditionError.length > 0 &&
                      renderErrorAtBottom(addressAdditionError)}
                    {zeroResultsError &&
                      renderErrorAtBottom(
                        'No Results found from Google for this pincode and address combination'
                      )}
                  </div>
                </div>
              </div>
            </Scrollbars>
            <div className={classes.dialogActions}>
              <AphButton
                color="primary"
                fullWidth
                disabled={disableSubmit}
                className={disableSubmit ? classes.buttonDisable : ''}
                onClick={() => prepareAddress()}
                title={'Save and use'}
              >
                SAVE AND USE
              </AphButton>
            </div>
          </>
        ) : (
          <div className={classes.searchContainer}>
            <PlacesAutocomplete
              value={searchInput}
              onChange={handleChange}
              onSelect={handleSelect}
              searchOptions={{
                componentRestrictions: { country: ['in'] },
              }}
            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading,
              }) => {
                return (
                  <div>
                    <div className={classes.topSection}>
                      <Typography>Set Pickup Location</Typography>
                      <AphButton onClick={() => showManualAddressDialog(true)}>
                        Use Address Form
                      </AphButton>
                    </div>
                    <div className={classes.searchInput}>
                      <div className={classes.popLocationIcon}>
                        <img
                          src={require('../images/ic_search.svg')}
                          alt=""
                          title={'Location'}
                        />
                      </div>
                      <AphTextField
                        placeholder="Search for Area, Street Name ..."
                        {...getInputProps()}
                        onKeyDown={(e) => {
                          e.key === 'Enter' && e.preventDefault();
                        }}
                        type="search"
                        title={'Search for Area, Street Name ...'}
                        className={classes.inputSearchIcon}
                      />
                      <div className={classes.locationAutoComplete}>
                        {loading ? (
                          <div className={classes.progressLoader}>
                            <CircularProgress size={30} />
                          </div>
                        ) : suggestions.length ? (
                          <ul>
                            {suggestions.map((suggestion, index) => {
                              return suggestion.index < SEARCH_KEYWORD_LIMIT ? (
                                <li {...getSuggestionItemProps(suggestion)}>
                                  {renderSuggestion(
                                    suggestion.formattedSuggestion.mainText,
                                    suggestion.formattedSuggestion.secondaryText
                                  )}
                                </li>
                              ) : null;
                            })}
                          </ul>
                        ) : debounceSearchInput.length && searchInput.length ? (
                          <div className={classes.noResults}>
                            No Results Found
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                );
              }}
            </PlacesAutocomplete>
          </div>
        )}
      </div>
    </div>
  );
};
