export const GET_RADIOLOGY_LOCATIONS = ` query GetRadiologyLocations {
  getRadiologyLocations {
    location
    locationId
  }
}`;
export const GET_RADIOLOGY_HOSPITALS = `query GetRadiologyHospitalsClinics($regionId: String, $source: String) {
    getRadiologyHospitalsClinics(regionId: $regionId, source: $source) {
      unitName
      unitType
      unitId
    }
  }`;

export const GET_RADIOLOGY_TESTS = `query GetRadiologyDepartments($locationId: String) {
    getRadiologyDepartments(regionId: $locationId) {
      department
      departmentId
    }
  }`;

export const UPLOAD_RADIOLOGY_PRESCRIPTION = `mutation UploadRadiologyPrescription(
    $prescriptionInput: radiologyPrescriptionFileProperties
  ) {
    uploadRadiologyPrescription(prescriptionInput: $prescriptionInput) {
      status
      message
    }
  }`;

export const BOOK_RADIOLOGY_TEST = `mutation BookRadiologyTest($bookingParams: RadiologyBookingParams) {
    bookRadiologyTest(bookingParams: $bookingParams) {
      status
    }
  }
`;
