import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
  DiagnosticCartItem,
  FinalCartItemsToDisplay,
  getPatientAge,
  getTestsCirclePrice,
  isCacheDataExpired,
  LineItemsObject,
  parseStatus,
  PatientObjWithLineItems,
  SearchResult,
} from './helpers/commonHelpers';
import {
  Button,
  ClickAwayListener,
  FormHelperText,
  Paper,
  TextField,
} from '@material-ui/core';
import useDebounce from '../hooks/useDebounce';
import axios from '../axios';
import {
  CACHE_EXPIRE_IN_HOURS,
  DIAGNOSTIC_SEARCH_URL,
  GENDER_MAP,
  GET_REPORT_TAT,
  MIN_AGE_CONFIG,
  MIN_AGE_FOR_ORDER_CONFIG,
} from '../appConfig';
import Scrollbars from 'react-custom-scrollbars';
import { PatientLineItem } from './PatientLineItem';
import { useOrderContext } from '../OrderProvider';
import { SearchItem } from './SearchItem';
import format from 'date-fns/format';
import { PatientCartRecommPackage } from './PatientCartRecommPackage';
import AphDialog from './AphDialog';
import AphButton from './AphButton';
import addHours from 'date-fns/addHours';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapperBox: {
      minHeight: 200,
      position: 'relative',
      boxSizing: 'border-box',
      width: '100%',
      background: 'rgba(198, 255, 243, 0.1)',
      color: '#02475B',
    },
    searchBox: {
      position: 'relative',
      padding: '0px 12px',
      '& input': {
        paddingLeft: '33px !important',
      },
    },
    formGroup: {
      position: 'relative',
      padding: '20px 0px',
      '& input': {
        fontSize: '13px',
        fontWeight: 500,
        padding: '7px 6px',
      },
      '& div': {
        fontSize: '13px',
        fontWeight: 500,
        minHeight: 40,
        display: 'flex',
      },
      '& label': {
        fontSize: '14px',
        fontWeight: 'normal',
        display: 'block',
        lineHeight: '18px',
        opacity: 0.8,
      },
    },
    textField: {
      width: '100%',
      marginTop: 8,
      background: '#FAFBFC',
      border: '2px solid #DFE1E6',
      boxSizing: 'border-box',
      borderRadius: 8,
      '&>div:after': {
        display: 'none !important',
      },
      '&>div:before': {
        display: 'none !important',
      },
    },
    absolutedBtn: {
      position: 'absolute',
      top: 48,
      left: 6,
      color: '#FCB716',
      '& button': {
        padding: 0,
        boxShadow: 'unset',
        color: '#FCB716',
        textTransform: 'uppercase',
        background: 'none !important',
        minWidth: 'unset',
        '& img': {
          paddingRight: '6px',
        },
        '& span': {
          fontWeight: 'bold',
        },
      },
    },
    errorMsg: {
      paddingTop: '8px',
      fontSize: '10px',
      lineHeight: '13px',
      color: '#AB2300',
      '& img': {
        verticalAlign: 'middle',
      },
    },
    autoSearchPopover: {
      position: 'absolute',
      top: 93,
      left: 10,
      backgroundColor: theme.palette.common.white,
      boxShadow: '0 5px 20px 0 rgba(128, 128, 128, 0.3)',
      borderRadius: 10,
      width: '94%',
      zIndex: 9,
    },
    searchList: {
      '& ul': {
        padding: 0,
        margin: 8,
        '& li': {
          listStyle: 'none',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          borderBottom: ' 0.5px solid #b8d3db',
          padding: '8px 10px 8px 10px',
          fontSize: '13px',
          fontWeight: 500,
          alignItems: 'center',
          '& button': {
            padding: 0,
            margin: 0,
            minWidth: 0,
          },
          '&:hover': {
            background: '#F0F4F5',
          },
          '&.disable': {
            paddingBottom: 38,
            opacity: 0.6,
          },
          position: 'relative',
        },
      },
    },
    testList: {
      padding: 0,
      margin: 0,
      '&>li': {
        listStyleType: 'none',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        margin: '16px 12px',
        padding: '12px 8px',
        border: '1px solid rgba(0, 0, 0, 0.2)',
        borderRadius: 10,
        [theme.breakpoints.down(300)]: {
          padding: '16px 6px',
        },
      },
      '&>li:first-child': {
        marginTop: 0,
      },
    },
    emptyCartList: {
      justifyContent: 'center !important',
      border: 'none !important',
    },
    undoBanner: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      background: '#E8FAFF',
      border: '1px solid #D4D4D4',
      position: 'relative',
      padding: '4px 16px',
      boxSizing: 'border-box',
      bottom: 0,
      '& > span': {
        fontSize: 12,
        fontWeight: 500,
        lineHeight: '19px',
        color: '#01475B',
      },
      '& >button': {
        fontWeight: 700,
        fontSize: 12,
        lineHeight: '19px',
        color: '#FC9916',
      },
    },
    dupeCheck: {
      display: 'flex',
      padding: 20,
      alignItems: 'center',
      flexDirection: 'column',
      textAlign: 'center',
    },
  })
);

interface PatientCartItemsProps {
  setFinalCartItems: (items: FinalCartItemsToDisplay[]) => void;
  couponApplied: boolean;
  cartArrayAfterDiscount: PatientObjWithLineItems[];
}
export const PatientCartItems: React.FC<PatientCartItemsProps> = (props) => {
  const classes = useStyles();
  const {
    selectedProfile,
    selectedAddress,
    serviceabilityData,
    setDiagnosticCartItems,
    diagnosticCartItems,
    patientLineItems,
    setPatientLineItems,
    searchText,
    setSearchText,
    isUserCare,
    setCircleBenefitsApplied,
    circleMemberShipPlan,
    deletedPatientLineItems,
    setDeletedPatientLineItems,
    updatePatientLineItemPricing,
    addedDiagnosticCartPackageItemdId,
    deletedDiagnosticCartItems,
    setDeletedDiagnosticCartItems,
    setAddedDiagnosticCartPackageItemId,
    orderSource,
    setSelectedSlot,
    setSelectedSlotDate,
    modifyFlowOrder,
    modifyOrderSelectedProfile,
    setIsItemRemoved,
    setSelectedProfile,
  } = useOrderContext();
  const { setFinalCartItems, couponApplied, cartArrayAfterDiscount } = props;
  const [showUndoBanner, setShowUndoBanner] = useState<boolean>(false);
  const [showSearchResults, setShowSearchResults] = useState<boolean>(false);
  const [searchResultError, setSearchResultError] = useState<string>('');
  const [searchDiagnosticResults, setSearchDiagnosticResults] = useState<any[]>(
    []
  );
  const [selectedPatientItems, setSelectedPatientItems] = useState<
    LineItemsObject[]
  >([]);
  const [itemToRemove, setItemToRemove] = useState<{
    itemId: Number;
    patientId: string;
  } | null>(null);
  const [minAgeForOrder, setMinAgeForOrder] = useState<number>(7);
  const [updatePricing, setUpdatePricing] = useState<boolean>(false);
  const debouncedSearchTerm = useDebounce(searchText, 500);
  const [openDupeCheck, setOpenDupeChecks] = useState<boolean>(false);
  const [dupeMsg, setDupeMsg] = useState<string>('');
  const isMinor = getPatientAge(selectedProfile?.dateOfBirth) < minAgeForOrder;

  const fetchConfig = async () => {
    const res = await fetch(MIN_AGE_CONFIG);
    return await parseStatus(res.status, res);
  };

  const readFromLocalStorage = () => {
    return (
      localStorage.getItem(MIN_AGE_FOR_ORDER_CONFIG) &&
      JSON.parse(localStorage.getItem(MIN_AGE_FOR_ORDER_CONFIG))
    );
  };

  useEffect(() => {
    const dataFromLocalStorage = readFromLocalStorage();
    if (
      dataFromLocalStorage &&
      !isCacheDataExpired(dataFromLocalStorage?.expireDate)
    ) {
      setMinAgeForOrder(dataFromLocalStorage?.minAgeForDiagOrder);
    } else {
      fetchConfig().then((data) => {
        if (data?.minAgeForDiagOrder) {
          localStorage.setItem(
            MIN_AGE_FOR_ORDER_CONFIG,
            JSON.stringify({
              ...data,
              expireDate: addHours(new Date(), CACHE_EXPIRE_IN_HOURS),
            })
          );
          setMinAgeForOrder(data?.minAgeForDiagOrder);
        }
      });
    }
  }, []);

  const handleTestSearchChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const val = (event.target as HTMLInputElement).value;
    setSearchResultError('');
    setSearchText(val);
  };

  const handleSearchClickAway = () => {
    setShowSearchResults(false);
  };

  const setUpfinalCartData = (cartItems: PatientObjWithLineItems[]) => {
    const manipulatedData = [...cartItems].reduce(
      (
        finalData: {
          itemId: number;
          itemName: string;
          count: number;
          priceToDisplay: number;
          reportTAT: string;
        }[],
        currentPatientObject
      ) => {
        const finalItemIds = finalData.map((item) => item.itemId);
        currentPatientObject.lineItems.forEach((lineItem) => {
          if (finalItemIds.includes(lineItem.itemId)) {
            const itemData = finalData.find(
              (item) => item.itemId === lineItem.itemId
            );
            itemData && itemData.count++;
          } else {
            finalData.push({
              itemId: lineItem.itemId,
              itemName: lineItem.itemName || '',
              count: 1,
              priceToDisplay: lineItem?.isCouponOnMrp
                ? lineItem.mrp
                : lineItem.priceBeforeDiscount,
              reportTAT: lineItem?.reportTAT,
            });
          }
        });
        return finalData;
      },
      []
    );
    setFinalCartItems(manipulatedData);
  };

  const searchTestsResults = async (txt?: string) => {
    if (
      (searchText.length > 2 || (txt && txt.length > 2)) &&
      serviceabilityData.cityID &&
      selectedAddress?.id
    ) {
      await axios
        .post(DIAGNOSTIC_SEARCH_URL, {
          keyword: txt ?? searchText,
          cityId: serviceabilityData.cityID,
          size: 50,
        })
        .then((res: any) => {
          if (
            res?.data?.success &&
            Array.isArray(res?.data?.data!) &&
            res?.data?.data?.length
          ) {
            setSearchDiagnosticResults(res.data.data);
          } else {
            setSearchDiagnosticResults([]);
          }
          setShowSearchResults(true);
          setSearchResultError('');
        });
    } else {
      setShowSearchResults(false);
      setSearchResultError(
        'Choose a serviceable address and type three characters atleast to search.'
      );
    }
  };

  const addItemToCart = (itemToBeAdded: DiagnosticCartItem) => {
    isUserCare && setCircleBenefitsApplied(true);
    setDiagnosticCartItems([...diagnosticCartItems, itemToBeAdded]);
    setUpLineItemsData(itemToBeAdded); // add item to patient items obj
  };

  const setUpLineItemsData = (itemData: DiagnosticCartItem) => {
    const itemPrices = getTestsCirclePrice(
      itemData?.diagnosticPricing,
      isUserCare || !!circleMemberShipPlan
    );
    const { price, groupPlan, mrp, priceBeforeDiscount, cashbackAmount } =
      itemPrices;
    let patientItemsObj: PatientObjWithLineItems[] = [...patientLineItems];
    let currentPatientIdx = patientItemsObj.findIndex(
      (patientItem) => patientItem.patientID === selectedProfile?.id
    );
    const lineItem: LineItemsObject = {
      itemName: itemData.itemName,
      priceBeforeDiscount,
      mrp,
      price,
      groupPlan,
      itemId: itemData.itemId,
      couponDiscAmount: 0,
      isCouponOnMrp: false,
      groupPlanDiscAmount: 0,
      reportTAT: itemData.reportTAT,
      circleCashback: cashbackAmount,
      testParametersCount: itemData?.testParametersCount,
      packageWorthText: itemData?.packageWorthText,
    };
    if (currentPatientIdx > -1) {
      patientItemsObj[currentPatientIdx].lineItems.push(lineItem);
      patientItemsObj[currentPatientIdx].totalPrice += price;
    } else {
      const newPatientObj: PatientObjWithLineItems = {
        patientID: selectedProfile?.id || '',
        lineItems: [lineItem],
        totalPrice: price,
      };
      patientItemsObj.push(newPatientObj);
    }
    setPatientLineItems(patientItemsObj);
    setUpdatePricing(true);
  };
  const getConflictItemIds = (item: SearchResult) => {
    const currentPatientItems = patientLineItems?.find(
      (patientObj) => patientObj?.patientID === selectedProfile?.id
    );
    const currentPatientCartItems = currentPatientItems?.lineItems?.map(
      (lineItem) =>
        diagnosticCartItems?.find(
          (cartItem) => cartItem?.itemId === lineItem?.itemId
        )
    );
    const currentPatientCartItemIds = currentPatientCartItems?.map(
      (cartItem) => cartItem?.itemId
    );
    if (item?.diagnostic_item_inclusion_ids?.length > 1) {
      const existedInclusionIds = item?.diagnostic_item_inclusion_ids?.filter(
        (inclusionId) => currentPatientCartItemIds?.includes(inclusionId)
      );
      return {
        removedItemIds: existedInclusionIds,
        conflictedItemIds: [item?.diagnostic_item_id],
      };
    } else {
      const existedCartPackages = currentPatientCartItems?.filter(
        (cartItem) =>
          cartItem?.inclusionIds?.length > 1 &&
          cartItem?.inclusionIds?.includes(item?.diagnostic_item_id)
      );
      const conflictIds = existedCartPackages?.map((cart) => cart?.itemId);
      return {
        removedItemIds: conflictIds?.length ? [item?.diagnostic_item_id] : [],
        conflictedItemIds: conflictIds?.length ? conflictIds : [],
      };
    }
  };

  const checkDuplicates = (item) => {
    const { conflictedItemIds, removedItemIds } = getConflictItemIds(item);
    if (removedItemIds?.length && conflictedItemIds?.length) {
      let updatedPatientItems = [...patientLineItems];
      let updatedCartItems = [...diagnosticCartItems];
      const itemsToBeRemoved: string[] = [];
      const itemsToKeep: string[] = [];
      updatedPatientItems = updatedPatientItems?.map((patientObj) => {
        removedItemIds.forEach((id: number) => {
          let itemIdx = patientObj?.lineItems?.findIndex(
            (item) => item?.itemId === id
          );
          if (itemIdx > -1) {
            !itemsToBeRemoved.includes(
              patientObj?.lineItems?.[itemIdx]?.itemName!
            ) &&
              itemsToBeRemoved.push(
                patientObj?.lineItems?.[itemIdx]?.itemName!
              );
            patientObj.totalPrice -= patientObj?.lineItems?.[itemIdx]?.price;
            patientObj?.lineItems?.splice(itemIdx, 1);
          }
          const indexToRemoveFromCartItemsArray = updatedCartItems?.findIndex(
            (item) =>
              item?.patientId === patientObj?.patientID && item?.itemId === id
          );

          indexToRemoveFromCartItemsArray > -1 &&
            updatedCartItems.splice(indexToRemoveFromCartItemsArray, 1);
        });
        conflictedItemIds?.forEach((id: number) => {
          let itemIdx = patientObj?.lineItems?.findIndex(
            (item) => item?.itemId === id
          );
          itemIdx > -1 &&
            !itemsToKeep.includes(
              patientObj?.lineItems?.[itemIdx]?.itemName!
            ) &&
            itemsToKeep.push(patientObj?.lineItems?.[itemIdx]?.itemName!);
        });
        return patientObj;
      });
      setDiagnosticCartItems(updatedCartItems);
      setPatientLineItems(updatedPatientItems);
      setDupeMsg(
        `Your cart has been revised! The "${itemsToBeRemoved.join(
          '", " '
        )}" has been removed from your cart as it is already included in another test "${itemsToKeep.join(
          '", " '
        )}" in your cart.`
      );
      setOpenDupeChecks(true);
    }
  };

  const startAddingItemToCart = async (searchResult: SearchResult) => {
    let itemToAdd: DiagnosticCartItem = {
      diagnosticPricing: searchResult.diagnostic_item_price,
      itemId: searchResult.diagnostic_item_id,
      inclusions: searchResult.diagnostic_inclusions,
      itemName: searchResult.diagnostic_item_name,
      itemType: searchResult.diagnostic_item_itemType,
      patientId: selectedProfile?.id,
      reportTAT: '',
      testParametersCount: searchResult?.testParametersCountWithHeaderLogic,
      canonicalTag: searchResult?.diagnostic_item_canonicalTag,
      gender: searchResult?.diagnostic_item_gender,
      inclusionIds: searchResult?.diagnostic_item_inclusion_ids,
      observations: searchResult?.diagnostic_inclusions_test_parameter_data,
      packageWorthText: searchResult?.diagnostic_item_package_worth_text || '',
    };

    // modify order flow existed item
    const isPrevOrderItem = modifyFlowOrder?.diagnosticOrderLineItems?.find(
      (orderLineItem) =>
        orderLineItem?.itemId === searchResult.diagnostic_item_id
    );
    if (isPrevOrderItem) {
      itemToAdd = {
        diagnosticPricing: isPrevOrderItem?.pricingObj,
        inclusions: isPrevOrderItem?.itemObj?.inclusions?.map(
          (diagnosticInclusion) => diagnosticInclusion?.name
        ),
        itemId: isPrevOrderItem?.itemId,
        itemName: isPrevOrderItem?.itemName,
        itemType: isPrevOrderItem?.itemObj?.itemType,
        patientId: modifyFlowOrder?.patientId,
        reportTAT: '',
        testParametersCount: 1,
        canonicalTag: isPrevOrderItem?.canonicalTag,
        inclusionIds: isPrevOrderItem?.inclusionIds,
      };
    }
    await axios
      .post(`${GET_REPORT_TAT}`, {
        itemIds: [searchResult.diagnostic_item_id],
        cityId: serviceabilityData.cityID,
        pincode: Number(localStorage.getItem('currentZip')),
        latitude: selectedAddress?.latitude || null,
        longitude: selectedAddress?.longitude || null,
        bookingSource: orderSource,
      })
      .then(({ data }) => {
        if (data) {
          itemToAdd.reportTAT = data.preOrderReportTATMessage;
        }
      });
    if (
      selectedPatientItems.findIndex(
        (x) => x.itemId === Number(searchResult.diagnostic_item_id)
      ) === -1
    ) {
      addItemToCart(itemToAdd);
      checkDuplicates(searchResult);
      if (deletedPatientLineItems) {
        setDeletedPatientLineItems(null);
      }
    }
  };

  /////////////////////////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    if (couponApplied) {
      if (cartArrayAfterDiscount && cartArrayAfterDiscount.length) {
        const currentPatientItems = cartArrayAfterDiscount.find(
          (patientObj) => patientObj.patientID === selectedProfile?.id
        );
        currentPatientItems &&
          setSelectedPatientItems(currentPatientItems.lineItems);
      } else {
        setSelectedPatientItems([]);
      }
      setUpfinalCartData(cartArrayAfterDiscount);
    } else {
      if (patientLineItems.length) {
        const currentPatientItems = patientLineItems.find(
          (patientObj) => patientObj.patientID === selectedProfile?.id
        );
        currentPatientItems &&
          setSelectedPatientItems(currentPatientItems.lineItems);
      } else {
        setSelectedPatientItems([]);
      }
      setUpfinalCartData(patientLineItems);
    }

    // eslint-disable-next-line
  }, [
    patientLineItems,
    couponApplied,
    cartArrayAfterDiscount,
    circleMemberShipPlan,
  ]);

  useEffect(() => {
    if (debouncedSearchTerm) {
      searchTestsResults(debouncedSearchTerm);
    }
    // eslint-disable-next-line
  }, [debouncedSearchTerm]);

  useEffect(() => {
    if (updatePricing) {
      setUpdatePricing(false);
      updatePatientLineItemPricing(isUserCare || !!circleMemberShipPlan);
    }
  }, [
    updatePricing,
    isUserCare,
    circleMemberShipPlan,
    updatePatientLineItemPricing,
  ]);

  useEffect(() => {
    if (selectedProfile) {
      setSearchText('');
      if (isMinor) {
        setSearchResultError(
          `Only RT-PCR can be booked for patients below ${minAgeForOrder} years`
        );
      } else {
        setSearchResultError('');
      }
      const patientItemsToLook =
        couponApplied && cartArrayAfterDiscount?.length
          ? cartArrayAfterDiscount
          : patientLineItems;
      const currentPatientItems = patientItemsToLook.find(
        (patientObj) => patientObj.patientID === selectedProfile.id
      );
      if (currentPatientItems) {
        setSelectedPatientItems(currentPatientItems.lineItems);
      } else {
        setSelectedPatientItems([]);
      }
    }
    // eslint-disable-next-line
  }, [selectedProfile]);

  useEffect(() => {
    const itemIdxFlatArray = diagnosticCartItems.findIndex(
      (cItem) =>
        cItem.itemId === itemToRemove?.itemId &&
        cItem.patientId === itemToRemove.patientId
    );
    itemIdxFlatArray > -1 && diagnosticCartItems.splice(itemIdxFlatArray, 1);
    setDiagnosticCartItems([...diagnosticCartItems]);
    const selectedPatientObjIdx = patientLineItems.findIndex(
      (patientObj) => patientObj.patientID === itemToRemove?.patientId
    );
    let updatedItems = [...patientLineItems];
    if (selectedPatientObjIdx > -1) {
      const itemInQuestion = updatedItems[
        selectedPatientObjIdx
      ].lineItems.findIndex((item) => item.itemId === itemToRemove?.itemId);
      if (itemInQuestion > -1) {
        let patientInQuestion = updatedItems[selectedPatientObjIdx];
        patientInQuestion.totalPrice -=
          patientInQuestion.lineItems[itemInQuestion].price;
        patientInQuestion.lineItems.splice(itemInQuestion, 1);
        !patientInQuestion.lineItems.length &&
          updatedItems.splice(selectedPatientObjIdx, 1);
      }
      setPatientLineItems(updatedItems);
      if (deletedPatientLineItems) {
        setDeletedPatientLineItems(null);
      }
    }
    // eslint-disable-next-line
  }, [itemToRemove]);

  useEffect(() => {
    if (
      deletedPatientLineItems?.lineItems?.length > 0 &&
      deletedPatientLineItems?.patientID === selectedProfile?.id
    ) {
      setShowUndoBanner(true);
    } else {
      if (deletedPatientLineItems?.lineItems?.length > 0 && showUndoBanner) {
        setDeletedPatientLineItems(null);
      }
      setShowUndoBanner(false);
    }
  }, [deletedPatientLineItems, selectedPatientItems?.length]);

  const handleUndo = () => {
    const patientItemsObj: PatientObjWithLineItems[] = [...patientLineItems];
    const currentPatientIdx = patientItemsObj.findIndex(
      (patientItem) => patientItem.patientID === selectedProfile?.id
    );
    const existingLineItems = patientItemsObj?.[
      currentPatientIdx
    ]?.lineItems?.filter(
      (lineItem) => lineItem?.itemId !== addedDiagnosticCartPackageItemdId
    );
    const newPatientLineItemsToBeAdded = {
      ...deletedPatientLineItems,
      lineItems: [...deletedPatientLineItems?.lineItems, ...existingLineItems],
    };

    patientItemsObj?.splice(currentPatientIdx, 1, newPatientLineItemsToBeAdded);

    const DiagnosticCartItemsAfterRemovingPackage: DiagnosticCartItem[] =
      diagnosticCartItems?.filter(
        (cartItem) => cartItem?.itemId !== addedDiagnosticCartPackageItemdId
      );
    const newDiagnosticCartItems: DiagnosticCartItem[] = [
      ...DiagnosticCartItemsAfterRemovingPackage,
      ...deletedDiagnosticCartItems,
    ];

    setDiagnosticCartItems(newDiagnosticCartItems);

    const newPatientLineItems: PatientObjWithLineItems[] =
      patientItemsObj?.reduce((acc, patientLineItem) => {
        let totalPrice = 0;
        const newLineItems = patientLineItem?.lineItems?.map((lineItem) => {
          const cartItem = newDiagnosticCartItems?.find(
            (diagCartItem) => diagCartItem?.itemId === lineItem?.itemId
          );
          const itemPrices = getTestsCirclePrice(
            cartItem?.diagnosticPricing,
            circleMemberShipPlan ? true : isUserCare
          );
          const { price, groupPlan, mrp, priceBeforeDiscount, cashbackAmount } =
            itemPrices;

          totalPrice += price;

          return {
            itemName: cartItem.itemName,
            priceBeforeDiscount,
            mrp,
            price,
            groupPlan,
            itemId: cartItem.itemId,
            couponDiscAmount: 0,
            isCouponOnMrp: false,
            groupPlanDiscAmount: 0,
            reportTAT: cartItem.reportTAT,
            circleCashback: cashbackAmount,
            testParametersCount: cartItem?.testParametersCount || 1,
          };
        });

        acc.push({
          patientID: patientLineItem?.patientID,
          totalPrice,
          lineItems: newLineItems,
        });
        return acc;
      }, [] as PatientObjWithLineItems[]);
    setPatientLineItems(newPatientLineItems);
    setDeletedDiagnosticCartItems(null);
    setAddedDiagnosticCartPackageItemId(null);
    setDeletedPatientLineItems(null);
  };

  const populateModifyOrderLineItems = (prevDiagnosticOrderLineItems) => {
    const cartItems = [];
    const lineItems = [];
    let totalPrice = 0;
    prevDiagnosticOrderLineItems?.forEach((item) => {
      if (!item?.isRemoved) {
        const itemPrices = getTestsCirclePrice(
          item?.pricingObj,
          circleMemberShipPlan ? true : isUserCare
        );
        const { price, groupPlan, mrp, priceBeforeDiscount, cashbackAmount } =
          itemPrices;
        const priceToUse = item?.itemObj?.isOnMrpCoupon ? mrp : price;

        totalPrice += priceToUse;
        lineItems.push({
          itemName: item?.itemName,
          priceBeforeDiscount,
          mrp,
          price: price,
          groupPlan,
          itemId: item?.itemId,
          couponDiscAmount: item?.couponDiscAmount,
          groupPlanDiscAmount: 0,
          reportTAT: item?.itemObj?.reportTATMessage,
          circleCashback: cashbackAmount,
          testParametersCount: item?.testParametersCount || 1,
        });

        cartItems.push({
          diagnosticPricing: item?.pricingObj,
          inclusions: item?.itemObj?.inclusions?.map(
            (diagnosticInclusion) => diagnosticInclusion?.name
          ),
          itemId: item?.itemId,
          itemName: item?.itemName,
          itemType: item?.itemObj?.itemType,
          patientId: modifyFlowOrder?.patientId,
          reportTAT: item?.itemObj?.reportTATMessage,
          testParametersCount: 1,
          canonicalTag: item?.diagnostics?.canonicalTag,
          gender: item?.itemObj?.gender,
        });
      }
    });
    setDiagnosticCartItems(cartItems);
    const PatientObjWithLineItems: PatientObjWithLineItems[] = [
      {
        patientID: modifyFlowOrder?.patientId,
        totalPrice,
        lineItems: lineItems,
      },
    ];
    setPatientLineItems(PatientObjWithLineItems);
  };

  const populateModifyProfileChangeLineItems = (profile) => {
    const filterLineItems = [];
    const removedLineItems = [];

    let totalPrice = 0;
    const lineItems = patientLineItems?.[0]?.lineItems;
    lineItems?.forEach((lineItem) => {
      const diagItem = diagnosticCartItems?.find(
        (diagItem) => diagItem?.itemId === lineItem?.itemId
      );

      const shouldAddDiagItemsToCart = isMinor
        ? diagItem?.itemId?.toString() === process.env.REACT_APP_RTPCR_ID
        : profile?.gender === GENDER_MAP[diagItem?.gender] ||
          GENDER_MAP[diagItem?.gender] === 'OTHER';

      if (shouldAddDiagItemsToCart) {
        totalPrice += lineItem?.price;
        filterLineItems.push(lineItem);
      } else {
        removedLineItems.push(lineItem);
      }
    });
    const modifyDiagnosticCartItems = diagnosticCartItems?.map((cartItem) => {
      return {
        ...cartItem,
        patientId: profile?.id,
      };
    });
    setDiagnosticCartItems(modifyDiagnosticCartItems);
    const PatientObjWithLineItems: PatientObjWithLineItems[] = [
      {
        patientID: profile?.id,
        totalPrice,
        lineItems: filterLineItems,
      },
    ];
    setPatientLineItems(PatientObjWithLineItems);
    if (removedLineItems?.length) {
      setIsItemRemoved(true);
    }
    setSelectedProfile(profile);
  };

  useEffect(() => {
    if (modifyFlowOrder && selectedProfile) {
      if (modifyOrderSelectedProfile) {
        // populate existing lineItems to another selected patient
        populateModifyProfileChangeLineItems(modifyOrderSelectedProfile);
      } else {
        // modify flow populate lineitems
        populateModifyOrderLineItems(modifyFlowOrder?.diagnosticOrderLineItems);
      }
    }
  }, [
    modifyFlowOrder,
    selectedProfile,
    isUserCare,
    modifyOrderSelectedProfile,
  ]);

  useEffect(() => {
    if (modifyFlowOrder && serviceabilityData.cityID && selectedProfile) {
      setSelectedSlot({
        isPaidSlot:
          modifyFlowOrder?.attributesObj?.distanceCharges > 0 ||
          modifyFlowOrder?.attributesObj?.collectionChargesV2?.paidSlotMessage
            ?.length > 0,
        slotDetail: {
          slotDisplayTime: format(
            new Date(modifyFlowOrder?.slotDateTimeInUTC),
            'hh:mm aaa'
          ),
          internalSlots: [
            format(new Date(modifyFlowOrder?.slotDateTimeInUTC), 'hh:mm aaa'),
          ],
        },
      });
      setSelectedSlotDate(new Date(modifyFlowOrder?.slotDateTimeInUTC));
    }
  }, [modifyFlowOrder, serviceabilityData, selectedProfile]);

  const genderAllowItem = (item: SearchResult) => {
    if (GENDER_MAP[item?.diagnostic_item_gender] === 'OTHER') return true;
    if (GENDER_MAP[item?.diagnostic_item_gender] === selectedProfile?.gender)
      return true;
    return false;
  };
  /////////////////////////////////////////////////////////////////////////////////////////////////
  return (
    <>
      <h3>
        <span>Items in your Cart</span>
        {selectedProfile && (
          <em>{`${selectedProfile.firstName} ${selectedProfile.lastName} • ${
            selectedProfile.gender
          }, ${getPatientAge(selectedProfile.dateOfBirth!)}`}</em>
        )}
      </h3>
      {/* new ui */}
      <div className={classes.wrapperBox}>
        <ClickAwayListener onClickAway={handleSearchClickAway}>
          <div className={classes.searchBox}>
            <div className={classes.formGroup}>
              <label>Search and Add Tests</label>
              <TextField
                className={classes.textField}
                value={searchText}
                onChange={handleTestSearchChange}
                placeholder="Search for Lab Tests"
                onKeyPress={(e) => {
                  e.key === 'Enter' && searchTestsResults();
                }}
                // disabled={isMinor}
              />
              <div className={classes.absolutedBtn}>
                <Button
                  disabled={isMinor}
                  onClick={() => {
                    searchTestsResults();
                  }}
                >
                  <img src={require('../images/search.png')} alt=" Search" />
                </Button>
              </div>
              {/* this is error message */}
              {searchResultError && (
                <FormHelperText className={classes.errorMsg} component="div">
                  {searchResultError}
                </FormHelperText>
              )}
              {/* this is error message */}
            </div>
            {showSearchResults && (
              <Paper className={classes.autoSearchPopover}>
                <Scrollbars autoHide={true} autoHeight autoHeightMax={'35vh'}>
                  {/* this is for loading */}
                  {/* <div className={classes.progressLoader}>
                        <CircularProgress size={30} />
                      </div> */}
                  {/* this is for loading */}
                  <div className={classes.searchList}>
                    <ul>
                      {searchDiagnosticResults.length > 0 &&
                        searchDiagnosticResults.map(
                          (searchResult: SearchResult, idx) => {
                            const shouldBlockItemAddition = isMinor
                              ? searchResult.diagnostic_item_id.toString() !==
                                process.env.REACT_APP_RTPCR_ID
                              : false;
                            const isItemExistInCart =
                              selectedPatientItems.findIndex(
                                (x) =>
                                  x.itemId ===
                                  Number(searchResult.diagnostic_item_id)
                              ) !== -1;

                            return (
                              <li
                                key={`searchTestResults-idx-${idx}`}
                                className={
                                  !genderAllowItem(searchResult) ||
                                  shouldBlockItemAddition
                                    ? 'disable'
                                    : ''
                                }
                                onClick={() =>
                                  !shouldBlockItemAddition &&
                                  genderAllowItem(searchResult) &&
                                  startAddingItemToCart(searchResult)
                                }
                              >
                                <SearchItem
                                  shouldBlockItemAddition={
                                    shouldBlockItemAddition ||
                                    !genderAllowItem(searchResult)
                                  }
                                  isGenderRestricted={
                                    !genderAllowItem(searchResult)
                                  }
                                  isAgeRestricted={shouldBlockItemAddition}
                                  isItemExistInCart={isItemExistInCart}
                                  item={searchResult}
                                />
                              </li>
                            );
                          }
                        )}
                      {searchDiagnosticResults.length === 0 && (
                        <li>
                          <div>No Search Results Found</div>
                        </li>
                      )}
                    </ul>
                  </div>
                </Scrollbars>
              </Paper>
            )}
          </div>
        </ClickAwayListener>
        <ul className={classes.testList}>
          {patientLineItems.length > 0 && selectedPatientItems.length > 0
            ? selectedPatientItems.map(
                (lineItem: LineItemsObject, idx: number) => {
                  if (lineItem?.itemId === addedDiagnosticCartPackageItemdId) {
                    return (
                      <PatientCartRecommPackage
                        lineItem={lineItem}
                        patientId={selectedProfile?.id!}
                        setItemToRemove={setItemToRemove}
                        handleUndo={handleUndo}
                        showUndoBanner={showUndoBanner}
                      />
                    );
                  }
                  return (
                    <li key={'patient-item-' + idx}>
                      <PatientLineItem
                        lineItem={lineItem}
                        patientId={selectedProfile?.id!}
                        setItemToRemove={setItemToRemove}
                      />
                    </li>
                  );
                }
              )
            : !isMinor && (
                <li
                  key={'patient-item-empty'}
                  className={classes.emptyCartList}
                >
                  Start Adding Items for this patient
                </li>
              )}
        </ul>
        <AphDialog open={openDupeCheck} maxWidth="sm">
          <div className={classes.dupeCheck}>
            <p>{dupeMsg}</p>
            <AphButton onClick={() => setOpenDupeChecks(false)} color="primary">
              OK
            </AphButton>
          </div>
        </AphDialog>
      </div>
    </>
  );
};
