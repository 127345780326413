import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
  getPackageObservationCount,
  LineItemsObject,
  parseStatus,
  isCacheDataExpired,
  getActiveInclusions,
} from './helpers/commonHelpers';
import {
  CACHE_EXPIRE_IN_HOURS,
  PACKAGE_RECOMM_CONFIG,
  RECOMM_CONFIG_KEY,
} from '../appConfig';
import { useOrderContext } from '../OrderProvider';
import addHours from 'date-fns/addHours';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    recommPackage: {
      display: 'block !important',
      padding: '0px !important',
      '& .emptyList': {
        height: 20,
      },
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '10px 8px 10px 10px',
      '& img': {
        cursor: 'pointer',
      },
    },
    lhs: {
      display: 'flex',
      flexDirection: 'column',
      '& .title': {
        fontWeight: '500',
        fontSize: '14px',
        lineHeight: '22px',
        color: '#01475B',
      },
      '& .inclusionCount': {
        fontWeight: '400',
        fontSize: '12px',
        lineHeight: '16px',
        color: '#02475B',
        opacity: '0.8',
      },
    },
    rhs: {
      display: 'flex',
      flexDirection: 'column',
      gap: 4,
      flex: '1 1 0px',
      alignItems: 'flex-end',
      '& .undoCTA': {
        fontWeight: '700',
        fontSize: '13px',
        lineHeight: '17px',
        textAlign: 'center',
        color: '#FC9916',
        cursor: 'pointer',
      },
      '& .price': {
        fontWeight: '600',
        fontSize: '14px',
        lineHeight: '14px',
        textAlign: 'right',
        color: '#02475B',
      },
      '& .testCount': {
        fontWeight: '500',
        fontSize: '12px',
        lineHeight: '14px',
        textAlign: 'right',
        color: '#007C9D',
        '& img': {
          paddingRight: '4px',
        },
      },
    },
    inclusions: {
      marginBlock: '0px',
      marginInline: '0px',
      paddingInline: '20px',
      listStyleType: 'disc !important',
      '&>li': {
        fontSize: '10px',
        paddingTop: 8,
        '&>div': {
          display: 'flex',
          justifyContent: 'space-between',
          fontWeight: '500',
          fontSize: '10px',
          lineHeight: '13px',
          color: '#02475B',
        },
      },
    },
    otherInclusions: {
      marginBlock: '0px',
      marginInline: '0px',
      paddingInlineStart: '30px',
      paddingInlineEnd: '20px',
      listStyleType: 'disc !important',
      '&>li': {
        fontSize: '10px',
        paddingTop: 8,
        '&>div': {
          display: 'flex',
          justifyContent: 'space-between',
          fontWeight: '500',
          fontSize: '10px',
          lineHeight: '13px',
          color: '#02475B',
        },
      },
      '&>li:last-child': {
        fontSize: '10px',
        paddingTop: 8,
        paddingBottom: 8,
        '&>div': {
          display: 'flex',
          justifyContent: 'space-between',
          fontWeight: '500',
          fontSize: '10px',
          lineHeight: '13px',
          color: '#02475B',
        },
      },
    },
    moreTestCTA: {
      fontWeight: 500,
      fontSize: '10px',
      lineHeight: '13px',
      textAlign: 'right',
      color: '#FCB716',
      paddingRight: '20px',
      cursor: 'pointer',
    },
    packageReportInfoWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: 4,
      flexWrap: 'wrap',
      gap: 10,
    },
    packageReportTime: {
      display: 'flex',
      alignItems: 'center',
      gap: 4,
      '& span': {
        fontWeight: 500,
        fontSize: 10,
        color: '#01475B',
      },
    },
    packageTestPreparation: {
      display: 'flex',
      alignItems: 'center',
      gap: 4,
      '& span': {
        fontWeight: 500,
        fontSize: 10,
        color: '#01475B',
        opacity: 0.6,
      },
    },
  })
);

interface PatientCartRecommPackageProps {
  lineItem: LineItemsObject;
  patientId: string;
  setItemToRemove: (
    itemToRemove: { itemId: number; patientId: string } | null
  ) => void;
  handleUndo?: () => void;
  showUndoBanner?: boolean;
}
export const PatientCartRecommPackage: React.FC<
  PatientCartRecommPackageProps
> = (props) => {
  const [inclusionCount, setInclusionCount] = useState<number>(5);
  const [showExpandedView, setShowExpandedView] = useState<boolean>(false);
  const classes = useStyles();
  const {
    diagnosticCartItems,
    deletedPatientLineItems,
    deletedDiagnosticCartItems,
  } = useOrderContext();
  const { lineItem, handleUndo, showUndoBanner, setItemToRemove, patientId } =
    props;

  const fetchConfig = async () => {
    const res = await fetch(PACKAGE_RECOMM_CONFIG);
    return await parseStatus(res.status, res);
  };
  const readFromLocalStorage = () => {
    return (
      localStorage.getItem(RECOMM_CONFIG_KEY) &&
      JSON.parse(localStorage.getItem(RECOMM_CONFIG_KEY))
    );
  };

  useEffect(() => {
    const dataFromLocalStorage = readFromLocalStorage();
    if (
      dataFromLocalStorage &&
      !isCacheDataExpired(dataFromLocalStorage?.expireDate)
    ) {
      setInclusionCount(dataFromLocalStorage?.cartConfig?.inclusionRows);
    } else {
      fetchConfig().then((data) => {
        if (data?.cartConfig) {
          localStorage.setItem(
            RECOMM_CONFIG_KEY,
            JSON.stringify({
              ...data,
              expireDate: addHours(new Date(), CACHE_EXPIRE_IN_HOURS),
            })
          );
          if (data?.cartConfig?.cardType === 'show-inclusion') {
            setShowExpandedView(false);
          } else {
            setShowExpandedView(true);
          }
          setInclusionCount(data?.cartConfig?.inclusionRows);
        }
      });
    }
  }, []);

  const { priceBeforeDiscount, mrp } = lineItem;
  const priceToUse = lineItem?.isCouponOnMrp ? mrp : priceBeforeDiscount;

  const recommPackage = diagnosticCartItems?.find(
    (cartItem) => cartItem?.itemId === lineItem?.itemId
  );

  const isDeletedLineItemsContainsInclusion = (inclusion) => {
    return deletedPatientLineItems?.lineItems?.some(
      (lineItem) => lineItem?.itemId === inclusion?.itemId
    );
  };

  const isDeletedLinePackageContainsInclusion = (inclusion) => {
    return deletedDiagnosticCartItems?.some((cartItem) => {
      return cartItem?.inclusionIds?.includes(inclusion?.itemId);
    });
  };

  const includedInclusions = [];
  const otherInclusions = [];
  recommPackage?.diagnosticInclusions?.forEach((inclusion) => {
    const isInclusionIncluded =
      isDeletedLineItemsContainsInclusion(inclusion) ||
      isDeletedLinePackageContainsInclusion(inclusion);

    if (isInclusionIncluded) {
      includedInclusions.push({
        ...inclusion,
        included: true,
      });
    } else {
      otherInclusions.push({
        ...inclusion,
        included: false,
      });
    }
  });

  const allInclusions = [...includedInclusions, ...otherInclusions];
  let remainingInclusionCountToFill = 0;
  let showInclusions = allInclusions;
  if (inclusionCount && !showExpandedView) {
    showInclusions = allInclusions?.slice(0, inclusionCount);
    if (showInclusions?.length < inclusionCount) {
      remainingInclusionCountToFill = inclusionCount - showInclusions?.length;
    }
  }

  const otherInclusionsToShow = showInclusions?.filter(
    (inclusion) => !inclusion?.included
  );
  const includedInclusionToShow = showInclusions?.filter(
    (inclusion) => inclusion?.included
  );
  const remainingInclusionCount =
    allInclusions?.length - showInclusions?.length;

  const renderEmptyLists = () => {
    return new Array(remainingInclusionCountToFill)
      ?.fill({})
      ?.map((empty, idx) => <div className="emptyList" key={idx}></div>);
  };

  const handleItemRemove = (itemId: number, patientId: string) => {
    setItemToRemove({ itemId, patientId });
  };

  const observationCount = getPackageObservationCount(
    recommPackage?.diagnosticInclusions
  );

  const otherInclusionsObservationCount =
    getPackageObservationCount(otherInclusions);
  return (
    <li className={classes.recommPackage}>
      <div className={classes.header}>
        <div className={classes.lhs}>
          <span className="title">{recommPackage?.itemName}</span>
          <span className="inclusionCount">
            Includes {observationCount} Test{observationCount > 0 && 's'}
          </span>
          <div className={classes.packageReportInfoWrapper}>
            <div className={classes.packageReportTime}>
              <img src={require('../images/clock.svg')} alt="" />
              <span>{recommPackage?.reportTAT}</span>
            </div>
          </div>
        </div>
        <div className={classes.rhs}>
          {showUndoBanner && (
            <span className="undoCTA" onClick={() => handleUndo()}>
              UNDO
            </span>
          )}
          <span className="price">₹{priceToUse}</span>
          <div className="testCount">
            <img
              src={require('../images/ic_dual_persons.svg')}
              width={16}
              height={10}
              alt="person"
            />
            <span>1 X ₹{priceToUse}</span>
          </div>
        </div>
        {!showUndoBanner && (
          <div onClick={() => handleItemRemove(lineItem.itemId, patientId)}>
            <img src={require('../images/ic_cross.png')} alt="" />
          </div>
        )}
      </div>
      <ul className={classes.inclusions}>
        {includedInclusionToShow.map((inclusion, idx) => {
          const observationCount = getActiveInclusions(
            inclusion?.allObservations
          );
          return (
            <li key={`IncludedInclusion${idx}`}>
              <div>
                <span>{inclusion?.name}</span>
                <span>
                  {observationCount || 1} Test
                  {observationCount > 1 && 's'}
                </span>
              </div>
            </li>
          );
        })}
        {otherInclusionsToShow?.length > 0 && (
          <li>
            <div>
              <span>Others</span>
              <span>
                {otherInclusionsObservationCount} Test
                {otherInclusionsObservationCount > 1 && 's'}
              </span>
            </div>
          </li>
        )}
      </ul>
      {otherInclusionsToShow?.length > 0 && (
        <ul className={classes.otherInclusions}>
          {otherInclusionsToShow?.map((inclusion, idx) => {
            const observationCount = getActiveInclusions(
              inclusion?.allObservations
            );
            return (
              <li key={`otherInclusion${idx}`}>
                <div>
                  <span>{inclusion?.name}</span>
                  <span>
                    {observationCount || 1} Test
                    {observationCount > 1 && 's'}
                  </span>
                </div>
              </li>
            );
          })}
        </ul>
      )}
      {remainingInclusionCount > 0 && (
        <p
          className={classes.moreTestCTA}
          onClick={() => {
            setShowExpandedView(true);
          }}
        >
          +{remainingInclusionCount} more Test
          {remainingInclusionCount > 0 && 's'}
        </p>
      )}
      {showExpandedView && (
        <p
          className={classes.moreTestCTA}
          onClick={() => {
            setShowExpandedView(false);
          }}
        >
          view less
        </p>
      )}
      {remainingInclusionCountToFill > 0 && renderEmptyLists()}
    </li>
  );
};
