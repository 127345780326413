import axios from './axios';
import { WEB_BASE_URL } from './appConfig';
import {
  BOOK_RADIOLOGY_TEST,
  GET_RADIOLOGY_HOSPITALS,
  GET_RADIOLOGY_LOCATIONS,
  GET_RADIOLOGY_TESTS,
  UPLOAD_RADIOLOGY_PRESCRIPTION,
} from './queryConfig';

export const getRadiologyLocation = async () => {
  try {
    const { data } = await axios.post(WEB_BASE_URL, {
      query: GET_RADIOLOGY_LOCATIONS,
    });
    return data;
  } catch (e) {
    console.error('Error fetching getRadiologyLocation data', e);
  }
};

export const getRadiologyHospitals = async (value) => {
  try {
    const { data } = await axios.post(WEB_BASE_URL, {
      query: GET_RADIOLOGY_HOSPITALS,
      variables: {
        regionId: value,
        source: 'OPT',
      },
    });
    return data;
  } catch (e) {
    console.error('Error fetching getRadiologyHospitals data', e);
  }
};

export const getRadiologyDepartments = async (value) => {
  try {
    const { data } = await axios.post(WEB_BASE_URL, {
      query: GET_RADIOLOGY_TESTS,
      variables: {
        locationId: value,
      },
    });
    return data;
  } catch (e) {
    console.error('Error fetching getRadiologyDepartments data', e);
  }
};

export const uploadRadiologyUploadPrescriptions = async (uploadedFile) => {
  try {
    const { data } = await axios.post(WEB_BASE_URL, {
      query: UPLOAD_RADIOLOGY_PRESCRIPTION,
      variables: {
        prescriptionInput: {
          content: uploadedFile?.base64Content.split(`;base64,`)?.pop(),
          mimeType: uploadedFile?.mimeType,
        },
      },
    });
    return data;
  } catch (e) {
    console.error('Error fetching uploadRadiologyUploadPrescriptions data', e);
  }
};

export const bookRadiologyTest = async (bookingParams) => {
  try {
    const { data } = await axios.post(WEB_BASE_URL, {
      query: BOOK_RADIOLOGY_TEST,
      variables: { bookingParams: bookingParams },
    });
    return data;
  } catch (e) {
    console.error('Error fetching bookRadiologyTest data', e);
  }
};
