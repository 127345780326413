import React, { useEffect, useState } from 'react';
import { Theme, Typography, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { Link } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';

const useStyles = makeStyles((theme: Theme) => {
  return {
    signInContainer: {
      height: '100%',
    },
    signinImg: {
      '& h1': {
        fontSize: 24,
        fontWeight: 700,
        textAlign: 'center',
        color: '#02475b',
        textTransform: 'uppercase',
        fontFamily: "'IBM Plex Sans', sans-serif",
        [theme.breakpoints.down('xs')]: {
          marginBottom: 0,
        },
      },
      '& img': {
        [theme.breakpoints.down('xs')]: {
          width: '100%',
        },
      },
    },
    signInContent: {
      '& h2': {
        fontSize: 27,
        color: '#02475b',
        fontWeight: 700,
        margin: '30px 0',
        textTransform: 'uppercase',
        fontFamily: "'IBM Plex Sans', sans-serif",
        [theme.breakpoints.down('xs')]: {
          margin: '10px 0',
        },
      },
      [theme.breakpoints.down('xs')]: {
        textAlign: 'center',
      },
    },
    logo: {},
    loginForm: {},
    primaryBtn: {
      padding: '12px 20px',
      background: '#02475b',
      fontSize: 16,
      color: '#ffffff',
      textAlign: 'center',
      fontWeight: 700,
      margin: '20px 0 0',
      lineHeight: '16px',
      width: 200,
      textTransform: 'uppercase',
      borderRadius: 20,
      textDecoration: 'none',
      display: 'block',
      '&:hover': {
        backgroundColor: '#02475b !important',
        boxShadow: '2px 3px 8px #aaaaaa;',
      },
      [theme.breakpoints.down('xs')]: {
        margin: 'auto',
      },
    },
    disabledLogin: {
      color: '#fff !important',
      opacity: '0.7',
      padding: '12px 20px',
      background: '#02475b',
      fontSize: 16,
      textAlign: 'center',
      fontWeight: 700,
      margin: '20px 0 0',
      lineHeight: '16px',
      width: 200,
      textTransform: 'uppercase',
      borderRadius: 20,
      textDecoration: 'none',
      display: 'block',
      [theme.breakpoints.down('xs')]: {
        margin: 'auto',
      },
    },
    formControl: {
      width: '100%',
      margin: '0 0 30px',
    },
    mainContent: {
      background: '#f7f8f5',
      height: '100%',
      boxSizing: 'border-box',
      padding: '80px 50px',
      [theme.breakpoints.down('xs')]: {
        padding: '34px 15px',
      },
    },
    otpContainer: {
      position: 'relative',
      '& a': {
        position: 'absolute',
        right: 0,
        bottom: 0,
        fontSize: 16,
        color: '#02475b',
        fontWeight: 700,
        textDecoration: 'none',
        fontFamily: "'IBM Plex Sans', sans-serif",
      },
    },
    loader: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    errorMsg: {
      color: 'red',
    },
    signInGrid: {
      [theme.breakpoints.down('xs')]: {
        paddingTop: '0 !important',
      },
    },
  };
});

export const OktaLoginForm = (props: any) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [isLoginDisabled, setIsLoginDisabled] = useState<boolean>(true);
  const classes = useStyles();

  const { oktaAuth } = useOktaAuth();
  const [sessionToken, setSessionToken] = useState<string>();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMsg, setErrorMsg] = useState<boolean>(false);

  useEffect(() => {
    if (username?.length > 5 && password.length > 2) {
      setIsLoginDisabled(false);
      setErrorMsg(null);
    }
  }, [username, password]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    oktaAuth
      .signInWithCredentials({ username, password })
      .then((res) => {
        setLoading(false);
        const sessionToken = res.sessionToken;
        setSessionToken(sessionToken);
        // sessionToken is a one-use token, so make sure this is only called once
        oktaAuth.signInWithRedirect({ sessionToken });
      })
      .catch((err) => {
        setErrorMsg(err);
        console.log('Found an error', err);
      })
      .finally(() => {
        localStorage.removeItem('storeId');
        setLoading(false);
      });
  };

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  if (sessionToken) {
    // Hide form while sessionToken is converted into id/access tokens
    return null;
  }

  return (
    <div className={classes.signInContainer}>
      <div className={classes.mainContent}>
        <Grid container spacing={4}>
          <Grid item md={6}>
            <div className={classes.signinImg}>
              <img src={require('../images/sign-in.svg')} alt="" />
              <h1>Diagnostics Order Punching Tool</h1>
            </div>
          </Grid>
          <Grid className={classes.signInGrid} item md={6} sm={6} xs={12}>
            <div className={classes.signInContent}>
              <Link to="" className={classes.logo}>
                <img src={require('../images/logo.png')} alt="Logo" />
              </Link>
              <Typography component="h2">Login</Typography>
              {errorMsg && (
                <Typography component="h5" className={classes.errorMsg}>
                  Authentication failed
                </Typography>
              )}
              <form
                className={classes.loginForm}
                noValidate
                autoComplete="off"
                onSubmit={handleSubmit}
              >
                <TextField
                  label="Enter Email"
                  value={username}
                  onChange={handleUsernameChange}
                  className={classes.formControl}
                  type="email"
                />

                <div className={classes.otpContainer}>
                  <TextField
                    label="Enter Password"
                    type="password"
                    className={classes.formControl}
                    value={password}
                    onChange={handlePasswordChange}
                  />
                  {/* <a href="#">Resend OTP</a> */}
                </div>
                {!loading ? (
                  <Button
                    type="submit"
                    className={
                      isLoginDisabled
                        ? classes.disabledLogin
                        : classes.primaryBtn
                    }
                    disabled={isLoginDisabled}
                  >
                    Login
                  </Button>
                ) : (
                  <div className={classes.loader}>
                    <CircularProgress size={22} />
                  </div>
                )}
              </form>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default OktaLoginForm;
