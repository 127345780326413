import { default as React, useEffect, useRef, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import AphTextField from './AphTextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import addMonths from 'date-fns/addMonths';
import { AphCalendar } from './AphCalendar';
import AphButton from './AphButton';
import AphDialog from './AphDialog';
import AphDialogClose from './AphDialogClose';
import FormHelperText from '@material-ui/core/FormHelperText';
import CircularProgress from '@material-ui/core/CircularProgress';
import Checkbox from '@material-ui/core/Checkbox';
import {
  bookRadiologyTest,
  getRadiologyDepartments,
  getRadiologyHospitals,
  getRadiologyLocation,
  uploadRadiologyUploadPrescriptions,
} from '../radiologyHelper';

import {
  isMobileNumberValid,
  MAX_FILE_SIZE_FOR_UPLOAD,
  INVALID_FILE_SIZE_ERROR,
  acceptedFilesNamesForFileUpload,
  toBase64,
  mimeType,
  getAgentEmail,
} from './helpers/commonHelpers';
import RadiologyConfirmation from './RadiologyConfirmation';
import {
  GetRadiologyLocations,
  HospitalClinicData,
  RadiologyBookingParams,
  RadiologyFormData,
  RadiologyFormField,
  RadiologyInputFields,
  RadiologyUnitTypes,
  RadiologyUploadedFileData,
  SubTestData,
  TestData,
} from '../types/radiologyBooking';
import { useOrderContext } from '../OrderProvider';
import { CDN_URL } from '../appConfig';

const useStyles = makeStyles((theme: Theme) => {
  return {
    container: {
      margin: 'auto',
      maxWidth: 1064,
      background: '#FFFFFF',
    },
    loading: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      zIndex: 2,
      backgroundColor: '#ffffff5d',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    shareDetails: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '45px 41px',
      [theme.breakpoints.down('xs')]: {
        padding: '30px 30px 0px 30px',
        flexDirection: 'column',
      },
    },
    detailsLhs: {
      flexBasis: '45%',
      '&>h1': {
        margin: 0,
        fontSize: 40,
        fontWeight: 700,
        lineHeight: '49px',
        marginBottom: '48px',
        [theme.breakpoints.down('xs')]: {
          fontSize: 16,
          lineHeight: 'unset',
          marginBottom: 6,
        },
      },
      '&>p': {
        marginLeft: 5,
        letterSpacing: 0.2,
        marginBottom: 24,
        fontSize: 16,
        fontWeight: 400,
        [theme.breakpoints.down('xs')]: {
          fontSize: 12,
          marginLeft: 0,
        },
        '& span': {
          color: '#E98925',
          '& img': {
            verticalAlign: 'middle',
            paddingLeft: 14,
          },
        },
      },
    },
    howItWorks: {
      marginTop: 100,
      [theme.breakpoints.down('xs')]: {
        padding: '10px 30px',
        margin: '40px -30px 0 -30px',
        background: 'rgba(49, 205, 149, 0.17)',
      },
      '& h2': {
        fontSize: 24,
        fontWeight: 600,
        margin: 0,
        [theme.breakpoints.down('xs')]: {
          fontSize: 14,
        },
      },
      '&>div': {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: 35,
        [theme.breakpoints.down('xs')]: {
          flexDirection: 'column',
          alignItems: 'flex-start',
          margin: '17px 0px 0px 18px',
        },
        '&>div': {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'center',
          textAlign: 'center',
          fontSize: 12,
          fontWeight: 500,
          flexBasis: '30%',
          [theme.breakpoints.down('xs')]: {
            flexDirection: 'row',
            marginBottom: 24,
            fontSize: 14,
          },
          '& img': {
            marginBottom: 14,
            [theme.breakpoints.down('xs')]: {
              marginBottom: 0,
              paddingRight: 20,
            },
          },
        },
      },
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: 600,
      position: 'relative',
      width: '41%',
      background: '#FAF7F7',
      borderRadius: 4,
      padding: '24px 48px',
      [theme.breakpoints.down('xs')]: {
        margin: '0px -30px',
        minHeight: 570,
        width: 'auto',
      },
      '&>h3': {
        margin: '0 0 25px 0',
        fontWeight: 'bold',
        fontSize: 24,
        textAlign: 'center',
      },
    },
    formGroup: {
      marginBottom: 35,
      [theme.breakpoints.down('xs')]: {
        marginBottom: 29,
      },
      '& p': {
        marginBottom: -24,
        paddingLeft: 13,
        [theme.breakpoints.down('xs')]: {
          marginBottom: -23,
        },
      },
    },
    input: {
      '&>div': {
        '&:before': {
          display: 'none',
        },
        '&:after': {
          display: 'none',
        },
        '&>input': {
          fontSize: 14,
          padding: '5px 13px',
          borderBottom: '1px solid #02475B',
          '&:focus': {
            borderBottom: '1px solid #31CD95',
          },
        },
      },
    },
    valid: {
      color: '#31CD95 !important',
    },
    invalid: {
      color: '#E94825 !important',
    },
    uploadHelper: {
      color: 'rgb(2, 71, 91, 0.66) !important',
    },
    select: {
      width: '100%',
      '&:after': {
        display: 'none',
      },
      '&:before': {
        display: 'none',
      },
      '&>div': {
        borderBottom: '1px solid #02475B',
        padding: '5px 13px',
        fontSize: 14,
        background: 'none !important',
        whiteSpace: 'nowrap',
        '&:focus': {
          borderBottom: '1px solid #31CD95',
        },
      },
      '& svg': {
        color: '#02475B',
      },
    },
    testsSelected: {
      padding: '2px 0px !important',
      '& button': {
        width: '100%',
      },
    },
    menuPopover: {
      borderRadius: 'unset',
      '& ul': {
        padding: '0px 17px',
        display: 'flex',
        flexDirection: 'column',
        '& li': {
          background: 'none !important',
          fontSize: 14,
          fontWeight: 500,
          borderBottom: '1px solid rgba(2, 71, 91, 0.2)',
          padding: '12px 10px',
          '&:last-child': {
            border: 'unset',
            borderBottom: 'none',
          },
          '&:hover': {
            color: '#31CD95',
          },
          '&.subHeader': {
            textTransform: 'uppercase',
            color: '#02475B',
            fontWeight: 600,
            padding: '20px 10px',
            opacity: 1,
            '&:hover': {
              color: '#02475B',
            },
          },
          '&.placeHolder': {
            display: 'none',
          },
          '&.radiologyHospital': {
            order: 0,
          },
        },
      },
    },
    flexed: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    datePickerInput: {
      width: 'calc(100% - 30px)',
    },
    datePickerBtn: {
      padding: 0,
      minWidth: 25,
      boxShadow: 'none',
      '& img': {
        width: 20,
        height: 20,
      },
    },
    datePickerDialog: {
      padding: 20,
    },
    formGroupLast: {
      marginBottom: 100,
    },
    uploadField: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'stretch',
      padding: '5px 13px',
      fontSize: 14,
      borderBottom: '1px solid #02475B',
    },
    uploadLabel: {
      flexBasis: '75%',
      '& span': {
        color: 'rgb(2, 71, 91, 0.66)',
        fontSize: 14,
        '&.error': {
          color: '#E94825',
          fontSize: 12,
        },
        '&.valid': {
          color: '#02475b',
          fontSize: 14,
        },
      },
    },
    iconBlock: {
      flexBasis: '20%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& button': {
        padding: 0,
        fontSize: 13,
        color: '#FC9916',
        fontWeight: 700,
        minWidth: 0,
        textTransform: 'uppercase',
        boxShadow: 'none',
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
    },
    submit: {
      bottom: 30,
      position: 'absolute',
      width: 'calc(100% - 96px)',
      '& button': {
        width: '100%',
        fontSize: 14,
        color: '#fff',
        padding: '11px 24px',
        borderRadius: 5,
        textTransform: 'uppercase',
        background: '#E98925',
        '&:disabled': {
          opacity: 0.4,
        },
      },
    },
  };
});

const defaultFormData: RadiologyFormData = {
  userName: {
    isTouched: false,
    isValid: false,
    value: '',
    helperText: '',
  },
  mobileNumber: {
    isTouched: false,
    isValid: false,
    value: '',
    helperText: '',
  },
  location: {
    isTouched: false,
    isValid: false,
    value: '',
    helperText: '',
  },
  unitId: {
    isTouched: false,
    isValid: false,
    value: '',
    helperText: '',
  },
  department: {
    isTouched: false,
    isValid: false,
    value: [],
    helperText: '',
  },
  testDescription: {
    isTouched: false,
    isValid: false,
    value: '',
    helperText: '',
  },
  appointmentDate: {
    isTouched: false,
    isValid: false,
    value: '',
    helperText: '',
  },
  uploadedFile: {
    isTouched: false,
    isValid: false,
    value: '',
    helperText: 'File type: pdf, jpg, png',
    fileName: 'Upload prescription',
  },
};

export const Radiology = () => {
  const {
    selectedNonCartFlowOrder,
    setShouldResetDashboard,
    setSelectedNonCartFlowOrder,
  } = useOrderContext();
  const classes = useStyles({});
  const nameInputRef = useRef<HTMLInputElement>(null);
  const inputFileRef = useRef<HTMLInputElement>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [formData, setFormData] = useState<RadiologyFormData>(defaultFormData);
  const [locationData, setLocationData] = useState<GetRadiologyLocations[]>([]);
  const [hospitalData, setHospitalData] = useState<HospitalClinicData[]>([]);
  const [clinicData, setClinicData] = useState<HospitalClinicData[]>([]);
  const [isCalendarOpen, setIsCalendarOpen] = useState<boolean>(false);
  const [testData, setTestData] = useState<TestData[]>([]);
  const [subTestData, setSubTestData] = useState<SubTestData[]>([]);
  const [selectedDate, setSelectedDate] = useState<string>('');
  const [uploadedFile, setUploadedFile] =
    useState<RadiologyUploadedFileData>(null);
  const [uploadErrorMsg, setUploadErrorMsg] = useState<string>('');
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [bookAppointment, setBookAppointment] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(true);
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [showTestsName, setShowTestsName] = useState<boolean>(false);

  const isPhoneNumberValid = (number: string) => {
    return isMobileNumberValid(number) && number?.length === 10;
  };

  const getHelperText = (field: RadiologyInputFields, isValid: boolean) => {
    switch (field) {
      case RadiologyInputFields.USER_NAME: {
        return isValid ? 'Full Name' : 'Enter your Full Name';
      }
      case RadiologyInputFields.MOBILE_NUMBER: {
        return isValid
          ? 'Mobile Number'
          : 'Enter your  10 digits mobile number';
      }
      case RadiologyInputFields.LOCATION: {
        return isValid ? 'City' : 'Choose a City';
      }
      case RadiologyInputFields.HOSPITAL: {
        return isValid ? 'Apollo Clinic' : 'Choose an Apollo Clinic';
      }
      case RadiologyInputFields.APPOINTMENT_DATE: {
        return isValid ? 'Appointment Date' : 'Enter the Appointment Date';
      }
      case RadiologyInputFields.DEPARTMENT: {
        return isValid ? 'Tests' : 'Choose Test(s)';
      }
    }
  };

  const validateInputField = (
    field: RadiologyInputFields,
    value: string | string[]
  ): boolean => {
    if (Array.isArray(value)) {
      return value?.length > 0;
    } else {
      if (field === RadiologyInputFields.MOBILE_NUMBER) {
        return isPhoneNumberValid(value);
      } else {
        return value?.length > 0;
      }
    }
  };

  const handleInputFocus = (field: RadiologyInputFields) => {
    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        [field]: {
          ...prevFormData[field],
          isTouched: true,
        },
      };
    });
  };

  const uploadPrescription = (file: File) => {
    toBase64(file)
      .then((res: string) => {
        setUploadedFile({
          mimeType: mimeType(file.name),
          base64Content: res,
          url: '',
        });

        setFormData((prevFormData) => {
          return {
            ...prevFormData,
            [RadiologyInputFields.UPLOAD_FILE]: {
              isTouched: true,
              isValid: true,
              value: '',
              helperText: 'Upload prescription',
              fileName: file.name,
            },
          };
        });
      })
      .catch((err) => {
        console.error('Error uploading prescription', err);
        setUploadedFile(null);
        setUploadErrorMsg('Unable to upload, please try again');
      })
      .finally(() => setIsUploading(false));
  };

  const handleInputBlurChange = (
    field: RadiologyInputFields,
    value: string | string[]
  ) => {
    setFormData((prevFormData) => {
      const prevFieldData = { ...prevFormData[field] };

      if (prevFieldData.isTouched) {
        prevFieldData.isValid = validateInputField(field, value);
        prevFieldData.helperText = getHelperText(field, prevFieldData.isValid);
      }

      return {
        ...prevFormData,
        [field]: {
          ...prevFieldData,
          value,
        },
      };
    });
  };

  const resetInputField = (field: RadiologyInputFields) => {
    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        [field]: {
          ...defaultFormData[field],
        },
      };
    });
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUploadedFile(null);
    setUploadErrorMsg(null);
    resetInputField(RadiologyInputFields.UPLOAD_FILE);
    const fileNames = e.target.files;
    if (fileNames?.length) {
      const file = fileNames[0] || null;
      const fileExtension = file?.name?.split('.')?.pop();
      const fileSize = file?.size;

      if (fileSize > MAX_FILE_SIZE_FOR_UPLOAD) {
        setUploadErrorMsg(INVALID_FILE_SIZE_ERROR);
      } else if (
        acceptedFilesNamesForFileUpload.includes(fileExtension?.toLowerCase())
      ) {
        setIsUploading(true);
        if (file) {
          uploadPrescription(file);
        }
      } else {
        setUploadErrorMsg('Upload failed. Please upload pdf/image format');
        setIsUploading(false);
      }
    }
  };

  const getTruncatedFileName = (fileName: string = '') =>
    fileName?.length > 31
      ? `${fileName.slice(0, 15)}.....${fileName.slice(-11)}`
      : fileName;

  const savePrescription = () => {
    uploadRadiologyUploadPrescriptions(uploadedFile)
      .then(({ data }) => {
        if (data?.uploadRadiologyPrescription?.status) {
          const url = data?.uploadRadiologyPrescription?.message || '';
          setUploadedFile((prevUploadedFile) => {
            return {
              ...prevUploadedFile,
              url,
            };
          });
        }
      })
      .catch((err) => {
        console.error('Error fetching prescription link', err);
        setUploadedFile(null);
      })
      .finally(() => setBookAppointment(true));
  };

  const handleSubmit = () => {
    setIsLoading(true);
    if (uploadedFile?.base64Content) {
      savePrescription();
    } else {
      setBookAppointment(true);
    }
  };

  const getDepartmentNames = () => {
    const departmentName = [];
    testData?.forEach((test) => {
      if (formData?.department?.value?.find((id) => id === test.departmentId)) {
        departmentName.push(test.department);
      }
    });
    return departmentName;
  };

  const bookRadiology = () => {
    const bookingParams: RadiologyBookingParams = {
      agentId: getAgentEmail(),
      userName: formData.userName.value,
      mobileNumber: formData.mobileNumber.value,
      location: locationData?.find(
        (location) => location.locationId === formData.location.value
      )?.location,
      unitId: formData.unitId.value,
      testNames: getDepartmentNames(),
      testDetails: formData.testDescription.value,
      appointmentDate: parse(
        formData.appointmentDate.value,
        'dd/MM/yyyy',
        new Date()
      ).toISOString(),
      prescriptionLink: uploadedFile?.url || '',
      source: 'OPT',
    };

    bookRadiologyTest(bookingParams)
      .then(({ data }) => {
        setIsSuccess(!!data?.bookRadiologyTest?.status);
      })
      .catch((err) => {
        console.error('Error trying to book radiology test', err);
        setIsSuccess(false);
      })
      .finally(() => {
        setIsLoading(false);
        setShowConfirmation(true);
      });
  };

  const isFormValid = () => {
    let isFormValid = true;
    for (const field in formData) {
      const fieldData = formData[field] as RadiologyFormField;
      if (field === RadiologyInputFields.TEST_DESCRIPTION) {
        if (isFormValid && !fieldData.isTouched) {
          isFormValid = true;
        } else {
          isFormValid = isFormValid && fieldData.isTouched && fieldData.isValid;
        }
      } else if (field !== RadiologyInputFields.UPLOAD_FILE) {
        isFormValid = isFormValid && fieldData.isTouched && fieldData.isValid;
      }
      if (!isFormValid) return isFormValid;
    }

    return isFormValid;
  };

  const resetData = () => {
    if (selectedNonCartFlowOrder) {
      setSelectedNonCartFlowOrder(null);
    } else {
      setShouldResetDashboard(true);
    }
    setFormData(defaultFormData);
    setTestData([]);
    setSubTestData([]);
    setSelectedDate('');
    setShowConfirmation(false);
    setUploadErrorMsg('');
    setUploadedFile(null);
  };

  useEffect(() => {
    getRadiologyLocation()
      .then((data) => {
        setLocationData(data?.data?.getRadiologyLocations);
      })
      .catch((err) => {
        console.error('Error fetching radiology locations', err);
        return setLocationData([]);
      });
  }, []);

  useEffect(() => {
    if (formData?.location?.value?.length) {
      setIsLoading(true);
      resetInputField(RadiologyInputFields.DEPARTMENT);
      resetInputField(RadiologyInputFields.TEST_DESCRIPTION);
      resetInputField(RadiologyInputFields.HOSPITAL);
      setHospitalData([]);
      setClinicData([]);
      setTestData([]);

      getRadiologyHospitals(formData?.location?.value)
        .then((data) => {
          const dataArray = data?.data?.getRadiologyHospitalsClinics;
          const hospitalArray = [];
          dataArray?.forEach((data) => {
            if (data?.unitType === 'HOSPITAL') {
              hospitalArray.push(data);
            }
          });
          setHospitalData(hospitalArray);
          const clinicArray = [];
          dataArray?.forEach((data) => {
            if (data?.unitType === 'CLINIC') {
              clinicArray.push(data);
            }
          });
          setClinicData(clinicArray);
        })
        .catch((err) => {
          console.error('Error fetching hospitals', err);
          setHospitalData([]);
          setClinicData([]);
        })
        .finally(() => setIsLoading(false));

      getRadiologyDepartments(formData?.location?.value)
        .then((data) => {
          setTestData(data?.data?.getRadiologyDepartments);
        })
        .catch((err) => {
          console.error('Error fetching tests', err);
          setTestData([]);
        })
        .finally(() => setIsLoading(false));
    }
  }, [formData?.location?.value]);

  useEffect(() => {
    handleInputBlurChange(RadiologyInputFields.APPOINTMENT_DATE, selectedDate);
  }, [selectedDate]);

  useEffect(() => {
    if (bookAppointment) {
      bookRadiology();
      setBookAppointment(false);
    }
  }, [bookAppointment]);

  return (
    <div className={classes.container}>
      <div className={classes.shareDetails}>
        <div className={classes.detailsLhs}>
          <h1>
            Access Best-in-class
            <br /> X-Ray and Scan
          </h1>
          <p>
            Access the best in class radiology and imaging services of Apollo,{' '}
            <b>#DirectApolloSe.</b>
          </p>
          <p>
            Services include X-Rays, Test Scans and other radiology procedure
          </p>

          <div className={classes.howItWorks}>
            <h2>How it works?</h2>
            <div>
              <div>
                <img
                  src={`${CDN_URL}/images/Radiology/ic_details.png`}
                  alt=""
                />
                Enter Patient Details
              </div>

              <div>
                <img src={`${CDN_URL}/images/Radiology/ic_call.png`} alt="" />
                We get back to you within 2 hours
              </div>

              <div>
                <img src={`${CDN_URL}/images/Radiology/ic_tick.png`} alt="" />
                We confirm your booking on call
              </div>
            </div>
          </div>
        </div>

        <div className={classes.form}>
          {!showConfirmation && (
            <>
              {isLoading && (
                <div className={classes.loading}>
                  <CircularProgress size={50} />
                </div>
              )}
              <h3>Enter Patient Details</h3>

              {/* Full Name */}
              <div className={classes.formGroup}>
                <AphTextField
                  inputRef={nameInputRef}
                  className={classes.input}
                  placeholder="Name"
                  value={formData.userName.value}
                  onFocus={() => {
                    handleInputFocus(RadiologyInputFields.USER_NAME);
                  }}
                  onBlur={(e) => {
                    const nameInputData = e?.currentTarget?.value;
                    handleInputBlurChange(
                      RadiologyInputFields.USER_NAME,
                      nameInputData
                    );
                  }}
                  onChange={(e) => {
                    const nameInputData = e?.currentTarget?.value;
                    handleInputBlurChange(
                      RadiologyInputFields.USER_NAME,
                      nameInputData
                    );
                  }}
                  helperText={formData.userName.helperText}
                  FormHelperTextProps={{
                    className: formData.userName.isValid
                      ? classes.valid
                      : classes.invalid,
                  }}
                />
              </div>

              {/* Mobile Number */}
              <div className={classes.formGroup}>
                <AphTextField
                  className={classes.input}
                  placeholder="Mobile"
                  value={formData.mobileNumber.value}
                  onFocus={() => {
                    handleInputFocus(RadiologyInputFields.MOBILE_NUMBER);
                  }}
                  onBlur={(e) => {
                    const phoneInputData = e.currentTarget.value;
                    handleInputBlurChange(
                      RadiologyInputFields.MOBILE_NUMBER,
                      phoneInputData
                    );
                  }}
                  onChange={(e) => {
                    const phoneInputData = e.currentTarget.value;
                    handleInputBlurChange(
                      RadiologyInputFields.MOBILE_NUMBER,
                      phoneInputData
                    );
                  }}
                  helperText={formData.mobileNumber.helperText}
                  FormHelperTextProps={{
                    className: formData.mobileNumber.isValid
                      ? classes.valid
                      : classes.invalid,
                  }}
                />
              </div>

              {/* Location */}
              <div className={classes.formGroup}>
                <Select
                  className={classes.select}
                  MenuProps={{
                    classes: { paper: classes.menuPopover },
                    getContentAnchorEl: null,
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                  }}
                  displayEmpty
                  value={formData.location.value}
                  onFocus={() => {
                    handleInputFocus(RadiologyInputFields.LOCATION);
                  }}
                  onBlur={(e) => {
                    const locationInputData = e.target.value as string;
                    handleInputBlurChange(
                      RadiologyInputFields.LOCATION,
                      locationInputData
                    );
                  }}
                  onChange={(e) => {
                    const locationInputData = e.target.value as string;
                    handleInputBlurChange(
                      RadiologyInputFields.LOCATION,
                      locationInputData
                    );
                  }}
                >
                  <MenuItem disabled value="">
                    Choose City
                  </MenuItem>
                  {locationData?.map((location) => (
                    <MenuItem
                      key={location?.locationId}
                      value={location?.locationId}
                    >
                      {location?.location}
                    </MenuItem>
                  ))}
                </Select>
              </div>

              {/* Hospital/Clinic */}
              <div className={classes.formGroup}>
                <Select
                  className={classes.select}
                  MenuProps={{
                    classes: { paper: classes.menuPopover },
                    getContentAnchorEl: null,
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                  }}
                  displayEmpty
                  value={formData.unitId.value}
                  onFocus={() => {
                    handleInputFocus(RadiologyInputFields.HOSPITAL);
                  }}
                  onBlur={(e) => {
                    const hospitalInputData = e.target.value;
                    handleInputBlurChange(
                      RadiologyInputFields.HOSPITAL,
                      hospitalInputData
                    );
                  }}
                  onChange={(e) => {
                    const hospitalInputData = e.target.value as string;
                    handleInputBlurChange(
                      RadiologyInputFields.HOSPITAL,
                      hospitalInputData
                    );
                  }}
                  placeholder="Select Apollo Clinic"
                >
                  <MenuItem
                    className={`${
                      hospitalData?.length > 0 || clinicData?.length > 0
                        ? 'placeHolder'
                        : ''
                    }`}
                    disabled
                    value=""
                  >
                    Select Apollo Clinic
                  </MenuItem>
                  {!!hospitalData?.find(
                    (unit) => unit.unitType === RadiologyUnitTypes.HOSPITAL
                  ) && (
                    <MenuItem className="subHeader radiologyHospital" disabled>
                      Hospitals
                    </MenuItem>
                  )}
                  {hospitalData?.map((unit) => (
                    <MenuItem
                      key={unit?.unitId}
                      value={unit?.unitId}
                      className={`${'radiologyHospital'}`}
                    >
                      {unit?.unitName}
                    </MenuItem>
                  ))}
                  {!!clinicData?.find(
                    (unit) => unit.unitType === RadiologyUnitTypes.CLINIC
                  ) && (
                    <MenuItem className="subHeader radiologyClinic" disabled>
                      Clinics
                    </MenuItem>
                  )}
                  {clinicData?.map((unit) => (
                    <MenuItem
                      key={unit?.unitId}
                      value={unit?.unitId}
                      className={`${'radiologyClinic'}`}
                    >
                      {unit?.unitName}
                    </MenuItem>
                  ))}
                </Select>
                {formData.unitId.helperText.length > 0 && (
                  <FormHelperText
                    className={
                      formData.unitId.isValid ? classes.valid : classes.invalid
                    }
                  >
                    {formData.unitId.helperText}
                  </FormHelperText>
                )}
              </div>

              {/* Appointment Date */}
              <div className={`${classes.formGroup} ${classes.flexed}`}>
                <AphTextField
                  type="text"
                  className={`${classes.input} ${classes.datePickerInput}`}
                  onFocus={() =>
                    handleInputFocus(RadiologyInputFields.APPOINTMENT_DATE)
                  }
                  onBlur={(e) => {
                    const dateInputData = e?.currentTarget?.value;
                    handleInputBlurChange(
                      RadiologyInputFields.APPOINTMENT_DATE,
                      dateInputData
                    );
                  }}
                  onKeyDown={() => {
                    setIsCalendarOpen(true);
                  }}
                  value={formData.appointmentDate.value}
                  helperText={formData.appointmentDate.helperText}
                  FormHelperTextProps={{
                    className: formData.appointmentDate.isValid
                      ? classes.valid
                      : classes.invalid,
                  }}
                  placeholder="Preferred Date of Appointment"
                />
                <AphButton
                  className={classes.datePickerBtn}
                  onClick={() => {
                    setIsCalendarOpen((prevValue) => !prevValue);
                    handleInputFocus(RadiologyInputFields.APPOINTMENT_DATE);
                  }}
                >
                  <img
                    src={`${CDN_URL}/images/${
                      isCalendarOpen
                        ? 'ic_calendar_close.svg'
                        : 'ic_calendar_show.svg'
                    }`}
                    alt=""
                  />
                </AphButton>
              </div>

              {/* Test */}
              <div className={classes.formGroup}>
                <Select
                  multiple
                  className={classes.select}
                  open={showTestsName}
                  onOpen={() => setShowTestsName(true)}
                  onClose={() => setShowTestsName(false)}
                  MenuProps={{
                    classes: { paper: classes.menuPopover },
                    getContentAnchorEl: null,
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                  }}
                  displayEmpty
                  renderValue={(selected: string[]) =>
                    selected?.length
                      ? selected
                          ?.filter((id) => !!id)
                          .map((id) => {
                            return testData?.find(
                              (name) => name.departmentId === id
                            )?.department;
                          })
                          .join(',')
                      : 'Choose Tests'
                  }
                  onFocus={() => {
                    handleInputFocus(RadiologyInputFields.DEPARTMENT);
                  }}
                  value={formData?.department?.value}
                  onBlur={(e) => {
                    const departmentInputData = e?.target?.value as string;
                    handleInputBlurChange(
                      RadiologyInputFields.DEPARTMENT,
                      departmentInputData
                    );
                  }}
                  onChange={(e) => {
                    const departmentInputData = e?.target?.value as string[];
                    handleInputBlurChange(
                      RadiologyInputFields.DEPARTMENT,
                      departmentInputData
                    );
                  }}
                >
                  <MenuItem disabled value="">
                    Choose Tests
                  </MenuItem>
                  {testData?.map((test) => (
                    <MenuItem
                      key={test?.departmentId}
                      value={test?.departmentId}
                    >
                      <Checkbox
                        checked={formData?.department?.value?.includes(
                          test?.departmentId
                        )}
                      />
                      {test?.department}
                    </MenuItem>
                  ))}
                  {testData?.length > 0 && (
                    <MenuItem className={classes.testsSelected}>
                      <AphButton
                        color="primary"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setShowTestsName(false);
                        }}
                      >
                        Done
                      </AphButton>
                    </MenuItem>
                  )}
                </Select>
                {formData.department.helperText.length > 0 && (
                  <FormHelperText
                    className={
                      formData.department.isValid
                        ? classes.valid
                        : classes.invalid
                    }
                  >
                    {formData.department.helperText}
                  </FormHelperText>
                )}
              </div>

              {/* Test Description */}
              <div className={classes.formGroup}>
                <AphTextField
                  className={classes.input}
                  placeholder="Add Details"
                  value={formData.testDescription.value}
                  onChange={(e) => {
                    const otherTestInputData = e?.currentTarget?.value;
                    handleInputBlurChange(
                      RadiologyInputFields.TEST_DESCRIPTION,
                      otherTestInputData
                    );
                  }}
                  helperText={formData.testDescription.helperText}
                  FormHelperTextProps={{
                    className: formData.testDescription.isValid
                      ? classes.valid
                      : classes.invalid,
                  }}
                />
              </div>

              {/* Upload Prescription */}
              <div className={`${classes.formGroup} ${classes.formGroupLast}`}>
                <div className={classes.uploadField}>
                  <input
                    ref={inputFileRef}
                    type="file"
                    hidden
                    onChange={handleFileChange}
                  />
                  <div className={classes.uploadLabel}>
                    {uploadErrorMsg?.length ? (
                      <span className="error">{uploadErrorMsg}</span>
                    ) : (
                      <span
                        className={`${
                          formData.uploadedFile.isValid ? 'valid' : ''
                        }`}
                      >
                        {getTruncatedFileName(formData.uploadedFile.fileName)}
                      </span>
                    )}
                  </div>
                  <div className={classes.iconBlock}>
                    {isUploading ? (
                      <CircularProgress size={20} />
                    ) : (
                      <AphButton
                        variant="text"
                        onClick={() => inputFileRef?.current?.click()}
                      >
                        UPLOAD
                      </AphButton>
                    )}
                  </div>
                </div>
                {formData.uploadedFile.helperText && (
                  <FormHelperText
                    className={
                      formData.uploadedFile.isValid
                        ? classes.valid
                        : classes.uploadHelper
                    }
                  >
                    {formData.uploadedFile.helperText}
                  </FormHelperText>
                )}
              </div>

              <div className={classes.submit}>
                <AphButton
                  color="primary"
                  onClick={handleSubmit}
                  disabled={!isFormValid()}
                >
                  Book Radiology Test
                </AphButton>
              </div>
            </>
          )}

          <RadiologyConfirmation
            isSuccess={isSuccess}
            showConfirmation={showConfirmation}
            btnAction={resetData}
            userName={formData.userName.value}
            department={getDepartmentNames()}
            testDescription={formData.testDescription.value}
          />
        </div>
      </div>

      <AphDialog
        open={isCalendarOpen}
        maxWidth="sm"
        onBackdropClick={() => {
          setIsCalendarOpen(false);
          !selectedDate && setSelectedDate(format(new Date(), 'dd/MM/yyyy'));
        }}
      >
        <AphDialogClose
          onClick={() => {
            setIsCalendarOpen(false);
            !selectedDate && setSelectedDate(format(new Date(), 'dd/MM/yyyy'));
          }}
          title={'Close'}
        />
        <div className={classes.datePickerDialog}>
          <AphCalendar
            getDate={(dateSelected: string) => {
              setSelectedDate(dateSelected);
              setIsCalendarOpen(false);
            }}
            selectedDate={parse(selectedDate, 'dd/MM/yyyy', new Date())}
            maxDate={addMonths(new Date(), 1)}
          />
        </div>
      </AphDialog>
    </div>
  );
};

export default Radiology;
