import React, { useCallback, useEffect, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
  FinalCartItemsToDisplay,
  PatientObjWithLineItems,
  couponPricingAccumulator,
  getTestsCirclePrice,
  CartDiscountObj,
  PricingObject,
  ValidatePayload,
  GetCouponsResponse,
  ValidateResponse,
  ValidateResponseDiagnostic,
  reportTATFormat,
  toTitleCase,
  LineItemsObject,
  CouponDiagnosticItem,
  roundOff,
  DiagnosticCartItem,
} from './helpers/commonHelpers';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  DIAGNOSTICS_GROUPPLAN,
  GET_ONE_APOLLO,
  DIAGNOSTIC_USER_ACCESS,
  CDN_URL,
} from '../appConfig';
import { AxiosResponse } from 'axios';
import axios from '../axios';
import { useOrderContext } from '../OrderProvider';
import { Coupon } from './Coupon';
import { getPackageIds } from './SubscriptionsUtil';
import AphCheckbox from './AphCheckbox';
import format from 'date-fns/format';
import isEqual from 'date-fns/isEqual';
import PhleboCharges from './PhleboCharges';
import Backdrop from '@material-ui/core/Backdrop';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    summaryList: {
      margin: 0,
      padding: 0,
      '& > li': {
        listStyleType: 'none',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: 12,
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: 14,
        borderBottom: '1px solid #ECECEC',
        '&>div': {
          display: 'flex',
          justifyContent: 'space-between',
          '& > div': {
            '&:first-child': {
              flexBasis: '70%',
              lineHeight: '22px',
            },
            '&>span': {
              fontWeight: 500,
              fontSize: 12,
              color: '#007C9D',
              display: 'block',
              background: '#F3FFFF',
              borderRadius: 4,
              padding: '4px 6px',
            },
            '&>p': {
              margin: 0,
            },
            '&:last-child': {
              textAlign: 'right',
            },
          },
        },
      },
    },
    couponSection: {
      border: '2px rgba(1, 71, 91, 0.3)',
      borderRadius: 10,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      color: '#FC9916',
      fontSize: 14,
      fontWeight: 600,
      padding: '8px',
      width: '93%',
      flexDirection: 'column',
    },
    fw: {
      fontWeight: 700,
    },
    imageAndTxtField: {
      display: 'flex',
      alignItems: 'center',
    },
    rotated: {
      '& img': {
        transform: 'rotate(-90deg)',
        padding: 0,
      },
    },
    subTotalWrap: {
      '&>div': {
        display: 'flex',
        flexDirection: 'row',
        fontSize: 14,
        justifyContent: 'space-between',
        fontWeight: 600,
        padding: '4px 12px',
      },
    },
    green: {
      color: '#00B38E',
    },
    modifySubTotal: {
      borderBottom: '1px solid #ddd',
      marginTop: 10,
      paddingBottom: '10px !important',
      '&>.toPayAmount': {
        fontSize: '20px !important',
        fontWeight: 700,
      },
    },
    subTotal: {
      fontSize: '24px !important',
      fontWeight: 700,
      borderTop: '1px solid #ddd',
      marginTop: 10,
      padding: '11px 12px !important',
    },
    footer: {
      background: '#F9F9F9',
      textAlign: 'right',
      padding: '32px 12px 25px 12px',
      '& button': {
        background: '#FCB716',
        width: 171,
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
        borderRadius: 5,
        color: '#fff',
        fontSize: 13,
        fontWeight: 'bold',
        padding: '8px 36px',
        whiteSpace: 'nowrap',
      },
    },
    errorMsg: {
      fontSize: '10px',
      lineHeight: '13px',
      color: '#AB2300',
      '& img': {
        verticalAlign: 'middle',
      },
    },
    couponLabel: {
      width: 170,
      height: 17,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      wordBreak: 'break-word',
    },
    removeCouponImg: {
      padding: 0,
    },
    circleImage: {
      width: 30,
      marginRight: 5,
      verticalAlign: 'middle',
    },
    cashbackSection: {
      background: ' #F3FFFF',
      border: '1px solid #309C80',
      borderRadius: 4,
      marginBottom: 20,
      padding: 9,
      '& span': {
        color: '#00B38E',
        fontWeight: 600,
        fontSize: '12px !important',
        margin: '0px 5px',
      },
    },
    cashback: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: 8,
      '& img': {
        width: 24,
        marginRight: 4,
      },
      '& div': {
        fontSize: 12,
        fontWeight: 500,
        display: 'flex',
        alignItems: 'center',
        '& img': {
          width: 24,
          '&.greenIcon': {
            filter:
              'invert(55%) sepia(75%) saturate(3154%) hue-rotate(133deg) brightness(92%) contrast(101%)',
          },
        },
        '&:last-of-type': {
          fontSize: 14,
          fontWeight: 700,
        },
      },
    },
    totalSavingsText: {
      borderBottom: '0.5px solid rgba(2, 71, 91, 0.2)',
      marginBottom: 9,
      fontSize: 11,
      justifyContent: 'unset',
      '& span': {
        color: '#00B38E',
        fontWeight: 600,
        fontSize: '12px !important',
        margin: '0px 5px',
      },
    },
    hcContainer: {
      display: 'flex',
      alignItems: 'center',
      margin: 8,
      '& > label': {
        fontWeight: 500,
        fontSize: 14,
        color: '#01475B',
        whiteSpace: 'nowrap',
      },
    },
    tat: {
      borderRadius: 4,
      display: 'inline-block',
      fontSize: '10px',
      fontWeight: 500,
      margin: '4px 0px !important',
      '& img': {
        paddingRight: 5,
        verticalAlign: 'middle',
      },
    },
    getAllReportsContainer: {
      background: '#E8FAFF',
      borderRadius: 4,
      padding: 8,
      display: 'flex',
      alignItems: 'center',
      fontSize: 10,
      fontWeight: 500,
      color: '#01475B',
      margin: 14,
      gap: 4,
      '& img': {
        height: 12,
        width: 12,
      },
    },
    couponSavings: {
      color: '#00B38E',
      fontSize: 12,
      fontWeight: 500,
    },
    couponNotApplied: {
      color: '#01475B',
      opacity: 0.5,
      fontSize: 12,
    },
    newTag: {
      fontWeight: 600,
      fontSize: 10,
      color: '#00B38E',
      background: '#D9FDE8',
      display: 'inline-block',
      borderRadius: 8,
      padding: '0px 6px',
      margin: 4,
    },
    removeTag: {
      fontWeight: 600,
      fontSize: 10,
      color: '#BF2600',
      background: '#FFEBE6',
      display: 'inline-block',
      borderRadius: 8,
      padding: '0px 6px',
      margin: 4,
    },
    modifyOrderPaymentInfo: {
      fontSize: 12,
      color: '#0087BA',
      padding: 8,
      display: 'flex',
      alignItems: 'flex-start',
      gap: 4,
    },
    removedItem: {
      fontWeight: 500,
      fontSize: 14,
      color: '#01475B',
      opacity: 0.7,
      textDecorationLine: 'line-through;',
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  })
);

export interface DiagCoupon {
  id: number;
  coupon: string;
  message: string;
  applicable?: string;
  textOffer?: string;
  frontEndCategory?: string;
  circleBenefits?: boolean;
  disabled?: boolean;
  couponError?: string;
}

interface OrderSummaryProps {
  finalCartItems: FinalCartItemsToDisplay[];
  handlePlaceOrderClick: () => void;
  placeOrderLoading: boolean;
  orderSource: string;
  couponSelected: string;
  setCouponSelected: (coupon: string) => void;
  setCartArrayAfterDiscount: (
    patientLineItems: PatientObjWithLineItems[]
  ) => void;
  cartDiscountObj: CartDiscountObj;
  setCartDiscountObj: (discountObj: CartDiscountObj) => void;
  removeCouponCallBack: () => void;
  collectionChargesLoader: boolean;
  setTotalAmountToPay: (totalAmount: number) => void;
  maxReportTAT: string;
  setShowModifyOrderConfirmationDialog: (value: boolean) => void;
}

interface CartItemForDiscount {
  diagnosticPricing: PricingObject[];
  inclusions: string[];
  itemId: number;
  itemName: string;
  itemType: string;
  patientId: string;
  visited: boolean;
}

export const OrderSummary: React.FC<OrderSummaryProps> = (props) => {
  const classes = useStyles();
  const {
    circleMemberShipPlan,
    couponValidateErr,
    diagnosticCartItems,
    hasDupes,
    homeCollectionCharges,
    isUserCare,
    orderResult,
    patientLineItems,
    selectedSlot,
    userPhoneNumber,
    couponApplied,
    userSubscriptions,
    selectedProfile,
    setCouponApplied,
    setCircleBenefitsApplied,
    setCouponValidateErr,
    setHomeCollectionCharges,
    setRemoveHCCharges,
    updatePatientLineItemPricing,
    isHcSelected,
    setIsHcSelected,
    setCircleCashback,
    modifyFlowOrder,
    modifyOrderPaidAmountBreakup,
    modifyOrderSelectedProfile,
    userAccess,
    selectedSlotDate,
    phleboCharges,
  } = useOrderContext();
  const {
    finalCartItems,
    handlePlaceOrderClick,
    placeOrderLoading,
    orderSource,
    couponSelected,
    setCouponSelected,
    setCartArrayAfterDiscount,
    cartDiscountObj,
    setCartDiscountObj,
    collectionChargesLoader,
    setTotalAmountToPay,
    maxReportTAT,
    setShowModifyOrderConfirmationDialog,
  } = props;

  const [showCouponDialog, setShowCouponDialog] = useState<boolean>(false);
  const [couponLoading, setCouponLoading] = useState<boolean>(false);
  const [activeCouponData, setActiveCouponData] =
    useState<ValidateResponse>(null);
  const [availableCoupons, setAvailableCoupons] = useState<DiagCoupon[]>([]);
  const [activeCoupon, setActiveCoupon] = useState<string>('');
  const [hcAmount, setHCAmount] = useState<number>(0);
  const pricingAccumulator = {
    subTotal: 0,
    toPay: 0,
    circleSavings: 0,
    cartSavings: 0,
    circleCashback: 0,
  };

  const [totalPricingObj, setTotalPricingObj] = useState<{
    subTotal: number;
    toPay: number;
    circleSavings: number;
    cartSavings: number;
    circleCashback: number;
  }>({
    subTotal: 0,
    toPay: 0,
    circleSavings: 0,
    cartSavings: 0,
    circleCashback: 0,
  });

  let amountToPay = 0;
  let hcRedeemed = 0;
  if (couponApplied) {
    amountToPay =
      cartDiscountObj.toPay +
      homeCollectionCharges.charges +
      homeCollectionCharges.distanceCharges +
      (circleMemberShipPlan?.price || 0);
  } else {
    amountToPay =
      totalPricingObj.toPay +
      homeCollectionCharges.charges +
      homeCollectionCharges.distanceCharges +
      (circleMemberShipPlan?.price || 0);
  }

  if (hcAmount && isHcSelected) {
    hcRedeemed = hcAmount >= amountToPay ? amountToPay : hcAmount;
    amountToPay = hcAmount >= amountToPay ? 0 : amountToPay - hcAmount;
  }

  const isModifyCartInitialState = (lineItems: LineItemsObject[]) => {
    const modifyCartItemIds = modifyFlowOrder?.diagnosticOrderLineItems
      ?.filter((lineItem) => lineItem?.itemId && !lineItem?.isRemoved)
      .map((lineItem) => lineItem?.itemId);

    const presentCartItemIds = lineItems?.map((cartItem) => cartItem?.itemId);

    return (
      modifyCartItemIds?.length === presentCartItemIds?.length &&
      presentCartItemIds?.every((cartItemId) =>
        modifyCartItemIds?.includes(cartItemId)
      )
    );
  };

  const calculateCartAfterDiscount = (couponData: ValidateResponse) => {
    const removeCircle =
      isUserCare || circleMemberShipPlan
        ? couponData?.circleBenefits
          ? false
          : true
        : true;
    const patientItemObjCopy: PatientObjWithLineItems[] = JSON.parse(
      JSON.stringify(patientLineItems)
    );
    const accumulatorObj = {
      ...couponPricingAccumulator,
    };
    const diagnosticCartItemsCopy: DiagnosticCartItem[] = JSON.parse(
      JSON.stringify(diagnosticCartItems)
    );

    const updatedPatientLineItems = patientItemObjCopy?.map((diagLineItem) => {
      const updatedPatientLineItem = { ...diagLineItem };

      const lineItems = updatedPatientLineItem?.lineItems?.map((item) => {
        const couponDetails = couponData?.diagnostics?.find(
          (x) => x?.testId === item?.itemId
        );
        const { applicable, mrp, onMrp, specialPrice: price } = couponDetails;
        const discountAmt = roundOff(couponDetails?.discountAmt, 1);

        const diagnosticCartItem = diagnosticCartItemsCopy?.find(
          (x) => Number(x?.itemId) === item?.itemId
        );

        const calculatedPricing = getTestsCirclePrice(
          diagnosticCartItem?.diagnosticPricing,
          (isUserCare || circleMemberShipPlan) && !removeCircle
        );
        if (item) {
          const priceToUse = onMrp ? mrp : price;
          if (applicable) {
            if (item.groupPlan === DIAGNOSTICS_GROUPPLAN.circle) {
              accumulatorObj.circleSavings += onMrp
                ? 0
                : Number((mrp - priceToUse).toFixed(0));
            }
            if ((isUserCare || circleMemberShipPlan) && !removeCircle) {
              accumulatorObj.circleCashback +=
                calculatedPricing?.cashbackAmount || 0;
            }
            accumulatorObj.cartSavings += onMrp
              ? 0
              : Number(
                  (
                    calculatedPricing.mrp -
                    (calculatedPricing.groupPlan ===
                    DIAGNOSTICS_GROUPPLAN.circle
                      ? calculatedPricing.nonCirclePrice
                      : calculatedPricing.price)
                  ).toFixed(0)
                );

            accumulatorObj.toPay += Number(priceToUse.toFixed(1));
            item.couponDiscAmount = Number(discountAmt.toFixed(1));
            item.isCouponOnMrp = onMrp;
            item.price = Number(
              ((onMrp ? item?.mrp : item?.price) - discountAmt).toFixed(1)
            );
            item.groupPlan = calculatedPricing.groupPlan;
            item.priceBeforeDiscount = calculatedPricing.price;
          } else {
            if (calculatedPricing.groupPlan === DIAGNOSTICS_GROUPPLAN.circle) {
              accumulatorObj.circleSavings += Number(
                (mrp - priceToUse).toFixed(0)
              );
            } else {
              accumulatorObj.cartSavings += Number(
                (mrp - priceToUse).toFixed(0)
              );
            }

            if ((isUserCare || circleMemberShipPlan) && !removeCircle) {
              accumulatorObj.circleCashback +=
                calculatedPricing?.cashbackAmount || 0;
            }

            item.couponDiscAmount = 0;
            item.isCouponOnMrp = false;
            item.price = Number(calculatedPricing.price.toFixed(1));
            item.priceBeforeDiscount = Number(
              calculatedPricing.price.toFixed(1)
            );
            item.groupPlan = calculatedPricing.groupPlan;
            accumulatorObj.toPay += Number(priceToUse.toFixed(1));
          }
        }

        accumulatorObj.totalDiscount += Number(discountAmt.toFixed(1));
        return couponDetails
          ? {
              ...item,
              couponDiscAmount: discountAmt,
            }
          : {
              ...item,
            };
      });
      delete updatedPatientLineItem['gender'];
      const totalPrice = roundOff(
        lineItems?.reduce((acc, item) => acc + item.price, 0),
        1
      );
      return { ...updatedPatientLineItem, totalPrice, lineItems };
    });

    accumulatorObj.toPay = accumulatorObj.toPay - accumulatorObj.totalDiscount;
    setCartArrayAfterDiscount(updatedPatientLineItems);
    setCartDiscountObj(accumulatorObj);
  };

  useEffect(() => {
    if (selectedProfile?.id && userPhoneNumber) {
      const apiPayload = {
        patientId: selectedProfile?.id,
        mobileNumber: `+91${userPhoneNumber}`,
      };
      axios
        .post(GET_ONE_APOLLO, apiPayload)
        .then(({ data }) => {
          setHCAmount(data?.getOneApolloUser?.availableHC);
        })
        .catch((err) => {
          console.error('error fetch one apollo details', err);
        });
    }
  }, [selectedProfile?.id, userPhoneNumber]);

  const fallbackLogic = (diagnostics: ValidateResponseDiagnostic[]) => {
    let totalCouponDisc = 0;
    const newDiagnostics = diagnostics.map((diagnostic) => {
      const existDiagnostic = modifyFlowOrder?.diagnosticOrderLineItems?.find(
        (lineItem) => lineItem?.itemId === diagnostic?.testId
      );
      if (existDiagnostic) {
        totalCouponDisc = totalCouponDisc + existDiagnostic?.couponDiscAmount;
        return {
          ...diagnostic,
          applicable: true,
          discountAmt: existDiagnostic?.couponDiscAmount,
          price: existDiagnostic?.price,
          freeHomeCollection: modifyFlowOrder?.collectionCharges === 0,
          onMrp: existDiagnostic?.itemObj?.isOnMrpCoupon || diagnostic?.onMrp,
        };
      }
      return diagnostic;
    });
    return {
      diagnostics: newDiagnostics,
      totalDisc: totalCouponDisc,
    };
  };

  const validateCoupon = useCallback(
    async (coupon: string, removeCircleBenefits?: boolean) => {
      try {
        if (!coupon?.length) return;

        setCouponLoading(true);
        setCouponApplied(false);
        setActiveCouponData(null);

        const existingPackageIds = getPackageIds(userSubscriptions);

        const selectedPlan =
          circleMemberShipPlan && `APOLLO:${circleMemberShipPlan?.subPlanId}`;
        if (
          existingPackageIds &&
          selectedPlan &&
          !existingPackageIds.includes(selectedPlan)
        ) {
          existingPackageIds.push(selectedPlan);
        }

        const packageIds = existingPackageIds?.length ? existingPackageIds : [];

        const validatePayload: ValidatePayload = {
          mobile: '+91' + userPhoneNumber,
          billAmount: 0,
          coupon,
          pinCode: localStorage.getItem('currentZip')!,
          diagnostics: [],
          packageIds,
        };

        let calculatedBillAmount = 0;
        const diagItems: CouponDiagnosticItem[] = [];
        patientLineItems?.map((patientLineItem) => {
          patientLineItem?.lineItems?.map((lineItem) => {
            const cartItem = diagnosticCartItems?.find(
              (x) => Number(x?.itemId) === lineItem?.itemId
            );
            const type =
              !!cartItem && cartItem.inclusions?.length > 1
                ? 'package'
                : 'test';
            if (cartItem) {
              const pricingObj = getTestsCirclePrice(
                cartItem?.diagnosticPricing,
                !removeCircleBenefits
              );

              const priceToUse = removeCircleBenefits
                ? pricingObj?.price
                : lineItem?.price;

              const mrpToUse = removeCircleBenefits
                ? pricingObj?.mrp
                : lineItem?.mrp;

              calculatedBillAmount += priceToUse;
              diagItems.push({
                categoryId: '',
                testId: Number(cartItem.itemId),
                mrp: mrpToUse,
                specialPrice: priceToUse,
                quantity: 1,
                type,
              });
            }
          });
        });
        const updatedDiagItems = diagItems.reduce(
          (aggItems: CouponDiagnosticItem[], curItem) => {
            const isItemExistIndex = aggItems.findIndex(
              (item) => item?.testId === curItem?.testId
            );
            if (isItemExistIndex > -1) {
              const existItem = aggItems[isItemExistIndex];
              const updateItem = {
                ...existItem,
                quantity: existItem?.quantity + 1,
              };
              aggItems?.splice(isItemExistIndex, 1, updateItem);
              return aggItems;
            } else {
              return [...aggItems, { ...curItem, quantity: 1 }];
            }
          },
          []
        );

        validatePayload.diagnostics = updatedDiagItems;
        validatePayload.billAmount = calculatedBillAmount;

        const validateResponse = (await axios.post(
          `${process.env.REACT_APP_COUPON_VALIDATION_ENDPOINT}`,
          validatePayload
        )) as AxiosResponse<GetCouponsResponse<ValidateResponse>>;

        if (
          validateResponse?.data?.errorCode ||
          (validateResponse?.data?.errorMsg && !modifyFlowOrder)
        ) {
          throw new Error(
            validateResponse?.data?.errorMsg ||
              'Something went wrong. please try again!'
          );
        }

        if (removeCircleBenefits && validateResponse?.data?.response) {
          validateResponse.data.response.circleBenefits = false;
        }

        const goToFallbackLogic =
          (validateResponse?.data?.errorMsg ||
            isModifyCartInitialState(patientLineItems?.[0]?.lineItems)) &&
          modifyFlowOrder;

        // fallback logic for onetime coupon, ex: maximum limit coupon or initial cart logic
        if (goToFallbackLogic) {
          const { diagnostics, totalDisc } = fallbackLogic(
            validateResponse?.data?.response?.diagnostics
          );
          validateResponse.data.response.circleBenefits = isUserCare;
          validateResponse.data.response.diagnostics = diagnostics;
          validateResponse.data.response.discount = totalDisc;
          validateResponse.data.response.valid = true;
        }
        return validateResponse?.data?.response;
      } catch (err) {
        console.error(err);
        setCouponValidateErr(err?.message);
        setCouponLoading(false);
        return null;
      }
    },
    [
      patientLineItems,
      diagnosticCartItems,
      setCouponApplied,
      setCouponValidateErr,
      userPhoneNumber,
    ]
  );

  const processCouponData = (couponData: ValidateResponse) => {
    if (!couponData?.valid) {
      setCouponValidateErr(couponData?.reason || 'Coupon cannot be applied!');
      setAvailableCoupons((prevAvailableCoupons) =>
        prevAvailableCoupons?.map((coupon) =>
          coupon?.coupon === couponData?.coupon
            ? {
                ...coupon,
                disabled: true,
                couponError: couponData?.reason || 'Coupon cannot be applied!',
              }
            : coupon
        )
      );
      setCouponSelected('');
      setCouponLoading(false);
    } else if (couponData?.diagnostics?.length) {
      setCouponSelected(couponData?.coupon);
      const removeHCCharges = couponData?.diagnostics?.some(
        (item) => item?.applicable && item?.freeHomeCollection
      );
      setRemoveHCCharges(removeHCCharges);
      removeHCCharges &&
        setHomeCollectionCharges({
          charges: 0,
          distanceCharges: 0,
          couponDiscApplied: true,
        });
      if (!couponData?.circleBenefits && circleMemberShipPlan) {
        updatePatientLineItemPricing(false);
      }
      calculateCartAfterDiscount(couponData);
      setCircleBenefitsApplied(couponData?.circleBenefits);
      setActiveCouponData(couponData);
      setCouponApplied(true);
      setCouponLoading(false);
    }
  };

  const diagnosticCartItemIdsString = diagnosticCartItems
    ?.map((item) => item?.itemId)
    ?.toString();

  const handleCouponValidate = useCallback(
    (couponData: ValidateResponse) => {
      if (!couponData) {
        setCouponSelected('');
        setCouponLoading(false);
      } else {
        const removeCircle = isUserCare && !couponData?.circleBenefits;
        if (removeCircle) {
          validateCoupon(couponData?.coupon, removeCircle).then(
            (couponData) => {
              couponData && processCouponData(couponData);
            }
          );
        } else {
          couponData && processCouponData(couponData);
        }
      }
    },
    [isUserCare, processCouponData, validateCoupon]
  );

  const handleCouponApply = () => {
    setCouponValidateErr('');
    validateCoupon(couponSelected).then((couponData) => {
      couponData && handleCouponValidate(couponData);
    });
  };

  useEffect(() => {
    const totalPriceObj = patientLineItems.reduce(
      (accObj, patientObj) => {
        const totalForCurrentPatient = patientObj.lineItems.reduce(
          (itemTotal, currentItem) => {
            const {
              mrp,
              priceBeforeDiscount,
              groupPlan,
              price,
              circleCashback,
            } = currentItem;
            itemTotal['subTotal'] += mrp;
            itemTotal['toPay'] += priceBeforeDiscount!;
            if (groupPlan === DIAGNOSTICS_GROUPPLAN.circle) {
              itemTotal['circleSavings'] += mrp - priceBeforeDiscount!;
            }
            if (isUserCare || !!circleMemberShipPlan) {
              itemTotal['circleCashback'] += circleCashback || 0;
            }
            itemTotal['cartSavings'] += [
              DIAGNOSTICS_GROUPPLAN.special,
              DIAGNOSTICS_GROUPPLAN.all,
            ]?.includes(groupPlan)
              ? mrp - price
              : 0;
            return itemTotal;
          },
          { ...pricingAccumulator }
        );
        accObj.subTotal += totalForCurrentPatient.subTotal;
        accObj.toPay += totalForCurrentPatient.toPay;
        accObj.circleSavings += totalForCurrentPatient.circleSavings;
        accObj.circleCashback += totalForCurrentPatient.circleCashback;
        accObj.cartSavings += totalForCurrentPatient.cartSavings;
        return accObj;
      },
      { ...pricingAccumulator }
    );
    setTotalAmountToPay(totalPriceObj?.toPay);
    setTotalPricingObj(totalPriceObj);
    // eslint-disable-next-line
  }, [patientLineItems]);

  useEffect(() => {
    handleCouponApply();
  }, [circleMemberShipPlan]);

  useEffect(() => {
    setCouponSelected(activeCoupon);
  }, [activeCoupon]);
  const modifyOrderSelectedProfileId = modifyOrderSelectedProfile?.id;
  useEffect(() => {
    // trigger when selected profile changes on modify flow
    if (modifyOrderSelectedProfileId && modifyFlowOrder) {
      setTimeout(() => {
        handleCouponApply();
      }, 1000);
    }
  }, [modifyOrderSelectedProfileId, modifyFlowOrder]);

  useEffect(() => {
    if (modifyFlowOrder) {
      setTimeout(() => {
        handleCouponApply();
      }, 1000);
    } else {
      handleCouponApply();
    }
  }, [couponSelected, modifyFlowOrder]);

  const removeCoupon = () => {
    setActiveCoupon(null);
    setActiveCouponData(null);
    setCouponApplied(false);
    setRemoveHCCharges(false);
  };

  useEffect(() => {
    setActiveCouponData(null);
    setActiveCoupon('');
  }, [diagnosticCartItems?.length]);

  useEffect(() => {
    setAvailableCoupons([]);
    if (userPhoneNumber && selectedProfile) {
      const getDiagnosticCoupons = async () => {
        const existingPackageIds = getPackageIds(userSubscriptions);
        const selectedPlan =
          circleMemberShipPlan && `APOLLO:${circleMemberShipPlan?.subPlanId}`;
        if (
          existingPackageIds &&
          selectedPlan &&
          !existingPackageIds.includes(selectedPlan)
        ) {
          existingPackageIds.push(selectedPlan);
        }
        const packageIds = existingPackageIds?.length ? existingPackageIds : [];

        const couponUrl = `${
          process.env.REACT_APP_COUPON_ENDPOINT
        }frontend?type=Diag${
          userPhoneNumber ? `&mobile=${userPhoneNumber}` : ''
        }${
          selectedProfile?.emailAddress?.length
            ? `&email=${selectedProfile.emailAddress}`
            : ''
        }${packageIds?.length ? `&packageId=${packageIds}` : ''}`;

        try {
          const { data } = await axios.get<GetCouponsResponse<DiagCoupon[]>>(
            couponUrl
          );
          const { errorMsg, response: couponData } = data;
          if (errorMsg) {
            return { error: errorMsg, data: [] as DiagCoupon[] };
          }
          return { data: couponData };
        } catch (err) {
          return { error: err, data: [] as DiagCoupon[] };
        }
      };

      getDiagnosticCoupons()
        .then((couponResponse) => {
          setAvailableCoupons(couponResponse?.data || []);
        })
        .catch((err) => console.error('Unable to fetch coupons!', err));
    }
  }, [
    circleMemberShipPlan,
    userSubscriptions,
    selectedProfile,
    userPhoneNumber,
  ]);

  useEffect(() => {
    couponApplied
      ? setCircleCashback(cartDiscountObj.circleCashback)
      : setCircleCashback(totalPricingObj.circleCashback);
  }, [couponApplied]);

  useEffect(() => {
    if (
      modifyFlowOrder &&
      modifyFlowOrder?.couponCode &&
      patientLineItems?.length
    ) {
      setTimeout(() => {
        setCouponSelected(modifyFlowOrder?.couponCode);
      }, 500);
    }
  }, [modifyFlowOrder, patientLineItems, diagnosticCartItemIdsString]);

  const renderItemCouponDisc = (cartItem: FinalCartItemsToDisplay) => {
    const { count, itemId } = cartItem || {};
    const couponItem = activeCouponData?.diagnostics?.find(
      (item) => item?.testId === itemId
    );
    if (couponItem?.applicable && couponItem?.discountAmt) {
      const fullCouponSavings = couponItem?.discountAmt * count;
      return (
        <span className={classes.couponSavings}>
          Coupon Savings ₹{fullCouponSavings?.toFixed(2)}
        </span>
      );
    }
    return (
      <span className={classes.couponNotApplied}>
        Coupon discount not applicable
      </span>
    );
  };

  const totalCartSavings = couponApplied
    ? cartDiscountObj.cartSavings + cartDiscountObj.totalDiscount
    : totalPricingObj.cartSavings;

  const totalCircleSavings = couponApplied
    ? cartDiscountObj.circleSavings
    : totalPricingObj.circleSavings;

  const totalCircleCashback = couponApplied
    ? cartDiscountObj.circleCashback
    : totalPricingObj.circleCashback;

  const totalSavings = totalCartSavings + totalCircleSavings;
  if (modifyFlowOrder) {
    const prevOrderItemIds = modifyFlowOrder?.diagnosticOrderLineItems?.map(
      (lineItem) => lineItem?.itemId
    );
    const currentCartItemIds = finalCartItems?.map((item) => item?.itemId);

    const filterPrevRemovedOrders =
      modifyFlowOrder?.diagnosticOrderLineItems?.filter(
        (lineItem) => !lineItem?.isRemoved
      );

    const removedOrders = filterPrevRemovedOrders?.filter(
      (lineItem) => !currentCartItemIds?.includes(lineItem.itemId)
    );

    const paidAmount =
      modifyOrderPaidAmountBreakup.subTotal +
      modifyFlowOrder.collectionCharges -
      modifyOrderPaidAmountBreakup.circleSavings -
      modifyOrderPaidAmountBreakup.cartSavings -
      modifyOrderPaidAmountBreakup?.couponSavings;

    const remainingAmountToBePaid = (amountToPay - paidAmount).toFixed();

    const hasPermissionToModifyOrder = () => {
      return (
        userAccess.includes(DIAGNOSTIC_USER_ACCESS.MODIFY_ORDER) ||
        (Number(remainingAmountToBePaid) > 0 &&
          userAccess.includes(DIAGNOSTIC_USER_ACCESS.UPSELL))
      );
    };

    const isModifyOrderSlotChanged = () => {
      return (
        !isEqual(
          new Date(modifyFlowOrder?.slotDateTimeInUTC),
          selectedSlotDate
        ) ||
        selectedSlot?.slotDetail?.slotDisplayTime !==
          format(new Date(modifyFlowOrder?.slotDateTimeInUTC), 'hh:mm aaa')
      );
    };

    const isModifyOrderSlotSelected = () => {
      return selectedSlotDate && selectedSlot;
    };

    const isProfileChanged = () =>
      modifyFlowOrder?.patientId !== selectedProfile?.id;

    const enableCartUpdateCTA =
      ((Number(remainingAmountToBePaid) > 0 || removedOrders?.length) &&
        finalCartItems?.length &&
        hasPermissionToModifyOrder()) ||
      (isModifyOrderSlotSelected() && isModifyOrderSlotChanged()) ||
      (isProfileChanged() && finalCartItems?.length);

    const getAmountTitle = () => {
      if (Number(remainingAmountToBePaid) >= 0) {
        return 'To Pay';
      } else {
        return modifyFlowOrder?.paymentType === 'ONLINE_PAYMENT'
          ? 'Refund Amount'
          : 'Updated Amount';
      }
    };

    const getPrevAmountTitle = () => {
      return modifyFlowOrder?.paymentType === 'ONLINE_PAYMENT'
        ? 'Paid'
        : 'Previous amount';
    };

    const calculateToPayAmount = () => {
      if (Number(remainingAmountToBePaid) >= 0) {
        return `₹${remainingAmountToBePaid}`;
      } else {
        return modifyFlowOrder?.paymentType === 'ONLINE_PAYMENT'
          ? `₹${Math.abs(Number(remainingAmountToBePaid))}`
          : `₹${amountToPay}`;
      }
    };
    return (
      <>
        <h3>UPDATED ORDER SUMMARY</h3>
        <Backdrop className={classes.backdrop} open={couponLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <div>
          {modifyFlowOrder && (
            <>
              <ul className={classes.summaryList}>
                {removedOrders?.map((removedOrder, removedOrderIdx) => {
                  return (
                    <li key={'final-item-' + removedOrderIdx}>
                      <div>
                        <div>
                          <del className={classes.removedItem}>
                            {toTitleCase(removedOrder.itemName)}
                          </del>
                          <b className={classes.removeTag}>Removed</b>
                        </div>
                        <div>
                          <p className={classes.removedItem}>
                            ₹{removedOrder.price}
                          </p>
                          <span>
                            {1} X ₹{removedOrder.price}
                          </span>
                        </div>
                      </div>
                    </li>
                  );
                })}
                {finalCartItems.length > 0 &&
                  finalCartItems.map((finalItem, idx) => (
                    <li key={'final-item-' + idx}>
                      <div>
                        <div>
                          {toTitleCase(finalItem.itemName)}
                          {!prevOrderItemIds?.includes(finalItem?.itemId) && (
                            <b className={classes.newTag}>New</b>
                          )}
                        </div>
                        <div>
                          <p>₹{finalItem.count * finalItem.priceToDisplay}</p>
                          <span>
                            {finalItem.count} X ₹{finalItem.priceToDisplay}
                          </span>
                        </div>
                      </div>
                      {couponApplied && renderItemCouponDisc(finalItem)}
                    </li>
                  ))}
              </ul>
              <div className={classes.subTotalWrap}>
                <div className={classes.modifySubTotal}>
                  <div>Subtotal</div>
                  <div>
                    ₹
                    {couponApplied
                      ? cartDiscountObj.subTotal
                      : totalPricingObj.subTotal}
                  </div>
                </div>
                {phleboCharges ? (
                  <PhleboCharges />
                ) : (
                  <>
                    <div>
                      <div>Home Collection Charges</div>
                      <div>₹ {homeCollectionCharges.charges}</div>
                    </div>
                    {homeCollectionCharges?.distanceCharges > 0 && (
                      <div>
                        <div>Distance Charges</div>
                        <div>₹ {homeCollectionCharges?.distanceCharges}</div>
                      </div>
                    )}
                  </>
                )}
                <div className={classes.green}>
                  <div>
                    <img
                      src={require('../images/circle_logo.png')}
                      alt="Circle Icon"
                      className={classes.circleImage}
                    />
                    Membership Discount
                  </div>
                  <div>
                    -₹
                    {couponApplied
                      ? cartDiscountObj.circleSavings
                      : totalPricingObj.circleSavings}
                  </div>
                </div>
                <div className={classes.green}>
                  <div>Cart Savings</div>
                  <div>
                    -₹
                    {couponApplied
                      ? cartDiscountObj.cartSavings
                      : totalPricingObj.cartSavings}
                  </div>
                </div>
                {couponApplied && (
                  <div className={classes.green}>
                    <div>Coupon Discount</div>
                    <div>-₹{cartDiscountObj.totalDiscount}</div>
                  </div>
                )}
                <div>
                  <div>Total</div>
                  <div>₹{amountToPay?.toFixed()}</div>
                </div>
                <div>
                  <div>{getPrevAmountTitle()}</div>
                  <div>
                    -₹
                    {paidAmount?.toFixed()}
                  </div>
                </div>
                <div className={classes.modifySubTotal}>
                  <div className={'toPayAmount'}>{getAmountTitle()}</div>
                  <div className={'toPayAmount'}>{calculateToPayAmount()}</div>
                </div>
              </div>
              <div className={classes.footer}>
                <Button
                  onClick={() => setShowModifyOrderConfirmationDialog(true)}
                  disabled={!enableCartUpdateCTA}
                  style={{
                    opacity: enableCartUpdateCTA ? 1 : 0.5,
                  }}
                >
                  UPDATE ORDER
                </Button>
              </div>
            </>
          )}
        </div>
      </>
    );
  }

  return (
    <>
      <h3>Order summary</h3>
      <div>
        <p>{finalCartItems?.length === 0 ? 'No ' : ''}Items in your Cart</p>
        {finalCartItems?.length > 0 && (
          <>
            <ul className={classes.summaryList}>
              {finalCartItems.length > 0 &&
                finalCartItems.map((finalItem, idx) => (
                  <li key={'final-item-' + idx}>
                    <div>
                      <div>{finalItem.itemName}</div>
                      <div>
                        <p>₹{finalItem.count * finalItem.priceToDisplay}</p>
                        <span>
                          {finalItem.count} X ₹{finalItem.priceToDisplay}
                        </span>
                      </div>
                    </div>
                    {couponApplied && renderItemCouponDisc(finalItem)}
                    {selectedSlot && (
                      <p className={classes.tat}>
                        <img src={require('../images/ic_clock.png')} alt="" />
                        {finalItem.reportTAT}
                      </p>
                    )}
                  </li>
                ))}
            </ul>
            {selectedSlot && maxReportTAT && (
              <div className={classes.getAllReportsContainer}>
                <img src={require('../images/ic_clock.png')} alt="" />
                {`Get all reports before ${reportTATFormat(maxReportTAT)}`}
              </div>
            )}

            <p className={classes.fw}>Total Charges</p>

            <div>
              <div>
                <div>
                  <>
                    <Coupon
                      availableCoupons={availableCoupons}
                      couponApplied={couponApplied}
                      setCouponSelected={setCouponSelected}
                      handleCouponApply={handleCouponApply}
                      alertMessage={couponValidateErr}
                      setAlertMessage={setCouponValidateErr}
                      showCouponDialog={showCouponDialog}
                      setShowCouponDialog={setShowCouponDialog}
                      setActiveCoupon={setActiveCoupon}
                      couponLoading={couponLoading}
                      removeCoupon={removeCoupon}
                      activeCouponData={activeCouponData}
                    />
                  </>
                </div>
              </div>
            </div>
            {hcAmount > 0 && (
              <div className={classes.hcContainer}>
                <AphCheckbox
                  color="primary"
                  checked={isHcSelected}
                  onChange={(e) => {
                    setIsHcSelected(Boolean(e.target.checked));
                  }}
                  id={'useHc'}
                  name="useHc"
                />
                <img
                  src={require('../images/ic_one_apollo.svg')}
                  alt="Circle Icon"
                  className={classes.circleImage}
                />
                <label htmlFor="useHc">
                  Available Health Credits: <b>₹{hcAmount}</b>
                </label>
              </div>
            )}
            <div className={classes.subTotalWrap}>
              <div>
                <div>Total MRP</div>
                <div>
                  ₹
                  {couponApplied
                    ? cartDiscountObj.subTotal
                    : totalPricingObj.subTotal}
                </div>
              </div>
              {(couponApplied
                ? cartDiscountObj.cartSavings
                : totalPricingObj.cartSavings) > 0 && (
                <div className={classes.green}>
                  <div>Discount on MRP</div>
                  <div>
                    -₹
                    {couponApplied
                      ? cartDiscountObj.cartSavings
                      : totalPricingObj.cartSavings}
                  </div>
                </div>
              )}
              <div className={classes.green}>
                <div>Coupon Discount</div>
                <div>-₹{couponApplied ? cartDiscountObj.totalDiscount : 0}</div>
              </div>
              <PhleboCharges />
              {circleMemberShipPlan && (
                <div>
                  <div>
                    <img
                      src={require('../images/circle_logo.png')}
                      alt="Circle Icon"
                      className={classes.circleImage}
                    />
                    membership for {circleMemberShipPlan.durationInMonth} months
                  </div>
                  <div>₹ {circleMemberShipPlan.price}</div>
                </div>
              )}
              {isHcSelected && (
                <div className={classes.green}>
                  <div>
                    <img
                      src={require('../images/ic_one_apollo.svg')}
                      alt="Circle Icon"
                      className={classes.circleImage}
                    />
                    Health Credits Redeemed
                  </div>
                  <div>-₹{hcRedeemed}</div>
                </div>
              )}
              <div className={classes.subTotal}>
                <div>To Pay</div>
                <div>₹{amountToPay?.toFixed()}</div>
              </div>
            </div>
          </>
        )}
        <div className={classes.footer}>
          {finalCartItems?.length > 0 &&
            ((totalSavings && Number(totalSavings) > 0) ||
              totalCircleCashback > 0) && (
              <div className={classes.cashbackSection}>
                <div
                  className={`${classes.totalSavingsText} ${classes.cashback}`}
                >
                  <img src={`${CDN_URL}/images/ic_cashback.png`} alt="" />
                  You
                  {totalSavings > 0 && (
                    <span>saved ₹{totalSavings?.toFixed()}</span>
                  )}
                  {totalSavings > 0 && totalCircleCashback > 0 ? 'and' : ''}
                  {totalCircleCashback > 0
                    ? ` earned
                      ${totalCircleCashback?.toFixed()} HC`
                    : ''}
                </div>

                {totalCircleSavings > 0 && (
                  <div className={classes.cashback}>
                    <div>
                      <img
                        src={require('../images/circle_logo.png')}
                        alt="Circle Icon"
                        className={classes.circleImage}
                      />
                      Membership Discount
                    </div>
                    <div>₹{totalCircleSavings}</div>
                  </div>
                )}
                {totalCartSavings > 0 && (
                  <div className={classes.cashback}>
                    <div>
                      <img
                        className="greenIcon"
                        src={`${CDN_URL}/images/ic_cart.svg`}
                        alt=""
                      />
                      Cart Savings
                    </div>
                    <div>₹{totalCartSavings}</div>
                  </div>
                )}
                {totalCircleCashback > 0 && (
                  <div className={classes.cashback}>
                    <div>
                      <img src={`${CDN_URL}/images/ic_star.svg`} alt="" />
                      Circle cashback
                    </div>
                    <div>₹{totalCircleCashback}</div>
                  </div>
                )}
              </div>
            )}
          {(!orderResult || orderResult.errorMessage) && (
            <Button
              onClick={() => handlePlaceOrderClick()}
              disabled={
                !(selectedSlot && orderSource) ||
                placeOrderLoading ||
                collectionChargesLoader
              }
              style={{
                opacity:
                  !(selectedSlot && orderSource) ||
                  placeOrderLoading ||
                  collectionChargesLoader
                    ? 0.5
                    : 1,
              }}
            >
              {placeOrderLoading || collectionChargesLoader ? (
                <CircularProgress size={25} />
              ) : (
                <>
                  {!orderResult
                    ? `PLACE ORDER`
                    : hasDupes
                    ? `PLACE ORDER`
                    : `ORDER AGAIN`}
                </>
              )}
            </Button>
          )}
        </div>
      </div>
    </>
  );
};
