import { SEND_CLEVERTAP_EVENT } from '../../appConfig';
import { PatientProfile } from './commonHelpers';
import axios from 'axios';

declare global {
  interface Window {
    webengage: any;
    clevertap: any;
  }
}

window.webengage = window.webengage || {};
window.clevertap = window.clevertap || {};

export const getCommonPatientAttributes = (patientDetails: PatientProfile) => {
  const patientAge =
    new Date().getFullYear() -
    new Date(patientDetails && patientDetails.dateOfBirth).getFullYear();
  return {
    'Patient Name':
      (patientDetails &&
        `${patientDetails.firstName} ${patientDetails.lastName}`) ||
      '',
    'Patient Uhid': (patientDetails && patientDetails.uhid) || '',
    'Patient Gender': (patientDetails && patientDetails.gender) || '',
    'Patient Age': (patientDetails && patientAge) || '',
  };
};

export const sendClevertTapEvent = (
  eventName: string,
  mobileNumber: string,
  data: any
) => {
  const payload = {
    eventName: eventName,
    mobileNumber: mobileNumber,
    data: data,
  };
  axios
    .post(SEND_CLEVERTAP_EVENT, {
      ...payload,
    })
    .then((response) => {
      console.log('response', response);
    })
    .catch((err) => {
      console.error(`Error sending clevertap event - ${eventName}`, err);
    });
};
