import React, { useState, useEffect } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import {
  findAddrComponents,
  isBrowser,
  PatientAddress,
  removeLeadingAndMultipleSpaces,
  toTitleCase,
} from './helpers/commonHelpers';
import axios from '../axios';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import {
  CDN_URL,
  MAXLENGTH_ADDRESS_LANDMARK,
  MAXLENGTH_ADDRESS_LINE_ONE,
  MAXLENGTH_ADDRESS_LINE_TWO,
  PINCODE_MAXLENGTH,
  REACT_APP_GOOGLE_API_KEY,
  REACT_APP_GOOGLE_MAP_API,
  libraries,
} from '../appConfig';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import AphButton from './AphButton';

const useStyles = makeStyles((theme: Theme) => {
  return {
    addressContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
      height: 'calc(100vh - 100px)',
    },
    leftSection: {
      padding: '18px 18px 0px',
      flexBasis: '40%',
      height: 'calc(100% - 18px)',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      borderRadius: '10px 0px 0px 10px',
    },
    formSection: {
      padding: '16px 0px',
      height: '50%',
    },
    formList: {
      display: 'flex',
      alignItems: 'center',
      gap: 10,
      justifyContent: 'space-between',
      marginBottom: '16px',
      position: 'relative',
      '& >label': {
        flexBasis: '36%',
        flexShrink: 0,
        marginRight: 10,
        fontSize: 12,
        lineHeight: '16px',
      },
      '& > input': {
        fontSize: 14,
        flexGrow: 1,
        lineHeight: '15px',
        paddingBottom: 2,
        border: '1px solid #00b38e',
        borderWidth: '0 0 1px 0',
        fontWeight: 500,
        '&:focus-visible': {
          outline: 'none',
        },
        '&:placeholder': {
          color: '#aabfc5',
          fontWeight: 400,
        },
        '&:disabled': {
          color: '#01475b80',
          background: 'transparent',
          cursor: 'not-allowed',
        },
      },
    },
    additionalInfo: {
      display: 'block',
      fontSize: 9,
      lineHeight: '10px',
      color: '#577d88',
    },
    charLimit: {
      position: 'absolute',
      right: 0,
      bottom: -15,
      fontSize: 10,
    },
    invalidText: {
      position: 'absolute',
      right: 0,
      bottom: -14,
      fontSize: 10,
      color: '#890000',
      margin: 0,
    },
    searchResults: {
      borderTop: '1px dashed #cccdcd',
      textAlign: 'center',
      cursor: 'pointer',
      height: '50%',
      paddingBottom: '16px',
      overflowY: 'auto',
      '&:-webkit-scrollbar': {
        width: '4px',
      },
      ' &:-webkit-scrollbar-thumb': {
        background: '#d0d0d0',
        borderRadius: '10px',
      },
    },
    addressTypeSearch: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexWrap: 'wrap',
    },
    addressTypeList: {
      display: 'flex',
      border: '1px solid #DAE7ED',
      alignItems: 'center',
      marginBottom: 3,
      '& label': {
        borderRight: '1px solid #DAE7ED',
        padding: '2px 10px',
        fontSize: 11,
        fontWeight: 500,
        borderRadius: 4,
      },
    },
    addressType: {
      borderRight: '1px solid #DAE7ED',
      padding: '2px 12px',
      fontSize: 10,
      lineHeight: '20px',
      fontWeight: 400,
      margin: 0,
      cursor: 'pointer',
      '&:last-child': {
        borderRight: 'none',
      },
    },
    selected: {
      color: '#F2BA44',
      fontWeight: 600,
    },
    cta: {
      textAlign: 'right',
      marginBottom: 3,
      '& > button ': {
        background: '#fff !important',
        color: '#FCA317 !important',
        border: '1px solid #FCA317',
        width: 82,
        borderRadius: 4,
        padding: 0,
        boxShadow: 'none',
      },
    },
    disabled: {
      filter: 'grayscale(1)',
    },
    list: {
      textAlign: 'left',
      color: '#01475B',
      borderBottom: '1px solid #DAE7ED',
      padding: '12px',
      '& > p': {
        fontSize: 16,
        fontWeight: 400,
        lineHeight: '20px',
        color: '#000000',
        margin: 0,
      },
    },
    title: {
      fontSize: '14px',
      lineHeight: '20px',
      fontWeight: 600,
      margin: '0 0 8px',
      display: 'flex',
      alignItems: 'center',
      color: '#121414',
    },
    selectedOption: {
      background: '#DDF1F8BF',
      '& > h3': {
        color: '#106C89',
      },
    },
    subTitle: {},
    noSearchResults: {},
    secondaryBtn: {
      background: '#f2ba44 !important',
      color: '#fff !important',
    },
    stickyCta: {
      position: 'sticky',
      bottom: 0,
      margin: '0 -18px 0px',
      textAlign: 'center',
      padding: '20px',
      boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
      zIndex: 2,
      background: '#fff',
      marginTop: 'auto',
      [theme.breakpoints.down(768)]: {
        position: 'fixed',
        bottom: 0,
        width: '100%',
      },
      '&>button': {
        width: '100%',
      },
    },
    rightSection: {
      flexBasis: '60%',
      flexShrink: 0,
      height: '100%',
      background: '#e5e3df',
      borderRadius: '0px 10px 10px 0px',
      [theme.breakpoints.down(768)]: {
        width: '100%',
      },
    },
    mapContentMain: {
      width: '100%',
      height: 'calc(100% - 24px)',
      borderRadius: '0px 10px 10px 0px',
      [theme.breakpoints.down('xs')]: {
        height: '74vh',
        '& div.gmnoprint': {
          display: 'none',
        },
      },
      '& div.gm-style-mtc': {
        display: 'none',
      },
      '& div.gm-svpc': {
        display: 'none',
      },
      '& button.gm-fullscreen-control': {
        display: 'none',
      },
      '& button.gm-svpc': {
        display: 'none',
      },
    },
    formattedAddress: {
      '-webkit-box-orient': 'vertical',
      '-webkit-line-clamp': '1',
      overflow: 'hidden',
      display: '-webkit-box',
      margin: 0,
      background: '#E5E5E5',
      lineHeight: '20px',
      fontSize: 12,
      padding: '2px 8px',
    },
    markerTitle: {
      position: 'absolute',
      padding: '0 10px',
      zIndex: 1,
      right: 'calc(50% - 74px)',
      top: 'calc(50% - 95px)',
      fontSize: 11,
      fontWeight: 600,
      backgroundColor: '#01475B',
      color: '#fff',
      height: 35,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: 10,
      [theme.breakpoints.down('xs')]: {
        top: '50%',
        marginTop: '-88px',
      },
    },
    loading: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      zIndex: 10,
      backgroundColor: '#0c0c0c',
      opacity: 0.4,
    },
  };
});

enum PATIENT_ADDRESS_TYPE {
  HOME = 'HOME',
  OFFICE = 'OFFICE',
  OTHER = 'OTHER',
}

const patientAddressTypes = [
  PATIENT_ADDRESS_TYPE.HOME,
  PATIENT_ADDRESS_TYPE.OFFICE,
  PATIENT_ADDRESS_TYPE.OTHER,
];

type AddNewAddressV2Props = {
  handleSaveAddress: (
    patientAddress: PatientAddress,
    saveLatLng?: boolean
  ) => void;
  checkServiceability: (
    latitude: number,
    longitude: number,
    address?: PatientAddress
  ) => Promise<any>;
  serviceable: boolean;
  geocodeLatLng: (
    zipcode: string,
    addressString?: string
  ) => Promise<{
    status: boolean;
    lat: any;
    lng: any;
  }>;
  renderErrorAtBottom: (errorMsg: string) => void;
  addAddressLoader: boolean;
  addressAdditionError: string;
  zeroResultsError: boolean;
  setZeroResultsError: (value: boolean) => void;
  setAddressAdditionError: (value: string) => void;
};

const CombinedAddressForm: React.FC<AddNewAddressV2Props> = (props) => {
  const classes = useStyles();

  const {
    handleSaveAddress,
    checkServiceability,
    serviceable,
    geocodeLatLng,
    addAddressLoader,
    addressAdditionError,
    zeroResultsError,
    renderErrorAtBottom,
    setZeroResultsError,
    setAddressAdditionError,
  } = props;
  const [addressCity, setAddressCity] = useState<string>('');
  const [addressState, setAddressState] = useState<string>('');
  const [addressPincode, setAddressPincode] = useState<string>('');
  const [addressLine1, setAddressLine1] = useState<string>('');
  const [addressLine2, setAddressLine2] = useState<string>('');
  const [addressLandmark, setAddressLandmark] = useState<string>('');
  const [addressType, setAddressType] = useState<string>(null);

  const [mapRef, setMapRef] = useState<google.maps.Map>(null);
  const [latitude, setLatitude] = useState<number>();
  const [longitude, setLongitude] = useState<number>();
  const [formattedAddress, setFormattedAddress] = useState<string>('');
  const [fetchingAddress, setFetchingAddress] = useState<boolean>(false);

  const [service, setService] =
    useState<google.maps.places.AutocompleteService | null>(null);
  const [suggestions, setSuggestions] = useState<
    google.maps.places.AutocompletePrediction[] | null
  >(null);

  const [isPincodevalid, setIsPincodeValid] = useState<boolean>(true);
  const [selectedAddress, setSelectedAddress] = useState<string>('');
  const [searchHit, setSearchHit] = useState<boolean>(false);
  const [showUpdatePincodeMessage, setShowUpdatePincodeMessage] =
    useState<boolean>(false);
  const [isPincodeUpdate, setIsPincodeUpdate] = useState<boolean>(false);

  useEffect(() => {
    if (isPincodeUpdate) {
      setIsPincodeUpdate(false);
      setShowUpdatePincodeMessage(true);
    }
  }, [isPincodeUpdate]);

  const { isLoaded: isMapScriptLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: REACT_APP_GOOGLE_API_KEY,
    libraries,
  });

  const handleSearch = () => {
    const address = `${addressLine2} ${addressLandmark} ${addressCity} ${addressPincode}`;
    setSuggestions([]);
    const displaySuggestions = (predictions, status) => {
      if (status?.toLowerCase() === 'ok') {
        setSuggestions(predictions);
      }
      setSearchHit(true);
    };
    service?.getPlacePredictions(
      { input: address, componentRestrictions: { country: ['in'] } },
      displaySuggestions
    );
  };

  const handleSelect = (address) => {
    setSelectedAddress(address);
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((resObj) => {
        if (resObj) {
          setLatitude(resObj.lat);
          setLongitude(resObj.lng);
          setAddressDetails(resObj.lat, resObj.lng);
        }
      })
      .catch((error: any) => console.error(error));
  };

  const setAddressDetails = (lat: number, lng: number) => {
    setFetchingAddress(true);
    setZeroResultsError(false);
    setAddressAdditionError('');
    setIsPincodeUpdate(false);
    setShowUpdatePincodeMessage(false);

    checkServiceability(lat, lng).then((res) => {
      if (!res?.data?.status) {
        setAddressAdditionError('This address is not serviceable!');
      }
      const googleMapsApiUrl = `${REACT_APP_GOOGLE_MAP_API}?latlng=${lat},${lng}&key=${REACT_APP_GOOGLE_API_KEY}`;
      axios
        .get(googleMapsApiUrl)
        .then(({ data }) => {
          if (data?.results?.[0]?.address_components) {
            const addrComponents = data.results[0].address_components || [];
            const mapFormattedAddress = data.results[0].formatted_address || '';
            const areaDetails = [
              findAddrComponents('sublocality_level_3', addrComponents),
              findAddrComponents('sublocality_level_2', addrComponents),
              findAddrComponents('sublocality_level_1', addrComponents),
              findAddrComponents('neighborhood', addrComponents),
            ].filter((area: string) => area);

            const selectedCity =
              findAddrComponents(
                'administrative_area_level_2',
                addrComponents
              ) || findAddrComponents('locality', addrComponents);

            const selectedState = findAddrComponents(
              'administrative_area_level_1',
              addrComponents,
              'state'
            );
            const selectedPincode = findAddrComponents(
              'postal_code',
              addrComponents
            );
            const selectedCountry = findAddrComponents(
              'country',
              addrComponents,
              'country'
            );
            const formattedAddressFields = [
              ...areaDetails,
              selectedCity,
              selectedState,
              selectedPincode,
              selectedCountry,
            ].filter((addressField: string) => addressField);

            setFormattedAddress(
              mapFormattedAddress || formattedAddressFields.join(', ')
            );

            setAddressCity(selectedCity);
            setAddressState(selectedState);
            setLatitude(lat);
            setLongitude(lng);
            if (selectedPincode !== addressPincode) {
              setIsPincodeUpdate(true);
            }
            setAddressPincode(selectedPincode);
          }
        })
        .catch((e: any) => {
          console.error(e);
        })
        .finally(() => {
          setFetchingAddress(false);
        });
    });
  };

  const renderMap = () => {
    return isMapScriptLoaded && latitude && longitude ? (
      <>
        <GoogleMap
          onLoad={(map) => onLoad(map)}
          onCenterChanged={() => {
            const latLng = mapRef ? mapRef?.getCenter()?.toJSON() : null;
            setLatitude(latLng?.lat || latitude);
            setLongitude(latLng?.lng || longitude);
          }}
          onDragEnd={() => {
            const latLng = mapRef ? mapRef.getCenter().toJSON() : null;
            setSelectedAddress('');
            setAddressLandmark('');
            setAddressDetails(latLng?.lat, latLng?.lng);
          }}
          center={{ lat: latitude, lng: longitude }}
          zoom={18}
          mapContainerClassName={classes.mapContentMain}
          options={{
            gestureHandling: 'greedy',
            scrollwheel: false,
          }}
        >
          <Marker
            position={{ lat: latitude, lng: longitude }}
            icon={{
              url: `${CDN_URL}/images/ic_place.svg`,
            }}
          />
          {!!latitude && !!longitude && (
            <div className={classes.markerTitle}>MOVE MAP TO ADJUST</div>
          )}
        </GoogleMap>
        <p className={classes.formattedAddress}>
          {formattedAddress?.length ? formattedAddress : null}
        </p>
      </>
    ) : null;
  };

  const onLoad = (map: google.maps.Map) => {
    setMapRef(map);
  };

  const checkForPincodeServiceability = async (pincode: string) => {
    try {
      setFetchingAddress(true);
      setAddressAdditionError('');
      const result = await geocodeLatLng(pincode);
      if (result.status && result.lat && result.lng) {
        const res = await checkServiceability(result.lat, result.lng);
        if (res?.data?.status) {
          const { city, state } = res?.data?.serviceability;
          setAddressCity(toTitleCase(city) || '');
          setAddressState(toTitleCase(state) || '');
          setIsPincodeValid(true);
        } else {
          setAddressAdditionError('The above pincode is not serviceable.');
        }
      } else {
        setIsPincodeValid(false);
      }
    } catch (e) {
      console.error('Error checking pincode serviceability: ', e);
      setIsPincodeValid(false);
    } finally {
      setFetchingAddress(false);
    }
  };

  useEffect(() => {
    if (isBrowser() && window?.google && !service) {
      const service = new window.google.maps.places.AutocompleteService();
      setService(service);
    }
    if (addressPincode?.length === PINCODE_MAXLENGTH) {
      setIsPincodeValid(true);
      checkForPincodeServiceability(addressPincode);
    }
  }, [addressPincode?.length === PINCODE_MAXLENGTH]);

  const isSearchDisabled =
    addressPincode?.length !== PINCODE_MAXLENGTH ||
    !isPincodevalid ||
    addressLine1?.length < 3 ||
    addressLine2?.length < 3 ||
    !serviceable ||
    zeroResultsError;

  const handleAddressTypeChange = (addressType) => {
    setAddressType(addressType);
  };

  const handlePincodeCenter = () => {
    axios
      .get(
        `${REACT_APP_GOOGLE_MAP_API}?address=${addressPincode}&components=country:in&key=${REACT_APP_GOOGLE_API_KEY}`
      )
      .then(({ data }) => {
        if (data?.results?.[0]?.address_components) {
          const addressComponents = data.results[0].address_components || [];
          const pincode = (
            addressComponents.find(
              (item) => item.types.indexOf('postal_code') > -1
            ) || {}
          ).long_name;
          const city = (
            addressComponents.find(
              (item) =>
                item.types.indexOf('locality') > -1 ||
                item.types.indexOf('administrative_area_level_2') > -1
            ) || {}
          ).long_name;
          const state = (
            addressComponents.find(
              (item) => item.types.indexOf('administrative_area_level_1') > -1
            ) || {}
          ).long_name;
          setAddressState(state || '');
          setAddressCity(city || '');
          if (pincode !== addressPincode) {
            setIsPincodeUpdate(true);
          }
          setAddressPincode(pincode || '');
          const { lat, lng } = data?.results[0]?.geometry?.location;
          lat && setLatitude(lat);
          lng && setLongitude(lng);
        }
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const handlePincode = (e) => {
    setAddressCity('');
    setAddressState('');
    setAddressPincode(e.target.value);
    setLatitude(null);
    setLongitude(null);
    setSuggestions([]);
    setSearchHit(false);
    setMapRef(null);
    setSelectedAddress('');
    setZeroResultsError(false);
    setShowUpdatePincodeMessage(false);
    setIsPincodeValid(true);
  };

  const disableSubmit =
    !addressLine1?.length ||
    addressLine1?.length > MAXLENGTH_ADDRESS_LINE_ONE ||
    !addressLine2?.length ||
    addressLine2?.length > MAXLENGTH_ADDRESS_LINE_TWO ||
    addressLandmark?.length > MAXLENGTH_ADDRESS_LANDMARK ||
    !addressType?.length ||
    addressPincode?.length < PINCODE_MAXLENGTH ||
    !isPincodevalid ||
    !latitude ||
    !longitude ||
    !serviceable ||
    zeroResultsError;

  const onConfirmLocation = () => {
    if (disableSubmit) {
      return;
    }
    const newAddress: PatientAddress = {
      addressLine1: addressLine1,
      addressLine2: addressLine2,
      city: addressCity,
      state: addressState,
      zipcode: addressPincode,
      addressType: addressType,
      landmark: addressLandmark,
      latitude: latitude,
      longitude: longitude,
      id: '',
      mobileNumber: '',
      otherAddressType: '',
    };

    if (newAddress?.latitude && newAddress?.longitude) {
      checkServiceability(newAddress?.latitude, newAddress?.longitude).then(
        (res) => {
          if (res?.data?.status) {
            handleSaveAddress(newAddress, true);
          }
        }
      );
    }
  };

  return (
    <div className={classes.addressContainer}>
      <div className={classes.leftSection}>
        {(fetchingAddress || addAddressLoader) && (
          <div className={classes.loading}></div>
        )}
        <div className={classes.formSection}>
          <div className={classes.formList}>
            <label>Pincode*</label>
            <input
              placeholder="Must be 6 digits"
              type="text"
              required
              maxLength={6}
              value={addressPincode}
              onChange={handlePincode}
              onKeyPress={(e) => {
                if (e.key !== 'Enter' && isNaN(Number(e.key)))
                  e.preventDefault();
              }}
              onBlur={() =>
                setIsPincodeValid(addressPincode?.length === PINCODE_MAXLENGTH)
              }
              autoFocus
            />
            {!isPincodevalid && (
              <p className={classes.invalidText}>Invalid zip code</p>
            )}
          </div>
          <div className={classes.formList}>
            <label>House No. | Street No.*</label>
            <input
              placeholder="House No. | Street No."
              value={addressLine1}
              onChange={(e) => setAddressLine1(e.target.value)}
              onBlur={() => removeLeadingAndMultipleSpaces(addressLine1)}
              maxLength={MAXLENGTH_ADDRESS_LINE_ONE}
            />
            <span className={classes.charLimit}>
              {addressLine1.length} / {MAXLENGTH_ADDRESS_LINE_ONE}
            </span>
          </div>
          <div className={classes.formList}>
            <label>
              Apartment | Locality*{' '}
              <span className={classes.additionalInfo}>used in search</span>
            </label>
            <input
              placeholder="Apartment | Locality"
              value={addressLine2}
              onChange={(e) => setAddressLine2(e.target.value)}
              onBlur={() =>
                setAddressLine2(removeLeadingAndMultipleSpaces(addressLine2))
              }
              maxLength={MAXLENGTH_ADDRESS_LINE_TWO}
            />
            <span className={classes.charLimit}>
              {addressLine2.length} / {MAXLENGTH_ADDRESS_LINE_TWO}
            </span>
          </div>
          <div className={classes.formList}>
            <label>
              Landmark
              <span className={classes.additionalInfo}>used in search</span>
            </label>
            <input
              placeholder="Landmark (Optional)"
              value={addressLandmark}
              onChange={(e) => setAddressLandmark(e.target.value)}
              onBlur={() =>
                setAddressLandmark(
                  removeLeadingAndMultipleSpaces(addressLandmark)
                )
              }
              maxLength={MAXLENGTH_ADDRESS_LANDMARK}
            />
            <span className={classes.charLimit}>
              {addressLandmark.length} / {MAXLENGTH_ADDRESS_LANDMARK}
            </span>
          </div>
          <div className={classes.formList}>
            <label>City</label>
            <input
              disabled
              placeholder="Auto filled by pincode"
              value={addressCity}
            />
          </div>
          <div className={classes.formList}>
            <label>State</label>
            <input
              disabled
              placeholder="Auto filled by pincode"
              value={addressState}
            />
          </div>
          <div className={classes.addressTypeSearch}>
            <div className={classes.addressTypeList}>
              <label>Address Type*</label>
              {patientAddressTypes.map((patientAddressType, index) => {
                return (
                  <p
                    key={`patientAddressType_${index}`}
                    className={`${classes.addressType} ${
                      addressType === patientAddressType ? classes.selected : ''
                    }`}
                    onClick={() => handleAddressTypeChange(patientAddressType)}
                  >
                    {patientAddressType}
                  </p>
                );
              })}
            </div>
            <div className={classes.cta}>
              <Button
                variant="contained"
                color="secondary"
                disabled={isSearchDisabled || addAddressLoader}
                className={`${isSearchDisabled ? classes.disabled : ''}`}
                onClick={handleSearch}
              >
                Search
              </Button>
            </div>
          </div>
          {!!zeroResultsError &&
            renderErrorAtBottom(
              'No Results found from Google for this pincode and address combination'
            )}
          {!!showUpdatePincodeMessage &&
            renderErrorAtBottom(
              'Pincode has been updated. Please check again!'
            )}
          {!!addressAdditionError?.length &&
            renderErrorAtBottom(addressAdditionError)}
        </div>

        <div className={classes.searchResults}>
          {suggestions?.length > 0 &&
            suggestions.map((suggestionData) => {
              return (
                <div
                  className={`${classes.list} ${
                    selectedAddress === suggestionData.description
                      ? classes.selectedOption
                      : ''
                  }`}
                  key={suggestionData.place_id}
                  onClick={() => handleSelect(suggestionData.description)}
                >
                  <h3 className={`${classes.title}`}>
                    {suggestionData?.structured_formatting?.main_text}
                  </h3>
                  <p className={classes.subTitle}>
                    {suggestionData?.structured_formatting?.secondary_text}
                  </p>
                </div>
              );
            })}
          {searchHit && (!suggestions || suggestions.length === 0) && (
            <div className={classes.noSearchResults}>
              <h4 className={classes.title}>Search results not found!</h4>
              <Button
                color="secondary"
                className={classes.secondaryBtn}
                onClick={handlePincodeCenter}
              >
                Use PINCODE CENTER
              </Button>
            </div>
          )}
        </div>

        <div className={classes.stickyCta}>
          <AphButton
            color="primary"
            fullWidth
            disabled={disableSubmit}
            onClick={onConfirmLocation}
            title={'CONFIRM ADDRESS'}
          >
            {addAddressLoader ? (
              <CircularProgress size={20} color="secondary" />
            ) : serviceable ? (
              'CONFIRM ADDRESS'
            ) : (
              'Not Serviceable'
            )}
          </AphButton>
        </div>
      </div>
      <div className={classes.rightSection}>{renderMap()}</div>
    </div>
  );
};

export default CombinedAddressForm;
