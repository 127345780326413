import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useOrderContext } from '../OrderProvider';
import { DIAGNOSTICS_GROUPPLAN } from '../appConfig';
import { getTestsCirclePrice, toTitleCase } from './helpers/commonHelpers';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    summaryList: {
      margin: 0,
      padding: 0,
      width: '100%',
      '& > li': {
        listStyleType: 'none',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: 12,
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: 14,
        borderBottom: '1px solid #ECECEC',
        '&>div': {
          display: 'flex',
          justifyContent: 'space-between',
          '& > div': {
            '&:first-child': {
              flexBasis: '70%',
              lineHeight: '22px',
              fontWeight: 500,
              fontSize: 14,
              color: '#01475B',
            },
            '&>span': {
              fontWeight: 500,
              fontSize: 12,
              color: '#007C9D',
              display: 'block',
              background: '#F3FFFF',
              borderRadius: 4,
              padding: '4px 6px',
            },
            '&>p': {
              margin: 0,
              whiteSpace: 'nowrap',
            },
            '&:last-child': {
              textAlign: 'right',
            },
          },
        },
      },
    },
    fw: {
      fontWeight: 700,
    },
    subTotalWrap: {
      borderBottom: '1px solid #ECECEC',
      '&>div': {
        display: 'flex',
        flexDirection: 'row',
        fontSize: 14,
        justifyContent: 'space-between',
        fontWeight: 600,
        padding: '4px 12px',
        paddingTop: 8,
      },
    },
    green: {
      color: '#00B38E',
    },
    removeCouponImg: {
      padding: 0,
    },
    circleImage: {
      width: 30,
      marginRight: 5,
      verticalAlign: 'middle',
    },
    couponSavings: {
      color: '#00B38E',
      fontSize: 12,
      fontWeight: 500,
    },
    slashPrice: {
      fontSize: 10,
      lineHeight: '16px',
      fontWeight: 600,
      opacity: 0.8,
      textDecoration: 'line-through',
      color: '#02475B',
    },
    prevOrderSummaryRoot: {
      margin: '0px !important',
    },
    titleRoot: {
      height: '38px !important',
      minHeight: '38px !important',
      borderRadius: 8,
      background: '#01475B',
    },
    contentRoot: {
      fontWeight: 700,
      fontSize: 14,
      color: '#FFFFFF',
      padding: 0,
      margin: '0px !important',
    },
    detailsRoot: {
      padding: 0,
    },
    summaryContainer: {
      width: '100%',
    },
  })
);

type amountBreakup = {
  subTotal: number;
  cartSavings: number;
  circleSavings: number;
  couponSavings: number;
};

export const PreviousOrderSummary: React.FC = () => {
  const classes = useStyles();
  const {
    modifyFlowOrder,
    modifyOrderPaidAmountBreakup,
    setModifyOrderPaidAmountBreakup,
    isUserCare,
  } = useOrderContext();
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  useEffect(() => {
    if (modifyFlowOrder) {
      const amountBreakup: amountBreakup = {
        subTotal: 0,
        cartSavings: 0,
        circleSavings: 0,
        couponSavings: 0,
      };

      if (modifyFlowOrder?.diagnosticOrderLineItems.length > 0) {
        modifyFlowOrder?.diagnosticOrderLineItems.forEach((diagItem) => {
          if (!diagItem?.isRemoved) {
            amountBreakup.couponSavings += diagItem?.couponDiscAmount;
            const priceByGroupPlan = diagItem.pricingObj.find(
              (pricing: any) => pricing?.groupPlan === diagItem?.groupPlan
            );

            const allPricing = diagItem?.pricingObj?.find(
              (pricing) => pricing?.groupPlan === DIAGNOSTICS_GROUPPLAN?.all
            );

            if (priceByGroupPlan) {
              const mrpToUse = priceByGroupPlan?.mrp;
              const priceToUse = diagItem?.itemObj?.isOnMrpCoupon
                ? priceByGroupPlan?.mrp
                : priceByGroupPlan?.price;

              amountBreakup.subTotal = amountBreakup?.subTotal + mrpToUse;

              if (diagItem?.groupPlan !== DIAGNOSTICS_GROUPPLAN?.circle) {
                amountBreakup.cartSavings =
                  amountBreakup.cartSavings + (mrpToUse - priceToUse);
              } else if (
                diagItem?.groupPlan === DIAGNOSTICS_GROUPPLAN?.circle
              ) {
                amountBreakup.cartSavings =
                  amountBreakup.cartSavings + (mrpToUse - allPricing?.price);
                amountBreakup.circleSavings =
                  amountBreakup?.circleSavings + (allPricing?.mrp - priceToUse);
              }
            }
          }
        });
      }

      setModifyOrderPaidAmountBreakup(amountBreakup);
    }
  }, [modifyFlowOrder]);

  const amountToPay =
    modifyOrderPaidAmountBreakup?.subTotal +
    modifyFlowOrder?.collectionCharges -
    modifyOrderPaidAmountBreakup?.circleSavings -
    modifyOrderPaidAmountBreakup?.couponSavings -
    modifyOrderPaidAmountBreakup?.cartSavings;

  return (
    <>
      <Accordion
        classes={{
          expanded: classes.prevOrderSummaryRoot,
        }}
        expanded={isExpanded}
        onChange={() => {
          setIsExpanded((expanded) => !expanded);
        }}
      >
        <AccordionSummary
          expandIcon={
            <img src={`${require('../images/ic_white_arrow.svg')}`} alt="" />
          }
          classes={{
            root: classes.titleRoot,
            content: classes.contentRoot,
          }}
        >
          PREVIOUS ORDER SUMMARY
        </AccordionSummary>
        <AccordionDetails
          classes={{
            root: classes.detailsRoot,
          }}
        >
          <div className={classes.summaryContainer}>
            <ul className={classes.summaryList}>
              {modifyFlowOrder?.diagnosticOrderLineItems &&
                modifyFlowOrder?.diagnosticOrderLineItems.map((item, idx) => {
                  const { mrp, priceBeforeDiscount } = getTestsCirclePrice(
                    item?.pricingObj,
                    isUserCare
                  );
                  const priceToUse = item?.itemObj?.isOnMrpCoupon
                    ? mrp
                    : priceBeforeDiscount;
                  return (
                    <>
                      {!item?.isRemoved && (
                        <li key={'order-item-' + idx}>
                          <div>
                            <div>{toTitleCase(item.itemName)}</div>
                            <div>
                              <p>
                                ₹ {priceToUse}
                                {mrp !== priceToUse && (
                                  <span className={classes.slashPrice}>
                                    ₹{mrp}
                                  </span>
                                )}
                              </p>
                              <span>1 X ₹{priceToUse}</span>
                            </div>
                          </div>
                        </li>
                      )}
                    </>
                  );
                })}
            </ul>
            <div className={classes.subTotalWrap}>
              <div>
                <div>Subtotal</div>
                <div>₹ {modifyOrderPaidAmountBreakup?.subTotal}</div>
              </div>
              <div>
                <div>Home Collection Charges</div>
                <div>₹ {modifyFlowOrder?.collectionCharges || 0}</div>
              </div>
              <div className={classes.green}>
                <div>
                  <img
                    src={require('../images/circle_logo.png')}
                    alt="Circle Icon"
                    className={classes.circleImage}
                  />
                  Membership Discount
                </div>
                <div>-₹ {modifyOrderPaidAmountBreakup?.circleSavings}</div>
              </div>
              <div className={classes.green}>
                <div>Cart Savings</div>
                <div>-₹ {modifyOrderPaidAmountBreakup?.cartSavings}</div>
              </div>
              <div className={classes.green}>
                <div>Coupon Savings</div>
                <div>
                  -₹ {modifyOrderPaidAmountBreakup?.couponSavings?.toFixed(2)}
                </div>
              </div>
            </div>
            <div className={classes.subTotalWrap}>
              <div>
                <div>Total</div>
                <div>₹ {amountToPay}</div>
              </div>
              <div>
                <div>Paid</div>
                <div> ₹{amountToPay}</div>
              </div>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </>
  );
};
