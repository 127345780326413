import React, { useState } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import {
  downloadFile,
  isDocumentFile,
  isImageFile,
} from './helpers/commonHelpers';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(() =>
  createStyles({
    documentViewer: {
      border: '0.5px solid #00B38E',
      borderRadius: 2,
      background: 'none',
      '& >div': {
        '& canvas': {
          width: (props: any) => {
            return `${props?.width}px !important`;
          },
          height: (props: any) => {
            return `${props?.height}px !important`;
          },
        },
        '& .react-pdf__Page__textContent': {
          width: (props: any) => {
            return `${props?.width}px !important`;
          },
          height: (props: any) => {
            return `${props?.height}px !important`;
          },
        },
      },
    },
    overlay: {
      position: 'absolute',
      bottom: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: 'rgba(0,0,0,0.5)',
      width: (props: any) => {
        return `${props?.width}px !important`;
      },
      height: (props: any) => {
        return `${props?.height}px !important`;
      },
    },
    imageContainer: {
      border: '0.5px solid #00B38E',
      borderRadius: 2,
      position: 'relative',
      cursor: 'pointer',
      width: (props: any) => {
        return `${props?.width}px !important`;
      },
      height: (props: any) => {
        return `${props?.height}px !important`;
      },
    },
  })
);

interface RenderImageProps {
  url: string;
  width: number;
  height: number;
}

const RenderImage: React.FC<RenderImageProps> = ({ url, width, height }) => {
  const classes = useStyles({
    height,
    width,
  });
  const [isHovered, setIshovered] = useState(false);
  return (
    <div
      onMouseOver={() => setIshovered(true)}
      onMouseLeave={() => setIshovered(false)}
      className={classes.imageContainer}
    >
      {isImageFile(url) && (
        <img src={url} width={width} height={height} alt="" />
      )}
      {isDocumentFile(url) && (
        <Document
          error={''}
          onLoadError={() => {
            console.error('error occured while fetching pdf', url);
          }}
          className={classes.documentViewer}
          file={url}
          loading={<CircularProgress size={14} />}
        >
          <Page pageNumber={1} />
        </Document>
      )}
      {isHovered && (
        <div className={classes.overlay} onClick={() => downloadFile(url)}>
          <img src={require('../images/Union.png')} alt="" />
        </div>
      )}
    </div>
  );
};

export default RenderImage;
