import CircularProgress from '@material-ui/core/CircularProgress';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/styles/makeStyles';
import React, { useState, useEffect, useRef } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import eachDayOfInterval from 'date-fns/eachDayOfInterval';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import addDays from 'date-fns/addDays';
import isAfter from 'date-fns/isAfter';
import axios from '../axios';
import Slider from 'react-slick';
import {
  GET_CUSTOMIZED_SLOT_V2,
  GET_HOME_COLLECTION_CHARGES,
  phleboCallOnModify,
} from '../appConfig';
import {
  PatientObjWithLineItems,
  sanitizeLineItems,
  SlotObject,
  SlotObjectWithDate,
  SLOT_DATE_FORMAT_FOR_API,
} from './helpers/commonHelpers';
import {
  getCommonPatientAttributes,
  sendClevertTapEvent,
} from './helpers/eventTracking';
import { useOrderContext } from '../OrderProvider';
import { phleboChargesV2 } from '../types/globalTypes';
import PhleboCharges from './PhleboCharges';

const useStyles = makeStyles((theme: Theme) => {
  return {
    slotWrap: {
      padding: 14,
    },
    date: {
      boxSizing: 'border-box',
      borderRadius: 10,
      padding: 12,
      border: '1px solid rgba(0,0,0,0.2)',
      '&>p': {
        margin: 0,
        fontSize: 14,
        fontWeight: 500,
      },
    },
    dateWrap: {
      marginTop: 10,
      '& .slick-prev': {
        left: -23,
      },
      '& .slick-next': {
        right: -23,
      },
    },
    availableDate: {
      padding: '6px 17px',
      borderRadius: 10,
      cursor: 'pointer',
      width: 'auto !important',
      display: 'flex !important',
      flexDirection: 'column',
      alignItems: 'center',
      '& p': {
        margin: 0,
        fontWeight: 600,
        fontSize: 16,
        '&:last-child': {
          fontWeight: 500,
          fontSize: 12,
        },
      },
    },
    alert: {
      marginTop: 20,
      background: ' #FCFDDA',
      fontSize: 10,
      fontWeight: 600,
      padding: 12,
      display: 'flex',
      alignItems: 'flex-start',
      borderRadius: 10,
      '& img': {
        paddingRight: 5,
      },
    },
    activeDate: {
      background: '#E9FAEE !important',
      color: '#121414',
      border: '1.5px solid #00B38E',
    },
    tabs: {
      minHeight: 242,
    },
    slotsLoader: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, 50%)',
    },
    tabsRoot: {},
    tabRoot: {
      fontSize: 14,
      fontWeight: 500,
      textAlign: 'center',
      padding: '11px 10px 0px',
      color: '#01475b',
      opacity: 0.6,
      width: '42% !important',
      textTransform: 'none',
      minWidth: '33%',
      minHeight: 'unset',
      '&>span': {
        '&:first-child': {
          '& img': {
            marginBottom: '0 !important',
          },
          flexDirection: 'row',
          '&>div': {
            width: 'auto',
            height: 'auto',
            marginBottom: '0 !important',
            '&>img': {
              verticalAlign: 'middle',
              paddingRight: 5,
            },
          },
        },
      },
    },
    tabIcon: {
      marginRight: 6,
    },
    tabSelected: {
      color: '#00B38E',
      opacity: 1,
    },
    tabsIndicator: {
      backgroundColor: '#00b38e',
      height: 2,
    },
    slot: {
      position: 'relative',
      background: '#FFF',
      border: '1px solid #D9D9D7',
      borderRadius: 12,
      padding: '8px 10px',
      fontSize: 12,
      fontWeight: 500,
      margin: '0px 8px 16px 3px',
      cursor: 'pointer',
      textAlign: 'center',
      '& p': {
        color: '#EB762E',
        position: 'absolute',
        background: '#FCF0E4',
        left: 10,
        top: -10,
        display: 'flex',
        padding: '0px 6px',
        alignItems: 'center',
        gap: 4,
        borderRadius: 12,
        margin: 0,
        fontSize: 10,
        fontWeight: 600,
      },
    },
    slotCont: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      marginTop: 16,
    },
    footer1: {
      boxShadow: '0px -4px 4px rgba(0, 0, 0, 0.1)',
      margin: '0 -14px -14px -14px',
      padding: '18px 25px',
      marginTop: 14,
      '& button': {
        width: '100%',
        fontSize: 13,
        fontWeight: 'bold',
      },
    },
    footer2: {
      marginTop: 20,
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'row',
      '& button': {
        fontSie: 13,
        padding: '8px 20px',
        borderRadius: 10,
        '&:first-child': {
          background: 'unset',
          boxShadow: 'unset',
          color: '#FCA317',
          paddingLeft: 0,
        },
      },
    },
    confrimDetails: {
      '&>p': {
        fontSize: 14,
        fontWeight: 500,
        textAlign: 'center',
        '&>span': {
          color: ' #00B38E',
        },
      },
    },
    disabled: {
      opacity: 0.5,
      cursor: 'default !important',
    },
    slotBooked: {
      display: 'flex',
      justifyContent: 'space-between',
      fontSize: 14,
      fontWeight: 500,
      '& span': {
        color: '#FCA317',
        textTransform: 'uppercase',
        fontWeight: 'bold',
        cursor: 'pointer',
      },
    },
    slotLoader: {
      textAlign: 'center',
      marginTop: 20,
    },
    noSlots: {
      margin: 10,
      marginTop: 20,
    },
  };
});

interface SlotProps {
  setCollectionChargesLoader: (toggleLoader: boolean) => void;
}
export const Slots: React.FC<SlotProps> = ({ setCollectionChargesLoader }) => {
  const {
    selectedAddress,
    serviceabilityData,
    selectedSlotDate,
    setSelectedSlotDate,
    setSelectedSlot,
    selectedSlot,
    enableSlotSelection,
    setEnableSlotSelection,
    patientLineItems,
    setHomeCollectionCharges,
    diagnosticCartItems,
    orderResult,
    removeHCCharges,
    isUserCare,
    selectedProfile,
    slotDaysByCity,
    modifyFlowOrder,
    hasAccessToReschedule,
    userPhoneNumber,
    circleMemberShipPlan,
    setPhleboCharges,
  } = useOrderContext();
  const isPrimaryOrder = modifyFlowOrder?.id === modifyFlowOrder?.parentOrderId;
  const classes = useStyles({});
  const sliderRef = useRef(null);
  const [tabValue, setTabValue] = useState<number>(0);
  const TabContainer: React.FC = (props) => {
    return <Typography component="div">{props.children}</Typography>;
  };
  const [slotDate, setSlotDate] = useState<Date>(
    new Date(new Date().toDateString())
  );
  const [openSlots, setOpenSlots] = useState<boolean>(false);
  const [tempSelectedSlot, setTempSelectedSlot] =
    useState<SlotObjectWithDate | null>(null);
  const [distanceCharges, setDistanceCharges] = useState<number>(0);
  const [morningSlots, setMorningSlots] = useState<SlotObject[]>([]);
  const [afternoonSlots, setAfternoonSlots] = useState<SlotObject[]>([]);
  const [days, setDays] = useState<Date[]>([]);
  const [slotsLoader, setSlotsLoader] = useState<boolean>(false);
  const [manualDateChange, setManualDateChange] = useState<boolean>(false);

  useEffect(() => {
    if (slotDaysByCity) {
      const interval = eachDayOfInterval({
        start: new Date(),
        end: addDays(new Date(), slotDaysByCity),
      });
      setDays(interval);
    }
  }, [slotDaysByCity]);

  const getPatientLineItemsWithoutName = () => {
    const dummyPatientItems: PatientObjWithLineItems[] = JSON.parse(
      JSON.stringify(patientLineItems)
    );
    const updatedCartItems = dummyPatientItems.map((patientObj) => {
      patientObj.lineItems = patientObj.lineItems.map((lineItem) => {
        const {
          mrp,
          price,
          groupPlan,
          itemId,
          couponDiscAmount,
          groupPlanDiscAmount,
        } = lineItem;
        return {
          mrp,
          price,
          groupPlan,
          itemId,
          couponDiscAmount,
          groupPlanDiscAmount,
        };
      });
      return patientObj;
    });
    return updatedCartItems;
  };

  const patientLineItemsWithoutName = getPatientLineItemsWithoutName();

  const getAreaSlotsByIdAndDate = () => {
    setSlotsLoader(true);
    return axios
      .post(`${GET_CUSTOMIZED_SLOT_V2}`, {
        selectedDate: format(slotDate, 'yyyy-MM-dd'),
        patientAddressObj: {
          patientAddressID: selectedAddress?.id,
          addressLine1: selectedAddress?.addressLine1,
          addressLine2: selectedAddress?.addressLine2,
          zipcode: selectedAddress?.zipcode,
          latitude: selectedAddress?.latitude || 0, // keep this as 0, spl config for OPT
          longitude: selectedAddress?.longitude || 0, // keep this as 0, spl config for OPT
          city: selectedAddress?.city,
          state: selectedAddress?.state,
        },
        patientsObjWithLineItems: patientLineItemsWithoutName,
        billAmount: patientLineItemsWithoutName.reduce(
          (billTotal, patientObj) => {
            const patientTotal = patientObj?.lineItems.reduce(
              (prev, curr) => prev + curr.price,
              0
            );
            return billTotal + patientTotal;
          },
          0
        ),
        serviceability: serviceabilityData,
        slotByClusterConfig: true,
      })
      .then((res) => {
        const { data } = res;
        if (data?.available_slots && data.available_slots.length) {
          const allSlots = data.available_slots;
          const mrngSlots = allSlots.filter((item: SlotObject) => {
            const slotTime = parse(
              item.slotDetail.slotDisplayTime,
              'hh:mm a',
              new Date()
            );
            return parse('12:00 PM', 'hh:mm a', new Date()) > slotTime;
          });
          const noonSlots = allSlots.filter((item: SlotObject) => {
            const slotTime = parse(
              item.slotDetail.slotDisplayTime,
              'hh:mm a',
              new Date()
            );
            return parse('12:00 PM', 'hh:mm a', new Date()) <= slotTime;
          });
          setMorningSlots(mrngSlots);
          setAfternoonSlots(noonSlots);
          if (!mrngSlots?.length) {
            setTabValue(1);
          } else {
            setTabValue(0);
          }
          if (data?.distanceCharges) {
            setDistanceCharges(data.distanceCharges);
          } else {
            setDistanceCharges(0);
          }
          setSlotsLoader(false);
          return allSlots;
        } else {
          setMorningSlots([]);
          setAfternoonSlots([]);
          setDistanceCharges(0);
          setSelectedSlot(null);
          const newDate = addDays(slotDate, 1);
          const maxDate = days[days.length - 1];
          if (!isAfter(newDate, maxDate) && !manualDateChange) {
            setSlotDate(newDate);
            setSelectedSlotDate(newDate);
          } else {
            setSlotsLoader(false);
          }
          const itemIds: number[] = patientLineItems?.reduce((acc, item) => {
            acc = acc.concat(
              item?.lineItems?.map((lineItem) => lineItem?.itemId)
            );
            return acc;
          }, [] as number[]);

          const itemNames: string[] = patientLineItems?.reduce((acc, item) => {
            acc = acc.concat(
              item?.lineItems?.map((lineItem) => lineItem?.itemName)
            );
            return acc;
          }, [] as string[]);

          const cartValue = patientLineItems?.reduce(
            (price, item) =>
              price +
              item?.lineItems?.reduce(
                (total, lineItem) => total + lineItem?.price,
                0
              ),
            0
          );

          const eventData = {
            ...getCommonPatientAttributes(selectedProfile),
            Latitude: selectedAddress?.latitude || '',
            Longitude: selectedAddress?.longitude || '',
            'Address Pincode': selectedAddress?.zipcode || '',
            City: selectedAddress?.city || '',
            'Slot date': slotDate ? format(slotDate, 'dd-MMM-yyyy') : '',
            'Slot time': '',
            Type: '',
            'No. of slots': 0,
            'Circle user': isUserCare ? 'Yes' : 'No',
            itemIds: JSON.stringify(itemIds),
            itemNames: JSON.stringify(itemNames),
            'Cart Value': cartValue,
            Source: 'OPT',
          };
          sendClevertTapEvent(
            'Diagnostic slot time selected',
            userPhoneNumber,
            eventData
          );
          return [];
        }
      })
      .catch((e) => {
        alert('Something went wrong!');
        setSlotsLoader(false);
        return [];
      })
      .finally(() => {
        setManualDateChange(false);
      });
  };

  const getHCCharges = () => {
    setCollectionChargesLoader(true);
    const patientItemsObj: PatientObjWithLineItems[] =
      // !couponApplied ?
      sanitizeLineItems(patientLineItems);
    // : [cartArrayAfterDiscount!];
    axios
      .post<phleboChargesV2>(
        GET_HOME_COLLECTION_CHARGES,

        {
          chargeDetailsInput: {
            patientAddressObj: {
              patientAddressID: selectedAddress?.id,
              addressLine1: selectedAddress?.addressLine1,
              addressLine2: selectedAddress?.addressLine2,
              zipcode: selectedAddress?.zipcode,
              latitude: selectedAddress?.latitude || 0,
              longitude: selectedAddress?.longitude || 0,
              city: selectedAddress?.city,
              state: selectedAddress?.state,
            },
            patientsObjWithLineItems: patientItemsObj,
            billAmount: patientItemsObj[0]?.lineItems.reduce(
              (prev, curr) => prev + curr.price,
              0
            ),
            serviceability: serviceabilityData,
            slotInfo: {
              paidSlot: selectedSlot?.isPaidSlot,
              slotDetails: selectedSlot?.slotDetail!,
            },
            isCirclePurchase: !!circleMemberShipPlan,
          },
          mobileNumber: '+91' + userPhoneNumber,
        }
      )
      .then((res) => {
        const { data } = res;
        if (data) {
          setPhleboCharges(data);
          removeHCCharges
            ? setHomeCollectionCharges({
                charges: 0,
                distanceCharges: 0,
                couponDiscApplied: true,
              })
            : setHomeCollectionCharges({
                charges: data.collectionCharges ?? 0,
                distanceCharges: data.additionalCharges ?? 0,
              });
        }
      })
      .finally(() => {
        setCollectionChargesLoader(false);
      });
  };

  const handleSlotSelection = (slotSelected: SlotObject) => {
    setTempSelectedSlot({
      ...slotSelected,
      slotDate: format(slotDate, SLOT_DATE_FORMAT_FOR_API),
    });
    setSelectedSlot(slotSelected);
  };

  useEffect(() => {
    if (slotDate && enableSlotSelection && openSlots) {
      getAreaSlotsByIdAndDate();
    }
    // eslint-disable-next-line
  }, [slotDate, enableSlotSelection, openSlots]);

  useEffect(() => {
    if (slotDate && days?.length && sliderRef) {
      const activeDateIndex = days?.findIndex(
        (date) => date.getTime() === slotDate.getTime()
      );
      if (activeDateIndex !== -1) {
        sliderRef?.current?.slickGoTo(activeDateIndex);
      }
    }
  }, [slotDate, sliderRef, days, selectedSlot]);

  const diagnosticCartItemIdsString = diagnosticCartItems
    ?.map((item) => item?.itemId)
    ?.toString();

  const patientLineItemIdsString = patientLineItems
    ?.reduce((allItemIds, curPatient) => {
      const itemIds = [];
      curPatient?.lineItems?.forEach((item) => itemIds.push(item?.itemId));
      return [...allItemIds, ...itemIds];
    }, [])
    ?.toString();

  const allowHcChargesonModify =
    modifyFlowOrder &&
    (phleboCallOnModify?.allowForAll ||
      diagnosticCartItems?.some((cartItem) =>
        phleboCallOnModify?.allowedItemIds?.includes(cartItem?.itemId)
      ) ||
      modifyFlowOrder?.diagnosticOrderLineItems?.some((lineItem) =>
        phleboCallOnModify?.allowedItemIds?.includes(lineItem?.itemId)
      ));

  useEffect(() => {
    if (
      modifyFlowOrder &&
      patientLineItemIdsString?.length > 0 &&
      selectedSlot &&
      isPrimaryOrder
    ) {
      if (allowHcChargesonModify) getHCCharges();
      else {
        setPhleboCharges(
          modifyFlowOrder?.attributesObj?.collectionChargesV2
            ? modifyFlowOrder?.attributesObj?.collectionChargesV2
            : {
                additionalCharges: 0,
                additionalChargesMessage: '',
                collectionCharges: modifyFlowOrder?.collectionCharges || 0,
                couponDiscApplied: false,
                isCircleBenefits: false,
                isPackageBenefits: false,
                paidSlotMessage: '',
                slashedAdditionalCharges: 0,
                slashedCollectionCharges: 0,
              }
        );
      }
    }
  }, [
    allowHcChargesonModify,
    modifyFlowOrder,
    isPrimaryOrder,
    patientLineItemIdsString,
    selectedSlot,
  ]);

  useEffect(() => {
    if (removeHCCharges) {
      setHomeCollectionCharges({
        charges: 0,
        distanceCharges: 0,
      });
      setPhleboCharges({
        additionalCharges: 0,
        additionalChargesMessage: '',
        collectionCharges: 0,
        couponDiscApplied: true,
        isCircleBenefits: false,
        isPackageBenefits: false,
        paidSlotMessage: '',
        slashedAdditionalCharges: 0,
        slashedCollectionCharges: 0,
      });
    }
    if (
      selectedSlot &&
      !removeHCCharges &&
      patientLineItemIdsString?.length &&
      !modifyFlowOrder
    ) {
      getHCCharges();
    }
    // eslint-disable-next-line
  }, [
    removeHCCharges,
    selectedSlot,
    patientLineItemIdsString,
    modifyFlowOrder,
    circleMemberShipPlan,
  ]);

  const isSelectedSlotExistInAvlSlots = async () => {
    try {
      const availableSlots = await getAreaSlotsByIdAndDate();
      return availableSlots?.some(
        (slot) =>
          slot?.slotDetail?.slotDisplayTime ===
          selectedSlot?.slotDetail?.slotDisplayTime
      );
    } catch (error) {
      console.error('error fetching getAreadSlotsByIdAndDate API');
      return false;
    }
  };

  const isSelectedSlotExpired = () => {
    const slotDateTime = parse(
      `${format(selectedSlotDate, 'yyyy-MM-dd')} ${
        selectedSlot?.slotDetail?.slotDisplayTime
      }`,
      'yyyy-MM-dd h:mm a',
      new Date()
    );
    return isAfter(new Date(), slotDateTime);
  };

  useEffect(() => {
    if (selectedSlotDate && selectedSlot && modifyFlowOrder) {
      if (isSelectedSlotExpired()) {
        setSelectedSlot(null);
        setSelectedSlotDate(new Date(new Date().toDateString()));
        setSlotDate(new Date(new Date().toDateString()));
        setTempSelectedSlot(null);
        setEnableSlotSelection(true);
      }
    }
  }, [
    modifyFlowOrder,
    diagnosticCartItemIdsString,
    selectedSlot,
    selectedSlotDate,
  ]);

  useEffect(() => {
    if (!modifyFlowOrder) {
      if (
        (diagnosticCartItemIdsString?.length &&
          !isSelectedSlotExistInAvlSlots()) ||
        !diagnosticCartItemIdsString?.length
      ) {
        setOpenSlots(false);
        setSelectedSlot(null);
        setSelectedSlotDate(new Date(new Date().toDateString()));
        setSlotDate(new Date(new Date().toDateString()));
        setTempSelectedSlot(null);
      }
    }
    // eslint-disable-next-line
  }, [diagnosticCartItemIdsString, modifyFlowOrder]);

  useEffect(() => {
    if (modifyFlowOrder && patientLineItemIdsString?.length) {
      setOpenSlots(true);
    }
  }, [diagnosticCartItemIdsString, modifyFlowOrder, patientLineItemIdsString]);

  const getSliderSettings = () => {
    return {
      infinite: false,
      autoPlay: false,
      slidesToShow: 4,
      slidesToScroll: 4,
      nextArrow: <img src={require('../images/right-arrow.svg')} alt="Next" />,
      prevArrow: (
        <img src={require('../images/left-arrow.svg')} alt="Previous" />
      ),
    };
  };

  const hasSlotChangePermission = () => {
    if (modifyFlowOrder) {
      return hasAccessToReschedule;
    }
    return true;
  };

  return (
    <div className={classes.slotWrap}>
      {/* to be show when choose slot */}
      {openSlots ? (
        <>
          {!selectedSlot ? (
            <>
              <div className={classes.date}>
                <p>{format(slotDate, 'MMMM yyyy')}</p>
                <div className={classes.dateWrap}>
                  <Slider {...getSliderSettings()} ref={sliderRef}>
                    {days?.map((sDate: Date, idx: number) => (
                      <div
                        onClick={() => {
                          if (!slotsLoader) {
                            setManualDateChange(true);
                            setSelectedSlotDate(sDate);
                            setSlotDate(sDate);
                            setTabValue(0);
                          }
                        }}
                        className={`${classes.availableDate} ${
                          sDate.getTime() === slotDate.getTime()
                            ? classes.activeDate
                            : ''
                        }`}
                        key={'day' + idx}
                      >
                        <p>{format(sDate, 'dd')}</p>
                        <p>{format(sDate, 'EE')}</p>
                      </div>
                    ))}
                  </Slider>
                </div>
              </div>

              {tempSelectedSlot?.isPaidSlot && distanceCharges > 0 && (
                <div className={classes.alert}>
                  <img src={require('../images/ic_preslot.png')} alt="" />
                  Premium Slot - Additional charge of ₹ {distanceCharges} will
                  be levied
                </div>
              )}

              {!slotsLoader ? (
                <>
                  {morningSlots.length > 0 || afternoonSlots.length > 0 ? (
                    <div className={classes.tabs}>
                      <>
                        <Tabs
                          value={tabValue}
                          classes={{
                            root: classes.tabsRoot,
                            indicator: classes.tabsIndicator,
                          }}
                          onChange={(e, newValue) => {
                            setTabValue(newValue);
                          }}
                        >
                          <Tab
                            classes={{
                              root: classes.tabRoot,
                              selected: classes.tabSelected,
                            }}
                            label="Morning"
                            title={'Morning'}
                            icon={
                              <img
                                width={'20'}
                                height={'20'}
                                className={classes.tabIcon}
                                src={require(`../images/${
                                  tabValue === 0
                                    ? 'ic_morningactive.svg'
                                    : 'ic_morningdefault.png'
                                }`)}
                                alt=""
                              />
                            }
                          />
                          {/* ic_morningactive.svg ic_morningdefault.png ic_noonactive.png ic_noondefault.png
                          ic_eveningactive.png ic_eveningdefault.png */}
                          <Tab
                            classes={{
                              root: classes.tabRoot,
                              selected: classes.tabSelected,
                            }}
                            label="Afternoon"
                            title={'Afternoon'}
                            icon={
                              <img
                                width={'20'}
                                height={'20'}
                                className={classes.tabIcon}
                                src={require(`../images/${
                                  tabValue === 1
                                    ? 'ic_noonactive.png'
                                    : 'ic_noondefault.png'
                                }`)}
                                alt=""
                              />
                            }
                          />
                        </Tabs>

                        {tabValue === 0 && (
                          <TabContainer>
                            <div className={classes.slotCont}>
                              {morningSlots.length > 0 &&
                                morningSlots.map(
                                  (slot: SlotObject, idx: number) => (
                                    <div
                                      key={
                                        slot.slotDetail.slotDisplayTime + idx
                                      }
                                      className={
                                        classes.slot +
                                        ` ${
                                          tempSelectedSlot?.slotDetail
                                            .slotDisplayTime ===
                                            slot.slotDetail.slotDisplayTime &&
                                          format(
                                            slotDate,
                                            SLOT_DATE_FORMAT_FOR_API
                                          ) === tempSelectedSlot.slotDate
                                            ? classes.activeDate
                                            : ''
                                        }`
                                      }
                                      onClick={() => {
                                        handleSlotSelection(slot);
                                      }}
                                    >
                                      {slot.slotDetail.slotDisplayTime}
                                      {slot.isPaidSlot && (
                                        <p>
                                          + ₹{distanceCharges}
                                          <img
                                            src={require('../images/hike.svg')}
                                            alt="Hike"
                                          />
                                        </p>
                                      )}
                                    </div>
                                  )
                                )}
                            </div>
                          </TabContainer>
                        )}
                        {tabValue === 1 && (
                          <TabContainer>
                            <div className={classes.slotCont}>
                              {afternoonSlots.length > 0 &&
                                afternoonSlots.map(
                                  (slot: SlotObject, idx: number) => (
                                    <div
                                      key={
                                        slot.slotDetail.slotDisplayTime + idx
                                      }
                                      className={
                                        classes.slot +
                                        ` ${
                                          tempSelectedSlot?.slotDetail
                                            .slotDisplayTime ===
                                            slot.slotDetail.slotDisplayTime &&
                                          format(
                                            slotDate,
                                            SLOT_DATE_FORMAT_FOR_API
                                          ) === tempSelectedSlot.slotDate
                                            ? classes.activeDate
                                            : ''
                                        }`
                                      }
                                      onClick={() => {
                                        handleSlotSelection(slot);
                                      }}
                                    >
                                      {slot.slotDetail.slotDisplayTime}
                                      {slot.isPaidSlot && (
                                        <img
                                          src={require('../images/ic_preslot.png')}
                                          alt=""
                                        />
                                      )}
                                    </div>
                                  )
                                )}
                            </div>
                          </TabContainer>
                        )}
                      </>
                    </div>
                  ) : (
                    <div className={classes.noSlots}>No Slots Available</div>
                  )}
                </>
              ) : (
                <div className={classes.slotLoader}>
                  <CircularProgress size={30} />
                </div>
              )}
            </>
          ) : (
            <div className={classes.slotBooked}>
              <>
                <div>{`${format(selectedSlotDate, 'dd MMMM yyyy')}, ${
                  tempSelectedSlot?.slotDetail.slotDisplayTime ||
                  format(selectedSlotDate, 'HH:mm')
                }`}</div>
                {(orderResult ? orderResult.errorMessage : true) &&
                  hasSlotChangePermission() && (
                    <span
                      onClick={() => {
                        setSelectedSlot(null);
                        setTempSelectedSlot(null);
                        setEnableSlotSelection(true);
                      }}
                    >
                      Change
                    </span>
                  )}
              </>
            </div>
          )}
        </>
      ) : (
        <>
          <div
            className={classes.slotBooked}
            onClick={() => enableSlotSelection && setOpenSlots(true)}
          >
            <span className={!enableSlotSelection ? classes.disabled : ''}>
              Pick Slot
            </span>
          </div>
        </>
      )}
    </div>
  );
};
