import axios from 'axios';
import { EXCLUDE_AUTH_HEADER_URLS } from './appConfig';

const redirectUnAuth = function (response) {
  if (response.status >= 401) {
    localStorage.clear();
    window.location.reload();
  }
};

axios.interceptors.request.use(
  function (request) {
    const token = localStorage.getItem('aphToken');
    if (
      token &&
      !EXCLUDE_AUTH_HEADER_URLS.some((url) => request?.url?.includes(url))
    ) {
      request.headers = request.headers || {};
      request.headers.Authorization = token;
      request.headers.languageCode = 'en';
      request.headers.lang_code = 'en';
    }
    return request;
  },
  function requestError(error) {
    // Do something with request error
    return Promise.reject(error);
  }
);
axios.interceptors.response.use(
  function (response) {
    if (response.headers.authorization) {
      localStorage.setItem('aphToken', response.headers.authorization);
    }
    redirectUnAuth(response);
    return response;
  },
  function responseError(error) {
    const { response } = error;
    /**
     * Checks if token is either expired and apis return .
     */
    redirectUnAuth(response);
    // Do something with response error
    return Promise.reject(error);
  }
);

export default axios;
