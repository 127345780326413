import axios from '../../axios';
import { AxiosResponse } from 'axios';
import format from 'date-fns/format';
import addMinutes from 'date-fns/addMinutes';
import compareAsc from 'date-fns/compareAsc';

import {
  DIAGNOSTICS_GROUPPLAN,
  GET_SUBSCRIPTION_STATUS,
  SERVICEABILITY_URL,
  WEB_TDP_URL,
} from '../../appConfig';
import {
  DIAGNOSTIC_ORDER_STATUS,
  Gender,
  phleboChargesV2,
} from '../../types/globalTypes';

export interface allObservations {
  observationName: string | null;
  mandatoryValue: string | null;
  isHeader: string | null;
  PrintOrder: string | null;
}

export interface diagnosticInclusions {
  itemId: number;
  name: string;
  allObservations: (allObservations | null)[] | null;
}

export interface DiagnosticCartItem {
  diagnosticPricing: PricingObject[];
  inclusions: string[];
  itemId: number;
  itemName: string;
  itemType: string;
  patientId: string;
  reportTAT: string;
  itemImageUrl?: string;
  gender?: string;
  testParametersCount: number;
  canonicalTag: string;
  diagnosticInclusions?: (diagnosticInclusions | null)[] | null;
  inclusionIds?: number[];
  observations?: any[];
  packageWorthText?: string;
}

export interface PricingObject {
  couponCode?: string | null;
  endDate: string | null;
  groupPlan: string;
  mrp: number;
  price: number;
  startDate: string | null;
  status: string;
  showDiscountPercent: boolean;
  additionalCircleCashback: AdditionalCircleCashback;
}

export interface AdditionalCircleCashback {
  cashbackType: CASHBACK_TYPE;
  cashbackValue: number;
  cashbackAmount: number;
  offerDescription: string;
}

export enum CASHBACK_TYPE {
  ABSOLUTE = 'ABSOLUTE',
  PERCENT = 'PERCENT',
}

export interface SearchResult {
  diagnostic_inclusions: string[];
  diagnostic_inclusions_test_parameter_data: any[];
  diagnostic_item_alias: string;
  diagnostic_item_alias_names: string;
  diagnostic_item_canonicalTag: string;
  diagnostic_item_collectionType: string;
  diagnostic_item_fromAgeInDays: number;
  diagnostic_item_id: number;
  diagnostic_item_itemRemarks: string;
  diagnostic_item_itemType: string;
  diagnostic_item_name: string;
  diagnostic_item_name_original: string;
  diagnostic_item_price: PricingObject[];
  diagnostic_item_testDescription: string;
  diagnostic_item_testPreparationData: string;
  diagnostic_item_toAgeInDays: number;
  testParametersCount: number;
  testParametersCountWithHeaderLogic: number;
  diagnostic_item_gender: string;
  diagnostic_item_inclusion_ids: number[];
  diagnostic_item_package_worth_text: string;
}
export interface PatientAddress {
  addressLine1: string;
  addressLine2: string;
  addressType: string;
  city: string;
  id: string;
  landmark: string;
  latitude: number | null;
  longitude: number | null;
  mobileNumber: string;
  otherAddressType: string;
  state: string;
  zipcode: string;
}

export interface NewProfile {
  firstName: string;
  lastName: string;
  mobileNumber: string;
  gender: string;
  emailAddress: string;
  dateOfBirth: string;
  relation: string;
  source: string;
  photoUrl?: string;
}
export interface PatientProfile {
  dateOfBirth: string;
  emailAddress: string;
  firstName: string;
  gender: string;
  id: string;
  lastName: string;
  mobileNumber: string;
  relation: string;
  uhid: string;
  lastBooked: Date;
}

export interface DiscountItem {
  testId: number;
  categoryId: string;
  mrp: number;
  specialPrice: number;
  quantity: number;
  discountAmt: number;
  onMrp: boolean;
  couponFree: number;
  applicable: boolean;
}

export interface ValidatePayload {
  mobile: string;
  billAmount: number;
  coupon: string;
  pinCode: string;
  diagnostics: CouponDiagnosticItem[];
  packageIds: string[];
}

export interface CouponDiagnosticItem {
  categoryId: string;
  testId: number;
  mrp: number;
  specialPrice: number;
  quantity: number;
  type: string;
}

// export interface CartObject {
//   itemId: number;
//   quantity: number;
//   price: number;
//   groupPlan: string;
//   discAmount: number;
//   mrp: number;
//   groupPlanDiscAmount: number;
// }

// export interface LineItemsSlot {
//   mrp: number;
//   price: number;
//   groupPlan: string;
//   itemId: number;
// }

export interface LineItemsObject {
  itemName?: string;
  priceBeforeDiscount?: number;
  mrp: number;
  price: number;
  groupPlan: string;
  itemId: number;
  couponDiscAmount: number;
  isCouponOnMrp?: boolean;
  packageWorthText?: string;
  groupPlanDiscAmount: number;
  reportTAT?: string;
  testParametersCount?: number;
  circleCashback?: number;
  gender?: Gender;
}
export interface PatientObjWithLineItems {
  patientID: string;
  lineItems: LineItemsObject[];
  totalPrice: number;
  diagnosticOrdersId?: number;
}

export interface SlotDetail {
  internalSlots: string[];
  slotDisplayTime: string;
}

export interface SlotObject {
  isPaidSlot: boolean;
  slotDetail: SlotDetail;
}

export interface SlotObjectWithDate {
  isPaidSlot: boolean;
  slotDetail: SlotDetail;
  slotDate: string;
}

export enum PlanPurchaseType {
  first_time = 'first_time',
  renew = 'renew',
}

export interface SourceMetaData {
  source_identifier?: string | null;
  sub_plan_id?: string | null;
  purchase_type?: PlanPurchaseType | null;
  activity?: any | null;
}
export interface CreateUserSubscriptionInput {
  plan_id: string;
  sub_plan_id?: string | null;
  source_meta_data?: SourceMetaData | null;
  transaction_date_time?: any | null;
  amount?: number | null;
}

export interface DiagnosticOrderInput {
  allowHCWaiveOffForCirclePurchase: boolean;
  couponCode: string;
  patientAddressID: string;
  patientObjWithLineItems: PatientObjWithLineItems[];
  selectedDate: string;
  slotInfo: {
    paidSlot: boolean;
    slotDetails: SlotDetail;
  };
  serviceability: {
    cityID: number;
    stateID: number;
  };
  collectionCharges?: {
    charges: number;
    distanceCharges: number;
    couponDiscApplied?: boolean;
  };
  collectionChargesV2?: phleboChargesV2;
  bookingSource: string;
  deviceType: string;
  userSubscriptionId: string;
  subscriptionInclusionId: string;
  userSubscription?: CreateUserSubscriptionInput;
  storeId: string;
  isNonCartFlow?: boolean;
  slotByClusterConfig?: boolean;
  useMrp?: boolean;
  doctorName?: string;
}

export interface FinalCartItemsToDisplay {
  itemId: number;
  itemName: string;
  count: number;
  priceToDisplay: number;
  reportTAT?: string;
}

export interface CartDiscountObj {
  cartSavings: number;
  circleSavings: number;
  circleCashback: number;
  totalDiscount: number;
  toPay: number;
  subTotal: number;
}

export interface ValidateResponseDiagnostic {
  testId: number;
  type: string;
  categoryId: string;
  mrp: number;
  specialPrice: number;
  quantity: number;
  discountAmt: number;
  onMrp: boolean;
  couponFree: number;
  applicable: boolean;
  freeHomeCollection: boolean;
}

export interface ValidateResponse {
  mobile: string;
  email?: string;
  coupon: string;
  billAmount: number;
  paymentType?: string;
  pinCode: string;
  packageId?: string;
  packageIds: any[];
  diagnostics: ValidateResponseDiagnostic[];
  maxFreeProducts: number;
  freeDelivery: boolean;
  circleBenefits: boolean;
  discount: number;
  valid: boolean;
  reason: string;
  successMessage: string;
  textOffer: boolean;
}

export interface GetCouponsResponse<T> {
  errorCode: number;
  errorMsg: string | null;
  errorType: string;
  response: T;
}

export interface itemLevelReportTATObject {
  itemId: number;
  preOrderReportTATMessage: string;
  reportTATInUTC: string;
  reportTATMessage: string;
}

export type PlanSummary = {
  available_for_trial?: boolean;
  benefits_worth: string;
  currentSellingPrice: number;
  defaultPack: boolean;
  default_pack?: boolean;
  displayOnUI: boolean;
  durationInMonth: number;
  icon: string;
  price: number;
  renew_price: number;
  special_price_enabled: boolean;
  starter_pack?: boolean;
  subPlanId: string;
  valid_duration: number;
};

const imageFileNames = ['png', 'jpg', 'jpeg'];
const documentFileNames = ['pdf'];
const acceptedFilesNamesForFileUpload = [
  ...imageFileNames,
  ...documentFileNames,
];
const MAX_FILE_SIZE_FOR_UPLOAD = 5000000;
const INVALID_FILE_SIZE_ERROR =
  'Invalid File Size. File size must be less than 5MB';
const INVALID_FILE_TYPE_ERROR =
  'Invalid File Extension. Only files with .jpg, .png or .pdf extensions are allowed.';
const toBase64 = (file: any) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = (error) => reject(error);
  });
const isMobileNumberValid = (number: string) => /^\d{10}$/.test(number);

export const pinCodeServiceabilityApi = (
  latitude: number,
  longitude: number
): Promise<AxiosResponse> => {
  return axios.post(`${SERVICEABILITY_URL}`, {
    latitude,
    longitude,
  });
};
export {
  toBase64,
  INVALID_FILE_TYPE_ERROR,
  INVALID_FILE_SIZE_ERROR,
  MAX_FILE_SIZE_FOR_UPLOAD,
  acceptedFilesNamesForFileUpload,
  isMobileNumberValid,
};

export const isEmailValid = (text: string) => {
  let reg = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/;
  if (reg.test(text) === false) {
    return false;
  } else {
    return true;
  }
};

const getDiscount = (testsObj: any, getActualDiscount?: boolean) => {
  const discountPrice = testsObj.mrp - testsObj.price;
  if (getActualDiscount) {
    return discountPrice;
  } else if (discountPrice > 0) {
    return Math.round((discountPrice / testsObj.mrp) * 100);
  } else {
    return 0;
  }
};

export const getTestsCirclePrice = (
  diagnosticPricing: any,
  circleBenefitsApplied: boolean
) => {
  let availablePackages = diagnosticPricing?.filter((pricing: any) => {
    let available: boolean = true;

    if (pricing.status && pricing.status.toLowerCase().trim() !== 'active') {
      available = false;
    } else if (!pricing.groupPlan) {
      available = false;
    }
    return available;
  });
  const testsPriceObj = {
    price: 0,
    mrp: 0,
    discountPercentage: 0,
    couponCode: '',
    groupPlan: '',
    priceBeforeDiscount: 0,
    nonCircleMrp: 0,
    nonCirclePrice: 0,
    nonCirclePlan: '',
    isCircleDiscountGreater: false,
    cashbackText: '',
    cashbackAmount: 0,
    showDiscountPercent: false,
  };
  if (!availablePackages || availablePackages.length === 0) {
    return testsPriceObj;
  } else {
    const promotedPackage = availablePackages.reduce(
      (previousPackage, currentPackage) => {
        const currDiscount = currentPackage
          ? getDiscount(currentPackage, true)
          : 0;
        const prevDiscount = previousPackage
          ? getDiscount(previousPackage, true)
          : 0;

        if (currDiscount > prevDiscount) {
          return currentPackage;
        } else if (
          currDiscount === prevDiscount &&
          currentPackage?.groupPlan === DIAGNOSTICS_GROUPPLAN.special
        ) {
          return currentPackage;
        } else {
          return previousPackage ? previousPackage : currentPackage;
        }
      },
      null
    );

    if (promotedPackage) {
      // Add cashback from promoted package
      const cashbackAmount = promotedPackage?.additionalCircleCashback
        ?.cashbackAmount
        ? Math.round(promotedPackage?.additionalCircleCashback?.cashbackAmount)
        : 0;
      testsPriceObj.cashbackAmount = cashbackAmount;
      testsPriceObj.cashbackText = cashbackAmount
        ? `₹${cashbackAmount} cashback`
        : '';
      testsPriceObj.showDiscountPercent = promotedPackage.showDiscountPercent;
      testsPriceObj.discountPercentage = getDiscount(promotedPackage);
      testsPriceObj.couponCode = promotedPackage.couponCode;
      testsPriceObj.groupPlan = promotedPackage.groupPlan;
      if (promotedPackage.groupPlan === DIAGNOSTICS_GROUPPLAN.circle) {
        const splPrice = availablePackages.find(
          (pricing: any) => pricing.groupPlan === DIAGNOSTICS_GROUPPLAN.special
        );
        const allPrice = availablePackages.find(
          (pricing: any) => pricing.groupPlan === DIAGNOSTICS_GROUPPLAN.all
        );
        if (circleBenefitsApplied) {
          testsPriceObj.isCircleDiscountGreater = true;
          testsPriceObj.mrp = promotedPackage.mrp;
          testsPriceObj.price = promotedPackage.price;
          testsPriceObj.priceBeforeDiscount = promotedPackage.price;
          if (splPrice) {
            testsPriceObj.nonCircleMrp = splPrice?.mrp;
            testsPriceObj.nonCirclePrice = splPrice?.price;
            testsPriceObj.nonCirclePlan = DIAGNOSTICS_GROUPPLAN.special;
          } else {
            testsPriceObj.nonCircleMrp = allPrice?.mrp;
            testsPriceObj.nonCirclePrice = allPrice?.price;
            testsPriceObj.nonCirclePlan = DIAGNOSTICS_GROUPPLAN.all;
          }
        } else {
          if (splPrice) {
            testsPriceObj.mrp = splPrice.mrp;
            testsPriceObj.price = splPrice.price;
            testsPriceObj.priceBeforeDiscount = splPrice.price;
            testsPriceObj.discountPercentage = getDiscount(splPrice);
            testsPriceObj.groupPlan = splPrice.groupPlan;
          } else {
            testsPriceObj.mrp = allPrice.mrp;
            testsPriceObj.price = allPrice.price;
            testsPriceObj.priceBeforeDiscount = allPrice.price;
            testsPriceObj.discountPercentage = getDiscount(allPrice);
            testsPriceObj.groupPlan = allPrice.groupPlan;
          }
        }
      } else {
        testsPriceObj.mrp = promotedPackage.mrp;
        testsPriceObj.price = promotedPackage.price;
        testsPriceObj.priceBeforeDiscount = promotedPackage.price;
      }
    }
  }
  return testsPriceObj;
};

export const getObservationCount = (testObservation: any) => {
  const totalObservation =
    Array.isArray(testObservation) &&
    testObservation.reduce(
      (count, row) => count + getActiveInclusions(row?.allObservations),
      0
    );
  return totalObservation || 1;
};

export const getActiveInclusions = (testObservation): number => {
  const activeObservationsArr =
    (Array.isArray(testObservation) &&
      testObservation?.reduce((totalCount, observation) => {
        if (observation?.isHeader === 'No') {
          totalCount += 1;
        }
        return totalCount;
      }, 0)) ||
    0;
  return activeObservationsArr || 1;
};

export const hasLowerCase = (str: string) => /[a-z]/.test(str);

export const toTitleCase = (str: string) => {
  return str?.length
    ? str
        .toLowerCase()
        .split(' ')
        .map((word: string) => {
          return word.charAt(0).toUpperCase() + word.slice(1);
        })
        .join(' ')
    : str;
};

export const removeLeadingAndMultipleSpaces = (val: string) => {
  return val?.length ? val?.replace(/^\s+|\s+(?=\s)/g, '').slice(0, 60) : val;
};

export const getCircleSubscription = (userPhoneNumber: string) => {
  return axios.post(GET_SUBSCRIPTION_STATUS, {
    mobile_number: '+91' + userPhoneNumber,
    status: ['active', 'deferred_active'],
  });
};

export const getPatientAge = (dob: string): number => {
  if (dob) {
    const date1 = new Date();
    const date2 = new Date(dob);
    const diffTime = Math.abs(date1.getTime() - date2.getTime());
    const patientAge = Math.floor(diffTime / (1000 * 60 * 60 * 24 * 365));
    return patientAge;
  }
  return 0;
};

export const defaultNewUserData: NewProfile = {
  firstName: '',
  lastName: '',
  mobileNumber: '',
  gender: '',
  emailAddress: '',
  dateOfBirth: '',
  relation: '',
  source: 'ORDER_PUNCHING_TOOL',
};

export const dummyNewAddress = {
  addressLine1: '',
  addressLine2: '',
  addressType: '',
  city: '',
  id: '',
  landmark: '',
  latitude: null,
  longitude: null,
  mobileNumber: '',
  otherAddressType: '',
  state: '',
  zipcode: '',
};

export const SLOT_DATE_FORMAT_FOR_API = 'yyyy-MM-dd';

export const sanitizeLineItems = (
  patientLineItems: PatientObjWithLineItems[],
  selectedNonCartFlowOrder?: any
): PatientObjWithLineItems[] => {
  return JSON.parse(JSON.stringify(patientLineItems)).reduce(
    (
      pItemArr: PatientObjWithLineItems[],
      currentPatientItem: PatientObjWithLineItems
    ) => {
      if (
        selectedNonCartFlowOrder &&
        selectedNonCartFlowOrder?.patientId === currentPatientItem?.patientID
      ) {
        currentPatientItem.diagnosticOrdersId = selectedNonCartFlowOrder?.id;
      }
      currentPatientItem.lineItems = currentPatientItem.lineItems?.map(
        (lineItem) => {
          const {
            price,
            groupPlan,
            itemId,
            couponDiscAmount,
            groupPlanDiscAmount,
            mrp,
          } = lineItem;
          return {
            mrp,
            price,
            groupPlan,
            itemId,
            couponDiscAmount,
            groupPlanDiscAmount,
          };
        }
      );
      return [...pItemArr, currentPatientItem];
    },
    []
  );
};

export const extractItemPricesForGroupPlan = (
  cartItems: DiagnosticCartItem[],
  circleBenefitsApplied: boolean,
  groupPlan?: string
): LineItemsObject[] => {
  return cartItems?.map((item) => {
    const itemsPriceAndPlan = {
      itemId: 0,
      quantity: 0,
      price: 0,
      groupPlan: '',
      couponDiscAmount: 0,
      isCouponOnMrp: false,
      mrp: 0,
      groupPlanDiscAmount: 0,
      circleCashback: 0,
    };
    let pricingObj;
    if (groupPlan) {
      pricingObj = item.diagnosticPricing.find(
        (pricing: PricingObject) => pricing.groupPlan === groupPlan
      );
    } else {
      pricingObj = getTestsCirclePrice(
        item?.diagnosticPricing,
        circleBenefitsApplied
      );
    }
    if (pricingObj) {
      itemsPriceAndPlan.itemId = item.itemId;
      itemsPriceAndPlan.quantity = 1;
      itemsPriceAndPlan.price = pricingObj.price;
      itemsPriceAndPlan.groupPlan = pricingObj.groupPlan;
      itemsPriceAndPlan.mrp = pricingObj.mrp;
      itemsPriceAndPlan.circleCashback = pricingObj.cashbackAmount;
    }
    return itemsPriceAndPlan;
  });
};

export const couponPricingAccumulator: CartDiscountObj = {
  cartSavings: 0,
  circleSavings: 0,
  circleCashback: 0,
  totalDiscount: 0,
  toPay: 0,
  subTotal: 0,
};

export type GooglePlacesType =
  | 'premise'
  | 'neighborhood'
  | 'street_number'
  | 'route'
  | 'sublocality_level_3'
  | 'sublocality_level_2'
  | 'sublocality_level_1'
  | 'postal_code'
  | 'locality'
  | 'administrative_area_level_2'
  | 'administrative_area_level_1'
  | 'country';

export const findAddrComponents = (
  proptoFind: GooglePlacesType,
  addrComponents: {
    long_name: string;
    short_name: string;
    types: GooglePlacesType[];
  }[],
  state?: string
) => {
  const findItem = addrComponents.find(
    (item) => item.types.indexOf(proptoFind) > -1
  );
  return findItem
    ? state
      ? findItem.long_name || findItem.short_name
      : findItem.short_name || findItem.long_name
    : '';
};

export const getWebTdpUrl = (cTag) => (cTag ? `${WEB_TDP_URL}${cTag}` : ``);

export const downloadFile = (url: any) => {
  const name = getFileInfo(url)?.filename ?? 'NA';
  fetch(url)
    .then((resp) => resp.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = name;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    })
    .catch((e) => console.log('error occured while download file', e));
};

export const isImageFile = (url: string) =>
  imageFileNames.includes(getFileInfo(url)?.fileExt);

export const isDocumentFile = (url: string) =>
  documentFileNames.includes(getFileInfo(url)?.fileExt);

export const getFileInfo = (url: string) => ({
  filename: url.split('/').pop(),
  fileExt: url?.split('.')?.pop(),
});
export const getSlotDuration = (
  slotDateTimeInUTC: string,
  slotDurationInMinutes: number
): string => {
  return `${format(new Date(slotDateTimeInUTC), 'h:mm a')} - ${format(
    addMinutes(new Date(slotDateTimeInUTC), slotDurationInMinutes),
    'h:mm a'
  )}`.toLowerCase();
};

export const getAgentEmail = (): string => {
  const getOktaTokenStorage =
    localStorage.getItem('okta-token-storage') &&
    JSON.parse(localStorage.getItem('okta-token-storage'));
  return getOktaTokenStorage?.idToken?.claims?.email || 'NA';
};

export const getDiagnosticStatus = (
  status: DIAGNOSTIC_ORDER_STATUS,
  isInclusion?: boolean
) => {
  let statusString = '';
  switch (status) {
    case DIAGNOSTIC_ORDER_STATUS.ORDER_INITIATED:
      return 'Order Initiated';
    case DIAGNOSTIC_ORDER_STATUS.ORDER_CANCELLED:
      return 'Order Cancelled';
    case DIAGNOSTIC_ORDER_STATUS.ORDER_FAILED:
      return 'Order Failed';
    case DIAGNOSTIC_ORDER_STATUS.ORDER_PLACED:
      return 'Order Placed';
    case DIAGNOSTIC_ORDER_STATUS.PAYMENT_PENDING:
      return 'Payment Pending';
    case DIAGNOSTIC_ORDER_STATUS.PICKUP_CONFIRMED:
      return 'Pickup Confirmed';
    case DIAGNOSTIC_ORDER_STATUS.PICKUP_REQUESTED:
      return 'Order Confirmed';
    case DIAGNOSTIC_ORDER_STATUS.REPORT_GENERATED:
      return 'Report Generated';
    case DIAGNOSTIC_ORDER_STATUS.SAMPLE_COLLECTED:
    case DIAGNOSTIC_ORDER_STATUS.SAMPLE_RECEIVED_IN_LAB:
    case DIAGNOSTIC_ORDER_STATUS.SAMPLE_SUBMITTED:
    case DIAGNOSTIC_ORDER_STATUS.SAMPLE_COLLECTED_IN_LAB:
    case DIAGNOSTIC_ORDER_STATUS.SAMPLE_TESTED:
    case DIAGNOSTIC_ORDER_STATUS.SAMPLE_NOT_COLLECTED_IN_LAB:
      return isInclusion ? 'Sample Submitted' : 'Samples Received for Testing';
    case DIAGNOSTIC_ORDER_STATUS.PAYMENT_FAILED:
      return 'Payment Failed';
    case DIAGNOSTIC_ORDER_STATUS.PAYMENT_SUCCESSFUL:
      return 'Payment Successful';
    case DIAGNOSTIC_ORDER_STATUS.PHLEBO_COMPLETED:
      return 'Sample Collected';
    case DIAGNOSTIC_ORDER_STATUS.PHLEBO_CHECK_IN:
      return 'Apollo agent Check-in';
    case DIAGNOSTIC_ORDER_STATUS.ORDER_RESCHEDULED:
    case DIAGNOSTIC_ORDER_STATUS.ORDER_RESCHEDULED_REQUEST:
      return 'Order Rescheduled';
    case DIAGNOSTIC_ORDER_STATUS.SAMPLE_REJECTED_IN_LAB:
      return 'Sample Rejected';
    case DIAGNOSTIC_ORDER_STATUS.ORDER_COMPLETED:
      return 'Order Completed';
    case DIAGNOSTIC_ORDER_STATUS.PARTIAL_ORDER_COMPLETED:
      return 'Partial Order Completed';
    case DIAGNOSTIC_ORDER_STATUS.REFUND_INITIATED:
      return 'Partial Refund Initiated';
    case DIAGNOSTIC_ORDER_STATUS.NEW_LEAD:
      return 'Prescription Uploaded';
    case DIAGNOSTIC_ORDER_STATUS.LEAD_DROPPED:
      return 'Order Cancelled';
    case DIAGNOSTIC_ORDER_STATUS.CANCELLATION_REQUESTED:
      return 'Cancellation Requested';
    default:
      return statusString;
  }
};

const types = [
  {
    extensions: ['txt', 'text', 'conf', 'def', 'list', 'log', 'in', 'ini'],
    mime: 'text/plain',
  },
  {
    extensions: ['pdf'],
    mime: 'application/pdf',
  },
  {
    extensions: ['jpeg', 'jpg', 'jpe'],
    mime: 'image/jpeg',
  },
  {
    extensions: ['png'],
    mime: 'image/png',
  },
];

const extname = function (path: string) {
  if (!path || path.indexOf('.') === -1) {
    return '';
  } else {
    return path.split('.').pop()!.toLowerCase();
  }
};

export const mimeType = (file: string) => {
  let t = types.find((item) => item.extensions.includes(extname(file)));
  return (t && t.mime) || '*/*';
};

export const isNumber = (input: string) => {
  return /^\d+$/.test(input);
};

export const allowCopyPaste = (input: string) => {
  const phNumberSign = input?.[0];
  return phNumberSign === '+' && isNumber(input?.slice(1));
};

export const reportTATFormat = (date: string) => {
  return format(new Date(date), 'iii, do MMM, hh:mm aa');
};

export const createMappedDataForInclusions = (allObservations) => {
  const newList = [];
  allObservations?.map((observation) => {
    if (
      observation?.isHeader?.toLowerCase() === 'yes' ||
      !newList?.some(({ isHeader }) => isHeader?.toLowerCase() === 'yes')
    ) {
      newList?.push(observation);
    } else if (newList?.[newList?.length - 1]?.sub) {
      const subList = newList?.[newList?.length - 1]?.sub;
      const alreadyExists = subList?.find(
        (item) =>
          item?.observationName?.toLowerCase() ===
          observation?.observationName?.toLowerCase()
      );
      if (!alreadyExists) {
        newList?.[newList?.length - 1]?.sub?.push(observation);
      }
    } else {
      newList[newList.length - 1].sub = [observation];
    }
  });
  return newList;
};

export const updateModifyOrderPrice = (order) => {
  const diagnosticOrderLineItems = order?.diagnosticOrderLineItems?.map(
    (lineItem) => {
      const newPricingObj = lineItem?.pricingObj?.map((priceObj) => {
        if (priceObj.groupPlan !== 'ALL') {
          if (
            lineItem?.itemObj?.isOnMrpCoupon &&
            priceObj?.mrp === lineItem?.price + lineItem?.couponDiscAmount
          ) {
            return {
              ...priceObj,
              status: 'active',
            };
          } else if (
            priceObj?.price !==
            lineItem?.price + lineItem?.couponDiscAmount
          ) {
            return {
              ...priceObj,
              status: 'inactive',
            };
          }
          return {
            ...priceObj,
            status: 'active',
          };
        }
        return {
          ...priceObj,
          status: 'active',
        };
      });
      return {
        ...lineItem,
        pricingObj: newPricingObj,
      };
    }
  );
  return {
    ...order,
    diagnosticOrderLineItems: diagnosticOrderLineItems,
  };
};

export function fallbackCopyTextToClipboard(text: string) {
  var textArea = document.createElement('textarea');
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    var successful = document.execCommand('copy');
    var msg = successful ? 'successful' : 'unsuccessful';
    console.log('Fallback: Copying text command was ' + msg);
  } catch (err) {
    console.error('Fallback: Oops, unable to copy', err);
  }

  document.body.removeChild(textArea);
}
export function copyTextToClipboard(text: string) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
  }
  navigator.clipboard.writeText(text).then(
    function () {
      console.log('Async: Copying to clipboard was successful!');
    },
    function (err) {
      console.error('Async: Could not copy text: ', err);
    }
  );
}
export const getPackageObservationCount = (
  diagnosticInclusions: diagnosticInclusions[]
) => {
  const totalObservation = diagnosticInclusions?.reduce((count, row) => {
    return count + getActiveInclusions(row?.allObservations);
  }, 0);
  return totalObservation || 1;
};

export const parseStatus = (status: any, res: any): any => {
  return new Promise((resolve, reject) => {
    if (status >= 200 && status < 300) {
      if (res.url.indexOf('.html') > -1) {
        return resolve(res);
      }
      res.json().then((response: any) => resolve(response));
    } else {
      res.json().then((response: any) => reject(response));
    }
  });
};

export const isCacheDataExpired = (date: string) => {
  return compareAsc(new Date(date), new Date()) === -1 ? true : false;
};

export const isBrowser = () => typeof window !== 'undefined';

export const roundOff = (num: number, decimalPlaces: number = 0) =>
  Number(num?.toFixed(decimalPlaces));
