import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import ButtonBase from '@material-ui/core/ButtonBase';
import Link from '@material-ui/core/Link';
import { useOrderContext } from '../OrderProvider';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      '& header': {
        zIndex: 9,
      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
    header: {
      background: '#fff',
      padding: '10px 20px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    logo: {
      padding: '0 30px 0 0',
      borderRight: '1px solid rgba(0,0,0,0.1)',
      [theme.breakpoints.down('xs')]: {
        borderRight: 'unset',
      },
    },
    user: {
      cursor: 'pointer',
      position: 'relative',
    },
    logout: {
      padding: '12px 20px',
      position: 'absolute',
      borderRadius: 5,
      right: 0,
      background: '#000',
    },
    pageHead: {
      margin: 0,
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '18px',
      lineHeight: '23px',
      color: 'black',
      alignSelf: 'center',
      [theme.breakpoints.down('xs')]: {
        paddingBottom: 0,
        paddingRight: 10,
        fontSize: 15,
      },
    },
    prescriptionOrders: {
      margin: 'auto',
    },
  })
);

export default function ButtonAppBar() {
  const {
    isNonCartFlowActivated,
    selectedNonCartFlowOrder,
    radiologyTest,
    closePopupPrescription,
    setShouldResetDashboard,
    setSelectedNonCartFlowOrder,
    setRadiologyTest,
    setClosePopupPrescription,
    showModifyFlow,
    modifyFlowOrder,
  } = useOrderContext();
  const classes = useStyles();
  const [show, setShow] = React.useState<boolean>(false);

  const handleLogout = () => {
    localStorage.clear();
    window.location.reload();
  };

  const getHeadingTitle = () => {
    if (showModifyFlow || modifyFlowOrder) {
      return 'MODIFY ORDER';
    }
    if (isNonCartFlowActivated || radiologyTest) {
      if (selectedNonCartFlowOrder) {
        return 'PRESCRIPTION ORDER';
      }
      return radiologyTest
        ? 'RADIOLOGY TESTS BOOKING'
        : 'DIAGNOSTIC PRESCRIPTION ORDERS';
    }
    return 'BACKEND PUNCHING TOOL (Diagnostics)';
  };

  const handleGoBack = () => {
    closePopupPrescription && setClosePopupPrescription(false);
    if (selectedNonCartFlowOrder || radiologyTest) {
      setSelectedNonCartFlowOrder(null);
      setRadiologyTest(false);
    } else {
      setShouldResetDashboard(true);
    }
  };

  return (
    <div className={classes.root}>
      <AppBar position="sticky">
        <Toolbar className={classes.header}>
          {isNonCartFlowActivated ||
          radiologyTest ||
          showModifyFlow ||
          modifyFlowOrder ? (
            <ButtonBase onClick={() => handleGoBack()}>
              <img src={require('../images/ic_arrow-left.png')} alt="Logo" />
            </ButtonBase>
          ) : (
            <Link
              className={classes.logo}
              onClick={() => window.location.reload()}
            >
              <img src={require('../images/logo.png')} alt="Logo" />
            </Link>
          )}
          <h1 className={`${classes.pageHead} ${classes.prescriptionOrders}`}>
            {getHeadingTitle()}
          </h1>
          {!isNonCartFlowActivated && !radiologyTest && (
            <div onClick={() => setShow(!show)}>
              <div className={classes.user}>
                <img src={require('../images/profile.png')} alt="profile" />
                {show && (
                  <div
                    className={classes.logout}
                    onClick={() => handleLogout()}
                  >
                    Logout
                  </div>
                )}
              </div>
            </div>
          )}
        </Toolbar>
      </AppBar>
    </div>
  );
}
