import CircularProgress from '@material-ui/core/CircularProgress';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import makeStyles from '@material-ui/styles/makeStyles';
import axios from '../axios';
import format from 'date-fns/format';
import React, { useState } from 'react';
import { useOrderContext } from '../OrderProvider';
import AphButton from './AphButton';
import AphDialog from './AphDialog';
import AphDialogClose from './AphDialogClose';
import AphDialogTitle from './AphDialogTitle';
import AphTextField from './AphTextField';
import { getDiagnosticStatus } from './helpers/commonHelpers';
import formatDate from 'date-fns/format';
import {
  DIAGNOSTIC_USER_ACCESS,
  DIAG_NON_CART_FLOW_MESSAGES,
  GET_CANCELLATION_REASONS,
  GET_PRESCRIPTION_ORDER_DETAILS,
  UPDATE_ORDER_STATUS,
} from '../appConfig';
import { DIAGNOSTIC_ORDER_STATUS } from '../types/globalTypes';

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      backgroundColor: theme.palette.common.white,
      boxShadow: ' 0px 5px 20px rgba(128, 128, 128, 0.3)',
      borderRadius: 10,
      padding: 10,
      display: 'flex',
      marginBottom: 20,
      border: 'solid 1px transparent',
      position: 'relative',
    },
    itemName: {
      display: 'flex',
      fontSize: 14,
      fontWeight: 500,
      color: '#02475b',
      [theme.breakpoints.down('xs')]: {
        fontSize: 13,
      },
    },
    orderRow: {
      position: 'relative',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'row',
        paddingRight: 0,
      },
    },
    orderListingId: {
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '20px',
      '& span': {
        display: 'block',
        fontSize: 12,
        color: '#658F9B',
        fontWeight: 'normal',
        paddingTop: 4,
      },
      '& p': {
        margin: 0,
        fontStyle: 'italic',
        fontSize: '12px',
        fontWeight: 'normal',
        lineHeight: '16px',
        paddingTop: '4px',
      },
    },
    topCol: {
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'row',
      width: '100%',
      flexWrap: 'wrap',
      [theme.breakpoints.down('xs')]: {
        paddingRight: 10,
      },
    },
    orderInfo: {
      background: '#FFF0B3',
      borderRadius: 10,
      padding: '5px 12px',
      fontSize: 11,
      fontWeight: 500,
    },
    testIncluded: {
      background: '#F9F9F9',
      borderRadius: 10,
      '& ul': {
        margin: 0,
        padding: '2px 13px 2px 25px',
        width: 250,
        position: 'relative',
        '& li': {
          margin: 0,
          padding: '5px 0px',
          color: '#007C9D',
          fontSize: 13,
          fontWeight: 500,
          textTransform: 'capitalize',
          '& em': {
            fontSize: 10,
            background: '#4CAF50',
            fontStyle: 'normal',
            color: '#fff',
            marginLeft: '5px',
            padding: '2px 5px',
          },
          '&:nth-child(2)': {
            width: '80%',
          },
          '&:last-of-type': {
            width: '80%',
          },
        },
        '&>span': {
          display: 'block',
          textAlign: 'right',
          color: '#FC9916',
          fontWeight: 'bold',
          fontSize: 12,
          position: 'absolute',
          bottom: 7,
          right: 8,
          cursor: 'pointer',
        },
      },
    },
    hideList: {
      '&:nth-child(n+3)': {
        display: 'none',
      },
    },
    timing: {
      paddingTop: 6,
      fontSize: 12,
      fontWeight: 400,
    },
    cardSection1: {
      '& div': {
        '&:nth-child(2)': {
          marginTop: 14,
        },
      },
      [theme.breakpoints.down('xs')]: {
        order: 1,
      },
    },
    cardSection2: {
      [theme.breakpoints.down('xs')]: {
        order: 3,
        flexBasis: '100%',
        padding: '30px 0px',
      },
    },
    cardSection3: {
      [theme.breakpoints.down('xs')]: {
        order: 4,
      },
    },
    cardSection5: {
      [theme.breakpoints.down('xs')]: {
        order: 2,
      },
    },
    cancelTitle: {
      padding: '16px 12px',
      '& h2': {
        fontSize: 14,
        fontWeight: 500,
      },
    },
    cancellationReasons: {
      maxHeight: 300,
      overflow: 'auto',
      '&>ul': {
        margin: 0,
        padding: 0,
        '&>li': {
          cursor: 'pointer',
          listStyleType: 'none',
          padding: '12px 20px',
          borderBottom: '1px solid rgba(0,0,0,0.1)',
          fontSize: 13,
          fontWeight: 500,
          '&>div': {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            '&>div': {
              maxWidth: '70%',
            },
          },
          '&:last-child': {
            borderBottom: 'unset',
          },
        },
      },
    },
    loader: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center !important',
      minHeight: 45,
    },
    selectedReason: {
      background: '#00B38E',
      color: '#fff',
    },
    customReason: {
      margin: 8,
    },
    footer: {
      padding: '30px 42px',
      '&>button': {
        width: '100%',
        borderRadius: 10,
      },
      '& .MuiButton-root.Mui-disabled': {
        opacity: 0.5,
      },
    },
    orderCancelled: {
      padding: '42px 28px',
      textAlign: 'center',
      '&>div': {
        fontSize: 16,
        fontWeight: 700,
        paddingTop: 10,
      },
      '&>p': {
        color: '#1084A9',
        fontWeight: 400,
        fontSize: 12,
        margin: 0,
        paddingTop: 6,
      },
      '&>button': {
        width: '100%',
        borderRadius: 10,
        marginTop: 50,
      },
    },
    prescriptionOrder: {
      display: 'flex',
      marginTop: '35px',
    },
    orderStatus: {
      color: '#FC9916',
      fontWeight: 'bolder',
      paddingRight: '25px',
      fontSize: '14px',
      cursor: 'pointer',
    },
    actions: {
      display: 'flex',
      justifyContent: 'flex-end',
      width: '100%',
      '& button': {
        maxWidth: 140,
        fontSize: 13,
        color: '#FCA317 !important',
        padding: 8,
        boxShadow: 'none',
        fontWeight: 'bold',
        '&:disabled': {
          opacity: 0.5,
        },
      },
    },
  };
});

interface OrderCardProps {
  order: any;
  handleModifyOrder?: (order: any) => void;
  handleOrderCancel?: (order: any) => void;
}

const OrderCard: React.FC<OrderCardProps> = ({
  order,
  handleModifyOrder,
  handleOrderCancel,
}) => {
  const classes = useStyles({});
  const {
    userPhoneNumber,
    setIsNonCartFlowActivated,
    selectedNonCartFlowOrder,
    setSelectedNonCartFlowOrder,
    setClosePopupPrescription,
    userAccess,
  } = useOrderContext();
  const [ordersShowingAllItems, setOrdersShowingAllItems] = useState<string[]>(
    []
  );
  const [showCancellationReasonDialog, setShowCancellationReasonDialog] =
    useState<boolean>(false);

  const [isCancellationLoading, setIsCancellationLoading] =
    useState<boolean>(false);
  const [cancellationReasons, setCancellationReasons] = useState<string[]>([]);
  const [selectedReasonIndex, setSelectedReasonIndex] = useState<number>(-1);
  const [cancellationReason, setCancellationReason] = useState<string>('');
  const [showOrderCancelDialog, setShowOrderCancelDailog] =
    useState<boolean>(false);

  const handleNonCartOrderCancel = (order: any) => {
    setIsCancellationLoading(true);
    setSelectedNonCartFlowOrder(order);
    axios
      .get(GET_CANCELLATION_REASONS)
      .then(({ data }) => {
        setCancellationReasons(data?.reasons);
        setShowCancellationReasonDialog(true);
      })
      .catch((err) => {
        console.error('Error while fetching reasons', err);
      })
      .finally(() => {
        setIsCancellationLoading(false);
      });
  };

  const submitCancelOrder = () => {
    const reason =
      selectedReasonIndex === cancellationReasons?.length - 1
        ? cancellationReason
        : cancellationReasons?.[selectedReasonIndex];
    const payload = {
      diagnosticOrdersId: selectedNonCartFlowOrder?.id,
      reason: reason,
      mobileNumber: '+91' + userPhoneNumber,
    };
    axios
      .post(UPDATE_ORDER_STATUS, payload)
      .then(({ data }) => {
        if (data?.status) {
          setShowOrderCancelDailog(true);
        }
      })
      .catch((err) => {
        console.error('Error while fetching reasons', err);
      })
      .finally(() => {
        setShowCancellationReasonDialog(false);
        setCancellationReason('');
        setSelectedReasonIndex(-1);
      });
  };

  const handleOrderCancelSuccess = () => {
    setSelectedNonCartFlowOrder(null);
    setIsNonCartFlowActivated(true);
    setShowOrderCancelDailog(false);
  };

  const placeNewOrder = (order: any) => {
    getPrescriptionOrderDetails(order?.id, userPhoneNumber);
  };

  const getPrescriptionOrderDetails = (orderId, phoneNo) => {
    setClosePopupPrescription(true);
    axios
      .post(GET_PRESCRIPTION_ORDER_DETAILS, {
        diagnosticOrderId: orderId,
        phoneNo: '+91' + phoneNo,
      })
      .then(({ data }) => {
        setSelectedNonCartFlowOrder(data?.ordersList);
        setIsNonCartFlowActivated(true);
      })
      .catch((e) => console.error(e));
  };

  const isPrescription =
    order?.orderStatus === DIAGNOSTIC_ORDER_STATUS.NEW_LEAD;

  const isPhleboCheckIn =
    order?.orderStatus === DIAGNOSTIC_ORDER_STATUS.PHLEBO_CHECK_IN;

  const userHasPermissionToModifyOrder = userAccess?.length > 0;

  return (
    <div
      key={order.displayId}
      className={`${classes.root} ${classes.orderRow}`}
    >
      <div className={`${classes.topCol}`}>
        <div className={classes.cardSection1}>
          <div className={classes.orderListingId}>
            Order ID: #{order.displayId}
            <span>
              Booked on{' '}
              {order?.createdDate &&
                format(new Date(order?.createdDate), 'dd MMM, yyyy')}{' '}
              , {order?.createdTime}
            </span>
            <span>
              {order?.bookingSource}{' '}
              {order?.attributesObj?.agentLoginID !== 'NA' &&
                order?.attributesObj?.agentLoginID}
            </span>
          </div>
        </div>

        <div className={classes.cardSection2}>
          <span className={classes.itemName}>
            Tests for{' '}
            {!isPrescription &&
              `${order?.patientObj?.gender === 'MALE' ? 'Mr.' : 'Ms.'} 
              ${order?.patientObj?.firstName} 
              ${order?.patientObj?.lastName}`}{' '}
            :
          </span>
          {isPrescription && (
            <>
              <span className={classes.timing}>
                {`${order?.patientObj?.gender === 'MALE' ? 'Mr.' : 'Ms.'} ${
                  order?.patientObj?.firstName
                } ${order?.patientObj?.lastName}`}
              </span>
              <span>
                <div className={classes.prescriptionOrder}>
                  <div
                    className={classes.orderStatus}
                    onClick={() => placeNewOrder(order)}
                  >
                    PLACE ORDER
                  </div>
                  <div
                    className={classes.orderStatus}
                    onClick={() => handleNonCartOrderCancel(order)}
                  >
                    CANCEL ORDER
                  </div>
                </div>
              </span>
            </>
          )}
          {!isPrescription && (
            <div className={`${classes.testIncluded}`}>
              <ul>
                {order?.diagnosticOrderLineItems?.length > 0 &&
                  order.diagnosticOrderLineItems.map((orderItems: any) => (
                    <React.Fragment key={orderItems?.itemId}>
                      {!orderItems?.isRemoved && (
                        <li
                          key={orderItems?.itemId}
                          className={
                            !ordersShowingAllItems.includes(order?.displayId)
                              ? classes.hideList
                              : ''
                          }
                        >
                          {orderItems?.itemName}{' '}
                        </li>
                      )}
                    </React.Fragment>
                  ))}

                {order?.diagnosticOrderLineItems?.length > 2 && (
                  <>
                    {!ordersShowingAllItems.includes(order?.displayId) ? (
                      <span
                        onClick={() => {
                          const displayIds = [...ordersShowingAllItems];
                          displayIds.push(order.displayId);
                          setTimeout(() => {
                            setOrdersShowingAllItems(displayIds);
                          }, 0);
                        }}
                      >
                        + {order?.diagnosticOrderLineItems?.length - 2} More{' '}
                      </span>
                    ) : (
                      <span
                        onClick={() => {
                          const displayIds = [...ordersShowingAllItems];
                          const index = displayIds.findIndex(
                            (displayId) => displayId === order.displayId
                          );

                          index > -1 && displayIds.splice(index, 1);
                          setTimeout(() => {
                            setOrdersShowingAllItems(displayIds);
                          }, 0);
                        }}
                      >
                        Show less
                      </span>
                    )}
                  </>
                )}
              </ul>
            </div>
          )}
        </div>
        {!isPrescription && (
          <div className={classes.cardSection3}>
            <div className={classes.orderListingId}>Test Slot</div>
            <div className={classes.timing}>
              <div>{`${order?.slotStartTime} - ${order?.slotEndTime}`}</div>
              <div>
                {order?.slotDateTimeInUTC &&
                  format(new Date(order?.slotDateTimeInUTC), 'dd MMM, yyyy')}
              </div>
            </div>
          </div>
        )}
        <div className={classes.cardSection5}>
          <span className={classes.orderInfo}>
            {getDiagnosticStatus(order?.orderStatus)}
          </span>
        </div>
        <AphDialog open={showCancellationReasonDialog} maxWidth="sm">
          <AphDialogTitle className={classes.cancelTitle}>
            SELECT REASON FOR CANCELLATION
          </AphDialogTitle>
          <AphDialogClose
            onClick={() => setShowCancellationReasonDialog(false)}
            title={'close'}
          />
          <>
            {isCancellationLoading ? (
              <div className={classes.loader}>
                <CircularProgress size={32} />
              </div>
            ) : (
              <>
                <div className={classes.cancellationReasons}>
                  <ul>
                    {cancellationReasons?.map((reason, idx) => {
                      const isSelected = selectedReasonIndex === idx;
                      return (
                        <li
                          className={`${
                            isSelected ? classes.selectedReason : ''
                          }`}
                          onClick={() => setSelectedReasonIndex(idx)}
                          key={`reason-${idx}`}
                        >
                          <div>
                            <div>{reason}</div>
                            {isSelected ? (
                              <img
                                src={require('../images/ic_checkactive.png')}
                                alt=""
                              />
                            ) : (
                              <img
                                src={require('../images/ic_checkdefault.png')}
                                alt=""
                              />
                            )}
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
                {selectedReasonIndex === cancellationReasons?.length - 1 && (
                  <div className={classes.customReason}>
                    <AphTextField
                      onChange={(e) => {
                        setCancellationReason(e.target.value);
                      }}
                      value={cancellationReason}
                      label="Reason"
                      placeholder="Enter your reason here"
                    />
                  </div>
                )}
                <div className={classes.footer}>
                  <AphButton
                    onClick={() => {
                      submitCancelOrder();
                    }}
                    disabled={
                      selectedReasonIndex < 0 ||
                      (selectedReasonIndex ===
                        cancellationReasons?.length - 1 &&
                        cancellationReason === '')
                    }
                    color="primary"
                  >
                    SUBMIT
                  </AphButton>
                </div>
              </>
            )}
          </>
        </AphDialog>
        <AphDialog open={showOrderCancelDialog} maxWidth="sm">
          <AphDialogTitle className={classes.cancelTitle}>
            ORDER CANCELLED
          </AphDialogTitle>
          <AphDialogClose
            onClick={() => handleOrderCancelSuccess()}
            title={'close'}
          />
          <div className={classes.orderCancelled}>
            <img src={require('../images/ic_success.png')} alt="" />
            <div>{DIAG_NON_CART_FLOW_MESSAGES?.orderCancel}</div>
            <p>{`Cancelled on ${formatDate(
              new Date(),
              'MMMM, yyyy | hh:mm a'
            )}`}</p>
            <AphButton
              onClick={() => handleOrderCancelSuccess()}
              color="primary"
            >
              DONE
            </AphButton>
          </div>
        </AphDialog>
      </div>
      {!isPrescription && !isPhleboCheckIn && (
        <div className={classes.actions}>
          <AphButton
            disabled={
              !userAccess?.includes(DIAGNOSTIC_USER_ACCESS.CANCEL_ORDER) ||
              !order?.enableEdit
            }
            onClick={() => {
              if (
                userAccess?.includes(DIAGNOSTIC_USER_ACCESS.CANCEL_ORDER) &&
                order?.enableEdit
              ) {
                handleOrderCancel(order);
              }
            }}
          >
            Cancel Order
          </AphButton>
          <AphButton
            disabled={!userHasPermissionToModifyOrder || !order?.enableEdit}
            onClick={() => {
              if (userHasPermissionToModifyOrder && order?.enableEdit) {
                handleModifyOrder(order);
              }
            }}
          >
            Modify Order
          </AphButton>
        </div>
      )}
    </div>
  );
};

export default OrderCard;
