import React from 'react';
import Dashboard from './Dashboard';
interface HomeProtectedInterface {
  userRole: string;
  userName: string;
}

const HomeProtected: React.FC<HomeProtectedInterface> = (props) => {
  return (
    <>
      <Dashboard userName={props.userName} />
    </>
  );
};

export default HomeProtected;
