export enum DIAGNOSTIC_ORDER_STATUS {
  CANCELLATION_REQUESTED = 'CANCELLATION_REQUESTED',
  DEPT_RECEIVED = 'DEPT_RECEIVED',
  ORDER_CANCELLED = 'ORDER_CANCELLED',
  ORDER_CANCELLED_REQUEST = 'ORDER_CANCELLED_REQUEST',
  ORDER_COMPLETED = 'ORDER_COMPLETED',
  ORDER_FAILED = 'ORDER_FAILED',
  ORDER_INITIATED = 'ORDER_INITIATED',
  ORDER_MODIFIED = 'ORDER_MODIFIED',
  ORDER_PLACED = 'ORDER_PLACED',
  ORDER_RESCHEDULED = 'ORDER_RESCHEDULED',
  ORDER_RESCHEDULED_REQUEST = 'ORDER_RESCHEDULED_REQUEST',
  PARTIAL_DEPT_RECEIVED = 'PARTIAL_DEPT_RECEIVED',
  PARTIAL_ORDER_COMPLETED = 'PARTIAL_ORDER_COMPLETED',
  PAYMENT_FAILED = 'PAYMENT_FAILED',
  PAYMENT_PENDING = 'PAYMENT_PENDING',
  PAYMENT_SUCCESSFUL = 'PAYMENT_SUCCESSFUL',
  PHLEBO_CHECK_IN = 'PHLEBO_CHECK_IN',
  PHLEBO_COMPLETED = 'PHLEBO_COMPLETED',
  PICKUP_CONFIRMED = 'PICKUP_CONFIRMED',
  PICKUP_REQUESTED = 'PICKUP_REQUESTED',
  REFUND_INITIATED = 'REFUND_INITIATED',
  REFUND_REQUEST_INITIATED = 'REFUND_REQUEST_INITIATED',
  REPORT_GENERATED = 'REPORT_GENERATED',
  RESAMPLE_INITIATED = 'RESAMPLE_INITIATED',
  SAMPLE_COLLECTED = 'SAMPLE_COLLECTED',
  SAMPLE_COLLECTED_IN_LAB = 'SAMPLE_COLLECTED_IN_LAB',
  SAMPLE_NOT_COLLECTED_IN_LAB = 'SAMPLE_NOT_COLLECTED_IN_LAB',
  SAMPLE_RECEIVED_IN_LAB = 'SAMPLE_RECEIVED_IN_LAB',
  SAMPLE_REJECTED_IN_LAB = 'SAMPLE_REJECTED_IN_LAB',
  SAMPLE_SUBMITTED = 'SAMPLE_SUBMITTED',
  SAMPLE_TESTED = 'SAMPLE_TESTED',
  NEW_LEAD = 'NEW_LEAD',
  LEAD_DROPPED = 'LEAD_DROPPED',
}

export enum DIAGNOSTIC_ORDER_PAYMENT_TYPE {
  COD = 'COD',
  ONLINE_PAYMENT = 'ONLINE_PAYMENT',
}

export enum DIAGNOSTICS_GROUPPLAN {
  ALL = 'ALL',
  CIRCLE = 'CIRCLE',
  SPECIALDISCOUNTS = 'SPECIALDISCOUNTS',
}

export enum CASHBACK_TYPE {
  ABSOLUTE = 'ABSOLUTE',
  PERCENT = 'PERCENT',
}

export enum DIAGNOSTICS_TYPE {
  PACKAGE = 'PACKAGE',
  TEST = 'TEST',
}

export enum TEST_COLLECTION_TYPE {
  CENTER = 'CENTER',
  HC = 'HC',
}

export enum Gender {
  FEMALE = 'FEMALE',
  MALE = 'MALE',
  OTHER = 'OTHER',
}

export enum Relation {
  BROTHER = 'BROTHER',
  COUSIN = 'COUSIN',
  DAUGHTER = 'DAUGHTER',
  FATHER = 'FATHER',
  GRANDDAUGHTER = 'GRANDDAUGHTER',
  GRANDFATHER = 'GRANDFATHER',
  GRANDMOTHER = 'GRANDMOTHER',
  GRANDSON = 'GRANDSON',
  HUSBAND = 'HUSBAND',
  ME = 'ME',
  MOTHER = 'MOTHER',
  OTHER = 'OTHER',
  SISTER = 'SISTER',
  SON = 'SON',
  WIFE = 'WIFE',
}

export interface phleboChargesV2 {
  additionalCharges?: number | null;
  additionalChargesMessage?: string | null;
  collectionCharges?: number | null;
  couponDiscApplied?: boolean | null;
  isCircleBenefits?: boolean | null;
  isPackageBenefits?: boolean | null;
  paidSlotMessage?: string | null;
  slashedAdditionalCharges?: number | null;
  slashedCollectionCharges?: number | null;
}
